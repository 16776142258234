import http from "../../http-common";

class ProgramDataService {
  getAll() {
    return http.get("/programs");
  }

  get(id) {
    return http.get(`/programs/${id}`);
  }

  create(data) {
    return http.post("/programs", data);
  }

  update(id, data) {
    return http.put(`/programs/${id}`, data);
  }

  delete(id) {
    return http.delete(`/programs/${id}`);
  }

  deleteAll() {
    return http.delete("/programs");
  }

  findByCode(program_code) {
    return http.get(`/programs?program_code=${program_code}`);
  }

  findAllByProgramCategory(program_category) {
    return http.get(`/programs/findAllByProgramCategory`, {
      params: { program_category }
    });
  }

  // Find programs by both category and code
  findByProgramCategoryAndProgramCode(program_category, program_code) {
    return http.get(`/programs/findByProgramCategoryAndProgramCode`, {
      params: { program_category, program_code }
    });
  }
}

export default new ProgramDataService();