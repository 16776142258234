import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Alert, Table } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../../Navbar/Navbar.js';
import UserForm from './UserForm';
import TransactionForm from './TransactionForm';
import SanginiDataService from './../../services/sangini_details.service.js';
import VillageDataService from './../../services/village.service.js';
import SanginiDropdown from './SanginiDropdown.js';
import './../../../styles/forms/programPlanningData/ProgramPlanningData.css';
import './../../../styles/forms/sanginiBookletData/SanginiBooklet.css';
import SanginiUserDetailsDataService from './../../services/sangini_user_details.service.js';
import SanginiUserDropdown from './SanginiUserDropdown.js';
import AddUserPopup from './AddUserPopup.js';
import { useSelector } from 'react-redux';


function SanginiBooklet() {

    const { user: currentUser } = useSelector((state) => state.auth);
    useEffect(() => {
        if (currentUser) {
            console.log(currentUser);
        }

        const fetchSanginiData = async () => {
            if (currentUser && currentUser.role_id === 8) {
                try {
                    const sanginiResponse = await getSanginiData(currentUser.sangini_id);
                    console.log(sanginiResponse.data);
                    let village = "";
                    let block = "";
                    let district = "";
                    let state = "";

                    if (sanginiResponse.data.village_id) {
                        const villageResponse = await getVillageDataById(sanginiResponse.data.village_id);

                        if (villageResponse && villageResponse.data) {
                            village = villageResponse.data.village_name || '';
                            block = villageResponse.data.block || '';
                            district = villageResponse.data.district || '';
                            state = villageResponse.data.state || '';
                        }
                    }

                    setSanginiData({
                        village: village,
                        block: block,
                        district: district,
                        state: state,
                        sanginiId: sanginiResponse.data.sangini_id,
                    });
                } catch (error) {
                    console.error('Error fetching sangini data:', error);
                }
            }
        };

        fetchSanginiData();
    }, [currentUser]);




    const today = new Date();
    const defaultDate = today.toISOString().slice(0, 10); // Format as YYYY-MM-DD

    const [showUserForm, setShowUserForm] = useState(false);


    const [sanginiData, setSanginiData] = useState({
        village: '',
        block: '',
        district: '',
        state: '',
        sanginiId: '',
    })


    const [userData, setUserData] = useState({
        user_id: '',
        sangini_id: '',
        user_name: '',
        dob: '',
        mobileno: '',
        village_id: '',
        feedback: '',
        pad_cloth: '',
        brand_of_pad: '',
        date_user_added: defaultDate,
        is_educated_flag: '',
        feedback_rating_from_user: 0,
        feedback_remark: ''
    })


    const [errors, setErrors] = useState([]);
    const [message, setMessage] = useState('');

    const getSanginiData = async (sanginiId) => {
        try {
            const response = await SanginiDataService.get(sanginiId);
            console.log(response);
            return response;
        } catch (error) {
            console.log("Error fetching sangini data", error);
            setMessage("Error fetching sangini data");
            return null;
        }
    }

    const getVillageDataById = async (villageId) => {
        try {
            console.log(villageId);
            const response = await VillageDataService.get(villageId);
            console.log(response.data);
            return response;
        } catch (error) {
            console.log("Error fetching village details", error);
            setMessage("Error fetching village details");
            return null;
        }
    }

    const handleSanginiChange = async (e) => {
        setShowUserForm(false);
        const sanginiId = e.target.value;
        let sanginiResponse = null;

        if (sanginiId !== null && sanginiId !== '') {
            sanginiResponse = await getSanginiData(sanginiId);
        }


        if (sanginiResponse && sanginiResponse.data) {

            let village = "";
            let block = "";
            let district = "";
            let state = "";

            if (sanginiResponse.data.village_id) {
                const villageResponse = await getVillageDataById(sanginiResponse.data.village_id);

                if (villageResponse && villageResponse.data) {
                    village = villageResponse.data.village_name || '';
                    block = villageResponse.data.block || '';
                    district = villageResponse.data.district || '';
                    state = villageResponse.data.state || '';
                }
            }

            setSanginiData({
                ...sanginiData,
                village: village,
                block: block,
                district: district,
                state: state,
                sanginiId: sanginiResponse.data.sangini_id
            });
        }
    }


    const getUserData = async (userId) => {
        try {
            const response = await SanginiUserDetailsDataService.findByUserId(userId);
            console.log(response);
            return response;
        } catch (error) {
            console.log("Error fetching user data", error);
            setMessage("Error fetching user data");
            return null;
        }
    }


    const handleUserChange = async (e) => {
        const userId = e.target.value;

        setUserData(prevData => ({
            ...prevData,
            user_id: userId
        }));


        if (userId) { // Check if userId is neither null nor an empty string
            try {
                const userResponse = await getUserData(userId);
                if (userResponse && userResponse.data) {
                    setUserData(userResponse.data);
                    console.log("userData", userResponse);
                    setShowUserForm(userResponse.data[0].user_id);
                } else {
                    setShowUserForm(false); // Hide form if no data is returned
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                setMessage("Error fetching user data");
                setShowUserForm(false);
            }
        } else {
            setShowUserForm(false);
        }
    }



    const [addUser, setAddUser] = useState(false);
    const handleAdd = () => {
        setAddUser(true);
    }

    const [viewUser, setViewUser] = useState(false);

    const handleView = () => {
        setViewUser(true);
    }


    const handleClose = () => {
        setAddUser(false);
        setViewUser(false);
    }


    return (
        <Container className='sangini-booklet-page'>

            <Form id='sangini-booklet-form'>
                {errors.length > 0 && <Alert className='alert' variant="danger">{errors[0]}</Alert>}
                {message && <Alert variant="info">{message}</Alert>}
                <h2>Sangini Booklet</h2>

                <hr className='line-break' />
                <h3>Sangini Details</h3>

                {/* ************************************************************************************* */}
                <Container className='input-field-multiple'>

                    <Form.Group id="sangini-id" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Sangini Name & Mobile No:</Form.Label>
                        <SanginiDropdown
                            onChange={handleSanginiChange}
                            value={sanginiData.sanginiId}
                            name="sanginiId"
                            currentUser={currentUser}
                        />
                    </Form.Group>

                    <Form.Group id="sangini-village" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Village - Block - District - State:</Form.Label>
                        <Form.Control
                            type="text"
                            name="village"
                            value={`${sanginiData.village} - ${sanginiData.block} - ${sanginiData.district} - ${sanginiData.state}`}
                            readOnly
                            className="input"
                        />
                    </Form.Group>
                </Container>

                {/* ************************************************************************************* */}

                {
                    sanginiData.sanginiId !== '' &&
                    <>

                        <Container className='input-field-multiple add-user-btn-height-change'>
                            <Form.Group id="username_mobileno" className='input-field-single-column input-field-backgroud'>
                                <Form.Label>User Name & Mobile No:</Form.Label>
                                <SanginiUserDropdown
                                    sanginiId={sanginiData.sanginiId}
                                    onChange={handleUserChange}
                                    value={userData.user_id}
                                    name="user_id"
                                    refresh={addUser}
                                    refresh2={viewUser}
                                />
                            </Form.Group>

                            <Button className='program-planning-custom-btn change-width-btn' onClick={handleAdd}>Add New User</Button>
                        </Container>
                    </>
                }



                {
                    addUser &&
                    <AddUserPopup
                        sanginiId={sanginiData.sanginiId}
                        show={addUser}
                        handleClose={handleClose}
                    />
                }




                {/* ************************************************************************************* */}

                {
                    showUserForm &&
                    <UserForm
                        userData={userData}
                        viewUser={viewUser}
                        handleView={handleView}
                        handleClose={handleClose}
                    />
                }

                {/* ************************************************************************************* */}
                {
                    showUserForm &&
                    <TransactionForm
                        userData={userData}
                        sanginiId={sanginiData.sanginiId}
                        currentUser={currentUser}
                    />
                }


            </Form>
        </Container>
    );
}

export default SanginiBooklet;
