
import React, { useState,useEffect } from 'react';
import { Form, Container, Col, Button, Row, Alert } from 'react-bootstrap';
import PartnerDetailsDropdown from './PartnerDetailsDropdown';
import ProgramDetailsDropdown from './ProgramDetailsDropdown';
import './../../styles/Report.css';
import './../../styles/App.css';
import ReportGrid from './ReportGrid';
import ReportDataService from "../services/report.service";


function ExecutiveKPIReport() {


  //Static Data Attributes
  const [staticFormData, setStaticFormData] = useState(
    {
        reportType: '',
        fromDate: '',
        toDate:'',
        programCode: [],
        partnerCode: [],
    }
  );


  const [programDetails, setProgramDetails] = useState([]);
  const [partnerDetails, setPartnerDetails] = useState([]);


  const handleProgramChange = async (e) => {
    let arrProgram=[];
    var i;
    staticFormData.programCode=[];
    for (i=0; i<e.target.length; i++) { 
        //alert(count);
        if (e.target.options[i].selected) {
          arrProgram.push(e.target.options[i].value);
           // alert("inside:",e.target.options[i].value);
            //selectedArray[count] = e.target.options[i].value;
            //setProgramList([...programList, e.target.options[i].value]);
           
        } 
    }
    
    setStaticFormData({
      ...staticFormData,
      //programCode: program_code
      programCode : [...staticFormData.programCode,arrProgram]
    })
   
  }

  const handlePartnerChange = async (e) => {
    let arrPartner=[];
    staticFormData.partnerCode=[];
    //alert("inside3");
    var i;
    for (i=0; i<e.target.length; i++) { 
        //alert(count);
        if (e.target.options[i].selected) {
        arrPartner.push(e.target.options[i].value);
       
        }
    }

    setStaticFormData({
      ...staticFormData,
      partnerCode : [...staticFormData.partnerCode,arrPartner]
      })
  }

  const handleReportChange = async (e) => {
    let report_val = e.target.options[e.target.selectedIndex].value;
    setStaticFormData({
      ...staticFormData,
      reportType: report_val
    })
  }

  const handleStaticDataChange = (e) => {
    const { name, value } = e.target;
    setStaticFormData({
      ...staticFormData,
      [name]: value
    });
    // console.log("Static data", staticFormData);
  };



  


  //Error Handling and Validations
  const [errors, setErrors] = useState([]);

  const [message, setMessage] = useState('');



  const validateStaticData = () => {
    let validMsg = "";

    if (!staticFormData.reportType || staticFormData.reportType==='') {
        let error = 'Please Select Report Type.';
        setErrors({
          ...errors, error
        })
         //console.log(error);
         validMsg = error;
         return validMsg;
    }

    if (!staticFormData.fromDate || staticFormData.fromDate==='' || !staticFormData.toDate || staticFormData.toDate==='') {
      let error = 'Please Select from and to date to View Report';
      setErrors({
        ...errors, error
      })
      //console.log(error);
      validMsg = error;
      return validMsg;
    }



      if (staticFormData.fromDate ||  staticFormData.toDateDate) {
        let error = ""
        //console.log(error);
        validMsg = error;
      }


       
      console.log("validMsg",validMsg);
    return validMsg;
  };


  
  const validateGenStaticData = () => {
    let validMsg = "";
    if (!staticFormData.reportType || staticFormData.reportType==='') {
        let error = 'Please Select Report Type.';
        setErrors({
          ...errors, error
        })
         //console.log(error);
         validMsg = error;
         return validMsg;
    }

    if (!staticFormData.fromDate || staticFormData.fromDate==='' || !staticFormData.toDate || staticFormData.toDate==='') {
        let error = 'Please Select from and to date to View Report';
      setErrors({
        ...errors, error
      })
      //console.log(error);
      validMsg = error;
      return validMsg;
    }

    if (staticFormData.fromDate ||  staticFormData.toDateDate) {
        let error = ""
        //console.log(error);
        validMsg = error;
      }
      console.log("validMsg",validMsg);
    return validMsg;
  };



  const [rowData, setRowData] = useState([]);

  const [colDefs, setColDefs] = useState([]);


  useEffect(() => {
    const fetchRows = async () => {
        var dynamicFilters="";
       
        //ReportDataService.getPlannedImpReport(dynamicFilters)
        //.then(function (response) {
           // console.log("recieved data")
           // console.log(response.data)
            //setRowData(response.data);
            
            
          //})
          //.catch(e => {
          //  console.log(e);
          //})
      
    };
    fetchRows();
  }, []);

  const reset=()=> {
    //alert("inside reset");
    document.getElementById("report-form").reset();
    setMessage('');
    setStaticFormData({
        ...staticFormData,
        reportType: '',
        fromDate: "",
        toDate:"",
        programCode: [],
        partnerCode: []
      })
      window.location.reload();
      let noDataRow=[];
      setRowData(noDataRow);
    
  }

  const generateReport=()=>{

    let noDataRow=[];
    setRowData(noDataRow);
    let finalValidMsg=validateGenStaticData();
    //console.log("final")
   
    if(finalValidMsg==="")
    {
    setMessage("");
    
    

    staticFormData.programCode.map( function(obj){
        if(obj == ''){
            staticFormData.programCode=[];
        }
    });

    staticFormData.partnerCode.map( function(obj){
        if(obj == ''){
            staticFormData.partnerCode=[];
        }
    });

    let dynamicReportType=staticFormData.reportType===''?'NULL':staticFormData.reportType;
    let dynamicDateFrom=staticFormData.fromDate===''?'NULL':staticFormData.fromDate;
    let dynamicDateTo=staticFormData.toDate===''?'NULL':staticFormData.toDate;
    let dynamicPartnerCode=staticFormData.partnerCode.length===0?'NULL':staticFormData.partnerCode;
    let dynamicProgramCode=staticFormData.programCode.length===0?'NULL':staticFormData.programCode;
  
  var dynamicFilters="'"+dynamicReportType+"'"+"|"+"'"+dynamicDateFrom+"'"+"|"+"'"+dynamicDateTo+"'"+"|"+"'"+dynamicProgramCode+"'"+"|"+"'"+dynamicPartnerCode+"'";
  
  console.log("dynamicFilters:"+dynamicFilters);
    
  if(dynamicReportType === "1")
    {
        handleProductivityReport();
    }
    if(dynamicReportType === "2")
    {
        handleCollaborationReport();
    }
    if(dynamicReportType === "3")
    {
        handleInnovationReport();
    }
    if(dynamicReportType === "4")
    {
        handleQualityReport();
    }
    if(dynamicReportType === "5")
    {
        handleFeedbackReport();
    }

    ReportDataService.getExecutiveKPIReport(dynamicFilters)
    .then(function (response) {
        console.log(response.data)
        if(response.data===null || response.data==='')
        {
            console.log("No data");

        //   ReportDataService.putExecutiveKPIReport(dynamicFilters)
        //   .then(response => {
        //     console.log("Sangini KPI Report Generated");
        //     setMessage('Validation Success: Report Generated Successfully');
        //   })
        //   .catch(e => {
        //     console.log(e);
        //   });
        }
        else
        {
          console.log("data Exist");
        setRowData(response.data);
         // setMessage('Validation Success: Report Already Generated');
        }
        })
        .catch(e => {
          console.log(e);
        })
    
}
else
{
    
    setMessage('Validation Failed: '+ finalValidMsg);
}

  }

  

  const handleSubmit = async (e) => {
    let finalValidMsg=validateStaticData();
    //console.log("final")
    e.preventDefault();

    if(finalValidMsg==="")
    {
    setMessage("");
    

    staticFormData.programCode.map( function(obj){
        if(obj == ''){
            staticFormData.programCode=[];
        }
    });

    staticFormData.partnerCode.map( function(obj){
        if(obj == ''){
            staticFormData.partnerCode=[];
        }
    });


    let dynamicReportType=staticFormData.reportType===''?'NULL':staticFormData.reportType;
    let dynamicDateFrom=staticFormData.fromDate===''?'NULL':staticFormData.fromDate;
    let dynamicDateTo=staticFormData.toDate===''?'NULL':staticFormData.toDate;
    let dynamicPartnerCode=staticFormData.partnerCode.length===0?'NULL':staticFormData.partnerCode;
    let dynamicProgramCode=staticFormData.programCode.length===0?'NULL':staticFormData.programCode;
  
    var dynamicFilters="'"+dynamicReportType+"'"+"|"+"'"+dynamicDateFrom+"'"+"|"+"'"+dynamicDateTo+"'"+"|"+"'"+dynamicProgramCode+"'"+"|"+"'"+dynamicPartnerCode+"'";

    
    console.log("dynamicFilters:"+dynamicFilters);

        if(dynamicReportType === "1")
        {
            handleProductivityReport();
        }
        if(dynamicReportType === "2")
        {
            handleCollaborationReport();
        }
        if(dynamicReportType === "3")
        {
            handleInnovationReport();
        }
        if(dynamicReportType === "4")
          {
              handleQualityReport();
          }
        if(dynamicReportType === "5")
        {
            handleFeedbackReport();
        }
    
}
else
{
    setMessage('Validation Failed: '+ finalValidMsg);
}

  };


  const handleProductivityReport = () => { 
    let reportColumns=[
        { headerName: "Program Executive Name",field: "program_executive_name" ,width: "200px",tooltipField: "program_executive_name",headerTooltip: "Program Executive Name"},
        { headerName: "No. of Programs Planned",field: "programs_planned" ,width: "200px",tooltipField: "programs_planned",headerTooltip: "No. of Programs Planned"},
        { headerName: "No. of Programs Implemented",field: "programs_executed" ,width: "200px",tooltipField: "programs_executed",headerTooltip: "No. of Programs Implemented"},
        { headerName: "% Programs",field: "percentage_programs" ,width: "200px",tooltipField: "percentage_programs",headerTooltip: "Percentage"},
        { headerName: "Programs Score", field: "executive_score" ,width: "200px",tooltipField: "executive_score",headerTooltip: "Score"},
        { headerName: "No. of People Impacted Planned",field: "planned_impact" ,width: "200px",tooltipField: "planned_impact",headerTooltip: "No. of People Impacted Planned"},
        { headerName: "No. of People Impacted Implemented",field: "executed_impact" ,width: "200px",tooltipField: "executed_impact",headerTooltip: "No. of People Impacted Implemented"},
        { headerName: "% Impact",field: "percentage_impact" ,width: "200px",tooltipField: "percentage_impact",headerTooltip: "Percentage Impact"},
        { headerName: "Impact Score",field: "impact_score" ,width: "200px",tooltipField: "impact_score",headerTooltip: "Impact Score"},
        { headerName: "Amount Planned per Person",field: "planned_budget_per_person" ,width: "200px",tooltipField: "planned_budget_per_person",headerTooltip: "Amount Planned per Person"},
        { headerName: "Amount Spent per Person",field: "impacted_budget_per_person" ,width: "200px",tooltipField: "impacted_budget_per_person",headerTooltip: "Amount Spent per Person"},
        { headerName: "% Budget",field: "percentage_budget" ,width: "200px",tooltipField: "percentage_budget",headerTooltip: "Percentage Budget"},
        { headerName: "Budget Score",field: "budget_score" ,width: "200px",tooltipField: "budget_score",headerTooltip: "Budget Score"},
  ];
  setColDefs(reportColumns);
}


const handleCollaborationReport = () => { 
    let reportColumns=[
        { headerName: "Program Executive Name",field: "program_executive_name" ,width: "200px",tooltipField: "program_executive_name",headerTooltip: "Program Executive Name"},
        { headerName: "No. of Volunteers involved in DFT Programs",field: "no_vol_involved" ,width: "200px",tooltipField: "no_vol_involved",headerTooltip: "No. of Volunteers involved in DFT Programs"},
        { headerName: "No. of Hours utilized for DFT Programs",field: "hrs_spent" ,width: "200px",tooltipField: "hrs_spent",headerTooltip: "No. of Hours utilized for DFT Programs"},
        { headerName: "No. of Buildings and Grounds utilized for DFT Programs",field: "infra" ,width: "200px",tooltipField: "infra",headerTooltip: "No. of Buildings and Grounds utilized for DFT Programs"},
        { headerName: "Total No. of Programs",field: "no_of_programs" ,width: "200px",tooltipField: "no_of_programs",headerTooltip: "Total No. of Programs"},
        { headerName: "No. of Programs with Collaboration of NGO's for Volunteer & In-Kind Donations", field: "ngo_collab_count" ,width: "200px",tooltipField: "ngo_collab_count",headerTooltip: "No. of Programs with Collaboration of NGO's for Volunteer & In-Kind Donations"},
        { headerName: "NGO Collaboration Percentage",field: "ngo_collab_per" ,width: "200px",tooltipField: "ngo_collab_per",headerTooltip: "Percentage"},
        { headerName: "NGO Collaboration Score",field: "ngo_collab_rating" ,width: "200px",tooltipField: "ngo_collab_rating",headerTooltip: "Collaboration Score"},
        { headerName: "No. of Programs with Collaboration of Administration",field: "admin_collab_count" ,width: "200px",tooltipField: "admin_collab_count",headerTooltip: "No. of Programs with Collaboration of Administration"},
        { headerName: "Administration Collaboration Percentage",field: "admin_collab_per" ,width: "200px",tooltipField: "admin_collab_per",headerTooltip: "Administration Collaboration Percentage"},
       // { headerName: "Administration Collaboration Score",field: "admin_collab_rating" ,width: "200px",tooltipField: "admin_collab_rating",headerTooltip: "Administration Collaboration Score"},
 ];
  setColDefs(reportColumns);
}

const handleInnovationReport = () => { 
    let reportColumns=[
        { headerName: "Program Executive Name",field: "program_executive_name" ,width: "200px",tooltipField: "program_executive_name",headerTooltip: "Program Executive Name"},
        { headerName: "Total In-Kind Donations",field: "total_inkind_donations" ,width: "200px",tooltipField: "total_inkind_donations",headerTooltip: "Total In-Kind Donations"},
        { headerName: "Total Expense",field: "total_expense" ,width: "200px",tooltipField: "total_expense",headerTooltip: "Total Expense"},
        { headerName: "% In-Kind Donations",field: "percentage_inkind_donations" ,width: "200px",tooltipField: "percentage_inkind_donations",headerTooltip: "%"},
        { headerName: "Score",field: "donations_performance" ,width: "200px",tooltipField: "donations_performance",headerTooltip: "Score"},
      ];
  setColDefs(reportColumns);
}


const handleQualityReport = () => { 
  let reportColumns=[
      { headerName: "Program Executive Name",field: "program_executive_name" ,width: "200px",tooltipField: "program_executive_name",headerTooltip: "Program Executive Name"},
      { headerName: "Total no. of Entries",field: "no_of_entries" ,width: "200px",tooltipField: "no_of_entries",headerTooltip: "Total no. of Entries"},
      { headerName: "No. of entries on time",field: "entries_on_time" ,width: "200px",tooltipField: "entries_on_time",headerTooltip: "No. of entries on time"},
      { headerName: "% entries on time",field: "entries_on_time_percent" ,width: "200px",tooltipField: "entries_on_time_percent",headerTooltip: "%"},
      { headerName: "Score",field: "entries_score" ,width: "200px",tooltipField: "entries_score",headerTooltip: "Score"},
      { headerName: "No. of entries updated by senior",field: "entries_updated_by_senior" ,width: "200px",tooltipField: "entries_updated_by_senior",headerTooltip: "No. of entries updated by senior"},
      { headerName: "% of entries updated by Senior",field: "entries_updated_by_senior_percent" ,width: "200px",tooltipField: "entries_updated_by_senior_percent",headerTooltip: "5 of entries updated by Senior"},
      { headerName: "Score for entries updated by Senior",field: "senior_update_score" ,width: "200px",tooltipField: "senior_update_score",headerTooltip: "Score for entries updated by Senior"},
    ];
  setColDefs(reportColumns);
}
const handleFeedbackReport = () => { 
    let reportColumns=[
        { headerName: "Program Executive Name",field: "program_executive_name" ,width: "200px",tooltipField: "program_executive_name",headerTooltip: "Program Executive Name"},
        { headerName: "Avg Rating",field: "average_feedback" ,tooltipField: "rating",headerTooltip: "Avg Rating"},
        { headerName: "Score",field: "fb_score" ,tooltipField: "score",headerTooltip: "Score"},
      ];
  setColDefs(reportColumns);
}

  return (

    <Container className='report-page'>
      <Form id='report-form' onSubmit={handleSubmit} >
      
        {
          message && <Alert variant="info">{message}</Alert>
        }
        
        <Container className='input-grid'>
        <Container className='input-field-multiple'>
          <Form.Group id="header-text" className='header-input-field-single-row input-field-backgroud'>
              <Form.Label className='report-header'>Executive Performance Report</Form.Label>
            
            </Form.Group>

          </Container>

          <Container className='input-field-multiple'>
          <Form.Group id="reportType" className='input-field-single-column input-field-backgroud'>
            <Form.Label>Report Type:</Form.Label>
            <Form.Control className='input' as="select" name="reportType" onChange={handleReportChange}>
                <option value="">Select Report Type</option>
                <option value="1">Productivity</option>
                <option value="2">Collaboration</option>
                <option value="3">Innovation</option>
                <option value="4">Quality</option>
                <option value="5">Feedback</option>
              
            </Form.Control>
    
            </Form.Group>
          <Form.Group id="from-date" className='input-field-single-row input-field-backgroud'>
              <Form.Label>From Date:</Form.Label>
              <Form.Control
                type="date"
                name="fromDate"
                value={staticFormData.fromDate}
                onChange={handleStaticDataChange}
                className="input" />
            </Form.Group>

            <Form.Group id="to-date" className='input-field-single-row input-field-backgroud'>
              <Form.Label>To Date:</Form.Label>
              <Form.Control
                type="date"
                name="toDate"
                value={staticFormData.toDate}
                onChange={handleStaticDataChange}
                className="input" />
            </Form.Group>

            <Form.Group id="program-code" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Program Code/Name:</Form.Label>
              <ProgramDetailsDropdown
                name="programCode"
                onChange={handleProgramChange}
                programDetails={programDetails}
                setProgramDetails={setProgramDetails}
              />
            </Form.Group>


            <Form.Group id="partner-code" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Partner Code/Name:</Form.Label>
              <PartnerDetailsDropdown
                name="partnerCode"
                onChange={handlePartnerChange}
                partnerDetails={partnerDetails}
                setPartnerDetails={setPartnerDetails}
              />
            </Form.Group>
          </Container>
        </Container>

    <Container>
        <Form.Group id="planned-pgm-status" className='input-field-single-row input-field-backgroud'>
        <Button variant="primary" type="button" className="custom-btn"onClick={() => generateReport()}>View Report</Button>
        <Button type="button" className="custom-btn" onClick={() => reset()}>Reset</Button>
       </Form.Group>
    </Container>
       <ReportGrid rowData={rowData} colDefs={colDefs}/>
      </Form>
    </Container>
    
  );
}

export default ExecutiveKPIReport;