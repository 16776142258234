import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Form, Button, Alert, Spinner } from 'react-bootstrap';
import './Profile.css';
import http from '../../http-common';

const Profile = () => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [name, setName] = useState(currentUser.name);
    const [email, setEmail] = useState(currentUser.email);
    const [role, setRole] = useState('');
    const [memberSince, setMemberSince] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState([]);

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return 'Invalid Date';
        }
        return date.toISOString().split('T')[0]; // This will give us YYYY-MM-DD
    };

    useEffect(() => {
        const interceptor = http.interceptors.request.use(
            (config) => {
                if (currentUser?.accessToken) {
                    config.headers['x-access-token'] = currentUser.accessToken;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        setName(currentUser.name);
        setEmail(currentUser.email);
        setMemberSince(formatDate(currentUser.created_at));

        const fetchRoles = async () => {
            try {
                const response = await http.get('/test/getroles');
                setRoles(response.data);
                const userRole = response.data.find(r => r.role_id === currentUser.role_id);
                if (userRole) {
                    setRole(userRole.role_name);
                } else {
                    setRole(`Role ID: ${currentUser.role_id}`);
                }
            } catch (error) {
                setRole(`Error fetching role (ID: ${currentUser.role_id})`);
            }
        };

        fetchRoles();

        return () => {
            http.interceptors.request.eject(interceptor);
        };
    }, [currentUser]);

    const handleSaveChanges = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrors({});
        try {
            const response = await http.patch('/users/profile', {
                name,
                email,
            });
            setSuccessMessage('Profile updated successfully');
            dispatch({ type: 'UPDATE_USER', payload: response.data.user });
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000);
        } catch (error) {
            setErrors(error.response?.data || { message: 'An error occurred' });
        } finally {
            setLoading(false);
        }
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();
        setErrors({});

        if (newPassword !== confirmPassword) {
            setErrors({ confirm_password: "New password and confirm password do not match" });
            return;
        }

        setLoading(true);
        try {
            await http.put('/auth/changepassword', {
                old_password: currentPassword,
                new_password: newPassword,
            });
            setSuccessMessage('Password changed successfully');
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000);
        } catch (error) {
            setErrors(error.response?.data || { message: 'An error occurred' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container className="profile-container">
            <div className="profile-card">
                <h2 className="profile-header">Profile</h2>
                <Form onSubmit={handleSaveChanges}>
                    <Form.Group className="form-group">
                        <Form.Label>Name:</Form.Label>
                        <Form.Control
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        {errors.name && <Alert variant="danger">{errors.name}</Alert>}
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Label>Email:</Form.Label>
                        <Form.Control
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {errors.email && <Alert variant="danger">{errors.email}</Alert>}
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Label>Role:</Form.Label>
                        <Form.Control type="text" value={role || `Loading... (ID: ${currentUser.role_id})`} disabled />
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Label>Member Since:</Form.Label>
                        <Form.Control type="text" value={memberSince} disabled />
                    </Form.Group>
                    <Button type="submit" className="profile-custom-btn" disabled={loading}>
                        {loading ? <><Spinner animation="border" size="sm" /> Saving...</> : 'Save Changes'}
                    </Button>
                </Form>

                <h2 className="profile-header">Change Password</h2>
                <Form onSubmit={handleChangePassword}>
                    <Form.Group className="form-group">
                        <Form.Label>Current Password:</Form.Label>
                        <Form.Control
                            type="password"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                        {errors.old_password && <Alert variant="danger">{errors.old_password}</Alert>}
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Label>New Password:</Form.Label>
                        <Form.Control
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        {errors.new_password && <Alert variant="danger">{errors.new_password}</Alert>}
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Label>Confirm Password:</Form.Label>
                        <Form.Control
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        {errors.confirm_password && <Alert variant="danger">{errors.confirm_password}</Alert>}
                    </Form.Group>
                    <Button type="submit" className="profile-custom-btn" disabled={loading}>
                        {loading ? <><Spinner animation="border" size="sm" /> Changing...</> : 'Change Password'}
                    </Button>
                </Form>
                {successMessage && <Alert variant="success" className="mt-3">{successMessage}</Alert>}
                {errors.message && <Alert variant="danger" className="mt-3">{errors.message}</Alert>}
            </div>
        </Container>
    );
};

export default Profile;