import React, { useEffect, useState } from 'react';
import { Form, Container, Col, Button, Alert } from 'react-bootstrap';
import './../../../styles/App.css';
import './../../../styles/forms/programPlanningData/ProgramPlanningData.css';
import { useParams, useNavigate } from 'react-router-dom';
import './../../../styles/forms/programImplementationData/ProgramImplementation.css';
import EventMatrixDataService from './../../services/event_matrix_data.service.js';
import ProgramExecutiveDataService from './../../services/program_executives.service.js';
import EventPlanningDataService from './../../services/event_planning_data.service.js';
import VillageDataService from './../../services/village.service.js';
import PartnerDataService from './../../services/partner.service.js';
import ProgramDataService from './../../services/program.service.js';
import VolunteerDataService from './../../services/volunteer.service.js';
import { useSelector } from 'react-redux';

function ProgramImplementation() {
    const [loading, setLoading] = useState(false);
const { user: currentUser } = useSelector((state) => state.auth);
const [roleId, setRoleId] = useState(0);
const [userId, setUserId] = useState(0);
const [editableFields, setEditableFields] = useState({});
const [initialEditState, setInitialEditState] = useState({});

useEffect(() => {
    if (currentUser) {
        setRoleId(currentUser.role_id);
        setUserId(currentUser.id);
        console.log(currentUser);
    }
}, [currentUser]);

const { event_id } = useParams();
const navigate = useNavigate();

const [formData, setFormData] = useState({
    eventId: event_id,
    eventActualDate: '',
    programImplementationStatus: '',
    programExecutiveCode: currentUser.id,
    pgmManagerRoleUpdate: '',
    generalProgramRemarks: '',
    womenActualImpacted: 0,
    childrenActualImpacted: 0,
    othersActualImpacted: 0,
    indirectActualBeneficiary: 0,
    remarks1: '',
    investmentCostForPartnerDFFunded: 0,
    investmentCostForPartnerNotDFFunded: 0,
    investmentCostForDFT: 0,
    totalExpenses: 0,
    socialRoR: 0,
    remarks2: '',
    adultVolunteers: 0,
    hoursSpentByAdultVolunteers: 0,
    amountSpentOnAdultVolunteers: 0,
    highSchoolVolunteers: 0,
    hoursSpentByHighSchoolVolunteers: 0,
    amountSpentOnHighSchoolVolunteers: 0,
    collegeStudentVolunteers: 0,
    hoursSpentByCollegeStudentVolunteers: 0,
    amountSpentOnCollegeStudentVolunteers: 0,
    professionalVolunteers: 0,
    hoursSpentByProfessionalVolunteers: 0,
    amountSpentOnProfessionalVolunteers: 0,
    executiveVolunteers: 0,
    hoursSpentByExecutiveVolunteers: 0,
    amountSpentOnExecutiveVolunteers: 0,
    maleVolunteers: 0,
    femaleVolunteers: 0,
    remarks3: '',
    noOfHealthVans: 0,
    amountForHealthVans: 0,
    noOfBuildings: 0,
    amountForBuildings: 0,
    noOfGrounds: 0,
    amountForGrounds: 0,
    noOfOtherInfra: 0,
    amountForOtherInfra: 0,
    remarks4: '',
    noOfFoodEvents: 0,
    amountForFood: 0,
    noOfGoods: 0,
    amountForGoods: 0,
    noOfTents: 0,
    amountForTents: 0,
    noOfDecorations: 0,
    amountForDecorations: 0,
    noOfInstruments: 0,
    amountForInstruments: 0,
    noOfOtherInKindType: 0,
    amountForOtherInKindType: 0,
    remarks5: '',
    collabWithNGOForVolunteerAndInKindDonations: '',
    remarks6: '',
    collabWithAdministration: '',
    remarks7: '',
});

const [plannedData, setPlannedData] = useState({
    eventPlannedDate: '',
    eventCategory: '',
    programCode: '',
    partnerCode: '',
    villageId: '',
    womenImpactedPlanned: 0,
    childrenImpactedPlanned: 0,
    othersImpactedPlanned: 0,
    remarks1: '',
    budgetAmountPlanned: 0,
    paidFromDFDFTPlanned: 0,
    remarks2: '',
    programPlannedStatus: ''
});

const [villageData, setVillageData] = useState({
    villageId: '',
    villageName: '',
    state: '',
    district: '',
    taluka: '',
})

const [programDetails, setProgramDetails] = useState({
    programCode: '',
    programName: '',
    directIndirectRatio: 0
})

const [partnerDetails, setPartnerDetails] = useState({
    partnerCode: '',
    partnerName: ''
})

const [volunteerCharges, setVolunteerCharges] = useState({
    adultVolunteers: 0,
    highSchoolVolunteers: 0,
    collegeStudentVolunteers: 0,
    professionalVolunteers: 0,
    executiveVolunteers: 0
})

const [isProgramManager, setIsProgramManager] = useState(false);

useEffect(() => {
    fetchEventData(event_id);
}, [event_id, roleId]);

const fetchEventData = async (event_id) => {
    try {
        const implementationDataResponse = await EventMatrixDataService.get(event_id) || '';
        console.log(implementationDataResponse);

        if (implementationDataResponse.status !== 404) {
            const eventActualDate = new Date(implementationDataResponse.data.event_actual_date).toLocaleDateString('en-CA');

            
            const newFormData = {
                eventId: implementationDataResponse.data.event_id,
                eventActualDate: eventActualDate,
                programImplementationStatus: implementationDataResponse.data.program_implementation_status,
                programExecutiveCode: implementationDataResponse.data.program_executive_code,
                pgmManagerRoleUpdate: implementationDataResponse.data.update_by_pgm_role_flag,
                generalProgramRemarks: implementationDataResponse.data.general_program_remark,
                womenActualImpacted: implementationDataResponse.data.no_of_women_impacted_actual,
                childrenActualImpacted: implementationDataResponse.data.no_of_children_impacted_actual,
                othersActualImpacted: implementationDataResponse.data.no_of_other_impacted_actual,
                indirectActualBeneficiary: implementationDataResponse.data.no_of_indirect_beneficiary_actual,
                remarks1: implementationDataResponse.data.impact_remark_actual,
                investmentCostForPartnerDFFunded: implementationDataResponse.data.inv_cost_partner_df_funded,
                investmentCostForPartnerNotDFFunded: implementationDataResponse.data.inv_cost_partner_not_funded,
                investmentCostForDFT: implementationDataResponse.data.inv_cost_dft,
                totalExpenses: implementationDataResponse.data.total_expense,
                socialRoR: implementationDataResponse.data.social_ror,
                remarks2: implementationDataResponse.data.expenses_remark,
                adultVolunteers: implementationDataResponse.data.no_of_adult_volunteers,
                hoursSpentByAdultVolunteers: implementationDataResponse.data.hours_spend_by_adult_volunteers,
                amountSpentOnAdultVolunteers: implementationDataResponse.data.amount_spend_adult_volunteer,
                highSchoolVolunteers: implementationDataResponse.data.no_of_high_school_volunteers,
                hoursSpentByHighSchoolVolunteers: implementationDataResponse.data.hours_spend_by_high_school_volunteers,
                amountSpentOnHighSchoolVolunteers: implementationDataResponse.data.amount_spend_high_school_volunteers,
                collegeStudentVolunteers: implementationDataResponse.data.no_of_colledge_volunteers,
                hoursSpentByCollegeStudentVolunteers: implementationDataResponse.data.hours_spend_by_colledge_volunteers,
                amountSpentOnCollegeStudentVolunteers: implementationDataResponse.data.amount_spend_colledge_volunteers,
                professionalVolunteers: implementationDataResponse.data.no_of_professional_volunteers,
                hoursSpentByProfessionalVolunteers: implementationDataResponse.data.hours_spend_by_professional_volunteers,
                amountSpentOnProfessionalVolunteers: implementationDataResponse.data.amount_spend_professional_volunteers,
                executiveVolunteers: implementationDataResponse.data.no_of_executive_volunteers,
                hoursSpentByExecutiveVolunteers: implementationDataResponse.data.hours_spend_by_executive_volunteers,
                amountSpentOnExecutiveVolunteers: implementationDataResponse.data.amount_spend_executive_volunteers,
                maleVolunteers: implementationDataResponse.data.total_male_volunteers,
                femaleVolunteers: implementationDataResponse.data.total_female_volunteers,
                noOfHealthVans: implementationDataResponse.data.no_of_health_vans,
                amountForHealthVans: implementationDataResponse.data.amount_for_health_vans,
                noOfBuildings: implementationDataResponse.data.no_of_buildings,
                amountForBuildings: implementationDataResponse.data.amount_for_buildings,
                noOfGrounds: implementationDataResponse.data.no_of_grounds,
                amountForGrounds: implementationDataResponse.data.amount_for_grounds,
                noOfOtherInfra: implementationDataResponse.data.no_of_other_infrastructure,
                amountForOtherInfra: implementationDataResponse.data.amount_for_other_infrastructure,
                remarks4: implementationDataResponse.data.infrastructure_remark,
                noOfFoodEvents: implementationDataResponse.data.no_of_food_events,
                amountForFood: implementationDataResponse.data.amount_for_food_events,
                noOfGoods: implementationDataResponse.data.no_of_goods,
                amountForGoods: implementationDataResponse.data.amount_for_goods,
                noOfTents: implementationDataResponse.data.no_of_tents,
                amountForTents: implementationDataResponse.data.amount_for_tents,
                noOfDecorations: implementationDataResponse.data.no_of_decorations,
                amountForDecorations: implementationDataResponse.data.amount_for_decorations,
                noOfInstruments: implementationDataResponse.data.no_of_instruments,
                amountForInstruments: implementationDataResponse.data.amount_for_instruments,
                noOfOtherInKindType: implementationDataResponse.data.no_of_other_inkind_type,
                amountForOtherInKindType: implementationDataResponse.data.amount_for_inkind_type,
                remarks5: implementationDataResponse.data.inkind_donation_remark,
                collabWithNGOForVolunteerAndInKindDonations: implementationDataResponse.data.collaboration_ngo_flag,
                remarks6: implementationDataResponse.data.collaboration_ngo_remark,
                collabWithAdministration: implementationDataResponse.data.collaboration_admin_flag,
                remarks7: implementationDataResponse.data.collaboration_admin_remark,
                remarks3: implementationDataResponse.data.voluteer_remark_actual
            };

            setFormData(newFormData);

            const editable = {};
            const initialEdits = {};
            Object.keys(newFormData).forEach(key => {
                initialEdits[key] = false;
                editable[key] = isFieldEditable(key, newFormData[key], roleId, initialEdits);
            });
            setInitialEditState(initialEdits);
            setEditableFields(editable);

            const programExecutiveResponse = await ProgramExecutiveDataService.get(implementationDataResponse.data.program_executive_code);
            setIsProgramManager(programExecutiveResponse.data.role_mapped_id === 2);

            console.log(implementationDataResponse.data);
        }
    } catch (error) {
        console.error('Error fetching program data:', error);
    }
    finally {
        const response = await EventPlanningDataService.get(event_id);
        console.log(response.data);
        const eventPlannedDate = new Date(response.data.event_planned_date).toLocaleDateString('en-CA');
        setPlannedData({
            eventPlannedDate: eventPlannedDate,
            eventCategory: response.data.event_category,
            programCode: response.data.program_code,
            partnerCode: response.data.partner_code,
            villageId: response.data.village_id,
            womenImpactedPlanned: response.data.no_of_women_impacted_planned,
            childrenImpactedPlanned: response.data.no_of_children_impacted_planned,
            othersImpactedPlanned: response.data.no_of_other_impacted_planned,
            remarks1: response.data.impact_remark_planned,
            budgetAmountPlanned: response.data.budgeted_amount_planned,
            paidFromDFDFTPlanned: response.data.payed_from_df_dft_planned,
            remarks2: response.data.budgeted_remark_planned,
            programPlannedStatus: response.data.program_planning_status
        });

        const villageResponse = await VillageDataService.get(response.data.village_id);
        setVillageData({
            villageId: villageResponse.data.village_id,
            villageName: villageResponse.data.village_name,
            state: villageResponse.data.state,
            district: villageResponse.data.district,
            taluka: villageResponse.data.block
        })

        const partnerResponse = await PartnerDataService.findByCode(response.data.partner_code);
        setPartnerDetails({
            partnerCode: partnerResponse.data[0].partner_code,
            partnerName: partnerResponse.data[0].partner_name,
        })

        const programResponse = await ProgramDataService.findByProgramCategoryAndProgramCode(response.data.event_category, response.data.program_code);
        setProgramDetails({
            programCode: programResponse.data[0].program_code,
            programName: programResponse.data[0].program_name,
            directIndirectRatio: programResponse.data[0].direct_indirect_ratio
        })

        const volunteerResponse = await VolunteerDataService.getAll();
        console.log(volunteerResponse.data);

        const charges = {
            adultVolunteers: 0,
            highSchoolVolunteers: 0,
            collegeStudentVolunteers: 0,
            professionalVolunteers: 0,
            executiveVolunteers: 0
        };

        volunteerResponse.data.forEach((volunteer) => {
            let name = '';
            let value = 0;

            switch (volunteer.volunteer_code) {
                case 'C':
                    name = 'adultVolunteers';
                    value = volunteer.charges_per_hour;
                    break;
                case 'D':
                    name = 'collegeStudentVolunteers';
                    value = volunteer.charges_per_hour;
                    break;
                case 'B':
                    name = 'professionalVolunteers';
                    value = volunteer.charges_per_hour;
                    break;
                case 'A':
                    name = 'executiveVolunteers';
                    value = volunteer.charges_per_hour;
                    break;
                case 'E':
                    name = 'highSchoolVolunteers';
                    value = volunteer.charges_per_hour;
                    break;
                default:
                    break;
            }

            if (name) {
                charges[name] = volunteer.charges_per_hour;
            }
        })

        setVolunteerCharges(charges);
    }
};

const isFieldEditable = (fieldName, initialValue, userRoleId, initialEditState) => {
    if (userRoleId === 2) return true; // Program manager can edit all fields
    if (userRoleId === 1) {
        // Program executive can edit fields that were initially empty or that they've started editing
        return initialEditState[fieldName] || !initialValue;
    }
    return false; // Default to not editable
};

const handleChange = (e) => {
    const { name, value } = e.target;

    if (editableFields[name]) {
        setFormData(prevData => {
            const newFormData = {
                ...prevData,
                [name]: value
            };

            setInitialEditState(prevState => ({
                ...prevState,
                [name]: true
            }));

            let updatedData = { ...newFormData };

            if (['womenActualImpacted', 'childrenActualImpacted', 'othersActualImpacted'].includes(name)) {
                getIndirectBeneficiary(newFormData);
            }

            if (['investmentCostForPartnerDFFunded', 'investmentCostForPartnerNotDFFunded', 'investmentCostForDFT'].includes(name)) {
                getTotalExpenses(newFormData);
            }

            if (['adultVolunteers', 'hoursSpentByAdultVolunteers'].includes(name)) {
                const adultVolunteers = parseFloat(newFormData.adultVolunteers) || 0;
                const hoursSpentByAdultVolunteers = parseFloat(newFormData.hoursSpentByAdultVolunteers) || 0;
                const adultVolunteerCharge = parseFloat(volunteerCharges.adultVolunteers) || 0;

                updatedData.amountSpentOnAdultVolunteers = (adultVolunteers) * (hoursSpentByAdultVolunteers) * (adultVolunteerCharge);
            }

            if (['highSchoolVolunteers', 'hoursSpentByHighSchoolVolunteers'].includes(name)) {
                const highSchoolVolunteers = parseFloat(newFormData.highSchoolVolunteers) || 0;
                const hoursSpent = parseFloat(newFormData.hoursSpentByHighSchoolVolunteers) || 0;
                const volunteerCharge = parseFloat(volunteerCharges.highSchoolVolunteers) || 0;

                updatedData.amountSpentOnHighSchoolVolunteers = highSchoolVolunteers * hoursSpent * volunteerCharge;
            }

            if (['collegeStudentVolunteers', 'hoursSpentByCollegeStudentVolunteers'].includes(name)) {
                const collegeStudentVolunteers = parseFloat(newFormData.collegeStudentVolunteers) || 0;
                const hoursSpent = parseFloat(newFormData.hoursSpentByCollegeStudentVolunteers) || 0;
                const volunteerCharge = parseFloat(volunteerCharges.collegeStudentVolunteers) || 0;

                updatedData.amountSpentOnCollegeStudentVolunteers = collegeStudentVolunteers * hoursSpent * volunteerCharge;
            }

            if (['professionalVolunteers', 'hoursSpentByProfessionalVolunteers'].includes(name)) {
                const professionalVolunteers = parseFloat(newFormData.professionalVolunteers) || 0;
                const hoursSpent = parseFloat(newFormData.hoursSpentByProfessionalVolunteers) || 0;
                const volunteerCharge = parseFloat(volunteerCharges.professionalVolunteers) || 0;

                updatedData.amountSpentOnProfessionalVolunteers = professionalVolunteers * hoursSpent * volunteerCharge;
            }

            if (['executiveVolunteers', 'hoursSpentByExecutiveVolunteers'].includes(name)) {
                const executiveVolunteers = parseFloat(newFormData.executiveVolunteers) || 0;
                const hoursSpent = parseFloat(newFormData.hoursSpentByExecutiveVolunteers) || 0;
                const volunteerCharge = parseFloat(volunteerCharges.executiveVolunteers) || 0;

                updatedData.amountSpentOnExecutiveVolunteers = executiveVolunteers * hoursSpent * volunteerCharge;
            }

            return updatedData;
        });
    }
};
const [directBeneficiary, setDirectBeneficiary] = useState(0);


    const getIndirectBeneficiary = (formData) => {
        const directBeneficiary = (parseFloat(formData.womenActualImpacted) || 0)
            + (parseFloat(formData.childrenActualImpacted) || 0)
            + (parseFloat(formData.othersActualImpacted) || 0);

        setDirectBeneficiary(directBeneficiary);

        // console.log(`Direct Beneficiary: ${directBeneficiary}`);

        if (programDetails && programDetails.directIndirectRatio) {
            const indirectRatio = programDetails.directIndirectRatio;

            if (!isNaN(indirectRatio)) {
                const indirectBeneficiary = directBeneficiary * parseFloat(indirectRatio);
                const socialRoR = ((formData.totalExpenses / directBeneficiary) || 0).toFixed(2);
                // console.log(indirectBeneficiary);
                setFormData(prevData => ({
                    ...prevData,
                    indirectActualBeneficiary: indirectBeneficiary,
                    socialRoR: socialRoR
                }));
            } else {
                console.error('Invalid ratio values:', indirectRatio);
            }
        } else {
            console.error('Program details or ratio is undefined:', programDetails);
        }
    };



    const getTotalExpenses = (formData) => {
        const totalExpenses = (parseFloat(formData.investmentCostForPartnerNotDFFunded) || 0)
            + (parseFloat(formData.investmentCostForPartnerDFFunded) || 0)
            + (parseFloat(formData.investmentCostForDFT) || 0);

        const socialRoR = ((formData.totalExpenses / directBeneficiary) || 0).toFixed(2);


        setFormData(prevData => ({
            ...prevData,
            totalExpenses: totalExpenses,
            socialRoR: socialRoR
        }));
    };




    const [errors, setErrors] = useState([]);
    const [message, setMessage] = useState('');

    const validateData = () => {
        const newErrors = [];

        if (!formData.eventActualDate) newErrors.push('Please select an program date.');
        if(formData.eventActualDate < plannedData.eventPlannedDate) newErrors.push('Program actual date must be after planned date')
        if (!formData.programImplementationStatus) newErrors.push('Please select the implementation status of the program.')

        setErrors(newErrors);
        return newErrors.length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors([]);

        setLoading(true);

        if (validateData()) {

            // TODO: Temporarily removed volunteer remarks from formData while saving in DB since column is not present in DB.
            const dataToSave = {
                event_id: event_id,
                event_actual_date: formData.eventActualDate,
                program_implementation_status: formData.programImplementationStatus,
                program_executive_code: formData.programExecutiveCode,
                update_by_pgm_role_flag: formData.pgmManagerRoleUpdate,
                general_program_remark: formData.generalProgramRemarks,
                no_of_women_impacted_actual: formData.womenActualImpacted,
                no_of_children_impacted_actual: formData.childrenActualImpacted,
                no_of_other_impacted_actual: formData.othersActualImpacted,
                no_of_indirect_beneficiary_actual: formData.indirectActualBeneficiary,
                impact_remark_actual: formData.remarks1,
                inv_cost_partner_df_funded: formData.investmentCostForPartnerDFFunded,
                inv_cost_partner_not_funded: formData.investmentCostForPartnerNotDFFunded,
                inv_cost_dft: formData.investmentCostForDFT,
                total_expense: formData.totalExpenses,
                social_ror: formData.socialRoR,
                expenses_remark: formData.remarks2,
                no_of_adult_volunteers: formData.adultVolunteers,
                hours_spend_by_adult_volunteers: formData.hoursSpentByAdultVolunteers,
                amount_spend_adult_volunteer: formData.amountSpentOnAdultVolunteers,
                no_of_high_school_volunteers: formData.highSchoolVolunteers,
                hours_spend_by_high_school_volunteers: formData.hoursSpentByHighSchoolVolunteers,
                amount_spend_high_school_volunteers: formData.amountSpentOnHighSchoolVolunteers,
                no_of_colledge_volunteers: formData.collegeStudentVolunteers,
                hours_spend_by_colledge_volunteers: formData.hoursSpentByCollegeStudentVolunteers,
                amount_spend_colledge_volunteers: formData.amountSpentOnCollegeStudentVolunteers,
                no_of_professional_volunteers: formData.professionalVolunteers,
                hours_spend_by_professional_volunteers: formData.hoursSpentByProfessionalVolunteers,
                amount_spend_professional_volunteers: formData.amountSpentOnProfessionalVolunteers,
                no_of_executive_volunteers: formData.executiveVolunteers,
                hours_spend_by_executive_volunteers: formData.hoursSpentByExecutiveVolunteers,
                amount_spend_executive_volunteers: formData.amountSpentOnExecutiveVolunteers,
                total_male_volunteers: formData.maleVolunteers,
                total_female_volunteers: formData.femaleVolunteers,
                no_of_health_vans: formData.noOfHealthVans,
                amount_for_health_vans: formData.amountForHealthVans,
                no_of_buildings: formData.noOfBuildings,
                amount_for_buildings: formData.amountForBuildings,
                no_of_grounds: formData.noOfGrounds,
                amount_for_grounds: formData.amountForGrounds,
                no_of_other_infrastructure: formData.noOfOtherInfra,
                amount_for_other_infrastructure: formData.amountForOtherInfra,
                infrastructure_remark: formData.remarks4,
                no_of_food_events: formData.noOfFoodEvents,
                amount_for_food_events: formData.amountForFood,
                no_of_goods: formData.noOfGoods,
                amount_for_goods: formData.amountForGoods,
                no_of_tents: formData.noOfTents,
                amount_for_tents: formData.amountForTents,
                no_of_decorations: formData.noOfDecorations,
                amount_for_decorations: formData.amountForDecorations,
                no_of_instruments: formData.noOfInstruments,
                amount_for_instruments: formData.amountForInstruments,
                no_of_other_inkind_type: formData.noOfOtherInKindType,
                amount_for_inkind_type: formData.amountForOtherInKindType,
                inkind_donation_remark: formData.remarks5,
                collaboration_ngo_flag: formData.collabWithNGOForVolunteerAndInKindDonations,
                collaboration_ngo_remark: formData.remarks6,
                collaboration_admin_flag: formData.collabWithAdministration,
                collaboration_admin_remark: formData.remarks7,
                voluteer_remark_actual: formData.remarks3,
                update_by_id: currentUser.id

            }
            try {
                // TODO: dup-check before adding new data to DB
                const implementedDataResponse = await EventMatrixDataService.get(event_id);

                if (implementedDataResponse.status !== 404) {
                    console.log('editing');
                    const { event_id, ...editData } = dataToSave;
                    console.log(editData);
                    const response = await EventMatrixDataService.update(event_id, editData);

                    setLoading(false);
                    setMessage('Program Implementation Data successfully edited!');

                    setTimeout(() => {
                        window.location.reload()
                    }, 4000);

                }
            } catch (error) {
                if (error.response.status === 404) {
                    console.log('adding');
                    try {
                        const response = await EventMatrixDataService.create(dataToSave);
                        setLoading(false);
                        setMessage('Program Implementation Data successfully saved!');

                        setTimeout(() => {
                            navigate('/view-program-planning');
                        }, 4000);
                    }
                    catch (err) {
                        setLoading(false);
                        setMessage('Error saving data.');
                        console.error('Error saving data:', err);
                    }


                }
                else {
                    setLoading(false);
                    setMessage('Error editing data.');
                    console.error('Error editing data:', error);
                }



            }
        }
        else {
            setLoading(false);
        }
    };

    const madatory = () => <span className='mandatory-icon'>*</span>;


    // Error handling with timeout
    useEffect(() => {
        let timeout;
        if (errors.length > 0) {
            timeout = setTimeout(() => {
                setErrors([]);
            }, 4000);
        }
        return () => clearTimeout(timeout); // Cleanup on unmount or when errors change
    }, [errors]);

    useEffect(() => {
        if (formData) {
            const editable = {};
            Object.keys(formData).forEach(key => {
                editable[key] = isFieldEditable(key, formData[key], roleId, initialEditState);
            });
            setEditableFields(editable);
        }
    }, [roleId, formData, initialEditState]);

    return (
        <Container className='event-implement-page'>
            <Form id='event-implement-form' onSubmit={handleSubmit}>
                <Container className='input-grid'>
                    <h2>Program Details</h2>

                    <Container className='input-field-multiple'>
                        <Form.Group id="event-planned-date" className='input-field-single-column input-field-backgroud'>
                            <Form.Label>Program Planned Date:</Form.Label>
                            <Form.Control
                                type="date"
                                value={plannedData.eventPlannedDate}
                                disabled
                                className="input" />
                        </Form.Group>

                        <Form.Group id="program-code" className='input-field-single-column input-field-backgroud'>
                            <Form.Label>Program Code/Name:</Form.Label>
                            <Form.Control
                                type="text"
                                value={`${programDetails.programCode} - ${programDetails.programName}`}
                                disabled
                                className="input" />
                        </Form.Group>

                        <Form.Group id="event-category" className='input-field-single-row input-field-backgroud'>
                            <Form.Label>Program Category:</Form.Label>
                            <Form.Control
                                type="text"
                                value={plannedData.eventCategory}
                                disabled
                                className="input" />
                        </Form.Group>
                    </Container>

                    <Container className='input-field-multiple'>
                        <Form.Group id="partner-code" className='input-field-single-column input-field-backgroud'>
                            <Form.Label>Partner Code/Name:</Form.Label>
                            <Form.Control
                                type="text"
                                value={`${partnerDetails.partnerCode} - ${partnerDetails.partnerName}`}
                                disabled
                                className="input" />
                        </Form.Group>
                        <Form.Group className="input-field-single-column input-field-backgroud">
                            <Form.Label>Village - Taluka - District - State</Form.Label>
                            <Form.Control
                                type="text"
                                value={`${villageData.villageName} - ${villageData.taluka} - ${villageData.district} - ${villageData.state}`}
                                disabled
                                className="input" />
                        </Form.Group>
                        <Form.Group className="input-field-single-row input-field-backgroud">
                            <Form.Label>Program Planned Status</Form.Label>
                            <Form.Control
                                type="text"
                                value={plannedData.programPlannedStatus}
                                disabled
                                className="input" />
                        </Form.Group>
                    </Container>

                    <hr className='line-break' />
                    <br></br>
                    <h2>Implemented Program</h2>

                    <Container className='input-field-multiple'>
                        <Form.Group id="event-actual-date" className='input-field-single-row input-field-backgroud'>
                            <Form.Label>Program Actual Date:{madatory()}</Form.Label>
                            <Form.Control
                                type="date"
                                name="eventActualDate"
                                value={formData.eventActualDate}
                                onChange={handleChange}
                                disabled={!editableFields["eventActualDate"]}
                                min={plannedData.eventPlannedDate}
                                className="input" />
                        </Form.Group>

                        <Form.Group id="event-category" className='input-field-single-row input-field-backgroud'>
                            <Form.Label>Program Implementation Status:{madatory()}</Form.Label>
                            <Col className="radio-list">
                                <Form.Check
                                    type="radio"
                                    label="Implemented"
                                    name="programImplementationStatus"
                                    value="I"
                                    checked={formData.programImplementationStatus === 'I'}
                                    onChange={handleChange}
                                    disabled={!editableFields["programImplementationStatus"]}
                                    id="implemented"
                                />
                                <Form.Check
                                    type="radio"
                                    label="Cancelled"
                                    name="programImplementationStatus"
                                    value="C"
                                    checked={formData.programImplementationStatus === 'C'}
                                    onChange={handleChange}
                                    disabled={!editableFields["programImplementationStatus"]}
                                    id="cancelled"
                                />
                                <Form.Check
                                    type="radio"
                                    label="Rescheduled"
                                    name="programImplementationStatus"
                                    value="R"
                                    checked={formData.programImplementationStatus === 'R'}
                                    onChange={handleChange}
                                    disabled={!editableFields["programImplementationStatus"]}
                                    id="rescheduled"
                                />
                            </Col>
                        </Form.Group>
                    </Container>

                    <Container className='input-field-multiple'>
                        <Form.Group id="program-code" className='input-field-single-column input-field-backgroud'>
                            <Form.Label>Program Executive Code:</Form.Label>
                            <Form.Control
                                type="text"
                                value={`${formData.programExecutiveCode} : ${currentUser.name}`}
                                disabled
                                className="input" />
                        </Form.Group>

                        {currentUser.role_id === 2 &&
                            <Form.Group id="pgm-manager-role-update" className='input-field-single-row input-field-backgroud'>
                                <Form.Label>Any Update by Program Manager Role?:</Form.Label>
                                <Col className="radio-list">
                                    <Form.Check
                                        type="radio"
                                        label="Yes"
                                        name="pgmManagerRoleUpdate"
                                        value="Y"
                                        checked={formData.pgmManagerRoleUpdate === 'Y'}
                                        onChange={handleChange}
                                        disabled={!editableFields["pgmManagerRoleUpdate"]}
                                        id="yes-pgmrole"
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="No"
                                        name="pgmManagerRoleUpdate"
                                        value="N"
                                        checked={formData.pgmManagerRoleUpdate === 'N'}
                                        onChange={handleChange}
                                        disabled={!editableFields["pgmManagerRoleUpdate"]}
                                        id="no-pgm-role"
                                    />
                                </Col>
                            </Form.Group>
                        }

                        <Form.Group id="general-program-remarks" className='input-field-single-column input-field-backgroud'>
                            <Form.Label>General Program Remarks:</Form.Label>
                            <Form.Control
                                placeholder="Enter remarks"
                                as="textarea"
                                rows={3}
                                className="input"
                                name="generalProgramRemarks"
                                value={formData.generalProgramRemarks}
                                onChange={handleChange}
                                disabled={!editableFields["generalProgramRemarks"]}
                            />
                        </Form.Group>
                    </Container>

                </Container>

                <hr className='line-break' />
                <h2>Update Impact</h2>

                <Container className='input-field-multiple'>
                    <Form.Group id="women-actual-impacted" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Women Actual Impacted:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="womenActualImpacted"
                            value={formData.womenActualImpacted}
                            onChange={handleChange}
                            disabled={!editableFields["womenActualImpacted"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="children-actual-impacted" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Children Actual Impacted:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="childrenActualImpacted"
                            value={formData.childrenActualImpacted}
                            onChange={handleChange}
                            disabled={!editableFields["childrenActualImpacted"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="others-actual-impacted" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Others Actual Impacted:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="othersActualImpacted"
                            value={formData.othersActualImpacted}
                            onChange={handleChange}
                            disabled={!editableFields["othersActualImpacted"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="indirect-actual-beneficiary" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Indirect Actual Beneficiary:</Form.Label>
                        <Form.Control
                            type="text"
                            disabled
                            name="indirectActualBeneficiary"
                            value={formData.indirectActualBeneficiary}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="remarks1" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Remarks (Impact):</Form.Label>
                        <Form.Control
                            placeholder="Enter remarks"
                            as="textarea"
                            rows={3}
                            className="input"
                            name="remarks1"
                            value={formData.remarks1}
                            onChange={handleChange}
                            disabled={!editableFields["remarks1"]}
                        />
                    </Form.Group>
                </Container>

                <hr className='line-break' />
                <h2>Update Expenses</h2>

                <Container className='input-field-multiple'>
                    <Form.Group id="investment-cost-for-partner-dffunded" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Investment Cost for Partner (DF Funded):</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="investmentCostForPartnerDFFunded"
                            value={formData.investmentCostForPartnerDFFunded}
                            onChange={handleChange}
                            disabled={!editableFields["investmentCostForPartnerDFFunded"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="investment-cost-for-partner-not-dffunded" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Investment Cost for Partner (Not DF Funded):</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="investmentCostForPartnerNotDFFunded"
                            value={formData.investmentCostForPartnerNotDFFunded}
                            onChange={handleChange}
                            disabled={!editableFields["investmentCostForPartnerNotDFFunded"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="investment-cost-for-dft" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Investment Cost for DFT (Reimbursement):</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="investmentCostForDFT"
                            value={formData.investmentCostForDFT}
                            onChange={handleChange}
                            disabled={!editableFields["investmentCostForDFT"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="total-expenses" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Total Expenses:</Form.Label>
                        <Form.Control
                            type="text"
                            disabled
                            name="totalExpenses"
                            value={formData.totalExpenses}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="social-ror" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Social RoR:</Form.Label>
                        <Form.Control
                            type="text"
                            disabled
                            name="socialRoR"
                            value={formData.socialRoR}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="remarks2" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Remarks (Expenses):</Form.Label>
                        <Form.Control
                            placeholder="Enter remarks"
                            as="textarea"
                            rows={3}
                            className="input"
                            name="remarks2"
                            value={formData.remarks2}
                            onChange={handleChange}
                            disabled={!editableFields["remarks2"]}
                        />
                    </Form.Group>
                </Container>

                <hr className='line-break' />
                <h2>Update Volunteer Data</h2>

                <Container className='input-field-multiple'>
                    <Form.Group id="adult-volunteers" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Adult Volunteers:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="adultVolunteers"
                            value={formData.adultVolunteers}
                            onChange={handleChange}
                            disabled={!editableFields["adultVolunteers"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="hours-spent-by-adult-volunteers" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Hours Spent by Adult Volunteers:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="hoursSpentByAdultVolunteers"
                            value={formData.hoursSpentByAdultVolunteers}
                            onChange={handleChange}
                            disabled={!editableFields["hoursSpentByAdultVolunteers"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="amount-spent-on-adult-volunteers" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Amount Spent on Adult Volunteers:</Form.Label>
                        <Form.Control
                            type="text"
                            disabled
                            name="amountSpentOnAdultVolunteers"
                            value={formData.amountSpentOnAdultVolunteers}
                            className="input" />
                    </Form.Group>
                </Container>

                <Container className='input-field-multiple'>
                    <Form.Group id="high-school-volunteers" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>High School Volunteers:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="highSchoolVolunteers"
                            value={formData.highSchoolVolunteers}
                            onChange={handleChange}
                            disabled={!editableFields["highSchoolVolunteers"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="hours-spent-by-high-school-volunteers" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Hours Spent by High School Volunteers:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="hoursSpentByHighSchoolVolunteers"
                            value={formData.hoursSpentByHighSchoolVolunteers}
                            onChange={handleChange}
                            disabled={!editableFields["hoursSpentByHighSchoolVolunteers"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="amount-spent-on-high-school-volunteers" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Amount Spent on High School Volunteers:</Form.Label>
                        <Form.Control
                            type="text"
                            disabled
                            name="amountSpentOnHighSchoolVolunteers"
                            value={formData.amountSpentOnHighSchoolVolunteers}
                            className="input" />
                    </Form.Group>
                </Container>

                <Container className='input-field-multiple'>
                    <Form.Group id="college-student-volunteers" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>College Student Volunteers:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="collegeStudentVolunteers"
                            value={formData.collegeStudentVolunteers}
                            onChange={handleChange}
                            disabled={!editableFields["collegeStudentVolunteers"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="hours-spent-by-college-student-volunteers" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Hours Spent by College Student Volunteers:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="hoursSpentByCollegeStudentVolunteers"
                            value={formData.hoursSpentByCollegeStudentVolunteers}
                            onChange={handleChange}
                            disabled={!editableFields["hoursSpentByCollegeStudentVolunteers"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="amount-spent-on-college-student-volunteers" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Amount Spent on College Student Volunteers:</Form.Label>
                        <Form.Control
                            type="text"
                            disabled
                            name="amountSpentOnCollegeStudentVolunteers"
                            value={formData.amountSpentOnCollegeStudentVolunteers}
                            className="input" />
                    </Form.Group>
                </Container>

                <Container className='input-field-multiple'>
                    <Form.Group id="professional-volunteers" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Professional Volunteers:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="professionalVolunteers"
                            value={formData.professionalVolunteers}
                            onChange={handleChange}
                            disabled={!editableFields["professionalVolunteers"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="hours-spent-by-professional-volunteers" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Hours Spent by Professional Volunteers:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="hoursSpentByProfessionalVolunteers"
                            value={formData.hoursSpentByProfessionalVolunteers}
                            onChange={handleChange}
                            disabled={!editableFields["hoursSpentByProfessionalVolunteers"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="amount-spent-on-professional-volunteers" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Amount Spent on Professional Volunteers:</Form.Label>
                        <Form.Control
                            type="text"
                            disabled
                            name="amountSpentOnProfessionalVolunteers"
                            value={formData.amountSpentOnProfessionalVolunteers}
                            className="input" />
                    </Form.Group>
                </Container>

                <Container className='input-field-multiple'>
                    <Form.Group id="executive-volunteers" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Executive Volunteers:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="executiveVolunteers"
                            value={formData.executiveVolunteers}
                            onChange={handleChange}
                            disabled={!editableFields["executiveVolunteers"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="hours-spent-by-executive-volunteers" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Hours Spent by Executive Volunteers:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="hoursSpentByExecutiveVolunteers"
                            value={formData.hoursSpentByExecutiveVolunteers}
                            onChange={handleChange}
                            disabled={!editableFields["hoursSpentByExecutiveVolunteers"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="amount-spent-on-executive-volunteers" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Amount Spent on Executive Volunteers:</Form.Label>
                        <Form.Control
                            type="text"
                            disabled
                            name="amountSpentOnExecutiveVolunteers"
                            value={formData.amountSpentOnExecutiveVolunteers}
                            className="input" />
                    </Form.Group>
                </Container>

                <Container className='input-field-multiple'>
                    <Form.Group id="male-volunteers" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Male Volunteers:</Form.Label>
                        <Form.Control
                            type="number"
                            name="maleVolunteers"
                            value={formData.maleVolunteers}
                            onChange={handleChange}
                            disabled={!editableFields["maleVolunteers"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="female-volunteers" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Female Volunteers:</Form.Label>
                        <Form.Control
                            type="number"
                            name="femaleVolunteers"
                            value={formData.femaleVolunteers}
                            onChange={handleChange}
                            disabled={!editableFields["femaleVolunteers"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="remarks3" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Remarks (Volunteer):</Form.Label>
                        <Form.Control
                            placeholder="Enter remarks"
                            as="textarea"
                            rows={3}
                            className="input"
                            name="remarks3"
                            value={formData.remarks3}
                            onChange={handleChange}
                            disabled={!editableFields["remarks3"]}
                        />
                    </Form.Group>
                </Container>

                <hr className='line-break' />
                <h2>Update Infrastructure Data</h2>

                <Container className='input-field-multiple'>
                    <Form.Group id="no-of-health-vans" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>No of Health Vans:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="noOfHealthVans"
                            value={formData.noOfHealthVans}
                            onChange={handleChange}
                            disabled={!editableFields["noOfHealthVans"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="amount-for-health-vans" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Amount for Health Vans:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="amountForHealthVans"
                            value={formData.amountForHealthVans}
                            onChange={handleChange}
                            disabled={!editableFields["amountForHealthVans"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="no-of-buildings" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>No of Buildings:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="noOfBuildings"
                            value={formData.noOfBuildings}
                            onChange={handleChange}
                            disabled={!editableFields["noOfBuildings"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="amount-for-buildings" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Amount for Buildings:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="amountForBuildings"
                            value={formData.amountForBuildings}
                            onChange={handleChange}
                            disabled={!editableFields["amountForBuildings"]}
                            className="input" />
                    </Form.Group>
                </Container>

                <Container className='input-field-multiple'>
                    <Form.Group id="no-of-grounds" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>No of Grounds:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="noOfGrounds"
                            value={formData.noOfGrounds}
                            onChange={handleChange}
                            disabled={!editableFields["noOfGrounds"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="amount-for-grounds" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Amount for Grounds:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="amountForGrounds"
                            value={formData.amountForGrounds}
                            onChange={handleChange}
                            disabled={!editableFields["amountForGrounds"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="no-of-other-infra" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>No of Other Infra:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="noOfOtherInfra"
                            value={formData.noOfOtherInfra}
                            onChange={handleChange}
                            disabled={!editableFields["noOfOtherInfra"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="amount-for-other-infra" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Amount for Other Infra:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="amountForOtherInfra"
                            value={formData.amountForOtherInfra}
                            onChange={handleChange}
                            disabled={!editableFields["amountForOtherInfra"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="remarks4" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Remarks (Infrastructure):</Form.Label>
                        <Form.Control
                            placeholder="Enter remarks"
                            as="textarea"
                            rows={3}
                            className="input"
                            name="remarks4"
                            value={formData.remarks4}
                            onChange={handleChange}
                            disabled={!editableFields["remarks4"]}
                        />
                    </Form.Group>
                </Container>

                <hr className='line-break' />
                <h2>Update In-Kind Donation Data</h2>

                <Container className='input-field-multiple'>
                    <Form.Group id="no-of-food-events" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>No of Food Programs:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="noOfFoodEvents"
                            value={formData.noOfFoodEvents}
                            onChange={handleChange}
                            disabled={!editableFields["noOfFoodEvents"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="amount-for-food" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Amount for Food:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="amountForFood"
                            value={formData.amountForFood}
                            onChange={handleChange}
                            disabled={!editableFields["amountForFood"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="no-of-goods" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>No of Goods:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="noOfGoods"
                            value={formData.noOfGoods}
                            onChange={handleChange}
                            disabled={!editableFields["noOfGoods"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="amount-for-goods" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Amount for Goods:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="amountForGoods"
                            value={formData.amountForGoods}
                            onChange={handleChange}
                            disabled={!editableFields["amountForGoods"]}
                            className="input" />
                    </Form.Group>
                </Container>

                <Container className='input-field-multiple'>
                    <Form.Group id="no-of-tents" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>No of Tents:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="noOfTents"
                            value={formData.noOfTents}
                            onChange={handleChange}
                            disabled={!editableFields["noOfTents"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="amount-for-tents" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Amount for Tents:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="amountForTents"
                            value={formData.amountForTents}
                            onChange={handleChange}
                            disabled={!editableFields["amountForTents"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="no-of-decorations" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>No of Decorations:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="noOfDecorations"
                            value={formData.noOfDecorations}
                            onChange={handleChange}
                            disabled={!editableFields["noOfDecorations"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="amount-for-decorations" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Amount for Decorations:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="amountForDecorations"
                            value={formData.amountForDecorations}
                            onChange={handleChange}
                            disabled={!editableFields["amountForDecorations"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="no-of-instruments" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>No of Instruments:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="noOfInstruments"
                            value={formData.noOfInstruments}
                            onChange={handleChange}
                            disabled={!editableFields["noOfInstruments"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="amount-for-instruments" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Amount for Instruments:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="amountForInstruments"
                            value={formData.amountForInstruments}
                            onChange={handleChange}
                            disabled={!editableFields["amountForInstruments"]}
                            className="input" />
                    </Form.Group>
                </Container>

                <Container className='input-field-multiple'>
                    <Form.Group id="no-of-other-in-kind-type" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>No of Other In-Kind Type:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="noOfOtherInKindType"
                            value={formData.noOfOtherInKindType}
                            onChange={handleChange}
                            disabled={!editableFields["noOfOtherInKindType"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="amount-for-other-in-kind-type" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Amount for Other In-Kind Type:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            name="amountForOtherInKindType"
                            value={formData.amountForOtherInKindType}
                            onChange={handleChange}
                            disabled={!editableFields["amountForOtherInKindType"]}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="remarks5" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Remarks (In-Kind Donations):</Form.Label>
                        <Form.Control
                            placeholder="Enter remarks"
                            as="textarea"
                            rows={3}
                            className="input"
                            name="remarks5"
                            value={formData.remarks5}
                            onChange={handleChange}
                            disabled={!editableFields["remarks5"]}
                        />
                    </Form.Group>
                </Container>

                <hr className='line-break' />
                <h2>Update Collaboration Data</h2>

                <Container className='input-field-multiple'>
                    <Form.Group id="collab-with-ngo-for-volunteer-and-in-kind-donations" className='input-field-single-row input-field-backgroud'>
                        <Form.Label>Collaboration with NGO for Volunteer and In-Kind Donations:</Form.Label>
                        <Col className="radio-list">
                            <Form.Check
                                type="radio"
                                label="Yes"
                                name="collabWithNGOForVolunteerAndInKindDonations"
                                value="Y"
                                checked={formData.collabWithNGOForVolunteerAndInKindDonations === 'Y'}
                                onChange={handleChange}
                                disabled={!editableFields["collabWithNGOForVolunteerAndInKindDonations"]}
                                id="yes-collab1"
                            />
                            <Form.Check
                                type="radio"
                                label="No"
                                name="collabWithNGOForVolunteerAndInKindDonations"
                                value="N"
                                checked={formData.collabWithNGOForVolunteerAndInKindDonations === 'N'}
                                onChange={handleChange}
                                disabled={!editableFields["collabWithNGOForVolunteerAndInKindDonations"]}
                                id="no-collab1"
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group id="remarks6" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Remarks (Collaboration With NGO):</Form.Label>
                        <Form.Control
                            placeholder="Enter remarks"
                            as="textarea"
                            rows={3}
                            className="input"
                            name="remarks6"
                            value={formData.remarks6}
                            onChange={handleChange}
                            disabled={!editableFields["remarks6"]}
                        />
                    </Form.Group>
                </Container>

                <Container className='input-field-multiple'>
                    <Form.Group id="collab-with-ngo-for-other-expenses" className='input-field-single-row input-field-backgroud'>
                        <Form.Label>Collaboration with Administration (Village or District):</Form.Label>
                        <Col className="radio-list">
                            <Form.Check
                                type="radio"
                                label="Yes"
                                name="collabWithAdministration"
                                value="Y"
                                checked={formData.collabWithAdministration === 'Y'}
                                onChange={handleChange}
                                disabled={!editableFields["collabWithAdministration"]}
                                id="yes-collab2"
                            />
                            <Form.Check
                                type="radio"
                                label="No"
                                name="collabWithAdministration"
                                value="N"
                                checked={formData.collabWithAdministration === 'N'}
                                onChange={handleChange}
                                disabled={!editableFields["collabWithAdministration"]}
                                id="no-collab2"
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group id="remarks7" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Remarks (Collaboration With Administration):</Form.Label>
                        <Form.Control
                            placeholder="Enter remarks"
                            as="textarea"
                            rows={3}
                            className="input"
                            name="remarks7"
                            value={formData.remarks7}
                            onChange={handleChange}
                            disabled={!editableFields["remarks7"]}
                        />
                    </Form.Group>
                </Container>

                {errors.length > 0 &&
                    <Alert className='alert' variant="danger">{errors[0]}</Alert>
                }
                {message && <Alert variant="info">{message}</Alert>}
                <Button variant="primary" type="submit" className="program-planning-custom-btn">
                    {loading ? 'Saving Data...' : 'Submit'}
                </Button>
            </Form>
        </Container>
    );
}

export default ProgramImplementation;