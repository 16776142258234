import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Dropdown, DropdownButton } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../../styles/forms/programPlanningData/ProgramPlanningData.css'
import './../../styles/App.css';

const ReportGenYearDropdown = ({ onChange }) => {
    const [reportYears, setReportYears] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchReportYears = async () => {
            try {
                //const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/villageDetails`);

                let yearLst=[]   
                let year1 = 2022;
                let cntYear = 1;   

                while(cntYear <=10){
                    console.log("yearLst:",yearLst);
                    yearLst.push({year1:year1});
                    year1 = year1 +1;
                    cntYear = cntYear +1;
                }
                console.log("yearLst:",yearLst);
                setReportYears(yearLst);
             
            } catch (err) {
                console.log(err);
                setError('Failed to fetch report gen years');
            } finally {
                setLoading(false);
            }
        };

        fetchReportYears();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;


    return (
        <Form.Group className="reportMonthDropdown">
            <Form.Control className='input' as="select" onChange={onChange}>
                <option value="" data_id="">Select Report Year</option>
                {reportYears.map((detail) => (
                    <option key={detail.year1} value={detail.year1}>
                        {detail.year1}
                    </option>
                ))}
            </Form.Control>
        </Form.Group>
    );
};

export default ReportGenYearDropdown;
