import http from "../../http-common";

class SanginiRegistrationService {

  create(data) {
    return http.post("/sanginiRegistration", data);
  }

  getSanginiIdFromMobileNumber(mobileNumber) {
    return http.get("/sanginiRegistration/"+mobileNumber)
  }

  updateSanginiDetailsAfterBecomingSangini(data) {
    return http.post("/sanginiRegistration/updateSanginiDetails", data);
  }

  updateSanginiDetailsAllFields(data) {
    return http.post("/sanginiRegistration/updateAllFields", data);
  }

  addSanginiSalesData(data) {
    return http.post("/sanginiRegistration/addSanginiSalesData", data);
  }

  editSanginiSalesData(data) {
    return http.post("/sanginiRegistration/editSanginiSalesData", data);
  }

  addSanginiTargetData(data) {
    return http.post("/sanginiRegistration/addSanginiTargetDetails", data);
  }

  editSanginiTargetData(data) {
    return http.post("/sanginiRegistration/editSanginiTargetDetails", data);
  }

  getSanginiDetailsBySanginiId(sanginiId) {
    return http.get("/sanginiRegistration/getSanginiDetailsBySanginiId/"+sanginiId);
  }

}

export default new SanginiRegistrationService();