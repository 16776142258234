import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../../../styles/forms/programPlanningData/ProgramPlanningData.css';
import PartnerDataService from './../../services/partner.service.js';

const PartnerDetailsDropdown = ({ onChange, name, value, disabled, roleId, userId }) => {
    const [partnerDetails, setPartnerDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        console.log("roleId: ", roleId);
        console.log("userId: ", userId);
        const fetchPartnerDetails = async () => {
            try {
                if (roleId === 1) {
                    const response = await PartnerDataService.getExecutivePartnerDetails(userId);
                    setPartnerDetails(Array.isArray(response.data) ? response.data : []);
                }
                else if (roleId === 2) {
                    const response = await PartnerDataService.getManagerPartnerDetails(userId);
                    setPartnerDetails(Array.isArray(response.data) ? response.data : []);
                }
                else {
                    const response = await PartnerDataService.getAll();
                    // Ensure response.data is an array
                    setPartnerDetails(Array.isArray(response.data) ? response.data : []);
                }

            } catch (err) {
                setError('Failed to fetch partner details');
                console.error('Fetch error:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchPartnerDetails();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <Form.Group className="partnerDetailsDropdown">
            <Form.Control
                className='input'
                as="select"
                name={name}
                value={value}
                onChange={onChange}
                disabled={disabled}
            >
                <option value="">Select a partner code</option>
                {partnerDetails.length > 0 ? (
                    partnerDetails.map((detail) => (
                        <option key={detail.partner_id} value={detail.partner_code}>
                            {detail.partner_code} : {detail.partner_name}
                        </option>
                    ))
                ) : (
                    <option disabled>No partners available</option>
                )}
            </Form.Control>
        </Form.Group>
    );
};

export default PartnerDetailsDropdown;