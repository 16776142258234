import { useEffect, useState } from "react";
import SanginiRegistrationService from "../services/sanginiRegistration.service";
import { Form, Container, Col, Button, Row, Alert, Toast } from 'react-bootstrap';
import VillageDetailsDropdown from "../forms/VillageDetailsDropdown";
import { isValidMobileNumber } from "../utils/mobileNumberValidationUtil";
import "../../styles/forms/sanginiData/sanginiData.css";
import moment from 'moment-timezone';
import { useSelector } from "react-redux";
function RegistrationAttendanceFormCustom() {

    const { user: currentUser } = useSelector((state) => state.auth);

    const [formData, setFormData] = useState(
        {
            trainingDate: '',
            sanginiName: '',
            sanginiDob: '',
            sanginiGender: '',
            sanginiMob: '',
            sanginiVillage: '',
            trainingStatus: '',
            padOrCloth: '',
            remarks: '',
            updated_by: '',
            program_executive_id: '',
            program_manager_id: ''
        }
    );

    useEffect(() => {
        setFormData(currentFormData => ({
            ...currentFormData,
            updated_by: currentUser?.id,
            program_executive_id: currentUser?.role_id === 1 ? currentUser?.id : null,
            program_manager_id: currentUser?.role_id === 1 ? currentUser?.manager_id : null,
        }));


        if(currentUser.role_id === 2){
            setFormData(currentFormData => ({
                ...currentFormData,
                program_manager_id: currentUser?.id
            }));
        }

        console.log(currentUser);
    }, [currentUser]);

    var pastDate = new Date(new Date().setFullYear(new Date().getFullYear() - 15));
    //Current date minus 15 years will be the max DOB that can be entered
    var maxDobDate = moment.tz(pastDate.toISOString(), "Asia/Kolkata").format('YYYY-MM-DD');

    var currentDate = new Date();
    var maxTrainingDate = moment.tz(currentDate.toISOString(), "Asia/Kolkata").format('YYYY-MM-DD');

    //Error Handling and Validations
    const [errors, setErrors] = useState([]);

    const [message, setMessage] = useState('');

    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleVillageFormDataChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            sanginiVillage: value
        });
    };

    const validateFormData = () => {
        let valid = true;

        if (!formData.sanginiMob) {
            let errorMessage = "Please enter sangini mobile number.";
            setErrors(prevErrors => {
                return [...prevErrors, errorMessage];
            });
            valid = false;
        }

        if (!isValidMobileNumber(formData.sanginiMob)) {
            let errorMessage = "Please enter valid sangini mobile number.";
            setErrors(prevErrors => {
                return [...prevErrors, errorMessage];
            });
            valid = false;
        }

        if (!formData.sanginiVillage) {
            let errorMessage = "Please choose sangini village."
            setErrors(prevErrors => {
                return [...prevErrors, errorMessage];
            });
            valid = false;
        }

        if (!formData.sanginiName) {
            let errorMessage = "Please enter sangini name."
            setErrors(prevErrors => {
                return [...prevErrors, errorMessage];
            });
            valid = false;
        }

        if (!formData.trainingDate) {
            let errorMessage = "Please enter training date."
            setErrors(prevErrors => {
                return [...prevErrors, errorMessage];
            });
            valid = false;
        }
        return valid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors([]);
        console.log(formData);
        if (validateFormData()) {
            console.log("Form data validated successfully.");
            try {
                console.log(formData);
                const response = SanginiRegistrationService.create(formData)
                    .then(response => {
                        console.log("Submitted", response.data);
                        if (response.status === 400 || response.status === 500)
                            setMessage("Error creating new sangini registration.");
                        else
                            setMessage("New Sangini Registration created successfully.")
                    })
                    .catch(e => {
                        setMessage("Error creating new sangini registration.");
                        console.log(e);
                    });
            }
            catch (error) {
                setMessage("Error creating new sangini registration");
                console.error(error);
            }
        }
    }


    return (

        <Container className='event-planning-page'>

            <Form id='sangini-form' onSubmit={handleSubmit}>
                <h2>Add New Sangini Registration</h2>
                {/* <Container className='input-field-multiple'></Container> */}
                <Container className='input-field-multiple'>
                    <Form.Group id="training-date" className='input-field-single-row input-field-backgroud'>
                        <Form.Label>Training Date:</Form.Label>
                        <Form.Control
                            type="date"
                            max={maxTrainingDate}
                            name="trainingDate"
                            value={formData.trainingDate}
                            onChange={handleFormDataChange}
                            onkeydown="return false"
                            className="input" />
                    </Form.Group>

                    <Form.Group id="sangini-name" className='input-field-single-row input-field-backgroud'>
                        <Form.Label>Sangini Name:</Form.Label>
                        <Form.Control
                            type="text"
                            name="sanginiName"
                            value={formData.sanginiName}
                            onChange={handleFormDataChange}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="training-date" className='input-field-single-row input-field-backgroud'>
                        <Form.Label>Sangini Date of Birth:</Form.Label>
                        <Form.Control
                            type="date"
                            name="sanginiDob"
                            max={maxDobDate}
                            value={formData.sanginiDob}
                            onkeydown="return false"
                            onChange={handleFormDataChange}
                            className="input" />
                    </Form.Group>
                </Container>

                <Container className='input-field-multiple'>
                    <Form.Group id="sangini-gender" className='input-field-single-row input-field-backgroud'>
                        <Form.Label >Sangini Gender:</Form.Label>
                        <Col className="radio-list">
                            <Form.Check
                                type="radio"
                                label="Male"
                                name="sanginiGender"
                                value="Male"
                                onChange={handleFormDataChange}
                                id="male"
                            />
                            <Form.Check
                                type="radio"
                                label="Female"
                                name="sanginiGender"
                                value="Female"
                                onChange={handleFormDataChange}
                                id="female"
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group id="sangini-mob" className='input-field-single-row input-field-backgroud'>
                        <Form.Label>Sangini Mobile Number:</Form.Label>
                        <Form.Control
                            type="text"
                            name="sanginiMob"
                            value={formData.sanginiMob}
                            onChange={handleFormDataChange}
                            className="input" />
                    </Form.Group>
                    <Form.Group id="sanginiVillage" className="input-field-single-column input-field-backgroud">
                        <Form.Label>Village - Taluka - District - State:</Form.Label>
                        <VillageDetailsDropdown
                            name="sanginiVillage"
                            onChange={handleVillageFormDataChange}
                            value={formData.sanginiVillage}
                        />
                    </Form.Group>
                </Container>

                <Container className='input-field-multiple'>
                    <Form.Group id="sangini-gender" className='input-field-single-row input-field-backgroud'>
                        <Form.Label >Training Status:</Form.Label>
                        <Col className="radio-list">
                            <Form.Check
                                type="radio"
                                label="Confirmed"
                                name="trainingStatus"
                                value="Confirmed"
                                onChange={handleFormDataChange}
                                id="confirmed"
                            />
                            <Form.Check
                                type="radio"
                                label="Not Confirmed"
                                name="trainingStatus"
                                value="Not Confirmed"
                                onChange={handleFormDataChange}
                                id="notConfirmed"
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group id="padOrCloth" className='input-field-single-row input-field-backgroud'>
                        <Form.Label >Type of Product That Is Used:</Form.Label>
                        <Col className="radio-list">
                            <Form.Check
                                type="radio"
                                label="Pad"
                                name="padOrCloth"
                                value="Pad"
                                onChange={handleFormDataChange}
                                id="pad"
                            />
                            <Form.Check
                                type="radio"
                                label="Cloth"
                                name="padOrCloth"
                                value="Cloth"
                                onChange={handleFormDataChange}
                                id="cloth"
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group id="sangini-remarks" className='input-field-single-row input-field-backgroud'>
                        <Form.Label>Remarks:</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="remarks"
                            value={formData.remarks}
                            onChange={handleFormDataChange}
                            className="input" />
                    </Form.Group>
                </Container>
                {
                    errors.map((err, indx) => {
                        if (indx === 0) {
                            return (<Alert variant="danger" key={"danger"}>{err}</Alert>);
                        }
                        return;
                    })
                }
                {
                    message && <Alert variant="info">{message}</Alert>
                }
                <Button variant="primary" type="submit" onClick={handleSubmit} className="custom-btn">Submit</Button>
            </Form>
        </Container>
    )
}

export default RegistrationAttendanceFormCustom;