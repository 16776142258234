import axios from "axios";
import authHeader from "./auth-header";
import http from "../http-common";

const getRoles = () => {
    return http.get("/test/getroles", { headers: authHeader() });
    }

export default {
    getRoles
};