
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../../styles/forms/programPlanningData/ProgramPlanningData.css'
import './../../styles/App.css';
import ProgramDataService from "../services/program.service";

const ProgramDetailsDropdown = ({ onChange, programDetails, setProgramDetails, name }) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    

    useEffect(() => {
        const fetchProgramDetails = async () => {
            try {
                //const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/programDetails/`);
                ProgramDataService.getAll()
                .then((response) => {
                    console.log(response.data);
                    setProgramDetails(response.data);
                })
                .catch(e => {
                    console.log(e);
                })
               
            } catch (err) {
                setError('Failed to fetch program details');
            } finally {
                setLoading(false);
            }
        };

        fetchProgramDetails();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <Form.Group className="programDetailsDropdown">
            <Form.Control className='input' as="select" name={name} onChange={onChange} multiple={true}>
                <option value="">Select All Program Code</option>
                {programDetails.map((detail) => (
                    <option key={detail.program_name} data_id={detail.program_code} value={detail.program_code}>
                        {detail.program_code} : {detail.program_name}
                    </option>
                ))}
            </Form.Control>
        </Form.Group>
    );
};

export default ProgramDetailsDropdown;
