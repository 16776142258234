import { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import SanginiDataService from "../services/sangini_details.service";
import SanginiRegistrationService from "../services/sanginiRegistration.service";
import { useSelector } from "react-redux";
import VillageDetailsDropdown from "../forms/VillageDetailsDropdown";
import { isValidMobileNumber } from "../utils/mobileNumberValidationUtil";
import SeniorSanginiDropdown from "../forms/sanginiData/SeniorSanginiDropdown";
function ViewSanginiFormCustom() {
    const { sangini_id } = useParams();
    const [targetsData, setTargetsData] = useState([]);
    const [salesData, setSalesData] = useState([]);
    const navigate = useNavigate();
    const { user: currentUser } = useSelector((state) => state.auth);
    const [roleId, setRoleId] = useState(0);
    const [initialFormData, setInitialFormData] = useState({});
    const [formData, setFormData] = useState({
        sanginiId: sangini_id,
        sanginiName: '',
        trainingDate: '',
        sanginiDob: '',
        sanginiGender: '',
        sanginiMob: '',
        sanginiJoiningDate: '',
        sanginiVillage: '',
        trainingStatus: '',
        highestEducation: '',
        padOrCloth: '',
        sanginiRole: '',
        isSeniorSangini: '',
        seniorSanginiId: '',
        isProductKnowledgeAssessmentScoreAboveThreshold: '',
        establishedPartnershipWith5Schools: '',
        isActive: '',
        inactiveDate: '',
        remarks: '',
        updated_by: currentUser?.id
    });

    useEffect(() => {
        if (currentUser) {
            setRoleId(currentUser.role_id);
        }
        setDataForDisplay();
        setFormData(currentFormData => ({
            ...currentFormData,
            updated_by: currentUser?.id
        }));
        setInitialFormData(currentFormData => ({
            ...currentFormData,
            updated_by: currentUser?.id
        }));
    }, [sangini_id, currentUser]);

    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState([]);

    const fetchTargetsDisplayData = async () => {
        const response = await SanginiDataService.getAllTargetsBySanginiId(sangini_id);
        if (response.data) {
            return response.data;
        }

        return [];
    }

    const fetchSalesDisplayData = async () => {
        const response = await SanginiDataService.getAllSalesBySanginiId(sangini_id);
        if (response.data) {
            return response.data;
        }
        return [];
    }

    const fetchSanginiData = async () => {
        const response = await SanginiDataService.get(sangini_id);
        if (response.data) {
            return response.data;
        }
        return [];
    }

    const transformDatabaseData = (data) => {
        if (!data) {
            return {};
        }
        return {
            sanginiId: sangini_id,
            sanginiName: data.name || '',
            sanginiDob: data.DOB ? new Date(data.DOB).toLocaleDateString('en-CA') : '',
            sanginiGender: data.sex || '',
            sanginiMob: data.mobileno || '',
            sanginiVillage: data.village_id || '',
            trainingStatus: data.status || '',
            trainingDate: data.training_date ? new Date(data.training_date).toLocaleDateString('en-CA') : '',
            padOrCloth: data.pad_cloth || '',
            sanginiJoiningDate: data.joining_date ? new Date(data.joining_date).toLocaleDateString('en-CA') : '',
            highestEducation: data.highest_education_qualification || '',
            sanginiRole: data.role || '',
            isSeniorSangini: data.senior_sangini_flag || '',
            seniorSanginiId: data.senior_sangini_id || '',
            isProductKnowledgeAssessmentScoreAboveThreshold: data.product_knowledge_score_flag || '',
            establishedPartnershipWith5Schools: data.establish_partnership_flag || '',
            isActive: data.active_inactive_flag || '',
            inactiveDate: data.active_inactive_date ? new Date(data.active_inactive_date).toLocaleDateString('en-CA') : '',
            remarks: data.remarks || ''
        };
    }

    const setDataForDisplay = async () => {
        try {
            const targetData = await fetchTargetsDisplayData();
            const salesData = await fetchSalesDisplayData();
            const sanginiData = await fetchSanginiData();
            setTargetsData(targetData);
            setSalesData(salesData);
            setFormData(transformDatabaseData(sanginiData));
            setInitialFormData(transformDatabaseData(sanginiData));
        } catch (err) {
            console.log(err);
            setMessage(err.message ? err.message + ". Please contact admin." : "Error occured while fetching sangini details. Please contact admin.")
        }
    }

    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleVillageFormDataChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            sanginiVillage: value
        });
    };

    const renderInputField = (name, label, type = "text") => (
        <Form.Group id={name} className='input-field-single-row input-field-backgroud'>
            <Form.Label>{label}:</Form.Label>
            <Form.Control
                type={type}
                name={name}
                value={formData[name]}
                onChange={handleFormDataChange}
                disabled={!isFieldEditable(name)}
                className="input"
            />
        </Form.Group>
    );

    const renderRadioGroup = (name, label, options) => (
        <Form.Group id={name} className='input-field-single-row input-field-backgroud'>
            <Form.Label>{label}:</Form.Label>
            <Col className="radio-list">
                {options.map(option => (
                    <Form.Check
                        key={option.value}
                        type="radio"
                        label={option.label}
                        name={name}
                        value={option.value}
                        checked={formData[name] === option.value}
                        onChange={handleFormDataChange}
                        disabled={!isFieldEditable(name)}
                        id={`${name}-${option.value}`}
                    />
                ))}
            </Col>
        </Form.Group>
    );

    const isFieldEditable = (fieldName) => {
        if (roleId === 2) return true; // Program manager can edit all fields
        if (roleId === 1) {
            // Program executive can only edit empty fields
            const initialValue = initialFormData[fieldName];
            if (fieldName === 'remarks' || fieldName === 'seniorSanginiId') {
                // For remarks, consider it empty if it's undefined, null, or contains only whitespace
                return !initialValue || initialValue.trim() === '';
            }
            if (typeof initialValue === 'number') {
                return initialValue === 0;
            }
            return !initialValue;
        }
        return false; // Default to not editable
    };

    const areTargetsAndSalesEditable = () => {
        if (roleId === 2) {
            return true;
        }
        return false;
    }

    const displaySeniorSanginiIdField = () => {
        return formData.isSeniorSangini === 'N';
    }

    const displayDateOfBeingInactiveField = () => {
        return formData.isActive === 'N';
    }

    const handleEditTarget = (targetDetails) => {
        navigate(`/edit-sangini-target`, { state: targetDetails });
    }

    const handleEditSale = (saleDetails) => {
        navigate(`/edit-sangini-sale`, { state: saleDetails });
    }

    const validateFormData = () => {
        let valid = true;
        if (!formData.sanginiMob) {
            let errorMessage = "Please enter sangini mobile number.";
            setErrors(prevErrors => {
                return [...prevErrors, errorMessage];
            });
            valid = false;
        }

        if (!isValidMobileNumber(formData.sanginiMob)) {
            let errorMessage = "Please enter valid sangini mobile number.";
            setErrors(prevErrors => {
                return [...prevErrors, errorMessage];
            });
            valid = false;
        }

        if (!formData.sanginiVillage) {
            let errorMessage = "Please choose sangini village."
            setErrors(prevErrors => {
                return [...prevErrors, errorMessage];
            });
            valid = false;
        }

        if (!formData.sanginiName) {
            let errorMessage = "Please enter sangini name."
            setErrors(prevErrors => {
                return [...prevErrors, errorMessage];
            });
            valid = false;
        }

        if (!formData.trainingDate) {
            let errorMessage = "Please enter training date."
            setErrors(prevErrors => {
                return [...prevErrors, errorMessage];
            });
            valid = false;
        }
        let newErrors = [];
        if (!formData.sanginiJoiningDate) {
            newErrors.push("Please enter sangini joining date.");
            valid = false;
        }
        if (!formData.sanginiRole) {
            newErrors.push("Please enter sangini role.");
            valid = false;
        }
        if (!formData.isActive) {
            newErrors.push("Please enter Is Sangini Active?");
            valid = false;
        }
        if (!formData.isProductKnowledgeAssessmentScoreAboveThreshold) {
            newErrors.push("Please enter Product Knowledge Assessment Score");
            valid = false;
        }
        setErrors(prevErrors => {
            return [...prevErrors, ...newErrors];
        });

        return valid;
    };

    const transformDateFields = (submittedData) => {
        return {
            ...submittedData,
            sanginiDob: submittedData.sanginiDob ? new Date(submittedData.sanginiDob).toLocaleDateString('en-CA') : '',
            trainingDate: submittedData.trainingDate ? new Date(submittedData.trainingDate).toLocaleDateString('en-CA') : '',
            sanginiJoiningDate: submittedData.sanginiJoiningDate ? new Date(submittedData.sanginiJoiningDate).toLocaleDateString('en-CA') : '',
            inactiveDate: submittedData.inactiveDate ? new Date(submittedData.inactiveDate).toLocaleDateString('en-CA') : '',
        }
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setErrors([]);
        setMessage('');
        if (validateFormData()) {
            try {
                const dataToSubmit = { ...formData };
                if (!isFieldEditable('remarks')) {
                    dataToSubmit.remarks = initialFormData.remarks;
                }
                const response = await SanginiRegistrationService.updateSanginiDetailsAllFields(dataToSubmit);
                if (response.status === 400 || response.status === 500) {
                    setMessage(response.data?.message || "Error updating sangini details.");
                } else {
                    setMessage("Sangini details updated successfully.");
                    setInitialFormData(transformDateFields(dataToSubmit));
                }
            } catch (error) {
                setMessage("Error updating sangini details.");
                console.error(error);
            }
        }
    }

    return (
        <Container className='view-sangini-page'>
            <Container id='view-sangini-form'>
                <Form id='view-sangini-form'>
                    <h3>Sangini Details</h3>
                    <Container className='input-field-multiple'>
                        {renderInputField("sanginiName", "Sangini Name")}
                        {renderInputField("trainingDate", "Sangini Training Date", "date")}
                        {renderInputField("sanginiJoiningDate", "Joining Date", "date")}
                        {renderInputField("highestEducation", "Highest Education Qualification")}
                    </Container>
                    <Container className='input-field-multiple'>
                        {renderInputField("sanginiDob", "Date of Birth", "date")}
                        {renderRadioGroup("sanginiGender", "Gender", [
                            { value: "Female", label: "Female" },
                            { value: "Male", label: "Male" }
                        ])}
                        {renderInputField("sanginiMob", "Mobile Number")}
                        {renderRadioGroup("trainingStatus", "Training Status", [
                            { value: "Confirmed", label: "Confirmed" },
                            { value: "Not Confirmed", label: "Not Confirmed" }
                        ])}
                    </Container>
                    <Container className="input-field-multiple">
                        {renderRadioGroup("padOrCloth", "Type of Product Used", [
                            { value: "Pad", label: "Pad" },
                            { value: "Cloth", label: "Cloth" }
                        ])}
                        {renderRadioGroup("sanginiRole", "Sangini Role", [
                            { value: "NGO", label: "NGO" },
                            { value: "ASHA", label: "ASHA" },
                            { value: "Housewife", label: "Housewife" },
                            { value: "Shop", label: "Shop" },
                            { value: "SHG", label: "SHG" },
                            { value: "HFH", label: "HFH" },
                            { value: "OTHER", label: "Other" }
                        ])}
                        {renderRadioGroup("isSeniorSangini", "Is a Senior Sangini?", [
                            { value: "Y", label: "Yes" },
                            { value: "N", label: "No" }
                        ])}
                    </Container>
                    <Container className="input-field-multiple">
                        {displaySeniorSanginiIdField() && <Form.Group id="sangini-id" className='input-field-single-column input-field-backgroud'>
                            <Form.Label>Senior Sangini Name & Mobile No:</Form.Label>
                            <SeniorSanginiDropdown
                                onChange={handleFormDataChange}
                                value={formData.seniorSanginiId}
                                name="seniorSanginiId"
                                disabled={!isFieldEditable('seniorSanginiId')}
                                currentUser={currentUser}
                            />
                        </Form.Group>}
                        <Form.Group id="sanginiVillage" className="input-field-single-column input-field-backgroud">
                            <Form.Label>Village - Taluka - District - State:</Form.Label>
                            <VillageDetailsDropdown
                                name="sanginiVillage"
                                onChange={handleVillageFormDataChange}
                                value={formData.sanginiVillage}
                                disabled={!isFieldEditable("sanginiVillage")}
                            />
                        </Form.Group>
                        {renderRadioGroup("isProductKnowledgeAssessmentScoreAboveThreshold", "Product Knowledge Assessment Score > 80%?", [
                            { value: "Y", label: "Y" },
                            { value: "N", label: "N" }
                        ])}
                    </Container>
                    <Container className="input-field-multiple">
                        {renderRadioGroup("establishedPartnershipWith5Schools", "Established Partnership With 5 Schools / Community in first 3 months:", [
                            { value: "Y", label: "Y" },
                            { value: "N", label: "N" }
                        ])}
                        {renderRadioGroup("isActive", "Is Sangini Active?", [
                            { value: "Y", label: "Y" },
                            { value: "N", label: "N" }
                        ])}
                        {displayDateOfBeingInactiveField() && renderInputField("inactiveDate", "Date of Being Inactive", "date")}
                        <Form.Group id="remarks" className='input-field-single-row input-field-backgroud'>
                            <Form.Label>Remarks:</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="remarks"
                                value={formData.remarks}
                                onChange={handleFormDataChange}
                                disabled={!isFieldEditable("remarks")}
                                className="input"
                            />
                        </Form.Group>
                    </Container>
                    {errors.length > 0 && <Alert variant="danger">{errors[0]}</Alert>}
                    {
                        message && <Alert variant="info">{message}</Alert>
                    }
                    <Button variant="primary" onClick={handleFormSubmit} className="custom-btn">Edit Sangini Details</Button>
                </Form>
                <Container className="table-wrapper input-grid">
                    <h3>Sangini Target Details</h3>
                    <Table striped bordered hover className='view-table'>
                        <thead>
                            <tr>
                                <th>Target Year</th>
                                <th>Target for Pads</th>
                                <th>Target for Villages</th>
                                <th>Target for Users</th>
                                <th>Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                            {targetsData.map(item => (
                                <tr key={item.sagini_id + item.target_year}>
                                    <td>{item.target_year}</td>
                                    <td>{item.target_for_pads}</td>
                                    <td>{item.target_for_no_of_villages}</td>
                                    <td>{item.target_for_no_of_users}</td>
                                    <td>{item.remarks}</td>

                                    <td>
                                        <Button className='update-custom-btn change-width-btn' disabled={!areTargetsAndSalesEditable()} onClick={() => handleEditTarget(item)}>Edit Target Data</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Container>
                <Container className="table-wrapper input-grid">
                    <h3>Sangini Sales Details</h3>
                    <Table striped bordered hover className='view-table'>
                        <thead>
                            <tr>
                                <th>Sale Date</th>
                                <th>Size</th>
                                <th>Pack Of</th>
                                <th>Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {salesData.map(item => (
                                <tr key={item.sangini_sales_id}>
                                    <td>{new Date(item.date).toLocaleDateString('en-IN', {
                                        day: "numeric",
                                        month: "short",
                                        year: "numeric"
                                    })}</td>
                                    <td>{item.size}</td>
                                    <td>{item.pack_of}</td>
                                    <td>{item.quantity}</td>

                                    <td>
                                        <Button className='update-custom-btn change-width-btn' disabled={!areTargetsAndSalesEditable()} onClick={() => handleEditSale(item)}>Edit Sale Data</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Container>
            </Container>
        </Container>
    );
}

export default ViewSanginiFormCustom;