import http from "../../http-common";

class ReportDataService {
 
  getPlannedImpReport(filter) {
    return http.get(`/reports?filter=${filter}`);
  }

  getOtherReport(filter) {
    return http.get(`/reports/OtherReports?filter=${filter}`);
  }

  getSanginiLevelReport(filter) {
    return http.get(`/reports/SanginiLevelReports?filter=${filter}`);
  }

  getReportMonths() {
    return http.get("/reports/findReportMonth");
  }

  putSanginiLevelReport(filter) {
    return http.post(`/reports/PutSanginiLevelReport?filter=${filter}`);
  }

  getSanginiTop10Report(filter) {
    return http.get(`/reports/SanginiTop10Reports?filter=${filter}`);
  }

  getSanginiBottom10Report(filter) {
    return http.get(`/reports/SanginiBottom10Reports?filter=${filter}`);
  }

  getSanginiKPIReport(filter) {
    return http.get(`/reports/SanginiKPIReport?filter=${filter}`);
  }

  getExecutiveKPIReport(filter) {
    return http.get(`/reports/ExecutiveKPIReport?filter=${filter}`);
  }

  getFundTrackingReport(filter) {
    return http.get(`/reports/FundTrackingReport?filter=${filter}`);
  }

  getRecReport(filter) {
    return http.get(`/reports/ReconReports?filter=${filter}`);
  }
  
  getZoneOverviewReport(filter) {
    return http.get(`/reports/ZoneoverviewReport`);
  }

  getPartnerwiseImpactReport(filter) {
    return http.get(`/reports/PartnerwiseImpactReport?filter=${filter}`);
  }

    getHFHReport(filter) {
      return http.get(`/reports/HFHReport?filter=${filter}`);
    }
  
    getHFHGraph(filter) {
      return http.get(`/reports/HFHGraph?filter=${filter}`);
    }
    getProgramDetailsReport(filter) {
      return http.get(`/reports/ProgramDetailsReport?filter=${filter}`);
    }
}

export default new ReportDataService();