
import React, { useState,useEffect } from 'react';
import { Form, Container, Col, Button, Row, Alert } from 'react-bootstrap';
import axios from 'axios';
import PartnerDetailsDropdown from './PartnerDetailsDropdown';
import ProgramDetailsDropdown from './ProgramDetailsDropdown';
import VillageDetailsDropdown from './VillageDetailsDropdown';
import VillageBlockDropdown from './VillageBlockDropdown';
import VillageDistrictDropdown from './VillageDistrictDropdown';
import VillageStateDropdown from './VillageStateDropdown';
import VillageZoneDropdown from './VillageZoneDropdown';
import './../../styles/Report.css';
import './../../styles/App.css';
import ReportGrid from './ReportGrid';
import ReportDataService from "../services/report.service";
import { useForm } from 'react-hook-form';



function DynamicReport() {


  //Static Data Attributes
  const [staticFormData, setStaticFormData] = useState(
    {
        fromDate: '',
        toDate:'',
        consolidated: '',
        programCode: [],
        partnerCode: [],
        pgmStatus:'',
        villageCode:[],
        villageState:[],
        villageZone:[],
        villageDistrict:[],
        villageBlock:[],
        category:'',
        beneficiary:'',
        reportType:''
    }
  );

  const handleStaticDataChange = (e) => {
    const { name, value } = e.target;
    setStaticFormData({
      ...staticFormData,
      [name]: value
    });
    // console.log("Static data", staticFormData);
  };

  const [programDetails, setProgramDetails] = useState([]);
  const [villageDetails, setVillageDetails] = useState([]);
  const [villageStates, setVillageStates] = useState([]);
  const [villageZones, setVillageZones] = useState([]);
  const [villageDistricts, setVillageDistricts] = useState([]);
  const [villageBlocks, setVillageBlocks] = useState([]);
  const [partnerDetails, setPartnerDetails] = useState([]);
  //const [programList, setProgramList] = useState([]);

  const handleConChange = async (e) => {
    setStaticFormData({
      ...staticFormData,
      consolidated: e.target.value
    })
    /*if (e.target.value !== null || e.target.value !== undefined) {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/programDetails/${e.target.value}`);
      setProgramDetails(response.data);
    }*/
  }

  const handleStatusChange = async (e) => {
    //alert("inside");
    //alert(e.target.value);
    setStaticFormData({
      ...staticFormData,
      pgmStatus: e.target.value
    })
    /*if (e.target.value !== null || e.target.value !== undefined) {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/programDetails/${e.target.value}`);
      setProgramDetails(response.data);
    }*/
  }

  const handleVillageChange = async (e) => {
    staticFormData.villageCode=[];
    let arrVillage=[];
    var i;
    let village_id = e.target.options[e.target.selectedIndex].getAttribute('data_id');
    for (i=0; i<e.target.length; i++) { 
        //alert(count);
        if (e.target.options[i].selected) {
          //alert(e.target.options[i].getAttribute('data_id'));
          arrVillage.push(e.target.options[i].getAttribute('data_id'));
          //alert(e.target.options[i].value);
            
        } 
    }
    console.log("arrVillage",arrVillage);
    setStaticFormData({
      ...staticFormData,
      villageCode : [...staticFormData.villageCode,arrVillage]
      
    }) 
    
  }

  const handleDistrictChange = async (e) => {
    let arrDistrict=[];
    staticFormData.villageDistrict=[];
    var i;
    for (i=0; i<e.target.length; i++) { 
        //alert(count);
        if (e.target.options[i].selected) {
          arrDistrict.push(e.target.options[i].value);
           
        } 
    }
    setStaticFormData({
      ...staticFormData,
      villageDistrict : [...staticFormData.villageDistrict,arrDistrict]
    }) 
    
  }

  const handleBlockChange = async (e) => {
    var i;
    let arrBlock=[];
    staticFormData.villageBlock=[];
    for (i=0; i<e.target.length; i++) { 
        //alert(count);
        if (e.target.options[i].selected) {
          arrBlock.push(e.target.options[i].value);
           
        } 
    } 

    setStaticFormData({
      ...staticFormData,
      villageBlock: [...staticFormData.villageBlock,arrBlock]
    })
    
  }

  const handleStateChange = async (e) => {
    let arrState=[];
    staticFormData.villageState=[];
    var i;
    for (i=0; i<e.target.length; i++) { 
        //alert(count);
        if (e.target.options[i].selected) {
          arrState.push(e.target.options[i].value);
           
        } 
    }
    
    setStaticFormData({
      ...staticFormData,
      villageState : [...staticFormData.villageState,arrState]
    })
    
  }

  const handleZoneChange = async (e) => {
    let arrZone=[];
    var i;
    staticFormData.villageZone=[];
    for (i=0; i<e.target.length; i++) { 
        //alert(count);
        if (e.target.options[i].selected) {
          arrZone.push(e.target.options[i].value);
           
        } 
    }
    
    setStaticFormData({
      ...staticFormData,
      villageZone : [...staticFormData.villageZone,arrZone]
    })
    
  }





  const handleProgramChange = async (e) => {
    let arrProgram=[];
    var i;
    staticFormData.programCode=[];
    for (i=0; i<e.target.length; i++) { 
        //alert(count);
        if (e.target.options[i].selected) {
          arrProgram.push(e.target.options[i].value);
           // alert("inside:",e.target.options[i].value);
            //selectedArray[count] = e.target.options[i].value;
            //setProgramList([...programList, e.target.options[i].value]);
           
        } 
    }
    
    setStaticFormData({
      ...staticFormData,
      //programCode: program_code
      programCode : [...staticFormData.programCode,arrProgram]
    })
   
  }

  const handlePartnerChange = async (e) => {
    let arrPartner=[];
    staticFormData.partnerCode=[];
    //alert("inside3");
    var i;
    for (i=0; i<e.target.length; i++) { 
        //alert(count);
        if (e.target.options[i].selected) {
        arrPartner.push(e.target.options[i].value);
       
        }
    }

    setStaticFormData({
      ...staticFormData,
      partnerCode : [...staticFormData.partnerCode,arrPartner]
      })
  }

  const handleCategoryChange = async (e) => {
    //alert("inside3");
    let category_val = e.target.options[e.target.selectedIndex].value;
    setStaticFormData({
      ...staticFormData,
      category: category_val
    })
  }

  const handleBeneficiaryChange = async (e) => {
    let beneficiary_val = e.target.options[e.target.selectedIndex].value;
    setStaticFormData({
      ...staticFormData,
      beneficiary: beneficiary_val
    })
  }
  
  const handleReportChange = async (e) => {
    let report_val = e.target.options[e.target.selectedIndex].value;
    setStaticFormData({
      ...staticFormData,
      reportType: report_val
    })
  }

  const handleProgChange = async (e) => {
    setStaticFormData({
      ...staticFormData,
      pgmStatus: e.target.value
    })
    /*if (e.target.value !== null || e.target.value !== undefined) {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/programDetails/${e.target.value}`);
      setProgramDetails(response.data);
    }*/
  }

  const handlePartChange = async (e) => {
    setStaticFormData({
      ...staticFormData,
      pgmStatus: e.target.value
    })
    /*if (e.target.value !== null || e.target.value !== undefined) {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/programDetails/${e.target.value}`);
      setProgramDetails(response.data);
    }*/
  }
  



  //Dynamic Village Data Attributes
  const [villageCounter, setVillageCounter] = useState(0);

  const [villageFormData, setVillageFormData] = useState([
    {
      key: 0,
      village: '',
      village_id: 0,
      womenImpactedPlanned: 0,
      childrenImpactedPlanned: 0,
      othersImpactedPlanned: 0,
      remarks1: '',
      budgetAmountPlanned: 0,
      paidFromDFDFTPlanned: 0,
      remarks2: '',
      programPlannedStatus: ''
    }
  ]);


  //Error Handling and Validations
  const [errors, setErrors] = useState([]);

  const [message, setMessage] = useState('');



  const validateStaticData = () => {
    let validMsg = "";

    if (!staticFormData.fromDate && staticFormData.toDate) {
      let error = 'Please Select From Date.';
      setErrors({
        ...errors, error
      })
      //console.log(error);
      validMsg = error;
    }

    if (!staticFormData.toDate && staticFormData.fromDate) {
        let error = 'Please Select To Date.';
        setErrors({
          ...errors, error
        })
        //console.log(error);
        validMsg = error;
      }

      //alert(staticFormData.reportType);
      if (!staticFormData.reportType || staticFormData.reportType==='') {
        let error = 'Please Select Report Type.';
        setErrors({
          ...errors, error
        })
        //console.log(error);
        validMsg = error;
      }

      //alert(staticFormData.pgmStatus);
      //alert(staticFormData.reportType);
      if((staticFormData.reportType==="3"  || staticFormData.reportType==="4" || staticFormData.reportType==="5") && staticFormData.pgmStatus!=='')
      {
        let error = 'Do not Select Status for this Report Type.';
        setErrors({
          ...errors, error
        })
        //console.log(error);
        validMsg = error;
      }

      console.log("category:",staticFormData.category);
      if((staticFormData.reportType==="3"  || staticFormData.reportType==="4" || staticFormData.reportType==="5") && staticFormData.category!=='')
        {
          let error = 'Do not Select Category for this Report Type.';
          setErrors({
            ...errors, error
          })
          //console.log(error);
          validMsg = error;
        }


      if (staticFormData.toDate && staticFormData.fromDate && staticFormData.reportType && ((staticFormData.reportType==="3"  || staticFormData.reportType==="4" || staticFormData.reportType==="5") && staticFormData.category==='') && ((staticFormData.reportType==="3"  || staticFormData.reportType==="4" || staticFormData.reportType==="5") && staticFormData.pgmStatus==='')) {
        let error = ""
        //console.log(error);
        validMsg = error;
      }
      console.log("validMsg",validMsg);
    return validMsg;
  };



  const validateDynamicData = () => {
    let valid = true;

    villageFormData.map((selectedVillage) => {
      if (!selectedVillage.village_id) {
        let error = 'Please select a village.';
        setErrors({
          ...errors, error
        })
        console.log(error);
        valid = false;
      }

      if (!selectedVillage.programPlannedStatus) {
        let error = `Please select status for ${selectedVillage.village}`;
        setErrors({
          ...errors, error
        })
        console.log(error);
        valid = false;
      }
    })

    return valid;
  };

  /*const [rowData, setRowData] = useState([
    { zone:"Zone1",state: "State1", district: "District1", block: "Block1", village_name: "Village1" ,partner_name:"partner_name1",beneficiary:"beneficiary1",planned_health_pgm:"planned_health_pgm1",planned_health_impact:"planned_health_impact1",planned_health_budget:"planned_health_budget1",implemented_health_pgm:"implemented_health_pgm1",implemented_health_impact:"implemented_health_impact1",implemented_health_expense:"implemented_health_expense1",planned_education_pgm:"planned_education_pgm1",planned_education_impact:"planned_education_impact1",planned_education_budget:"planned_education_budget1",implemented_education_pgm:"implemented_education_pgm1",implemented_education_impact:"implemented_education_impact1",implemented_education_expense:"implemented_education_expense1",planned_life_pgm:"planned_life_pgm1",planned_life_impact:"planned_life_impact1",planned_life_budget:"planned_life_budget1",implemented_life_pgm:"implemented_life_pgm1",implemented_life_impact:"implemented_life_impact1",implemented_life_expense:"implemented_life_expense1"},
    {  zone:"Zone2",state: "State2", district: "District2", block: "Block2", village_name: "Village2" ,partner_name:"partner_name1",beneficiary:"beneficiary1",planned_health_pgm:"planned_health_pgm1",planned_health_impact:"planned_health_impact1",planned_health_budget:"planned_health_budget1",implemented_health_pgm:"implemented_health_pgm1",implemented_health_impact:"implemented_health_impact1",implemented_health_expense:"implemented_health_expense1",planned_education_pgm:"planned_education_pgm1",planned_education_impact:"planned_education_impact1",planned_education_budget:"planned_education_budget1",implemented_education_pgm:"implemented_education_pgm1",implemented_education_impact:"implemented_education_impact1",implemented_education_expense:"implemented_education_expense1",planned_life_pgm:"planned_life_pgm1",planned_life_impact:"planned_life_impact1",planned_life_budget:"planned_life_budget1",implemented_life_pgm:"implemented_life_pgm1",implemented_life_impact:"implemented_life_impact1",implemented_life_expense:"implemented_life_expense1"},
    {  zone:"Zone3",state: "State3", district: "District3", block: "Block3", village_name: "Village3" ,partner_name:"partner_name1",beneficiary:"beneficiary1",planned_health_pgm:"planned_health_pgm1",planned_health_impact:"planned_health_impact1",planned_health_budget:"planned_health_budget1",implemented_health_pgm:"implemented_health_pgm1",implemented_health_impact:"implemented_health_impact1",implemented_health_expense:"implemented_health_expense1",planned_education_pgm:"planned_education_pgm1",planned_education_impact:"planned_education_impact1",planned_education_budget:"planned_education_budget1",implemented_education_pgm:"implemented_education_pgm1",implemented_education_impact:"implemented_education_impact1",implemented_education_expense:"implemented_education_expense1",planned_life_pgm:"planned_life_pgm1",planned_life_impact:"planned_life_impact1",planned_life_budget:"planned_life_budget1",implemented_life_pgm:"implemented_life_pgm1",implemented_life_impact:"implemented_life_impact1",implemented_life_expense:"implemented_life_expense1"},
  ]);*/

  const [rowData, setRowData] = useState([]);

  const [colDefs, setColDefs] = useState([]);


  const [catgData, setCatgData] = useState([]);

  useEffect(() => {
    const fetchRows = async () => {
        var dynamicFilters="";
        if(catgData==="H")
            {
                console.log("grid is H")
                handleHChange();
            }
            else
            {
                console.log("grid is All")
                handleAllChange();
            }
        //ReportDataService.getPlannedImpReport(dynamicFilters)
        //.then(function (response) {
           // console.log("recieved data")
           // console.log(response.data)
            //setRowData(response.data);
            
            
          //})
          //.catch(e => {
          //  console.log(e);
          //})
      
    };
    fetchRows();
  }, []);

  const reset=()=> {
    //alert("inside reset");
    document.getElementById("report-form").reset();
    //document.getElementById("from-date").reset();
    setStaticFormData({
        ...staticFormData,
        fromDate: "",
        toDate:"",
        pgmStatus:"",
        consolidated: '',
        programCode: [],
        partnerCode: [],
        pgmStatus:'',
        villageCode:[],
        villageState:[],
        villageZone:[],
        villageDistrict:[],
        villageBlock:[],
        category:'',
        beneficiary:'',
        reportType:''
      })

      let noDataRow=[];
      setRowData(noDataRow);
    
  }

  const handleSubmit = async (e) => {
    let finalValidMsg=validateStaticData();
    //console.log("final")
    e.preventDefault();

    if(finalValidMsg==="")
    {
    setMessage("");
    
    console.log("test array:",staticFormData.partnerCode);

    staticFormData.programCode.map( function(obj){
        if(obj == ''){
            staticFormData.programCode=[];
        }
    });

    staticFormData.partnerCode.map( function(obj){
        if(obj == ''){
            staticFormData.partnerCode=[];
        }
    });

    staticFormData.villageCode.map( function(obj){
        if(obj == ''){
            staticFormData.villageCode=[];
        }
    });

    staticFormData.villageState.map( function(obj){
        if(obj == ''){
            staticFormData.villageState=[];
        }
    });

    staticFormData.villageDistrict.map( function(obj){
        if(obj == ''){
            staticFormData.villageDistrict=[];
        }
    });

    staticFormData.villageZone.map( function(obj){
        if(obj == ''){
            staticFormData.villageZone=[];
        }
    });

    staticFormData.villageBlock.map( function(obj){
        if(obj == ''){
            staticFormData.villageBlock=[];
        }
    });

    console.log("staticFormData.villageCode",staticFormData.villageCode);

    let dynamicDateFrom=staticFormData.fromDate===''?'NULL':staticFormData.fromDate;
    let dynamicDateTo=staticFormData.toDate===''?'NULL':staticFormData.toDate;

    let dynamicVillageId=staticFormData.villageCode.length===0?'NULL':staticFormData.villageCode;
    let dynamicPartnerCode=staticFormData.partnerCode.length===0?'NULL':staticFormData.partnerCode;
    let dynamicProgramCode=staticFormData.programCode.length===0?'NULL':staticFormData.programCode;
    let dynamicPgmStatus=staticFormData.pgmStatus==='' || staticFormData.pgmStatus==='Both' ?'NULL':staticFormData.pgmStatus;
    let dynamicCategory=staticFormData.category===''?'All':staticFormData.category;
    let dynamicBeneficiary=staticFormData.beneficiary===''?'NULL':staticFormData.beneficiary;
    let dynamicVillageState=staticFormData.villageState.length===0?'NULL':staticFormData.villageState;
    let dynamicVillageBlock=staticFormData.villageBlock.length===0?'NULL':staticFormData.villageBlock;
    let dynamicVillageZone=staticFormData.villageZone.length===0?'NULL':staticFormData.villageZone;
    let dynamicVillageDistrict=staticFormData.villageDistrict.length===0?'NULL':staticFormData.villageDistrict;
    let dynamicReportType=staticFormData.reportType===''?'NULL':staticFormData.reportType;
    
    console.log("dynamicBeneficiary:"+dynamicBeneficiary);
    setCatgData(dynamicCategory);
   
//and event_planned_date between '2023-06-01' and '2023-06-30'

//ep.village_id

//ep.partner_code


    var dynamicFilters="'"+dynamicDateFrom+"'"+"|"+"'"+dynamicDateTo+"'"+"|"+"'"+dynamicVillageId+"'"+"|"+"'"+dynamicPartnerCode+"'"+"|"+"'"+dynamicProgramCode+"'"+"|"+"'"+dynamicPgmStatus+"'"+"|"+"'"+dynamicBeneficiary+"'"+"|"+"'"+dynamicVillageState+"'"+"|"+"'"+dynamicVillageBlock+"'"+"|"+"'"+dynamicVillageZone+"'"+"|"+"'"+dynamicVillageDistrict+"'"+"|"+"'"+dynamicReportType+"'";
    
    console.log("dynamicFilters:"+dynamicFilters);
    if(dynamicReportType==="1" || dynamicReportType==="2")
    {
    if(dynamicCategory==="H")
        {
            console.log("grid is H")
            if(dynamicReportType==="1")
            {
                handleHChange();
            }
            else
            {
                handleR2HChange();
            }
        }
    else if(dynamicCategory==="L")
        {
                console.log("grid is L")
                if(dynamicReportType==="1")
                {
                    handleLChange();
                }
                else
                {
                    handleR2LChange();
                }
        }
    else if(dynamicCategory==="E")
        {
                console.log("grid is E")
                if(dynamicReportType==="1")
                {
                    handleEChange();
                }
                else
                {
                    handleR2EChange();
                }
        }
     else
        {
            console.log("grid is All")
            if(dynamicReportType==="1")
            {
                handleAllChange();
            }
            else
            {
                handleR2AllChange();
            }
        }
    }

    if(dynamicReportType==="3")
    {
        handleVolunteerChange();
    }
    else if(dynamicReportType==="4")
    {
        handleInfChange();
    }
    else if(dynamicReportType==="5")
    {
        handleInKindChange();   
    }

        if(dynamicReportType==="1" || dynamicReportType==="2")
        {
            ReportDataService.getPlannedImpReport(dynamicFilters)
            .then(function (response) {
                console.log("recieved data")
                console.log(response.data)
                if(response.data===null || response.data==='')
                {
                    console.log("no data found");
                    let noDataRow=[];
                    setRowData(noDataRow);
                }
                else
                {
                    /*staticFormData.villageCode=[];
                    staticFormData.partnerCode=[];
                    staticFormData.programCode=[];
                    staticFormData.villageBlock=[];
                    staticFormData.villageState=[];
                    staticFormData.villageDistrict=[];
                    staticFormData.villageZone=[];*/
                setRowData(response.data);
                }
                
              })
              .catch(e => {
                console.log(e);
              })
        }
        else
        {
            ReportDataService.getOtherReport(dynamicFilters)
            .then(function (response) {
                console.log("recieved data")
                console.log(response.data)
                if(response.data===null || response.data==='')
                {
                    console.log("no data found");
                    let noDataRow=[];
                    setRowData(noDataRow);
                }
                else
                {
                    /*staticFormData.villageCode=[];
                    staticFormData.partnerCode=[];
                    staticFormData.programCode=[];
                    staticFormData.villageBlock=[];
                    staticFormData.villageState=[];
                    staticFormData.villageDistrict=[];
                    staticFormData.villageZone=[];*/
                setRowData(response.data);
                }
                
              })
              .catch(e => {
                console.log(e);
              })
        }
   
}
else
{
    
    setMessage('Validation Failed: '+ finalValidMsg);
}

  };

  const handleAllChange = () => { 
    let test=[
    { headerName: "Input Parameters", children: [ { field: "zone" ,width: "500px" ,tooltipField: "zone",
        headerTooltip: "Zone"},
        { headerName: "State",field: "state" ,width: "200px",tooltipField: "state",headerTooltip: "State"},
        { headerName: "District",field: "district" ,width: "200px",tooltipField: "district",headerTooltip: "District"},
        { headerName: "Block",field: "block" ,width: "200px",tooltipField: "block",headerTooltip: "Block"},
        { headerName: "Village Name",field: "village_name" ,width: "200px",tooltipField: "village_name",headerTooltip: "Village Name"},
        { headerName: "Partner Name",field: "partner_name" ,width: "200px",tooltipField: "partner_name",headerTooltip: "Partner Name"},
        { headerName: "Program Name",field: "program_name" ,width: "200px",tooltipField: "program_name",headerTooltip: "Program Name"},
        { headerName: "Beneficiary",field: "beneficiary" ,width: "200px",tooltipField: "beneficiary",headerTooltip: "Beneficiary"}] },
    { headerName: "Planned Health",width: "500px", children: [  { headerName:"PGM", field: "planned_health_pgm" ,width: "500px" ,tooltipField: "planned_health_pgm",headerTooltip: "PGM"},
        { headerName:"Impact",field: "planned_health_impact" ,width: "500px",tooltipField: "planned_health_impact",headerTooltip: "Impact"},
        { headerName:"Budget",field: "planned_health_budget" ,width: "500px",tooltipField: "planned_health_budget",headerTooltip: "Budget"}] },
    { headerName: "Implemented Health",width: "500px", children: [   { headerName: "PGM",field: "implemented_health_pgm" ,width: "500px",tooltipField: "implemented_health_pgm",headerTooltip: "PGM"},
        { headerName:"Impact",field: "implemented_health_impact" ,width: "500px",tooltipField: "implemented_health_impact",headerTooltip: "Impact"},
        { headerName:"Expense",field: "implemented_health_expense" ,width: "500px",tooltipField: "implemented_health_expense",headerTooltip: "Expense"}] },
    { headerName: "Planned Education",width: "500px", children: [  { headerName:"PGM",field: "planned_education_pgm" ,width: "500px",tooltipField: "planned_education_pgm",headerTooltip: "PGM"},
        { headerName:"Impact",field: "planned_education_impact" ,width: "500px",tooltipField: "planned_education_impact",headerTooltip: "Impact"},
        { headerName:"Budget",field: "planned_education_budget" ,width: "500px",tooltipField: "planned_education_budget",headerTooltip: "Budget"}] },
    { headerName: "Implemented Education",width: "500px", children: [   { headerName: "PGM",field: "implemented_education_pgm" ,width: "500px",tooltipField: "implemented_education_pgm",headerTooltip: "PGM"},
        { headerName:"Impact",field: "implemented_education_impact" ,width: "500px",tooltipField: "implemented_education_impact",headerTooltip: "Impact"},
        { headerName:"Expense",field: "implemented_education_expense" ,tooltipField: "implemented_education_expense",headerTooltip: "Expense"}] },
    { headerName: "Planned Livelihood",width: "500px", children: [  { headerName: "PGM",field: "planned_life_pgm" ,width: "500px",tooltipField: "planned_life_pgm",headerTooltip: "PGM"},
        { headerName:"Impact",field: "planned_life_impact" ,width: "500px",tooltipField: "planned_life_impact",headerTooltip: "Impact"},
        { headerName:"Budget",field: "planned_life_budget" ,width: "500px",tooltipField: "planned_life_budget",headerTooltip: "Budget"}] },
    { headerName: "Implemented Livelihood",width: "500px", children: [   { headerName: "PGM",field: "implemented_life_pgm" ,width: "500px",tooltipField: "implemented_life_pgm",headerTooltip: "PGM"},
        { headerName:"Impact",field: "implemented_life_impact" ,width: "500px",tooltipField: "implemented_life_impact",headerTooltip: "Impact"},
        { headerName:"Expense",field: "implemented_life_expense" ,width: "500px",tooltipField: "implemented_life_expense",headerTooltip: "Expense"}] },
   
  ];
  setColDefs(test);
}

const handleHChange = () => { 
    console.log("inside h function");
    let test=[
    { headerName: "Input Parameters", children: [ { field: "zone" ,width: "500px" ,tooltipField: "zone",
        headerTooltip: "Zone"},
        { headerName: "State",field: "state" ,width: "200px",tooltipField: "state",headerTooltip: "State"},
        { headerName: "District",field: "district" ,width: "200px",tooltipField: "district",headerTooltip: "District"},
        { headerName: "Block",field: "block" ,width: "200px",tooltipField: "block",headerTooltip: "Block"},
        { headerName: "Village Name",field: "village_name" ,width: "200px",tooltipField: "village_name",headerTooltip: "Village Name"},
        { headerName: "Partner Name",field: "partner_name" ,width: "200px",tooltipField: "partner_name",headerTooltip: "Partner Name"},
        { headerName: "Program Name",field: "program_name" ,width: "200px",tooltipField: "program_name",headerTooltip: "Program Name"},
        { headerName: "Beneficiary",field: "beneficiary" ,width: "200px",tooltipField: "beneficiary",headerTooltip: "Beneficiary"}] },
    { headerName: "Planned Health",width: "500px", children: [  { headerName:"PGM", field: "planned_health_pgm" ,width: "500px" ,tooltipField: "planned_health_pgm",headerTooltip: "PGM"},
        { headerName:"Impact",field: "planned_health_impact" ,width: "500px",tooltipField: "planned_health_impact",headerTooltip: "Impact"},
        { headerName:"Budget",field: "planned_health_budget" ,width: "500px",tooltipField: "planned_health_budget",headerTooltip: "Budget"}] },
    { headerName: "Implemented Health",width: "500px", children: [   { headerName: "PGM",field: "implemented_health_pgm" ,width: "500px",tooltipField: "implemented_health_pgm",headerTooltip: "PGM"},
        { headerName:"Impact",field: "implemented_health_impact" ,width: "500px",tooltipField: "implemented_health_impact",headerTooltip: "Impact"},
        { headerName:"Expense",field: "implemented_health_expense" ,width: "500px",tooltipField: "implemented_health_expense",headerTooltip: "Expense"}] },
    
  ]
  setColDefs(test);
}

const handleEChange = () => { 
    let test=[
    { headerName: "Input Parameters", children: [ { field: "zone" ,width: "500px" ,tooltipField: "zone",
        headerTooltip: "Zone"},
        { headerName: "State",field: "state" ,width: "200px",tooltipField: "state",headerTooltip: "State"},
        { headerName: "District",field: "district" ,width: "200px",tooltipField: "district",headerTooltip: "District"},
        { headerName: "Block",field: "block" ,width: "200px",tooltipField: "block",headerTooltip: "Block"},
        { headerName: "Village Name",field: "village_name" ,width: "200px",tooltipField: "village_name",headerTooltip: "Village Name"},
        { headerName: "Partner Name",field: "partner_name" ,width: "200px",tooltipField: "partner_name",headerTooltip: "Partner Name"},
        { headerName: "Program Name",field: "program_name" ,width: "200px",tooltipField: "program_name",headerTooltip: "Program Name"},
        { headerName: "Beneficiary",field: "beneficiary" ,width: "200px",tooltipField: "beneficiary",headerTooltip: "Beneficiary"}] },
        { headerName: "Planned Education",width: "500px", children: [  { headerName:"PGM",field: "planned_education_pgm" ,width: "500px",tooltipField: "planned_education_pgm",headerTooltip: "PGM"},
            { headerName:"Impact",field: "planned_education_impact" ,width: "500px",tooltipField: "planned_education_impact",headerTooltip: "Impact"},
            { headerName:"Budget",field: "planned_education_budget" ,width: "500px",tooltipField: "planned_education_budget",headerTooltip: "Budget"}] },
        { headerName: "Implemented Education",width: "500px", children: [   { headerName: "PGM",field: "implemented_education_pgm" ,width: "500px",tooltipField: "implemented_education_pgm",headerTooltip: "PGM"},
            { headerName:"Impact",field: "implemented_education_impact" ,width: "500px",tooltipField: "implemented_education_impact",headerTooltip: "Impact"},
            { headerName:"Expense",field: "implemented_education_expense" ,tooltipField: "implemented_education_expense",headerTooltip: "Expense"}] },
    
  ];
  setColDefs(test);

}

const handleLChange = () => { 
    let test=[
    { headerName: "Input Parameters", children: [ { field: "zone" ,width: "500px" ,tooltipField: "zone",
        headerTooltip: "Zone"},
        { headerName: "State",field: "state" ,width: "200px",tooltipField: "state",headerTooltip: "State"},
        { headerName: "District",field: "district" ,width: "200px",tooltipField: "district",headerTooltip: "District"},
        { headerName: "Block",field: "block" ,width: "200px",tooltipField: "block",headerTooltip: "Block"},
        { headerName: "Village Name",field: "village_name" ,width: "200px",tooltipField: "village_name",headerTooltip: "Village Name"},
        { headerName: "Partner Name",field: "partner_name" ,width: "200px",tooltipField: "partner_name",headerTooltip: "Partner Name"},
        { headerName: "Program Name",field: "program_name" ,width: "200px",tooltipField: "program_name",headerTooltip: "Program Name"},
        { headerName: "Beneficiary",field: "beneficiary" ,width: "200px",tooltipField: "beneficiary",headerTooltip: "Beneficiary"}] },
        { headerName: "Planned Livelihood",width: "500px", children: [  { headerName: "PGM",field: "planned_life_pgm" ,width: "500px",tooltipField: "planned_life_pgm",headerTooltip: "PGM"},
            { headerName:"Impact",field: "planned_life_impact" ,width: "500px",tooltipField: "planned_life_impact",headerTooltip: "Impact"},
            { headerName:"Budget",field: "planned_life_budget" ,width: "500px",tooltipField: "planned_life_budget",headerTooltip: "Budget"}] },
        { headerName: "Implemented Livelihood",width: "500px", children: [   { headerName: "PGM",field: "implemented_life_pgm" ,width: "500px",tooltipField: "implemented_life_pgm",headerTooltip: "PGM"},
            { headerName:"Impact",field: "implemented_life_impact" ,width: "500px",tooltipField: "implemented_life_impact",headerTooltip: "Impact"},
            { headerName:"Expense",field: "implemented_life_expense" ,width: "500px",tooltipField: "implemented_life_expense",headerTooltip: "Expense"}] },
    
  ];
  setColDefs(test);

}

const handleR2AllChange = () => { 
    let test=[
    { headerName: "Input Parameters", children: [ { field: "zone" ,width: "500px" ,tooltipField: "zone",
        headerTooltip: "Zone"},
        { headerName: "State",field: "state" ,width: "200px",tooltipField: "state",headerTooltip: "State"},
        { headerName: "District",field: "district" ,width: "200px",tooltipField: "district",headerTooltip: "District"},
        { headerName: "Block",field: "block" ,width: "200px",tooltipField: "block",headerTooltip: "Block"},
        { headerName: "Village Name",field: "village_name" ,width: "200px",tooltipField: "village_name",headerTooltip: "Village Name"},
        { headerName: "Partner Name",field: "partner_name" ,width: "200px",tooltipField: "partner_name",headerTooltip: "Partner Name"},
        { headerName: "Program Name",field: "program_name" ,width: "200px",tooltipField: "program_name",headerTooltip: "Program Name"},
        { headerName: "Beneficiary",field: "beneficiary" ,width: "200px",tooltipField: "beneficiary",headerTooltip: "Beneficiary"}] },
    { headerName: "Planned Health",width: "500px", children: [  { headerName:"PGM", field: "planned_health_pgm" ,width: "500px" ,tooltipField: "planned_health_pgm",headerTooltip: "PGM"},
        { headerName:"Impact",field: "planned_health_impact" ,width: "500px",tooltipField: "planned_health_impact",headerTooltip: "Impact"},
        { headerName:"Budget",field: "planned_health_budget" ,width: "500px",tooltipField: "planned_health_budget",headerTooltip: "Budget"}] },
    { headerName: "Planned Education",width: "500px", children: [  { headerName:"PGM",field: "planned_education_pgm" ,width: "500px",tooltipField: "planned_education_pgm",headerTooltip: "PGM"},
        { headerName:"Impact",field: "planned_education_impact" ,width: "500px",tooltipField: "planned_education_impact",headerTooltip: "Impact"},
        { headerName:"Budget",field: "planned_education_budget" ,width: "500px",tooltipField: "planned_education_budget",headerTooltip: "Budget"}] },
    { headerName: "Planned Livelihood",width: "500px", children: [  { headerName: "PGM",field: "planned_life_pgm" ,width: "500px",tooltipField: "planned_life_pgm",headerTooltip: "PGM"},
        { headerName:"Impact",field: "planned_life_impact" ,width: "500px",tooltipField: "planned_life_impact",headerTooltip: "Impact"},
        { headerName:"Budget",field: "planned_life_budget" ,width: "500px",tooltipField: "planned_life_budget",headerTooltip: "Budget"}] },
    
  ];
  setColDefs(test);
}

const handleR2HChange = () => { 
    console.log("inside h function");
    let test=[
    { headerName: "Input Parameters", children: [ { field: "zone" ,width: "500px" ,tooltipField: "zone",
        headerTooltip: "Zone"},
        { headerName: "State",field: "state" ,width: "200px",tooltipField: "state",headerTooltip: "State"},
        { headerName: "District",field: "district" ,width: "200px",tooltipField: "district",headerTooltip: "District"},
        { headerName: "Block",field: "block" ,width: "200px",tooltipField: "block",headerTooltip: "Block"},
        { headerName: "Village Name",field: "village_name" ,width: "200px",tooltipField: "village_name",headerTooltip: "Village Name"},
        { headerName: "Partner Name",field: "partner_name" ,width: "200px",tooltipField: "partner_name",headerTooltip: "Partner Name"},
        { headerName: "Program Name",field: "program_name" ,width: "200px",tooltipField: "program_name",headerTooltip: "Program Name"},
        { headerName: "Beneficiary",field: "beneficiary" ,width: "200px",tooltipField: "beneficiary",headerTooltip: "Beneficiary"}] },
    { headerName: "Planned Health",width: "500px", children: [  { headerName:"PGM", field: "planned_health_pgm" ,width: "500px" ,tooltipField: "planned_health_pgm",headerTooltip: "PGM"},
        { headerName:"Impact",field: "planned_health_impact" ,width: "500px",tooltipField: "planned_health_impact",headerTooltip: "Impact"},
        { headerName:"Budget",field: "planned_health_budget" ,width: "500px",tooltipField: "planned_health_budget",headerTooltip: "Budget"}] },
    { headerName: "Implemented Health",width: "500px", children: [   { headerName: "PGM",field: "implemented_health_pgm" ,width: "500px",tooltipField: "implemented_health_pgm",headerTooltip: "PGM"},
        { headerName:"Impact",field: "implemented_health_impact" ,width: "500px",tooltipField: "implemented_health_impact",headerTooltip: "Impact"},
        { headerName:"Expense",field: "implemented_health_expense" ,width: "500px",tooltipField: "implemented_health_expense",headerTooltip: "Expense"}] },
    
  ]
  setColDefs(test);
}

const handleR2EChange = () => { 
    let test=[
    { headerName: "Input Parameters", children: [ { field: "zone" ,width: "500px" ,tooltipField: "zone",
        headerTooltip: "Zone"},
        { headerName: "State",field: "state" ,width: "200px",tooltipField: "state",headerTooltip: "State"},
        { headerName: "District",field: "district" ,width: "200px",tooltipField: "district",headerTooltip: "District"},
        { headerName: "Block",field: "block" ,width: "200px",tooltipField: "block",headerTooltip: "Block"},
        { headerName: "Village Name",field: "village_name" ,width: "200px",tooltipField: "village_name",headerTooltip: "Village Name"},
        { headerName: "Partner Name",field: "partner_name" ,width: "200px",tooltipField: "partner_name",headerTooltip: "Partner Name"},
        { headerName: "Program Name",field: "program_name" ,width: "200px",tooltipField: "program_name",headerTooltip: "Program Name"},
        { headerName: "Beneficiary",field: "beneficiary" ,width: "200px",tooltipField: "beneficiary",headerTooltip: "Beneficiary"}] },
        { headerName: "Planned Education",width: "500px", children: [  { headerName:"PGM",field: "planned_education_pgm" ,width: "500px",tooltipField: "planned_education_pgm",headerTooltip: "PGM"},
            { headerName:"Impact",field: "planned_education_impact" ,width: "500px",tooltipField: "planned_education_impact",headerTooltip: "Impact"},
            { headerName:"Budget",field: "planned_education_budget" ,width: "500px",tooltipField: "planned_education_budget",headerTooltip: "Budget"}] },
        { headerName: "Implemented Education",width: "500px", children: [   { headerName: "PGM",field: "implemented_education_pgm" ,width: "500px",tooltipField: "implemented_education_pgm",headerTooltip: "PGM"},
            { headerName:"Impact",field: "implemented_education_impact" ,width: "500px",tooltipField: "implemented_education_impact",headerTooltip: "Impact"},
            { headerName:"Expense",field: "implemented_education_expense" ,tooltipField: "implemented_education_expense",headerTooltip: "Expense"}] },
    
  ];
  setColDefs(test);

}

const handleR2LChange = () => { 
    let test=[
    { headerName: "Input Parameters", children: [ { field: "zone" ,width: "500px" ,tooltipField: "zone",
        headerTooltip: "Zone"},
        { headerName: "State",field: "state" ,width: "200px",tooltipField: "state",headerTooltip: "State"},
        { headerName: "District",field: "district" ,width: "200px",tooltipField: "district",headerTooltip: "District"},
        { headerName: "Block",field: "block" ,width: "200px",tooltipField: "block",headerTooltip: "Block"},
        { headerName: "Village Name",field: "village_name" ,width: "200px",tooltipField: "village_name",headerTooltip: "Village Name"},
        { headerName: "Partner Name",field: "partner_name" ,width: "200px",tooltipField: "partner_name",headerTooltip: "Partner Name"},
        { headerName: "Program Name",field: "program_name" ,width: "200px",tooltipField: "program_name",headerTooltip: "Program Name"},
        { headerName: "Beneficiary",field: "beneficiary" ,width: "200px",tooltipField: "beneficiary",headerTooltip: "Beneficiary"}] },
        { headerName: "Planned Livelihood",width: "500px", children: [  { headerName: "PGM",field: "planned_life_pgm" ,width: "500px",tooltipField: "planned_life_pgm",headerTooltip: "PGM"},
            { headerName:"Impact",field: "planned_life_impact" ,width: "500px",tooltipField: "planned_life_impact",headerTooltip: "Impact"},
            { headerName:"Budget",field: "planned_life_budget" ,width: "500px",tooltipField: "planned_life_budget",headerTooltip: "Budget"}] },
        { headerName: "Implemented Livelihood",width: "500px", children: [   { headerName: "PGM",field: "implemented_life_pgm" ,width: "500px",tooltipField: "implemented_life_pgm",headerTooltip: "PGM"},
            { headerName:"Impact",field: "implemented_life_impact" ,width: "500px",tooltipField: "implemented_life_impact",headerTooltip: "Impact"},
            { headerName:"Expense",field: "implemented_life_expense" ,width: "500px",tooltipField: "implemented_life_expense",headerTooltip: "Expense"}] },
    
  ];
  setColDefs(test);

}

const handleVolunteerChange = () => { 
    let test=[
    { headerName: "Input Parameters", children: [ { field: "zone" ,width: "500px" ,tooltipField: "zone",
        headerTooltip: "Zone"},
        { headerName: "State",field: "state" ,width: "200px",tooltipField: "state",headerTooltip: "State"},
        { headerName: "District",field: "district" ,width: "200px",tooltipField: "district",headerTooltip: "District"},
        { headerName: "Block",field: "block" ,width: "200px",tooltipField: "block",headerTooltip: "Block"},
        { headerName: "Village Name",field: "village_name" ,width: "200px",tooltipField: "village_name",headerTooltip: "Village Name"},
        { headerName: "Partner Name",field: "partner_name" ,width: "200px",tooltipField: "partner_name",headerTooltip: "Partner Name"},
        { headerName: "Program Name",field: "program_name" ,width: "200px",tooltipField: "program_name",headerTooltip: "Program Name"},
        { headerName: "Health",field: "implemented_health_pgm" ,width: "200px",tooltipField: "implemented_health_pgm",headerTooltip: "Health"},
        {headerName: "Livelihood",field: "implemented_Livelihood_pgm" ,width: "200px",tooltipField: "implemented_Livelihood_pgm",headerTooltip: "Livelihood"},
        {headerName: "Education",field: "implemented_education_pgm" ,width: "200px",tooltipField: "implemented_education_pgm",headerTooltip: "Education"},
        {headerName: "Women",field: "women_impact" ,width: "200px",tooltipField: "implemented_health_pgm",headerTooltip: "Women"},
        {headerName: "Children",field: "children_impact" ,width: "200px",tooltipField: "implemented_health_pgm",headerTooltip: "Children"},
        {headerName: "Others",field: "others_impact" ,width: "200px",tooltipField: "implemented_health_pgm",headerTooltip: "Others"},
    ] },
    { headerName: "Adult",width: "500px", children: [  { headerName:"#Volunteer", field: "no_of_adult_volunteers" ,width: "500px" ,tooltipField: "no_of_adult_volunteers",headerTooltip: "#Volunteer"},
        { headerName:"#Hours",field: "hours_spend_by_adult_volunteers" ,width: "500px",tooltipField: "hours_spend_by_adult_volunteers",headerTooltip: "#Hours"},
        { headerName:"Amount",field: "amount_spend_adult_volunteer" ,width: "500px",tooltipField: "amount_spend_adult_volunteer",headerTooltip: "Amount"}] },
    { headerName: "High School",width: "500px", children: [  { headerName:"#Volunteer",field: "no_of_high_school_volunteers" ,width: "500px",tooltipField: "no_of_high_school_volunteers",headerTooltip: "#Volunteer"},
        { headerName:"#Hours",field: "hours_spend_by_high_school_volunteers" ,width: "500px",tooltipField: "hours_spend_by_high_school_volunteers",headerTooltip: "#Hours"},
        { headerName:"Amount",field: "amount_spend_high_school_volunteers" ,width: "500px",tooltipField: "amount_spend_high_school_volunteers",headerTooltip: "Amount"}] },
    { headerName: "College Students",width: "500px", children: [  { headerName: "#Volunteer",field: "no_of_colledge_volunteers" ,width: "500px",tooltipField: "no_of_colledge_volunteers",headerTooltip: "#Volunteer"},
        { headerName:"#Hours",field: "hours_spend_by_colledge_volunteers" ,width: "500px",tooltipField: "hours_spend_by_colledge_volunteers",headerTooltip: "#Hours"},
        { headerName:"Amount",field: "amount_spend_colledge_volunteers" ,width: "500px",tooltipField: "amount_spend_colledge_volunteers",headerTooltip: "Amount"}] },
    { headerName: "Professionals",width: "500px", children: [  { headerName: "#Volunteer",field: "no_of_professional_volunteers" ,width: "500px",tooltipField: "no_of_professional_volunteers",headerTooltip: "#Volunteer"},
        { headerName:"#Hours",field: "hours_spend_by_professional_volunteers" ,width: "500px",tooltipField: "hours_spend_by_professional_volunteers",headerTooltip: "#Hours"},
        { headerName:"Amount",field: "amount_spend_professional_volunteers" ,width: "500px",tooltipField: "amount_spend_professional_volunteers",headerTooltip: "Amount"}] },
    { headerName: "Executives",width: "500px", children: [  { headerName: "#Volunteer",field: "no_of_executive_volunteers" ,width: "500px",tooltipField: "no_of_professional_volunteers",headerTooltip: "#Volunteer"},
        { headerName:"#Hours",field: "hours_spend_by_executive_volunteers" ,width: "500px",tooltipField: "hours_spend_by_executive_volunteers",headerTooltip: "#Hours"},
        { headerName:"Amount",field: "amount_spend_executive_volunteers" ,width: "500px",tooltipField: "amount_spend_executive_volunteers",headerTooltip: "Amount"}] },
    { headerName: "Male",width: "500px", children: [  { headerName: "#Volunteer",field: "total_male_volunteers" ,width: "500px",tooltipField: "total_male_volunteers",headerTooltip: "#Volunteer"}] },
    { headerName: "Female",width: "500px", children: [  { headerName: "#Volunteer",field: "total_female_volunteers" ,width: "500px",tooltipField: "total_female_volunteers",headerTooltip: "#Volunteer"}] },
  ];
  setColDefs(test);
}

const handleInfChange = () => { 
    let test=[
        { headerName: "Input Parameters", children: [ { field: "zone" ,width: "500px" ,tooltipField: "zone",
            headerTooltip: "Zone"},
            { headerName: "State",field: "state" ,width: "200px",tooltipField: "state",headerTooltip: "State"},
            { headerName: "District",field: "district" ,width: "200px",tooltipField: "district",headerTooltip: "District"},
            { headerName: "Block",field: "block" ,width: "200px",tooltipField: "block",headerTooltip: "Block"},
            { headerName: "Village Name",field: "village_name" ,width: "200px",tooltipField: "village_name",headerTooltip: "Village Name"},
            { headerName: "Partner Name",field: "partner_name" ,width: "200px",tooltipField: "partner_name",headerTooltip: "Partner Name"},
            { headerName: "Program Name",field: "program_name" ,width: "200px",tooltipField: "program_name",headerTooltip: "Program Name"},
            { headerName: "Health",field: "implemented_health_pgm" ,width: "200px",tooltipField: "implemented_health_pgm",headerTooltip: "Health"},
            {headerName: "Livelihood",field: "implemented_Livelihood_pgm" ,width: "200px",tooltipField: "implemented_Livelihood_pgm",headerTooltip: "Livelihood"},
            {headerName: "Education",field: "implemented_education_pgm" ,width: "200px",tooltipField: "implemented_education_pgm",headerTooltip: "Education"},
            {headerName: "Women",field: "women_impact" ,width: "200px",tooltipField: "implemented_health_pgm",headerTooltip: "Women"},
            {headerName: "Children",field: "children_impact" ,width: "200px",tooltipField: "implemented_health_pgm",headerTooltip: "Children"},
            {headerName: "Others",field: "others_impact" ,width: "200px",tooltipField: "implemented_health_pgm",headerTooltip: "Others"},
        ] },
        { headerName: "Health Van",width: "500px", children: [  { headerName:"#Van", field: "no_of_health_vans" ,width: "500px" ,tooltipField: "no_of_health_vans",headerTooltip: "#Van"},
            { headerName:"Amount",field: "amount_for_health_vans" ,width: "500px",tooltipField: "amount_for_health_vans",headerTooltip: "Amount"}] },
        { headerName: "Buildings",width: "500px", children: [  { headerName:"#Buildings",field: "no_of_buildings" ,width: "500px",tooltipField: "no_of_buildings",headerTooltip: "#Buildings"},
            { headerName:"Amount",field: "amount_for_buildings" ,width: "500px",tooltipField: "amount_for_buildings",headerTooltip: "Amount"}] },
        { headerName: "Grounds",width: "500px", children: [  { headerName: "#Ground",field: "no_of_grounds" ,width: "500px",tooltipField: "no_of_grounds",headerTooltip: "#Ground"},
            { headerName:"Amount",field: "amount_for_grounds" ,width: "500px",tooltipField: "amount_for_grounds",headerTooltip: "Amount"}] },
        { headerName: "Others",width: "500px", children: [  { headerName: "#Volunteer",field: "no_of_other_infrastructure" ,width: "500px",tooltipField: "no_of_other_infrastructure",headerTooltip: "#Volunteer"},
            { headerName:"#Hours",field: "amount_for_other_infrastructure" ,width: "500px",tooltipField: "amount_for_other_infrastructure",headerTooltip: "#Hours"}] },
        
      ];
      setColDefs(test);
}

const handleInKindChange = () => { 
    let test=[
        { headerName: "Input Parameters", children: [ { field: "zone" ,width: "500px" ,tooltipField: "zone",
            headerTooltip: "Zone"},
            { headerName: "State",field: "state" ,width: "200px",tooltipField: "state",headerTooltip: "State"},
            { headerName: "District",field: "district" ,width: "200px",tooltipField: "district",headerTooltip: "District"},
            { headerName: "Block",field: "block" ,width: "200px",tooltipField: "block",headerTooltip: "Block"},
            { headerName: "Village Name",field: "village_name" ,width: "200px",tooltipField: "village_name",headerTooltip: "Village Name"},
            { headerName: "Partner Name",field: "partner_name" ,width: "200px",tooltipField: "partner_name",headerTooltip: "Partner Name"},
            { headerName: "Program Name",field: "program_name" ,width: "200px",tooltipField: "program_name",headerTooltip: "Program Name"},
            { headerName: "Health",field: "implemented_health_pgm" ,width: "200px",tooltipField: "implemented_health_pgm",headerTooltip: "Health"},
            {headerName: "Livelihood",field: "implemented_Livelihood_pgm" ,width: "200px",tooltipField: "implemented_Livelihood_pgm",headerTooltip: "Livelihood"},
            {headerName: "Education",field: "implemented_education_pgm" ,width: "200px",tooltipField: "implemented_education_pgm",headerTooltip: "Education"},
            {headerName: "Women",field: "women_impact" ,width: "200px",tooltipField: "implemented_health_pgm",headerTooltip: "Women"},
            {headerName: "Children",field: "children_impact" ,width: "200px",tooltipField: "implemented_health_pgm",headerTooltip: "Children"},
            {headerName: "Others",field: "others_impact" ,width: "200px",tooltipField: "implemented_health_pgm",headerTooltip: "Others"},
        ] },
        { headerName: "Food Events",width: "500px", children: [  { headerName:"#Food Events", field: "no_of_food_events" ,width: "500px" ,tooltipField: "no_of_food_events",headerTooltip: "#Food Events"},
            { headerName:"Amount",field: "amount_for_food_events" ,width: "500px",tooltipField: "amount_for_food_events",headerTooltip: "Amount"}] },
        { headerName: "Goods",width: "500px", children: [  { headerName:"#Goods",field: "no_of_goods" ,width: "500px",tooltipField: "no_of_goods",headerTooltip: "#Goods"},
            { headerName:"Amount",field: "amount_for_goods" ,width: "500px",tooltipField: "amount_for_goods",headerTooltip: "Amount"}] },
        { headerName: "Tents",width: "500px", children: [  { headerName: "#Tents",field: "no_of_tents" ,width: "500px",tooltipField: "no_of_tents",headerTooltip: "#Tents"},
            { headerName:"Amount",field: "amount_for_tents" ,width: "500px",tooltipField: "amount_for_tents",headerTooltip: "Amount"}] },
        { headerName: "Decoration",width: "500px", children: [  { headerName: "#Decoration",field: "no_of_decorations" ,width: "500px",tooltipField: "no_of_decorations",headerTooltip: "#Decoration"},
            { headerName:"Amount",field: "amount_for_decorations" ,width: "500px",tooltipField: "amount_for_decorations",headerTooltip: "Amount"}] },
        { headerName: "Instruments",width: "500px", children: [  { headerName: "#Instruments",field: "no_of_instruments" ,width: "500px",tooltipField: "no_of_instruments",headerTooltip: "#Instruments"},
            { headerName:"Amount",field: "amount_for_instruments" ,width: "500px",tooltipField: "amount_for_instruments",headerTooltip: "Amount"}] },
        { headerName: "Other In-Kind",width: "500px", children: [  { headerName: "#Others",field: "no_of_other_inkind_type" ,width: "500px",tooltipField: "no_of_other_inkind_type",headerTooltip: "#Others"},
            { headerName:"Amount",field: "amount_for_inkind_type" ,width: "500px",tooltipField: "amount_for_inkind_type",headerTooltip: "Amount"}] },
      ];
      setColDefs(test);
}



  return (

    <Container className='report-page'>
      <Form id='report-form' onSubmit={handleSubmit} >
      
        {
          message && <Alert variant="info">{message}</Alert>
        }
        
        <Container className='input-grid'>
        <Container className='input-field-multiple'>
          <Form.Group id="header-text" className='header-input-field-single-row input-field-backgroud'>
              <Form.Label className='report-header'>Dynamic Reports</Form.Label>
            
            </Form.Group>

          </Container>
          <Container className='input-field-multiple'>
          <Form.Group id="reportType" className='input-field-single-column input-field-backgroud'>
            <Form.Label>Report Type:</Form.Label>
            <Form.Control className='input' as="select" name="reportType" onChange={handleReportChange}>
                <option value="">Select Report Type</option>
                <option value="1">Planned Versus Implemented Report</option>
                <option value="2">Program Status Report</option>
                <option value="3">Volunteer Report</option>
                <option value="4">Infrastructure Report</option>
                <option value="5">In-Kind Donations Report</option>
              
            </Form.Control>
    
            </Form.Group>
          <Form.Group id="from-date" className='input-field-single-row input-field-backgroud'>
              <Form.Label>From Date:</Form.Label>
              <Form.Control
                type="date"
                name="fromDate"
                value={staticFormData.fromDate}
                onChange={handleStaticDataChange}
                className="input" />
            </Form.Group>

            <Form.Group id="to-date" className='input-field-single-row input-field-backgroud'>
              <Form.Label>To Date:</Form.Label>
              <Form.Control
                type="date"
                name="toDate"
                value={staticFormData.toDate}
                onChange={handleStaticDataChange}
                className="input" />
            </Form.Group>
          </Container>



          <Container className='input-field-multiple'>
            
          <Form.Group id="consolidated" className='input-field-single-row input-field-backgroud' >
              <Form.Label >Consolidated:</Form.Label>
              <Col className="radio-list">
                <Form.Check
                  type="radio"
                  label="Yes"
                  name="chkconsolidated"
                  value="Y"
                  checked={staticFormData.consolidated === 'Y'}
                  id="yes"
                  onChange={handleConChange}
                />
                <Form.Check
                  type="radio"
                  label="No"
                  name="chkconsolidated"
                  value="N"
                  checked={staticFormData.consolidated === 'N'}
                  id="no"
                  onChange={handleConChange}
                />
              </Col>
            </Form.Group>
            <Form.Group id="village-code" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Village Code/Name:</Form.Label>
              <VillageDetailsDropdown
                name="villageCode"
                onChange={handleVillageChange}
                villageDetails={villageDetails}
                setVillageDetails={setVillageDetails}
              />
            </Form.Group>

            <Form.Group id="category" className='input-field-single-column input-field-backgroud'>
            <Form.Label>Category:</Form.Label>
            <Form.Control className='input' as="select" name="category" multiple={true} onChange={handleCategoryChange}>
                <option value="All">All</option>
                <option value="H">Health</option>
                <option value="L">Livelihood</option>
                <option value="E">Education</option>
              
            </Form.Control>
    
            </Form.Group>
            
            <Form.Group id="beneficiary" className='input-field-single-column input-field-backgroud'>
            <Form.Label>Beneficiary Type:</Form.Label>
            <Form.Control className='input' as="select" name="beneficiary" multiple={true} onChange={handleBeneficiaryChange}>
                <option value="">All</option>
                <option value="1">Women</option>
                <option value="2">Children</option>
                <option value="3">Others</option>
              
            </Form.Control>
    
            </Form.Group>

          </Container>
        

          <Container className='input-field-multiple'>
          <Form.Group id="village-block" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Village Block:</Form.Label>
              <VillageBlockDropdown
                name="villageBlock"
                onChange={handleBlockChange}
                villageBlocks={villageBlocks}
                setVillageBlocks={setVillageBlocks}
              />
            </Form.Group>
            <Form.Group id="village-district" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Village District:</Form.Label>
              <VillageDistrictDropdown
                name="villageDistrict"
                onChange={handleDistrictChange}
                villageDistricts={villageDistricts}
                setVillageDistricts={setVillageDistricts}
              />
            </Form.Group>

            <Form.Group id="village-state" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Village State:</Form.Label>
              <VillageStateDropdown
                name="villageState"
                onChange={handleStateChange}
                villageStates={villageStates}
                setVillageStates={setVillageStates}
              />
            </Form.Group>
            
            <Form.Group id="village-zone" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Village Zone:</Form.Label>
              <VillageZoneDropdown
                name="villageZone"
                onChange={handleZoneChange}
                villageZones={villageZones}
                setVillageZones={setVillageZones}
              />
            </Form.Group>


          </Container>

        
          <Container className='input-field-multiple'>
            <Form.Group id="program-code" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Program Code/Name:</Form.Label>
              <ProgramDetailsDropdown
                name="programCode"
                onChange={handleProgramChange}
                programDetails={programDetails}
                setProgramDetails={setProgramDetails}
              />
            </Form.Group>


            <Form.Group id="partner-code" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Partner Code/Name:</Form.Label>
              <PartnerDetailsDropdown
                name="partnerCode"
                onChange={handlePartnerChange}
                partnerDetails={partnerDetails}
                setPartnerDetails={setPartnerDetails}
              />
            </Form.Group>

            <Form.Group id="planned-pgm-status" className='input-field-single-row input-field-backgroud'>
              <Form.Label >Planned PGM Status:</Form.Label>
              <Col className="radio-list">
                <Form.Check
                  type="radio"
                  label="P"
                  name="chkstatus"
                  value="P"
                  checked={staticFormData.pgmStatus === 'P'}
                  id="p"
                  onChange={handleStatusChange}
                />
                <Form.Check
                  type="radio"
                  label="U"
                  name="chkstatus"
                  value="U"
                  checked={staticFormData.pgmStatus === 'U'}
                  id="u"
                  onChange={handleStatusChange}
                />
                 <Form.Check
                  type="radio"
                  label="Both"
                  name="chkstatus"
                  value="Both"
                  checked={staticFormData.pgmStatus === 'Both'}
                  id="both"
                  onChange={handleStatusChange}
                />
              </Col>
            </Form.Group>
          </Container>
        </Container>

    <Container>
        <Form.Group id="planned-pgm-status" className='input-field-single-row input-field-backgroud'>
        <Button variant="primary" type="submit" className="custom-btn">Submit</Button>
        <Button type="button" className="custom-btn" onClick={() => reset()}>Reset</Button>
       </Form.Group>
    </Container>
       <ReportGrid rowData={rowData} colDefs={colDefs} isScroll='scroll' grdWidth='3600px'/>
      </Form>
    </Container>
    
  );
}

export default DynamicReport;
