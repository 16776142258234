import http from "../../http-common";

class FundReleasedDataService {
  getAll() {
    return http.get("/fundReleasedRecords");
  }

  create(data) {
    return http.post("/fundReleasedRecords", data);
  }

  update(id, data) {
    return http.put("/fundReleasedRecords/${id}", data);
  }

  delete(id) {
    return http.delete("/fundReleasedRecords/${id}");
  }

  deleteAll() {
    return http.delete("/fundReleasedRecords");
  }
}

export default new FundReleasedDataService();