import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Container, Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SanginiDataService from './../../services/sangini_details.service';
import SanginiUserDetailsDataService from './../../services/sangini_user_details.service.js';
import VillageDetailsDropdown from '../programPlanningData/VillageDetailsDropdown.js';
import VillageDataService from './../../services/village.service.js';

const ViewUserData = ({ sanginiName, userData, show, handleClose }) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [roleId, setRoleId] = useState(0);
    const [villageName, setVillageName] = useState('');
    const [newData, setNewData] = useState({ ...userData[0], sanginiName: sanginiName });
    const [initialData, setInitialData] = useState({ ...userData[0], sanginiName: sanginiName });
    const [errors, setErrors] = useState([]);
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (currentUser) {
            setRoleId(currentUser.role_id);
        }
        getVillageDataById();
        setNewData({ ...userData[0], sanginiName: sanginiName });
        setInitialData({ ...userData[0], sanginiName: sanginiName });
    }, [currentUser, userData, sanginiName]);

    const getVillageDataById = async () => {
        try {
            const response = await VillageDataService.get(userData[0].village_id);
            setVillageName(response.data.village_name);
        } catch (error) {
            console.log("Error fetching village details", error);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const isFieldEditable = (fieldName) => {
        if (fieldName === "sanginiName") return false; // Sangini Name is never editable
        if (roleId === 2) return true; // Program manager can edit all fields
        if (roleId === 1) {
            // Program executive can only edit empty fields
            const initialValue = initialData[fieldName];
            if (fieldName === 'feedback' || fieldName === 'feedback_remark') {
                return !initialValue || initialValue.trim() === '';
            }
            if (typeof initialValue === 'number') {
                return initialValue === 0;
            }
            return !initialValue;
        }
        return false; // Default to not editable
    };

    const validateData = async () => {
        const newErrors = [];
        const mobileRegex = /^[0-9]{10}$/; // Regex for validating a 10-digit mobile number

        if (!newData.mobileno) {
            newErrors.push('Please enter mobile no.');
        } else if (!mobileRegex.test(newData.mobileno)) {
            newErrors.push('Mobile No. must be a valid 10-digit number.');
        }

        if (newData.mobileno !== initialData.mobileno) {
            try {
                const response = await SanginiDataService.findAllByMobileNo(newData.mobileno);
                if (response.data.length > 0) {
                    newErrors.push('Mobile No. Already Exists!!');
                }
            } catch (e) {
                console.log(e)
            }

            try {
                const response = await SanginiUserDetailsDataService.findAllByMobileNo(newData.mobileno);
                if (response.data.length > 0) {
                    newErrors.push('Mobile No. Already Exists!!');
                }
            } catch (e) {
                console.log(e)
            }
        }

        if (!newData.village_id) {
            newErrors.push('Please select a village.');
        }
        if(userData.dob >= new Date().toLocaleDateString('en-CA')){
            newErrors.push('Enter valid date of birth!')
        }
    
        if (!userData.date_user_added) {
            newErrors.push('Please enter a joining date.');
        }
        if (userData.date_user_added <= userData.dob) {
            newErrors.push('Please enter valid joining date! Cannot be on or before date of birth!!');
        }


        setErrors(newErrors);
        return newErrors.length === 0;
    }

    const handleEdit = async (e) => {
        e.preventDefault();
        if (await validateData()) {
            try {
                await SanginiUserDetailsDataService.update(newData.user_id, newData);
                setMessage('User edited successfully!');
                setTimeout(() => {
                    handleClose();
                }, 2000);
            } catch (error) {
                setMessage('Error editing user data.');
                console.error('Error editing user data:', error);
            }
        }
    };

    const renderField = (fieldName, label, type = "text", as = "input") => (
        <Form.Group id={fieldName} className='input-field-single-column input-field-backgroud'>
            <Form.Label>{label}:</Form.Label>
            <Form.Control
                type={type}
                as={as}
                name={fieldName}
                value={type === "date" ? new Date(newData[fieldName]).toLocaleDateString('en-CA') : newData[fieldName] || ''}
                onChange={handleChange}
                disabled={!isFieldEditable(fieldName)}
                className='input'
            />
        </Form.Group>
    );

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>User Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className='input-grid'>
                    <Form className='view-user-data'>
                        <Container className='input-field-multiple'>
                            {renderField("sanginiName", "Sangini Name", "text")}
                            {renderField("user_name", "User Name", "text")}
                            {renderField("dob", "Date of Birth", "date")}
                        </Container>

                        <Container className='input-field-multiple'>
                            {renderField("mobileno", "Mobile Number", "text")}
                            <Form.Group id="village-id" className='input-field-single-column input-field-backgroud'>
                                <Form.Label>Village Name:</Form.Label>
                                <VillageDetailsDropdown
                                    name="village_id"
                                    onChange={handleChange}
                                    value={newData.village_id}
                                    disabled={!isFieldEditable("village_id")}
                                />
                            </Form.Group>
                            {renderField("pad_cloth", "Pad/Cloth", "text")}
                        </Container>

                        <Container className='input-field-multiple'>
                            {renderField("brand_of_pad", "Brand of Pad", "text")}
                            {renderField("feedback", "Feedback", "text", "textarea")}
                            {renderField("date_user_added", "Date User Added", "date")}
                        </Container>

                        <Container className='input-field-multiple'>
                            {renderField("is_educated_flag", "Is User Educated?", "text")}
                            <Form.Group id="feedback-rating" className='input-field-single-column input-field-backgroud'>
                                <Form.Label>Feedback Rating (Training):</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="feedback_rating_from_user"
                                    value={newData.feedback_rating_from_user || 1} // Default to 1
                                    onChange={handleChange}
                                    disabled={!isFieldEditable("feedback_rating_from_user")}
                                >
                                    {[1, 2, 3, 4, 5].map(rating => (
                                        <option key={rating} value={rating}>{rating}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            {renderField("feedback_remark", "Feedback Remark (Training)", "text", "textarea")}
                        </Container>
                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                {errors.length > 0 && <Alert variant="danger">{errors[0]}</Alert>}
                {message && <Alert variant="info">{message}</Alert>}
                <Button className='program-planning-custom-btn change-width-btn' variant="secondary" onClick={handleClose}>
                    Back
                </Button>
                <Button className='program-planning-custom-btn change-width-btn' variant="primary" onClick={handleEdit}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ViewUserData;
