import axios from "axios";

export default axios.create({
  baseURL: "http://97.74.81.104:8080/api",
  headers: {
    "Content-type": "application/json",
    "Connection": "keep-alive"
  },
  timeout: 2147483647,
});
