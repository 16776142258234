import http from "../../http-common";

class EventMatrixDataService {

  get(id) {
    return http.get(`/event_matrix_data/${id}`);
  }

  create(data) {
    return http.post("/event_matrix_data", data);
  }

  update(id, data) {
    return http.put(`/event_matrix_data/${id}`, data);
  }

}

export default new EventMatrixDataService();