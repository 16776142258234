import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import SanginiRegistrationService from "../services/sanginiRegistration.service";
import moment from 'moment-timezone';
import "../../styles/forms/sanginiData/sanginiData.css";
import SeniorSanginiDropdown from "../forms/sanginiData/SeniorSanginiDropdown";

function AfterBecomingSanginiFormCustom() {
    const { sangini_id } = useParams();
    const { user: currentUser } = useSelector((state) => state.auth);
    const [roleId, setRoleId] = useState(0);
    const [initialFormData, setInitialFormData] = useState({});

    const [formData, setFormData] = useState({
        sanginiId: sangini_id,
        sanginiName: '',
        sanginiJoiningDate: '',
        highestEducation: '',
        sanginiRole: '',
        isSeniorSangini: '',
        seniorSanginiId: '',
        isProductKnowledgeAssessmentScoreAboveThreshold: '',
        establishedPartnershipWith5Schools: '',
        isActive: '',
        inactiveDate: '',
        remarks: '',
        updated_by: currentUser?.id
    });

    const [errors, setErrors] = useState([]);
    const [message, setMessage] = useState('');

    var currentDate = new Date();
    var maxJoiningDate = moment.tz(currentDate.toISOString(), "Asia/Kolkata").format('YYYY-MM-DD');

    useEffect(() => {
        if (currentUser) {
            setRoleId(currentUser.role_id);
        }
        setExistingDataFields();
        setFormData(prevFormData => ({
            ...prevFormData,
            updated_by: currentUser?.id
        }));
    }, [currentUser]);

    const fetchData = async () => {
        try {
            const response = await SanginiRegistrationService.getSanginiDetailsBySanginiId(sangini_id);
            return response;
        } catch (err) {
            console.log(err);
            return {};
        }
    }

    const transformDatabaseData = (response) => {
        const data = response.data;
        if (!data) {
            return {};
        }
        return {
            sanginiId: sangini_id,
            sanginiName: data.name || '',
            sanginiJoiningDate: data.joining_date ? new Date(data.joining_date).toLocaleDateString('en-CA') : '',
            highestEducation: data.highest_education_qualification || '',
            sanginiRole: data.role || '',
            isSeniorSangini: data.senior_sangini_flag || '',
            seniorSanginiId: data.senior_sangini_id || '',
            isProductKnowledgeAssessmentScoreAboveThreshold: data.product_knowledge_score_flag || '',
            establishedPartnershipWith5Schools: data.establish_partnership_flag || '',
            isActive: data.active_inactive_flag || '',
            inactiveDate: data.active_inactive_date ? new Date(data.active_inactive_date).toLocaleDateString('en-CA') : '',
            remarks: data.remarks || ''
        };
    }

    const setExistingDataFields = async () => {
        const response = await fetchData();
        const transformedData = transformDatabaseData(response);
        setFormData(transformedData);
        setFormData(currentFormData => ({
            ...currentFormData,
            updated_by: currentUser?.id
        }));
        setInitialFormData(transformedData);
    }

    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const displaySeniorSanginiIdField = () => {
        return formData.isSeniorSangini === 'N';
    }

    const validateFormData = () => {
        let valid = true;
        const newErrors = [];

        if (!formData.sanginiJoiningDate) {
            newErrors.push("Please enter sangini joining date.");
            valid = false;
        }
        if (!formData.sanginiRole) {
            newErrors.push("Please enter sangini role.");
            valid = false;
        }
        if (!formData.isActive) {
            newErrors.push("Please enter Is Sangini Active?");
            valid = false;
        }
        if (!formData.isProductKnowledgeAssessmentScoreAboveThreshold) {
            newErrors.push("Please enter Product Knowledge Assessment Score");
            valid = false;
        }
        if (formData.inactiveDate) {
            if (new Date(formData.sanginiJoiningDate) > new Date(formData.inactiveDate)) {
                newErrors.push("Sangini inactive date should be on or after joining date.");
                valid = false;
            }
        }

        setErrors(newErrors);
        return valid;
    }

    const isFieldEditable = (fieldName) => {
        if (roleId === 2) return true; // Program manager can edit all fields
        if (roleId === 1) {
            // Program executive can only edit empty fields
            const initialValue = initialFormData[fieldName];
            if (fieldName === 'remarks') {
                // For remarks, consider it empty if it's undefined, null, or contains only whitespace
                return !initialValue || initialValue.trim() === '';
            }
            if (typeof initialValue === 'number') {
                return initialValue === 0;
            }
            return !initialValue;
        }
        return false; // Default to not editable
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors([]);
        if (validateFormData()) {
            try {
                const dataToSubmit = { ...formData };
                if (!isFieldEditable('remarks')) {
                    dataToSubmit.remarks = initialFormData.remarks;
                }
                const response = await SanginiRegistrationService.updateSanginiDetailsAfterBecomingSangini(dataToSubmit);
                if (response.status === 400 || response.status === 500) {
                    setMessage(response.data?.message || "Error updating sangini details.");
                } else {
                    setMessage("Sangini details updated successfully.");
                    setInitialFormData(dataToSubmit);
                }
            } catch (error) {
                setMessage("Error updating sangini details.");
                console.error(error);
            }
        }
    }

    const renderInputField = (name, label, type = "text") => (
        <Form.Group id={name} className='input-field-single-row input-field-backgroud'>
            <Form.Label>{label}:</Form.Label>
            <Form.Control
                type={type}
                name={name}
                value={formData[name]}
                onChange={handleFormDataChange}
                disabled={!isFieldEditable(name)}
                className="input"
            />
        </Form.Group>
    );

    const renderRadioGroup = (name, label, options) => (
        <Form.Group id={name} className='input-field-single-row input-field-backgroud'>
            <Form.Label>{label}:</Form.Label>
            <Col className="radio-list">
                {options.map(option => (
                    <Form.Check
                        key={option.value}
                        type="radio"
                        label={option.label}
                        name={name}
                        value={option.value}
                        checked={formData[name] === option.value}
                        onChange={handleFormDataChange}
                        disabled={!isFieldEditable(name)}
                        id={`${name}-${option.value}`}
                    />
                ))}
            </Col>
        </Form.Group>
    );

    return (
        <Container className='event-planning-page'>
            <Form id='sangini-form' onSubmit={handleSubmit}>
                <h2>Update Sangini Details</h2>
                <Container className='input-field-multiple'>
                    {renderInputField("sanginiName", "Sangini Name")}
                    <Form.Group id='sanginiJoiningDate' className='input-field-single-row input-field-backgroud'>
                        <Form.Label>Joining Date:</Form.Label>
                        <Form.Control
                            type='date'
                            name='sanginiJoiningDate'
                            value={formData.sanginiJoiningDate}
                            onChange={handleFormDataChange}
                            disabled={!isFieldEditable('sanginiJoiningDate')}
                            max={maxJoiningDate}
                            className="input"
                        />
                    </Form.Group>
                    {renderInputField("highestEducation", "Highest Education Qualification")}
                </Container>
                <Container className='input-field-multiple'>
                    {renderRadioGroup("sanginiRole", "Sangini Role", [
                        { value: "NGO", label: "NGO" },
                        { value: "ASHA", label: "ASHA" },
                        { value: "Housewife", label: "Housewife" },
                        { value: "Shop", label: "Shop" },
                        { value: "SHG", label: "SHG" },
                        { value: "HFH", label: "HFH" },
                        { value: "OTHER", label: "Other" }
                    ])}
                    {renderRadioGroup("isSeniorSangini", "Is a Senior Sangini?", [
                        { value: "Y", label: "Yes" },
                        { value: "N", label: "No" }
                    ])}
                </Container>
                <Container className='input-field-multiple'>
                    {displaySeniorSanginiIdField() && 
                    <Form.Group id="sangini-id" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Senior Sangini Name & Mobile No:</Form.Label>
                        <SeniorSanginiDropdown
                            onChange={handleFormDataChange}
                            value={formData.seniorSanginiId}
                            name="seniorSanginiId"
                            disabled={!isFieldEditable('seniorSanginiId')}
                            currentUser={currentUser}
                        />
                    </Form.Group>}
                    {renderRadioGroup("isProductKnowledgeAssessmentScoreAboveThreshold", "Product Knowledge Assessment Score > 80%?", [
                        { value: "Y", label: "Y" },
                        { value: "N", label: "N" }
                    ])}
                    {renderRadioGroup("establishedPartnershipWith5Schools", "Established Partnership With 5 Schools / Community in first 3 months:", [
                        { value: "Y", label: "Y" },
                        { value: "N", label: "N" }
                    ])}
                </Container>
                <Container className='input-field-multiple'>
                    {renderRadioGroup("isActive", "Is Sangini Active?", [
                        { value: "Y", label: "Y" },
                        { value: "N", label: "N" }
                    ])}
                    {renderInputField("inactiveDate", "Date of Being Inactive", "date")}
                    <Form.Group id="remarks" className='input-field-single-row input-field-backgroud'>
                        <Form.Label>Remarks:</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="remarks"
                            value={formData.remarks}
                            onChange={handleFormDataChange}
                            disabled={!isFieldEditable("remarks")}
                            className="input"
                        />
                    </Form.Group>
                </Container>
                {errors.length > 0 && <Alert variant="danger">{errors[0]}</Alert>}
                {message && <Alert variant="info">{message}</Alert>}
                <Button variant="primary" type="submit" className="custom-btn">Update Sangini Details</Button>
            </Form>
        </Container>
    );
}

export default AfterBecomingSanginiFormCustom;