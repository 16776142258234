import React, { useState, useEffect } from 'react';
import { Container, Form, Col, Button } from 'react-bootstrap';
import VillageDataService from './../../services/village.service.js';
import SanginiDataService from './../../services/sangini_details.service';
import ViewUserData from './ViewUserData.js';
import SanginiUserDetailsDataService from './../../services/sangini_user_details.service.js';

function UserForm({ userData, viewUser, handleView, handleClose}) {


    const [villageName, setVillageName] = useState('');

    const [mobileno, setMobileNo] = useState(userData[0].mobileno);

    const [newData, setNewData] = useState([]);

    useEffect(() => {
        
        const getUserData = async (userId) => {
            try {
                const response = await SanginiUserDetailsDataService.findByUserId(userId);
                console.log(response.data);
                setNewData(response.data);
                getVillageDataById(response.data)
                getMobileNo(response.data);
            } catch (error) {
                console.log("Error fetching user data", error);
                return null;
            }
        }

        const getVillageDataById = async (data) => {
            try {
                console.log(data[0]);
                const response = await VillageDataService.get(data[0].village_id);
                console.log(response);
                setVillageName(response.data.village_name);

            } catch (error) {
                console.log("Error fetching village details", error);
            }
        }

        const getMobileNo = async (data) => {
            setMobileNo(data[0].mobileno);
        }


        getUserData(userData[0].user_id);
    }, [userData, viewUser]);

    const [sanginiName, setSanginiName] = useState('');

    useEffect(() => {
        console.log(userData);
        const fetchSanginiName = async () => {
            try {
                const response = await SanginiDataService.get(userData[0].sangini_id);
                setSanginiName(response.data.name);
            } catch (error) {
                console.error('Error fetching Sangini name:', error);
            }
        };

        if (userData[0].sangini_id) {
            fetchSanginiName();
        }
    }, [userData[0].sangini_id]);


    return (

        <Container className='input-grid'>
            <hr className='line-break' />
            <h3>User Details</h3>
            <Container className='input-field-multiple add-user-btn-height-change'>
                <Form.Group id="sangini-id" className='input-field-single-column input-field-backgroud'>
                    <Form.Label>Sangini Name:</Form.Label>
                    <Form.Control
                        type="text"
                        value={sanginiName}
                        readOnly
                        className='input'
                    />
                </Form.Group>

                <Form.Group id="user-name" className='input-field-single-column input-field-backgroud '>
                    <Form.Label>User Name:</Form.Label>
                    <Form.Control
                        type="text"
                        value={userData[0].user_name || ''}
                        readOnly
                        className='input'
                    />
                </Form.Group>

                <Form.Group id="mobileno" className='input-field-single-column input-field-backgroud '>
                    <Form.Label>Mobile Number:</Form.Label>
                    <Form.Control
                        type="text"
                        value={mobileno || ''}
                        readOnly
                        className='input'
                    />
                </Form.Group>

                <Form.Group id="village-id" className='input-field-single-column input-field-backgroud '>
                    <Form.Label>Village Name:</Form.Label>
                    <Form.Control
                        type="text"
                        value={villageName || ''}
                        readOnly
                        className='input'
                    />
                </Form.Group>

                <Button className='program-planning-custom-btn change-width-btn' onClick={handleView}>View/Edit</Button>
            </Container>

            {
                viewUser &&
                <ViewUserData
                    sanginiName={sanginiName}
                    userData={newData}
                    show={viewUser}
                    handleClose={handleClose}
                />
            }
        </Container>
    );
}

export default UserForm;
