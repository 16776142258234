
import React, { useState,useEffect } from 'react';
import { Form, Container, Col, Button, Row, Alert } from 'react-bootstrap';
import './../../styles/Report.css';
import './../../styles/App.css';
import ReportGrid from './ReportGrid';
import ReportDataService from "../services/report.service";


function PartnerwiseImpactReport() {



  const [message, setMessage] = useState('');

  const [rowData, setRowData] = useState([]);

  const [colDefs, setColDefs] = useState([]);


  useEffect(() => {
    const fetchRows = async () => {
        var dynamicFilters="";
       
        //ReportDataService.getPlannedImpReport(dynamicFilters)
        //.then(function (response) {
           // console.log("recieved data")
           // console.log(response.data)
            //setRowData(response.data);
            
            
          //})
          //.catch(e => {
          //  console.log(e);
          //})
      
    };
    fetchRows();
  }, []);



  const generateReport=()=>{

    //console.log("final")
   
   
    handleZoneOverviewReport();

    ReportDataService.getZoneOverviewReport()
    .then(function (response) {
        console.log(response.data)
        if(response.data===null || response.data==='')
        {
            console.log("No data");

        //   ReportDataService.putSanginiKPIReport(dynamicFilters)
        //   .then(response => {
        //     console.log("Sangini KPI Report Generated");
        //     setMessage('Validation Success: Report Generated Successfully');
        //   })
        //   .catch(e => {
        //     console.log(e);
        //   });
        }
        else
        {
          console.log("data Exist");
        setRowData(response.data);
        //  setMessage('Validation Success: Report Generated');
        }
        })
        .catch(e => {
          console.log(e);
        })
    

  }

  

  const handleSubmit = async (e) => {
    //console.log("final")
    e.preventDefault();

  
    
    handleZoneOverviewReport();
    

  };


  const handleZoneOverviewReport = () => { 
    let reportColumns=[
        { headerName: "Zone",field: "zone" ,width: "200px",tooltipField: "zone",headerTooltip: "Zone"},
        { headerName: "State",field: "state" ,width: "200px",tooltipField: "state",headerTooltip: "State"},
        { headerName: "Partner",field: "partner_name" ,width: "200px",tooltipField: "partner_name",headerTooltip: "Partner"},
        { headerName: "Total no. of Districts",field: "no_of_district" ,width: "200px",tooltipField: "no_of_district",headerTooltip: "Total no. of Districts"},
        { headerName: "Total no. of Blocks",field: "no_of_block" ,width: "200px",tooltipField: "no_of_block",headerTooltip: "Total no. of Blocks"},
        { headerName: "Total no. of Villages",field: "no_of_village" ,width: "200px",tooltipField: "no_of_village",headerTooltip: "Total no. of Villages"},
 ];
  setColDefs(reportColumns);
}

  return (

    <Container className='report-page'>
      <Form id='report-form' onSubmit={handleSubmit} >
    

    <Container>
    <Container className='input-field-multiple'>
          <Form.Group id="header-text" className='header-input-field-single-row input-field-backgroud'>
              <Form.Label className='report-header'>Zone Overview Report</Form.Label>
            
            </Form.Group>

          </Container>
        <Form.Group id="planned-pgm-status" className='input-field-single-row input-field-backgroud'>
        <Button variant="primary" type="button" className="custom-btn"onClick={() => generateReport()}>View Report</Button>
       </Form.Group>
    </Container>
       <ReportGrid rowData={rowData} colDefs={colDefs}/>
      </Form>
    </Container>
    
  );
}

export default PartnerwiseImpactReport;