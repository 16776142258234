import http from "../../http-common";

class VillageDataService {
  getAll() {
    return http.get("/villages");
  }

  getBlock() {
    return http.get("/villages/findBlock");
  }

  getDistrict() {
    return http.get("/villages/findDistrict");
  }

  getZone() {
    return http.get("/villages/findZone");
  }

  getState() {
    return http.get("/villages/findState");
  }

  getPanchayat() {
    return http.get("/villages/findPanchayat");
  }

  getExecutive() {
    return http.get("/villages/findExecutive");
  }

  getVillageIDName() {
    return http.get("/villages/villageIdName");
  }

  get(id) {
    return http.get(`/villages/${id}`);
  }

  create(data) {
    return http.post("/villages", data);
  }

  createzone(data) {
    return http.post("/zones/createzone", data);
  }

  createblock(data) {
    return http.post("/blocks/createblock", data);
  }

  createstate(data) {
    return http.post("/states/createstate", data);
  }

  createdistrict(data) {
    return http.post("/districts/createdistrict", data);
  }

  createpanchayat(data) {
    return http.post("/panchayats/createpanchayat", data);
  }

  findAllVillageUnique(data) {
    return http.get("/villages/findAllVillageUnique", data);
  }

  update(id, data) {
    return http.put(`/villages/${id}`, data);
  }

  delete(id) {
    return http.delete(`/villages/${id}`);
  }

  deleteAll() {
    return http.delete("/villages");
  }
  findByName(village_name) {
    return http.get(`/villages?village_name=${village_name}`);
  }
 
}

export default new VillageDataService();