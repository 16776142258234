import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Table, Alert } from 'react-bootstrap';
import SanginiTransactionDetailsDataService from './../../services/sangini_transaction_details.service';

function TransactionForm({ userData, sanginiId, currentUser }) {

    const userId = userData[0].user_id;
    const [transactionData, setTransactionData] = useState([]);
    const [newTransaction, setNewTransaction] = useState({
        sangini_id: sanginiId,
        user_id: userId,
        transaction_date: new Date().toLocaleDateString('en-CA'),
        no_of_pads: 0,
        size_of_pads: '',
        is_product_return_flag: 'N',
        feedback_rating: 0,
        feedback_remark: '',
        updated_by_id: currentUser.id
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [validationErrors, setValidationErrors] = useState([]);
    const [message, setMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        // console.log(userData[0]);
        const fetchTransactionDetails = async () => {
            try {
                const response = await SanginiTransactionDetailsDataService.findAllBySanginiIdAndUserId(sanginiId, userId);
                setTransactionData(Array.isArray(response.data) ? response.data : []);
            } catch (err) {
                setError('Failed to fetch transactions');
                console.error('Fetch error:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchTransactionDetails();
        setValidationErrors([]);
    }, [userId, sanginiId]);

    useEffect(() => {
        let timer;
        if (showMessage) {
            timer = setTimeout(() => {
                setShowMessage(false);
            }, 4000); // 4 seconds
        }
        return () => clearTimeout(timer); 
    }, [showMessage]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewTransaction((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const validateData = () => {
        const newErrors = [];

        if (!newTransaction.transaction_date) {
            newErrors.push('Please select a date.');
        }

        if(newTransaction.transaction_date < new Date(userData[0].date_user_added).toLocaleDateString('en-CA')){
            newErrors.push('Enter valid transaction date. Transaction date cannnot be before joining date of user!!')
        }

        if (newTransaction.no_of_pads <= 0) {
            newErrors.push('Please select a valid number of pads.');
        }

        if (!newTransaction.size_of_pads) {
            newErrors.push('Please select size of pads.');
        }

        if (newTransaction.feedback_rating < 0) {
            newErrors.push('Please select a valid feedback rating.');
        }

        setValidationErrors(newErrors);
        return newErrors.length === 0;
    };

    const handleAdd = async () => {
        if (validateData()) {
            try {
                console.log(newTransaction);
                const response = await SanginiTransactionDetailsDataService.create(newTransaction);
                setTransactionData((prev) => [...prev, response.data]);
                setMessage('Transaction added successfully!');
                setShowMessage(true);
                setValidationErrors([]);
                resetForm();
            } catch (err) {
                console.error('Add transaction error:', err);
                setMessage('Failed to add transaction.');
                setShowMessage(true);
            }
        }
    };

    const handleEdit = async (transactionId) => {
        if (isEditing) {
            // If already in edit mode, update the transaction
            if (validateData()) {
                try {
                    const response = await SanginiTransactionDetailsDataService.update(transactionId, newTransaction);
                    setTransactionData((prev) => prev.map(item => item.transaction_id === transactionId ? response.data : item));
                    setMessage('Transaction updated successfully!');
                    setShowMessage(true);
                    setValidationErrors([]);
                    resetForm();
                } catch (err) {
                    console.error('Update transaction error:', err);
                    setMessage('Failed to update transaction.');
                    setShowMessage(true);
                }
            }
        } else {
            // Populate the form with the transaction data for editing
            const transactionToEdit = transactionData.find(item => item.transaction_id === transactionId);
            if (transactionToEdit) {
                setNewTransaction(transactionToEdit);
                setIsEditing(true);
            }
        }
    };

    const resetForm = () => {
        setNewTransaction({
            sangini_id: sanginiId,
            user_id: userId,
            transaction_date: new Date().toLocaleDateString('en-CA'),
            no_of_pads: 0,
            size_of_pads: '',
            is_product_return_flag: 'N',
            feedback_rating: 0,
            feedback_remark: '',
        });
        setIsEditing(false);
    };

    return (
        <Container className='input-grid'>
            <hr className='line-break' />
            <h3>Transaction Details</h3>
            {loading && <p>Loading...</p>}
            {error && <p>{error}</p>}
            {showMessage && <Alert variant="info">{message}</Alert>}

            <Container className="table-wrapper">
                <Table striped bordered hover className='view-table'>
                    <thead>
                        <tr>
                            <th>Transaction Date</th>
                            <th>No. of pads</th>
                            <th>Size of pads Name</th>
                            <th>Product Returned?</th>
                            <th>Feedback Rating</th>
                            <th>Feedback Remark</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactionData.map((item) => (
                            <tr key={item.transaction_id}>
                                <td>{new Date(item.transaction_date).toLocaleDateString('en-CA')}</td>
                                <td>{item.no_of_pads}</td>
                                <td>{item.size_of_pads}</td>
                                <td>{item.is_product_return_flag}</td>
                                <td>{item.feedback_rating}</td>
                                <td>{item.feedback_remark}</td>
                                {
                                    currentUser.role_id === 2 &&
                                    <td>
                                        <Button className='program-planning-custom-btn change-width-btn' onClick={() => handleEdit(item.transaction_id)}>Edit</Button>
                                    </td>
                                }

                            </tr>
                        ))}
                        {/* New row for adding/editing a transaction */}
                        <tr>
                            <td>
                                <Form.Control
                                    type="date"
                                    name="transaction_date"
                                    value={new Date(newTransaction.transaction_date).toLocaleDateString('en-CA')}
                                    onChange={handleChange}
                                    className='input'
                                    min={new Date(userData[0].date_user_added).toLocaleDateString('en-CA')}
                                />
                            </td>
                            <td>
                                <Form.Control
                                    type="number"
                                    min={0}
                                    name="no_of_pads"
                                    value={newTransaction.no_of_pads}
                                    onChange={handleChange}
                                    className='input'
                                />
                            </td>
                            <td>
                                <Form.Control
                                    as="select"
                                    name="size_of_pads"
                                    value={newTransaction.size_of_pads}
                                    onChange={handleChange}
                                    className='input'
                                >
                                    <option value="">Select Size</option>
                                    <option value="L">L</option>
                                    <option value="XL">XL</option>
                                </Form.Control>
                            </td>
                            <td>
                                <Form.Control
                                    as="select"
                                    name="is_product_return_flag"
                                    value={newTransaction.is_product_return_flag}
                                    onChange={handleChange}
                                    className='input'
                                >
                                    <option value="N">N</option>
                                    <option value="Y">Y</option>
                                </Form.Control>
                            </td>
                            <td>

                                <Form.Control
                                    as="select"
                                    name="feedback_rating"
                                    value={newTransaction.feedback_rating}
                                    onChange={handleChange}
                                    className='input'
                                >
                                    <option value="">Select rating</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </Form.Control>

                            </td>
                            <td>
                                <Form.Control
                                    as="textarea"
                                    rows={2}
                                    name="feedback_remark"
                                    value={newTransaction.feedback_remark}
                                    onChange={handleChange}
                                    className='input'
                                />
                            </td>
                            <td>
                                <Button className='program-planning-custom-btn change-width-btn' onClick={isEditing ? () => handleEdit(newTransaction.transaction_id) : handleAdd}>
                                    {isEditing ? 'Update' : 'Add'}
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Container>
            {validationErrors.length > 0 && (
                <Alert className='alert' variant="danger">
                    {validationErrors[0]}
                </Alert>
            )}
        </Container>
    );
}

export default TransactionForm;
