'use strict';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import ReportDataService from "../services/report.service";

import React, {
    useCallback,
    useMemo,
    useRef,
    useState,
    StrictMode,
    useEffect
  } from "react";
  import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
  import { CsvExportModule } from "@ag-grid-community/csv-export";
// Theme
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import {
    ColDef,
    ColGroupDef,
    GridApi,
    GridOptions,
    ModuleRegistry,
    createGrid,
  } from "@ag-grid-community/core";

ModuleRegistry.registerModules([ClientSideRowModelModule,CsvExportModule]);

  const ReportGrid = (props) => {
   //GridOptions = {
     //   alwaysShowHorizontalScroll: true
    //}
    
    const pagination = true;
    const paginationPageSize = 500;
    const paginationPageSizeSelector = [200, 500, 1000];
    
    const gridRef = useRef();

   
    console.log("test1:",props.catgData);

    if(props.catgData==="H")
    {
        console.log("INSIDE H");
    }


    //let rowData1;

    /*const [rowData, setRowData] = useState([
        { Zone:"Zone1",State: "State1", District: "District1", Block: "Block1", Village: "Village1" ,Health:"Health1",Livelihood:"Livelihood1",Partner:"Partner1",PlPGM:"PlPGM1",PlImpact:"PlImpact1",PLBudget:"PLBudget1",ImpPGM:"ImpPGM1",ImpImpact:"ImpImpact1",ImpExpense:"ImpExpense1"},
        {  Zone:"Zone2",State: "State2", District: "District2", Block: "Block2", Village: "Village2" ,Health:"Health2",Livelihood:"Livelihood2",Partner:"Partner2",PlPGM:"PlPGM2",PlImpact:"PlImpact2",PLBudget:"PLBudget2",ImpPGM:"ImpPGM2",ImpImpact:"ImpImpact2",ImpExpense:"ImpExpense2"},
        {  Zone:"Zone3",State: "State3", District: "District3", Block: "Block3", Village: "Village3" ,Health:"Health3",Livelihood:"Livelihood3",Partner:"Partner3",PlPGM:"PlPGM3",PlImpact:"PlImpact3",PLBudget:"PLBudget3",ImpPGM:"ImpPGM3",ImpImpact:"ImpImpact3",ImpExpense:"ImpExpense3"},
      ]);*/

      /*const [rowData, setRowData] = useState([
        { zone:"Zone1",state: "State1", district: "District1", block: "Block1", village_name: "Village1" ,partner_name:"partner_name1",beneficiary:"beneficiary1",planned_health_pgm:"planned_health_pgm1",planned_health_impact:"planned_health_impact1",planned_health_budget:"planned_health_budget1",implemented_health_pgm:"implemented_health_pgm1",implemented_health_impact:"implemented_health_impact1",implemented_health_expense:"implemented_health_expense1",planned_education_pgm:"planned_education_pgm1",planned_education_impact:"planned_education_impact1",planned_education_budget:"planned_education_budget1",implemented_education_pgm:"implemented_education_pgm1",implemented_education_impact:"implemented_education_impact1",implemented_education_expense:"implemented_education_expense1",planned_life_pgm:"planned_life_pgm1",planned_life_impact:"planned_life_impact1",planned_life_budget:"planned_life_budget1",implemented_life_pgm:"implemented_life_pgm1",implemented_life_impact:"implemented_life_impact1",implemented_life_expense:"implemented_life_expense1"},
        {  zone:"Zone2",state: "State2", district: "District2", block: "Block2", village_name: "Village2" ,partner_name:"partner_name1",beneficiary:"beneficiary1",planned_health_pgm:"planned_health_pgm1",planned_health_impact:"planned_health_impact1",planned_health_budget:"planned_health_budget1",implemented_health_pgm:"implemented_health_pgm1",implemented_health_impact:"implemented_health_impact1",implemented_health_expense:"implemented_health_expense1",planned_education_pgm:"planned_education_pgm1",planned_education_impact:"planned_education_impact1",planned_education_budget:"planned_education_budget1",implemented_education_pgm:"implemented_education_pgm1",implemented_education_impact:"implemented_education_impact1",implemented_education_expense:"implemented_education_expense1",planned_life_pgm:"planned_life_pgm1",planned_life_impact:"planned_life_impact1",planned_life_budget:"planned_life_budget1",implemented_life_pgm:"implemented_life_pgm1",implemented_life_impact:"implemented_life_impact1",implemented_life_expense:"implemented_life_expense1"},
        {  zone:"Zone3",state: "State3", district: "District3", block: "Block3", village_name: "Village3" ,partner_name:"partner_name1",beneficiary:"beneficiary1",planned_health_pgm:"planned_health_pgm1",planned_health_impact:"planned_health_impact1",planned_health_budget:"planned_health_budget1",implemented_health_pgm:"implemented_health_pgm1",implemented_health_impact:"implemented_health_impact1",implemented_health_expense:"implemented_health_expense1",planned_education_pgm:"planned_education_pgm1",planned_education_impact:"planned_education_impact1",planned_education_budget:"planned_education_budget1",implemented_education_pgm:"implemented_education_pgm1",implemented_education_impact:"implemented_education_impact1",implemented_education_expense:"implemented_education_expense1",planned_life_pgm:"planned_life_pgm1",planned_life_impact:"planned_life_impact1",planned_life_budget:"planned_life_budget1",implemented_life_pgm:"implemented_life_pgm1",implemented_life_impact:"implemented_life_impact1",implemented_life_expense:"implemented_life_expense1"},
      ]);*/

      const [rowData, setRowData] = useState([]);

      /*let setRowData = (data) => {
        this.setState({ rowData1: data });
      };*/
      //const [colDefs, setColDefs] = useState([]);
      // Column Definitions: Defines the columns to be displayed.
     
    const [colDefs, setColDefs] = useState([]);

    const defaultColDef = {
        flex: 1,sortable:true,filter:true
    };

    function onBtnExport() {
        gridRef.current.api.exportDataAsCsv();
      }

      /*useEffect(() => {
        const fetchRows = async () => {
            var dynamicFilters="";
            ReportDataService.getPlannedImpReport(dynamicFilters)
            .then(function (response) {
                console.log("recieved data")
                console.log(response.data)
                setRowData(response.data);
                
                //return response.data;
                //setRowData(response.data);
              })
              .catch(e => {
                console.log(e);
              })
          
        };
        fetchRows();
      }, []);*/

      /*useEffect(() => {
        const fetchRows = async () => {
            if(props.catgData==="H")
                {
                    console.log("grid is H")
                    handleHChange();
                }
                else
                {
                    console.log("grid is All")
                    handleAllChange();
                }
          
        };
        fetchRows();
      }, []);*/

     /* const onGridReady=(params)=>{
        var dynamicFilters="";
        console.log("grid is ready")
        if(props.catgData==="H")
            {
                console.log("grid is H")
                handleHChange();
            }
            else
            {
                console.log("grid is All")
                handleAllChange();
            }*/
        /*fetch("https://jsonplaceholder.typicode.com/comments").then(resp=>resp.json())
        .then(resp=>{console.log(resp)
          params.api.applyTransaction({add:resp})})*/
       //ReportDataService.getPlannedImpReport(dynamicFilters)
      /*.then((response) => {
        console.log("response received:");
        console.log(response.data);
        params.api.applyTransaction({add:response})

        fetch('https://www.ag-grid.com/example-assets/row-data.json').then(function (response) {
            return response.json();
        }).then(function (data) {
            gridApi.setGridOption('rowData', data);
        })
      })*/
      //.then(function (response) {
       // console.log("recieved data")
       // console.log(response.data)
       
        
        //return response.data;
        //setRowData(response.data);
      //})
      //.catch(e => {
       // console.log(e);
      //})
      //}
      
      return (
      
        <div className="rows" style={{ width: '1430px', height: '500px' ,overflow: props.isScroll}}>
         
          <div>
            <button onClick={onBtnExport} style={{ fontWeight: "bold" } } className="custom-btn">
              Export to Excel
            </button>
          </div>
          <div
        className={
            "ag-theme-quartz"
        }
        style={{ width: props.grdWidth, height: '500px'}}
    >
     <AgGridReact ref={gridRef} 
     rowData={props.rowData} 
     columnDefs={props.colDefs} defaultColDef={defaultColDef} pagination={pagination}
    paginationPageSize={paginationPageSize}
    paginationPageSizeSelector={paginationPageSizeSelector} 
/>
    </div>
       
    </div>
    );
  };

export default ReportGrid;