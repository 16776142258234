import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../../../styles/forms/programPlanningData/ProgramPlanningData.css';

const ProgramDetailsDropdown = ({ onChange, programDetails = [], setProgramDetails, name, value, disabled }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(false);
    }, [programDetails]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <Form.Group className="programDetailsDropdown">
            <Form.Control 
                className='input' 
                as="select" 
                name={name} 
                value={value} 
                onChange={onChange}
                disabled={disabled}
            >
                <option value="">Select a program code</option>
                {programDetails.length > 0 ? (
                    programDetails.map((detail) => (
                        <option key={detail.program_code} value={detail.program_code}>
                            {detail.program_code} : {detail.program_name}
                        </option>
                    ))
                ) : (
                    <option disabled>No programs available</option>
                )}
            </Form.Control>
        </Form.Group>
    );
};

export default ProgramDetailsDropdown;