import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Table, Pagination } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PartnerDetailsDropdown from './PartnerDetailsDropdown';
import ProgramDetailsDropdown from './ProgramDetailsDropdown';
import VillageDetailsDropdown from './VillageDetailsDropdown';
import './../../../styles/forms/programPlanningData/ProgramPlanningData.css';
import './../../../styles/forms/programPlanningData/UpdateProgramPlanning.css'
import EventPlanningDataService from './../../services/event_planning_data.service.js';
import VillageDataService from './../../services/village.service.js';
import PartnerDataService from './../../services/partner.service.js';
import ProgramDataService from './../../services/program.service.js';

function ViewProgramPlanning() {

    const { user: currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    if (currentUser) {
      console.log(currentUser);
    }
  }, [currentUser]);

    const today = new Date();
    const toDate = new Date().toLocaleDateString('en-CA'); // Format as YYYY-MM-DD
    const fromDate = new Date(today.setDate(today.getDate() - 90)).toLocaleDateString('en-CA');

    const [filters, setFilters] = useState({
        fromDate: fromDate,
        toDate: toDate,
        eventCategory: '',
        programCode: '',
        partnerCode: '',
        villageId: '',
    });

    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(15);
    const [programDetails, setProgramDetails] = useState([]);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [filters, currentPage]);

    const fetchData = async () => {
        try {
            let currentUserResponse = null;

            if(currentUser.role_id === 1){
                currentUserResponse = await PartnerDataService.getExecutivePartnerDetails(currentUser.id);
            }
            else if (currentUser.role_id === 2){
                currentUserResponse = await PartnerDataService.getManagerPartnerDetails(currentUser.id);
            }

            const allowedPartners = currentUserResponse.data.map(i => i.partner_code);
            console.log("Allowed Partners: ", allowedPartners);

            const { fromDate, toDate, eventCategory, programCode, partnerCode, villageId } = filters;
            const response = await EventPlanningDataService.getAll(
                fromDate,
                toDate,
                eventCategory,
                programCode,
                partnerCode,
                villageId,
                currentPage,
                pageSize,
                allowedPartners,
            );
            console.log(response.data);
            setTotalPages(response.data.totalPages);

            const updatedItems = await Promise.all(response.data.items.map(async (item) => {
                const villageNameResponse = await VillageDataService.get(item.village_id);
                const partnerResponse = await PartnerDataService.findByCode(item.partner_code);
                const programResponse = await ProgramDataService.findByProgramCategoryAndProgramCode(item.event_category, item.program_code);

                return {
                    ...item,
                    village_name: villageNameResponse.data.village_name,
                    partner_name: partnerResponse.data[0]?.partner_name || 'N/A',
                    program_name: programResponse.data[0]?.program_name || 'N/A',
                };
            }));

            setData(updatedItems);
            console.log(updatedItems);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = (id) => {
        navigate(`/edit-program/${id}`);
    };

    const handleImplement = (id) => {
        navigate(`/program-implementation/${id}`);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderPagination = () => {
        let items = [];
        for (let number = 1; number <= totalPages; number++) {
            items.push(
                <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
                    {number}
                </Pagination.Item>
            );
        }
        return items;
    };



    //Handling Filter Changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));

        if(name === 'partnerCode'){
            setFilters((prevFilters) => ({
                ...prevFilters,
                 villageId: '',
            }));
        }
        setCurrentPage(1); // Reset to first page on filter change
    };

    const handleEventCategoryChange = (e) => {
        const category = e.target.value;
    
        // Check if the clicked category is already selected
        const newCategory = filters.eventCategory === category ? '' : category;

        setFilters((prevFilters) => ({
            ...prevFilters,
            eventCategory: newCategory,
            programCode: '',
        }));
    
        // Only fetch program details if a category is selected
        if (newCategory) {
            ProgramDataService.findAllByProgramCategory(newCategory)
                .then(response => setProgramDetails(response.data))
                .catch(error => console.error('Error fetching program details:', error));
        } else {
            setProgramDetails([]); // Clear program details if no category is selected
        }

        setCurrentPage(1);
    };

    const handleClear = () => {
        setFilters({
            fromDate: fromDate,
            toDate: toDate,
            eventCategory: '',
            programCode: '',
            partnerCode: '',
            villageId: '',
        });
        setCurrentPage(1); // Reset to first page on clear
    };

    return (
        <Container className='update-event-planning-page'>
            <Container id='update-event-planning-form'>
                <Form className='input-grid'>
                    <h2>View/Edit Program</h2>
                    <Container className='input-field-multiple'>
                        <Form.Group controlId="filterDate" className='input-field-single-row input-field-backgroud'>
                            <Form.Label>From Date:</Form.Label>
                            <Form.Control
                                type="date"
                                name="fromDate"
                                value={filters.fromDate}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="filterDate" className='input-field-single-row input-field-backgroud'>
                            <Form.Label>To Date:</Form.Label>
                            <Form.Control
                                type="date"
                                name="toDate"
                                value={filters.toDate}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="filterCategory" className='input-field-single-row input-field-backgroud'>
                            <Form.Label>Program Category:</Form.Label>
                            <Form.Check
                                type="radio"
                                label="Health"
                                name="eventCategory"
                                value="H"
                                checked={filters.eventCategory === 'H'}
                                onClick={handleEventCategoryChange}
                            />
                            <Form.Check
                                type="radio"
                                label="Livelihood"
                                name="eventCategory"
                                value="L"
                                checked={filters.eventCategory === 'L'}
                                onClick={handleEventCategoryChange}
                            />
                            <Form.Check
                                type="radio"
                                label="Education"
                                name="eventCategory"
                                value="E"
                                checked={filters.eventCategory === 'E'}
                                onClick={handleEventCategoryChange}
                            />
                        </Form.Group>
                    </Container>

                    <Container className='input-field-multiple'>
                        <Form.Group controlId="filterProgramCode" className='input-field-single-column input-field-backgroud'>
                            <Form.Label>Program Code/Name:</Form.Label>
                            <ProgramDetailsDropdown
                                name="programCode"
                                onChange={handleChange}
                                programDetails={programDetails}
                                setProgramDetails={setProgramDetails}
                                value={filters.programCode}
                            />
                        </Form.Group>
                        <Form.Group controlId="filterPartnerCode" className='input-field-single-column input-field-backgroud'>
                            <Form.Label>Partner Code/Name:</Form.Label>
                            <PartnerDetailsDropdown
                                name="partnerCode"
                                onChange={handleChange}
                                value={filters.partnerCode}
                                roleId={currentUser.role_id}
                                userId={currentUser.id}
                            />
                        </Form.Group>
                        <Form.Group controlId="filterVillage" className='input-field-single-column input-field-backgroud'>
                            <Form.Label>Village - Taluka - District - State:</Form.Label>
                            <VillageDetailsDropdown
                                name="villageId"
                                onChange={handleChange}
                                value={filters.villageId}
                                roleId={currentUser.role_id}
                                userId={currentUser.id}
                                partnerCode={filters.partnerCode}
                            />
                        </Form.Group>
                    </Container>
                </Form>

                <Button variant="primary" onClick={handleClear} className="custom-btn">Clear Filters</Button>

                <Container className="table-wrapper">
                    {loading && <div className='loading-view'>Loading...</div>}
                    <Table striped bordered hover className='view-table'>
                        <thead>
                            <tr>
                                <th>Program Date</th>
                                <th>Program Category</th>
                                <th>Program Name</th>
                                <th>Partner Name</th>
                                <th>Village</th>
                                <th>Status</th>
                                <th>Heroes of Humanity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {

                                data.map(item => (
                                    <tr key={item.event_id}>
                                        <td>{new Date(item.event_planned_date).toLocaleDateString('en-CA')}</td>
                                        <td>{item.event_category}</td>
                                        <td>{item.program_name}</td>
                                        <td>{item.partner_name}</td>
                                        <td>{item.village_name}</td>
                                        <td>{item.program_planning_status}</td>
                                        <td>{item.hfh_flag}</td>
                                        <td>
                                            <Button className='update-custom-btn change-width-btn' onClick={() => handleEdit(item.event_id)}>View/Edit Program</Button>
                                        </td>
                                        <td>
                                            <Button className='update-custom-btn change-width-btn' onClick={() => handleImplement(item.event_id)}>Add/View/Edit Implemented Program</Button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </Container>

                <Pagination>
                    {renderPagination()}
                </Pagination>
            </Container>
        </Container>
    );
}

export default ViewProgramPlanning;
