import http from "../../http-common";

class SanginiTransactionDetailsDataService {


    create(data) {
        return http.post("/sangini_transaction", data);
    }

    // Find all transactions by sangini_id and user_id
    findAllBySanginiIdAndUserId(sangini_id, user_id) {
        return http.get(`/sangini_transaction/findAllBySanginiIdAndUserId`, {
            params: { sangini_id, user_id }
        });
    }

    update(id, data) {
        return http.put(`/sangini_transaction/${id}`, data);
    }
}
export default new SanginiTransactionDetailsDataService();
