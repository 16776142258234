import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/forms/sanginiData/sanginiData.css";
import { Alert, Button, Col, Container, Form } from "react-bootstrap";
import SanginiRegistrationService from "../services/sanginiRegistration.service.js";
import Navbar from "../Navbar/Navbar.js";
import { useSelector } from "react-redux";
function SanginiRegistrationSalesDataFormCustom() {

    const { sangini_id } = useParams();
    const navigate = useNavigate();
    const { user: currentUser } = useSelector((state) => state.auth);

    const [formData, setFormData] = useState({
        sanginiId: sangini_id,
        saleDate: '',
        size: '',
        packOfQty: '',
        qtySold: '',
        updated_by_id: currentUser?.id
    });

    const [sanginiName, setSanginiName] = useState('');

    const fetchData = async () => {
        try {
            const response = await SanginiRegistrationService.getSanginiDetailsBySanginiId(sangini_id);
            return response;
        } catch (err) {
            console.log(err);
            return {};
        }
    }

    const getSanginiName = (response) => {
        const data = response.data;
        if (!data) {
            return '';
        }
        return data.name ? data.name : '';
    }

    const getSanginiData = async () => {
        const response = await fetchData();
        setSanginiName(getSanginiName(response));
    }

    useEffect(() => {
        setFormData(currentFormData => ({
            ...currentFormData,
            updated_by_id: currentUser?.id
        }));
    }, [currentUser]);

    useEffect(() => {
        getSanginiData();
    }, []);

    const [errors, setErrors] = useState([]);

    const [message, setMessage] = useState('');

    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validateFormData = () => {
        let valid = true;

        if (!formData.saleDate) {
            let errorMessage = "Please enter date of sale."
            setErrors(prevErrors => {
                return [...prevErrors, errorMessage];
            });
            valid = false;
        }

        if (!formData.size) {
            let errorMessage = "Please enter size of product."
            setErrors(prevErrors => {
                return [...prevErrors, errorMessage];
            });
            valid = false;
        }

        if (!formData.packOfQty) {
            let errorMessage = "Please enter pack of quantity."
            setErrors(prevErrors => {
                return [...prevErrors, errorMessage];
            });
            valid = false;
        }

        if (!formData.qtySold) {
            let errorMessage = "Please enter quantity sold."
            setErrors(prevErrors => {
                return [...prevErrors, errorMessage];
            });
            valid = false;
        }

        return valid;
    }

    const resetFormData = () => {
        setFormData({
            sanginiId: sangini_id,
            saleDate: '',
            size: '',
            packOfQty: '',
            qtySold: ''
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
        setErrors([]);
        setMessage('');
        if (validateFormData()) {
            console.log("Form data validated successfully.");
            try {
                console.log(formData);
                const response = SanginiRegistrationService.addSanginiSalesData(formData)
                    .then(response => {
                        console.log("Submitted", response.data);
                        if (response.status === 400 || response.status === 500) {
                            setMessage("Error adding new sangini sales data.");
                        } else {
                            setMessage("Sangini sales data added successfully.");
                            resetFormData();
                        }
                    })
                    .catch(e => {
                        setMessage("Error adding new sangini sales data.");
                        console.log(e);
                    });
            }
            catch (error) {
                setMessage("Error adding new sangini sales data.");
                console.error(error);
            }
        }
    }

    const handleRedirect = () => {
        navigate('/');
    }

    return (
        <Container className='event-planning-page'>

            <Form id='sangini-form' onSubmit={handleSubmit}>
                <h2>Add Sangini Sales Data</h2>
                <Container className='input-field-multiple'>
                    <Form.Group id="sangini-name" className='input-field-single-row input-field-backgroud'>
                        <Form.Label>Sangini Name:</Form.Label>
                        <Form.Control
                            type="text"
                            name="sanginiName"
                            value={sanginiName}
                            disabled
                            className="input" />
                    </Form.Group>

                    <Form.Group id="sale-date" className='input-field-single-row input-field-backgroud'>
                        <Form.Label>Sale Date:</Form.Label>
                        <Form.Control
                            type="date"
                            name="saleDate"
                            value={formData.saleDate}
                            onChange={handleFormDataChange}
                            className="input" />
                    </Form.Group>

                </Container>
                <Container className='input-field-multiple'>
                <Form.Group id="size" className='input-field-single-row input-field-backgroud'>
                    <Form.Label >Size:</Form.Label>
                    <Col className="radio-list">
                        <Form.Check
                            type="radio"
                            label="L"
                            name="size"
                            value="L"
                            checked={formData.size === 'L'}
                            onChange={handleFormDataChange}
                            id="l"
                        />
                        <Form.Check
                            type="radio"
                            label="XL"
                            name="size"
                            value="XL"
                            checked={formData.size === 'XL'}
                            onChange={handleFormDataChange}
                            id="xl"
                        />
                    </Col>
                </Form.Group>

                <Form.Group id="pack-of-qty" className='input-field-single-row input-field-backgroud'>
                    <Form.Label >Pack Of:</Form.Label>
                    <Col className="radio-list">
                        <Form.Check
                            type="radio"
                            label="2"
                            name="packOfQty"
                            value="2"
                            checked={formData.packOfQty === '2'}
                            onChange={handleFormDataChange}
                            id="2"
                        />
                        <Form.Check
                            type="radio"
                            label="6"
                            name="packOfQty"
                            value="6"
                            checked={formData.packOfQty === '6'}
                            onChange={handleFormDataChange}
                            id="6"
                        />
                        <Form.Check
                            type="radio"
                            label="8"
                            name="packOfQty"
                            value="8"
                            checked={formData.packOfQty === '8'}
                            onChange={handleFormDataChange}
                            id="8"
                        />
                        <Form.Check
                            type="radio"
                            label="40"
                            name="packOfQty"
                            value="40"
                            checked={formData.packOfQty === '40'}
                            onChange={handleFormDataChange}
                            id="40"
                        />
                    </Col>
                </Form.Group>

                <Form.Group id="qty-sold" className='input-field-single-row input-field-backgroud'>
                    <Form.Label>Quantity:</Form.Label>
                    <Form.Control
                        type="number"
                        name="qtySold"
                        value={formData.qtySold}
                        min="0"
                        onChange={handleFormDataChange}
                        className="input" />
                </Form.Group>
                </Container>
                {
                    errors.map((err, indx) => {
                        if (indx === 0) {
                            return (<Alert variant="danger" key={"danger"}>{err}</Alert>);
                        }
                        return;
                    })
                }
                {
                    message && <Alert variant="info">{message}</Alert>
                }
                <Button variant="primary" type="submit" onClick={handleSubmit} className="custom-btn">Submit Sales Data</Button>
                <Button variant="primary" onClick={handleRedirect} className="custom-btn">Exit</Button>
            </Form>
        </Container>
    );
}
export default SanginiRegistrationSalesDataFormCustom;