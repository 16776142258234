import { useCallback, useState, useRef,useEffect } from 'react';

import { useSelector } from 'react-redux';
import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import Navbar from './Navbar/Navbar';
import ProgramDataService from "./services/program_master.service";
import './../styles/Master.css';

export const master = {
    pages: [{
      title:  "Program Master",
      elements: [{
        name: "program_name",
        title: "Name of the Program",
        type: "text",
        isRequired: true
      }, { 
        name: "program_code",
        title: "Short Name/Program Code",
        type: "text",
        isRequired: true
      },
      { 
        name: "program_category",
        title: "Category of the Program",
        type: "dropdown",
        isRequired: true,
        choices: [
          { value: "H", text: "Health" },
          { value: "L", text: "Livelihood" },
          { value: "E", text: "Education" }
        ]
      },
      { 
        name: "duration_days",
        title: "Duration of Program",
        type: "text",
        inputType:"number",
        isRequired: true
      },
      { 
        name: "expected_min_beneficiaries",
        title: "Minimum Number of Expected Beneficiaries",
        type: "text",
        inputType:"number",
        isRequired: true
      },
      { 
        name: "expected_max_beneficiaries",
        title: "Maximum Number of Expected Beneficiaries",
        type: "text",
        inputType:"number",
        isRequired: true
      },
      { 
        name: "heros_for_humanity",
        title: "Heroes for Humanity",
        type: "radiogroup",
        choices: ["Yes","No"],
        colCount: 1,
        isRequired: true
      },
      { 
        name: "direct_indirect_ratio",
        title: "Direct: Indirect Ratio (Ex: of ratio is 1:4 then enter 4 below)",
        type: "text",
        inputType: "number",
        isRequired: true
      },
      {
        name: "remarks",
        title: "Remarks",
        type: "comment"
      }
      ,
      { 
        name: "updated_by_id",
        title: "UserId",
        type: "text",
        inputType: "text",
        visible:"false",
        clearInvisibleValues:"false"
      },
      ]
    },
    
    ]
  ,
    showQuestionNumbers: "off",
    pageNextText: false,
    completeText: "Submit",
    showPrevButton: false,
    firstPageIsStarted: false,
    showPreviewBeforeComplete:false,
    // startSurveyText: "Take the Survey",
    completedHtml: "Thank you for submitting Program Master!"
  };

  const customCss = {
    "question": {
        "content": "report-header",
        "answered": "report-header",
        "titleRequired": "report-header"
    },
    "navigation": {
      "complete": "sv-btn sv-footer__next-btn my-button"
    }
};

  function Master() {
    // useRef enables the Model object to persist between state changes
    const survey = useRef(new Model(master)).current;
    const [surveyResults, setSurveyResults] = useState("");
    const [isSurveyCompleted, setIsSurveyCompleted] = useState(false);

    const { user: currentUser } = useSelector((state) => state.auth);
    const [roleId, setRoleId] = useState(0);
    const [userId, setUserId] = useState(0);

    useEffect(() => {
      if (currentUser) {
        setRoleId(currentUser.role_id);
        setUserId(currentUser.id);
        console.log("currentUser",currentUser);
      }
    }, [currentUser]);
  
    const displayResults = useCallback((sender) => {
      setSurveyResults(JSON.stringify(sender.data, null, 4));
      const results = JSON.stringify(sender.data);
      console.log("results",results);
      //alert(results);
     ProgramDataService.create(results)
      .then(response => {
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
      setIsSurveyCompleted(true);
    }, []);

    survey.css = customCss;
    survey.clearInvisibleValues=false;
    survey.data = {updated_by_id:userId};
    survey.onValueChanged.add((sender, options) => {
      const el = document.getElementById(options.updated_by_id);
      if (el) {
          el.value = options.value;
      }
  });
  
    survey.onServerValidateQuestions.add(ValidateDuplicates);
    survey.onComplete.add(displayResults);

    survey.onUpdateQuestionCssClasses
    .add(function (survey, options) {
        var classes = options.cssClasses

        classes.mainRoot += " sv_qstn";
        classes.root = "sq-root";
        classes.title += " sq-title"
        classes.item += " sq-item";
        classes.label += " sq-label";


        if (options.question.isRequired) {
            classes.title += " sq-title-required";
            classes.root += " sq-root-required";
        }
        if(!options.question.isEmpty()) {
            classes.root += " sv_answered";
        }
        if (options.question.getType() === "checkbox") {
            classes.root += " sq-root-cb";
        }
    });
  
    return (
      <div>
        <Survey model={survey} id="surveyContainer" />
  
      </div>
    );
  }

  function ValidateDuplicates(_, { data, errors, complete }) {
    const programCode = data["program_code"];
    if (!programCode) {
      complete();
      return;
    }
    ProgramDataService.findByCode(programCode)
      .then((response) => {
        console.log(response.data);
        if(response.data.length>0)
        {
          errors["program_code"] = "Program Code already Exist";
        }
        complete();
      })
      .catch(e => {
        console.log(e);
      })
  }
  
  export default Master;