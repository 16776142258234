
import { Chart } from "react-google-charts";
import React, { useState, useEffect } from 'react'
import img from "../../images/background3.jpg";
import DashboardDataService from './../services/dashboard.service.js';


/**
 * This js file includes code for different chars :
 * 1. Total Direct Impact till date as per the data we have in the database
 * 2. Total Indirect Impact till date as per the data we have in the database
 * 3. Bar graph for Programs executed of Last Financial year (Programs executed of Last FY APR 2023 - MAR 2024)
 * 4. Bar graph for Imapct executed of Last Financial year (Impact executed of Last FY APR 2023 - MAR 2024)
 * 5. Pie chart for Programs planned for the current year (Programs planned for FY 2024 - 2025)
 * 6. Bar graph for Programs executed till data for current financial year (Programs executed till date for FY 2024 - 2025)
 * 7. Pie chart for Impact planned for the current year (Impact planned for FY 2024 - 2025)
 * 8. Bar graph for Impact executed till data for current financial year (Impact executed till date for FY 2024 - 2025)
 */


export const optionsProgramaticImpactPrograms = {
  
  chart: {
   // title: "Programs executed of Last FY APR 23-MAR 24",
   // subtitle: "Programs: APR 23-MAR 24",
    fill: '#a51f3c',
  },  colors: ["#c2195c","#a51f3c","#31859c","#db9f40","#000000"],
 // width: "800" ,height:"400",
  fill: '#a51f3c',
};
export const optionsProgramaticImpactImpact = {
  
  chart: {
    // title: "Programatic Impact of Last FY APR 23-MAR 24",
    // subtitle: "Impact: APR 23-MAR 24",
    fill: '#FFFFFF',
  },  colors: ["#c2195c","#a51f3c","#31859c","#db9f40","#000000"],
  //width: "800" ,height:"400",
  fill: '#FFFFFF',
};

export const optionsPlannedExecutedPrograms = {
//  title: "Programs executed till date for FY 2024-25",
 // chartArea: { width: "50%",height:"50%" },
  hAxis: {
    title: "Programs",
    minValue: 0,
    textStyle:{color: '#000000'},
    titleTextStyle: { color: '#000000' }
  },
  vAxis: {
    title: "Zone",
    textStyle:{color: '#000000'},
    titleTextStyle: { color: '#000000' }
  },
  colors: ["#a51f3c", "#31859c"],
  // width: 400,
  // height: 350,
  backgroundColor: '##FFFFFF',
    legend: {
        textStyle: { color: '#000000' }
    },
    titleTextStyle: {
      color: '#000000'
  }
};
export const optionsPlannedExecutedImpact = {
 // title: "Impact executed till date  for FY 2024-25",
 // chartArea: { width: "50%" ,height:"50%"},
  hAxis: {
    title: "Impact",
    minValue: 0,
    textStyle:{color: '#000000'},
    titleTextStyle: { color: '#000000' }
  },
  vAxis: {
    title: "Zone",
    textStyle:{color: '#000000'},
    titleTextStyle: { color: '#000000' }
  },
  colors: ["#a51f3c", "#31859c"],
  // width: 400,
  // height: 350,
  backgroundColor: '#FFFFFF',
    legend: {
        textStyle: { color: '#000000' }
    },
    titleTextStyle: {
      color: '#000000'
  }
};



export const optionsPlannedPrograms = {
//  title: "Programs planned for FY 2024-25",
  is3D: false,
  // width: 400,
  // height: 350,
  backgroundColor: '#FFFFFF',
    legend: {
        textStyle: { color: '#000000' }
    },
    titleTextStyle: {
      color: '#000000'
  },
  slices: {0: {color: '#a51f3c'}, 1:{color: '#db9f40'}, 2:{color: '#31859c'},3: {color: '#c2195c'},4: {color: '#000000'}}
};
export const optionsPlannedImpact = {
  //title: "Impact planned for FY 2024-25",
  is3D: false,
  // width: 400,
  // height: 350,
  backgroundColor: '#FFFFFF',
    legend: {
        textStyle: { color: '#000000' }
    },
    titleTextStyle: {
      color: '#000000'
  },
  slices: {0: {color: '#a51f3c'}, 1:{color: '#db9f40'}, 2:{color: '#31859c'},3: {color: '#c2195c'},4: {color: '#000000'}}
  
};


export function Dashboard() {
  const [plannedProgramsCurrentYear, setPlannedProgramsCurrentYear]= useState([]); 
  useEffect( ()=>{
    const getPlannedProgramsCurrentYear= async()=>{
      const response= await  DashboardDataService.getPlannedProgramsCurrentYear();
      setPlannedProgramsCurrentYear(Array.isArray(response.data) ? response.data : []);
      }
      getPlannedProgramsCurrentYear();
  },[]);
  let plannedProgramsData =[];
  let plannedProgramsHeader = [];
  plannedProgramsHeader.push("Zone","Programs");
  plannedProgramsData.push(
    plannedProgramsHeader
  );
  for (var i = 0; i < plannedProgramsCurrentYear.length; i++){
    let data2 = [];
    data2.push(plannedProgramsCurrentYear[i].zone,
      plannedProgramsCurrentYear[i].programs);
      plannedProgramsData.push(data2);
  
  }
    console.log("plannedProgramsData",plannedProgramsData);
  const [plannedImpactCurrentYear, setPlannedImpactCurrentYear]= useState([]); 
  useEffect( ()=>{
    const getPlannedImpactCurrentYear= async()=>{
      const response= await  DashboardDataService.getPlannedImpactCurrentYear();
      setPlannedImpactCurrentYear(Array.isArray(response.data) ? response.data : []);
      }
      getPlannedImpactCurrentYear();
  },[]);

console.log("plannedImpactCurrentYear", plannedImpactCurrentYear);
let plannedImpactData =[];
let plannedImpacHeader = [];
plannedImpacHeader.push("Zone","Impact");
plannedImpactData.push(
  plannedImpacHeader
);
for (i = 0; i < plannedImpactCurrentYear.length; i++){
  let data2 = [];
  data2.push(plannedImpactCurrentYear[i].zone,
    plannedImpactCurrentYear[i].impact);
    plannedImpactData.push(data2);

}
  console.log("plannedImpactData",plannedImpactData);

  console.log("plannedImpactCurrentYear", plannedImpactCurrentYear);





  const [executedProgramsCurrentYear, setExecutedProgramsCurrentYear]= useState([]); 
  useEffect( ()=>{
    const getExecutedProgramsCurrentYear= async()=>{
      const response= await  DashboardDataService.getProgramsExecutedCurrentYear();
      setExecutedProgramsCurrentYear(Array.isArray(response.data) ? response.data : []);
      }
      getExecutedProgramsCurrentYear();
  },[]);
  let executedProgramsData =[];
  let executedProgramsHeader = [];
  executedProgramsHeader.push("Zone","Planned Programs","Executed Programs");
  executedProgramsData.push(
    executedProgramsHeader
  );
  for ( i = 0; i < executedProgramsCurrentYear.length; i++){
    let data2 = [];
    data2.push(executedProgramsCurrentYear[i].zone,
      executedProgramsCurrentYear[i].plannedPrograms,
      executedProgramsCurrentYear[i].executedPrograms);
      executedProgramsData.push(data2);
  
  }
    console.log("executedProgramsData",executedProgramsData);

    const [executedImpactCurrentYear, setExecutedImpactCurrentYear]= useState([]); 
    useEffect( ()=>{
      const getExecutedImpactCurrentYear= async()=>{
        const response= await  DashboardDataService.getImpactExecutedCurrentYear();
        setExecutedImpactCurrentYear(Array.isArray(response.data) ? response.data : []);
        }
        getExecutedImpactCurrentYear();
    },[]);
    let executedImpactData =[];
    let executedImpactHeader = [];
    executedImpactHeader.push("Zone","Planned Impact","Executed Impact");
    executedImpactData.push(
      executedImpactHeader
    );

    
    for ( i = 0; i < executedImpactCurrentYear.length; i++){
      let data2 = [];
      data2.push(executedImpactCurrentYear[i].zone,
        executedImpactCurrentYear[i].impactplanned,
        executedImpactCurrentYear[i].impactexecuted);
        executedImpactData.push(data2);
    
    }
      console.log("executedImpactData",executedImpactData);


 

      const [userProgramaticProgramsData, setUserProgramaticProgramsdata]= useState([]); 
      useEffect( ()=>{
        const getUserProgramaticProgramsdata= async()=>{
          const response= await  DashboardDataService.getProgramaticProgramsLastYear();
          setUserProgramaticProgramsdata(Array.isArray(response.data) ? response.data : []);
          }
          getUserProgramaticProgramsdata();
      },[]);
      let lastFYProgramaticProgramsData1 =[];
      let lastFYProgramaticProgramsHeader = [];
      lastFYProgramaticProgramsHeader.push("","East","West","North","South");
      lastFYProgramaticProgramsData1.push(
        lastFYProgramaticProgramsHeader
      );


      for ( i = 0; i < userProgramaticProgramsData.length; i++){
        let data2 = [];
        data2.push(userProgramaticProgramsData[i].quarter,
          userProgramaticProgramsData[i].EAST,
          userProgramaticProgramsData[i].WEST,
          userProgramaticProgramsData[i].NORTH,
          userProgramaticProgramsData[i].SOUTH);
          lastFYProgramaticProgramsData1.push(data2);
      
      }
        console.log("lastFYProgramaticProgramsData1",lastFYProgramaticProgramsData1);
     
      const [userProgramaticImpactData, setUserProgramaticImpactdata]= useState([]); 
      useEffect( ()=>{
        const getUserProgramaticImpactdata= async()=>{
          const response= await  DashboardDataService.getProgramaticImpactLastYear();
          setUserProgramaticImpactdata(Array.isArray(response.data) ? response.data : []);
          }
          getUserProgramaticImpactdata();
      },[]);
      let lastFYProgramaticImpactData1 =[];
      let lastFYProgramaticImpactHeader = [];
      lastFYProgramaticImpactHeader.push("","East","West","North","South");
      lastFYProgramaticImpactData1.push(
        lastFYProgramaticImpactHeader
      );


      for ( i = 0; i < userProgramaticImpactData.length; i++){
        let data2 = [];
        data2.push(userProgramaticImpactData[i].quarter,
          userProgramaticImpactData[i].EAST,
          userProgramaticImpactData[i].WEST,
          userProgramaticImpactData[i].NORTH,
          userProgramaticImpactData[i].SOUTH);
          lastFYProgramaticImpactData1.push(data2);
      
      }
        console.log("lastFYProgramaticImpactData1",lastFYProgramaticImpactData1);

        const [directIndirectImpactData, setdirectIndirectImpactData]= useState([]); 
        useEffect( ()=>{
          const getdirectIndirectImpactData= async()=>{
            const response= await  DashboardDataService.getTotalDirectIndirectImpact();
            setdirectIndirectImpactData(Array.isArray(response.data) ? response.data : []);
            }
            getdirectIndirectImpactData();
        },[]);
        let totalDirectImpact = 0;
        let totalIndirectImpact = 0;

  
  
        for ( i = 0; i < directIndirectImpactData.length; i++){
          totalDirectImpact = directIndirectImpactData[i].directimpact;
          totalIndirectImpact = directIndirectImpactData[i].indirectimpact;
        }
          console.log("totalDirectImpact ",totalDirectImpact);
          console.log("totalIndirectImpact ",totalIndirectImpact);


          let currentYear = new Date().getFullYear();    
          let lastYear = currentYear - 1;
          let nextYear = currentYear + 1;

  return (
    
    <table align={"center"} width={"80%"}>
    {/* <th align="center" border="1px">Total Impact till date : 155400</th> */}
    {/* <tr><td style={{background:'../../images/TotalImpact.png', border: '1px solid #ccc',width:'1500',height:'600'}} colspan ="2"> */}
    <tr><td bgcolor='#e3e3e3' style={{border: '1px solid #ccc'}} colspan ="2">
    {/* <tr><td  colspan ="2">
    <div style={{ backgroundImage: `url(${img})` }}> */}
    <br/><h3 align="center" style={{color: '#c2195c'}}>Total Impact Till Date (2022 - {currentYear}) </h3><br/> <br/> <br/><br/>
    <h1 align="center"> Total Direct Impact : {totalDirectImpact} </h1> <h5 align="center">Total Indirect Impact : {totalIndirectImpact}  </h5><br/><br/></td></tr>
    <tr><td style={{border: '1px solid #ccc', width:'40%', height:'500px'}}>
        <div > <th>Programs executed of Last FY APR {lastYear} - MAR {currentYear}</th><br/> <Chart
      chartType="Bar"
    //  width="800px"
     height="400px"
      data={lastFYProgramaticProgramsData1}
      options={optionsProgramaticImpactPrograms} 
    /></div>
 </td><td style={{border: '1px solid #ccc', width:'50%', height:'600px'}}><div > 
  <th>Programatic Impact of Last FY APR {lastYear} - MAR {currentYear}</th><br/>
   <Chart
      chartType="Bar"
     width="100%"
     height="400px"
      data={lastFYProgramaticImpactData1}
      options={optionsProgramaticImpactImpact} 
    /></div>
</td></tr><tr>
<td style={{border: '1px solid #ccc', width:'50%', height:'600px'}}>
<div >
  <th>Programs planned for FY {currentYear} - {nextYear}</th>
  
    <Chart 
      chartType="PieChart"
      data={plannedProgramsData}
      options={optionsPlannedPrograms}
      width={"100%"}
      height={"350px"}
    /></div></td>
<td style={{border: '1px solid #ccc', width:'50%', height:'500px'}}>
       <div > <th>Programs executed till date for FY {currentYear} - {nextYear}</th><Chart
      chartType="BarChart"
      width={"100%"}
     height={"350px"}
      data={executedProgramsData}
      options={optionsPlannedExecutedPrograms}
    /></div></td></tr>
        <tr>
        <td style={{border: '1px solid #ccc', width:'50%', height:'500px'}}><div><th>Impact planned for FY {currentYear} - {nextYear}</th><Chart
    chartType="PieChart"
    data={plannedImpactData}
    options={optionsPlannedImpact}
     width={"100%"}
    height={"350px"} 
  /></div></td>
<td style={{border: '1px solid #ccc', width:'50%', height:'500px'}}>  <div> <th>Impact executed till date  for FY {currentYear} - {nextYear}</th><Chart
      chartType="BarChart"
      width={"100%"}
      height={"350px"}
      data={executedImpactData}
      options={optionsPlannedExecutedImpact}
    /></div></td></tr>
</table>
  );
}

export default Dashboard;