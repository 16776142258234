import axios from "axios";
import authHeader from "./auth-header";
import http from "../http-common";

const getPublicContent = () => {
  return http.get("/test/all");
};

const getUserBoard = () => {
  return http.get("/test/user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  return http.get("/test/mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return http.get("/test/admin", { headers: authHeader() });
};

const getUsers = (currentuser) => {
  return http.post("/test/getusers", {'user_id': currentuser.id}, { headers: authHeader() });
};

const updateUser = (id, data) => {
  return http.put(`/test/updateuser/${id}`, data, { headers: authHeader() });
}

export default {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  getUsers,
  updateUser
};