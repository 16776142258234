import React, { useEffect } from "react";
import { Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import '../styles/App.css';

import Home from './Home';
import RegistrationMatrix from './RegistrationMatrix';
import Master from './Master';
import RegistrationAttendanceFormCustom from './components/RegistrationAttendanceFormCustom';
import PartnerMaster from './PartnerMaster';
import Login from "./components/Login";
import Register from "./components/Register";
import AdminBoard from "./components/AdminBoard";
import Profile from "./components/Profile";
import ProgramPlanning from './forms/programPlanningData/ProgramPlanning';
import ProgramPlanningBulk from './ProgramPlanningBulk';
import ViewProgramPlanning from './forms/programPlanningData/ViewProgramPlanning';

import { logout } from "../actions/auth";
import { clearMessage } from "../actions/message";
import VillageMaster from './VillageMaster';
import ZoneMaster from './ZoneMaster';
import BlockMaster from './BlockMaster';
import DistrictMaster from './DistrictMaster';
import StateMaster from './StateMaster';
import PanchayatMaster from './PanchayatMaster';
import EditProgram from './forms/programPlanningData/EditProgram';
import ProgramImplementation from './forms/programImplementationData/ProgramImplementation';
import DynamicReport from './Reports/DynamicReport';
import SanginiLevelReport from './Reports/SanginiLevelReport';
import SanginiTop10Bottom10 from './Reports/SanginiTop10Bottom10';
import ReconReports from './Reports/ReconReport';
import SanginiKPIReport from './Reports/SanginiKPIReport';
import ExecutiveKPIReport from './Reports/ExecutiveKPIReport';
import FundTrackingReport from './Reports/FundTrackingReport';
import SanginiBooklet from "./forms/sanginiBookletData/SanginiBooklet";
import ChooseSanginiFormCustom from "./components/ChooseSanginiFormCustom";
import AfterBecomingSanginiFormCustom from "./components/AfterBecomingSanginiFormCustom";
import AddSanginiTargetFormCustom from "./components/AddSanginiTargetFormCustom";
import SanginiRegistrationSalesDataFormCustom from "./components/SanginiRegistrationSalesDataFormCustom";
import Navbar from "./Navbar/Navbar";
import VolunteerMaster from './VolunteerMaster';
import FundReleasedMaster from './FundReleasedMaster';
import Dashboard from './components/Dashboard';
import PartnerwiseImpactReport from './Reports/PartnerwiseImpactReport';
import ZoneoverviewReport from './Reports/ZoneverviewReport';
import HFHReport from './Reports/HFHReport';
import ProgramDetailsReport from './Reports/ProgramDetailsReport';
import ViewSanginiFormCustom from "./components/ViewSanginiFormCustom";
import EditSanginiTarget from "./components/EditSanginiTarget";
import EditSanginiSale from "./components/EditSanginiSale";

// RBAC https://tarunnagpal78.medium.com/tutorial-how-to-create-rbac-role-based-access-control-in-reactjs-87cb9a960cf3
function App() {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  let location = useLocation();

  useEffect(() => {
    if (["/login", "/register"].includes(location.pathname)) {
      dispatch(clearMessage()); // clear message when changing location
    }
    if (["/logout"].includes(location.pathname)) {
      dispatch(logout());
      window.location.replace("/");

    }
  }, [dispatch, location]);


  return (
    <div className="App">
      <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='program-planning' element={<ProgramPlanning/>} />
        <Route path="/edit-program/:event_id" element={<EditProgram/>} />
        <Route path='view-program-planning' element={<ViewProgramPlanning/>} />
        <Route path='upload-program-planning' element={<ProgramPlanningBulk/>} />
        <Route path='program-implementation/:event_id' element={<ProgramImplementation/>} />
        <Route path='registration-matrix' element={<RegistrationMatrix/>} />
        <Route path='program-master' element={<Master/>} />
        <Route path='partner-master' element={<PartnerMaster/>} />
        <Route path='village-master' element={<VillageMaster/>} />
        <Route path='zone-master' element={<ZoneMaster/>} />
        <Route path='block-master' element={<BlockMaster/>} />
        <Route path='district-master' element={<DistrictMaster/>} />
        <Route path='state-master' element={<StateMaster/>} />
        <Route path='panchayat-master' element={<PanchayatMaster/>} />
        <Route path='volunteer-master' element={<VolunteerMaster/>} />
        <Route path='fundReleased-master' element={<FundReleasedMaster/>} />
        <Route path='dynamic-report' element={<DynamicReport/>} />
        <Route path='sangini-level-report' element={<SanginiLevelReport/>} />
        <Route path='sangini-top-bottom-10-report' element={<SanginiTop10Bottom10/>} />
        <Route path='recon-report' element={<ReconReports/>} />
        <Route path='sangini-kpi-report' element={<SanginiKPIReport/>} />
        <Route path='executive-kpi-report' element={<ExecutiveKPIReport/>} />
        <Route path='fund-tracking-report' element={<FundTrackingReport/>} />
        <Route path='partnerwise-impact-report' element={<PartnerwiseImpactReport/>} />      
        <Route path='zoneoverview-report' element={<ZoneoverviewReport/>} />  
        <Route path='hfh-report' element={<HFHReport/>} />      
        <Route path='programdetails-report' element={<ProgramDetailsReport/>} /> 
        <Route path='update-sangini-search' element={<ChooseSanginiFormCustom pathToRoute={'update-sangini-form'} formHeaderMessage={'Update Sangini Registration'}/>} />
        <Route path='update-sangini-form/:sangini_id' element={<AfterBecomingSanginiFormCustom/>} />
        <Route path='registration-attendance-form' element={<RegistrationAttendanceFormCustom/>} />
        <Route path='sangini-sales-data-search' element={<ChooseSanginiFormCustom pathToRoute={'sangini-sales-data-form'} formHeaderMessage={'Add Sangini Sales Data'}/>} />
        <Route path='sangini-sales-data-form/:sangini_id' element={<SanginiRegistrationSalesDataFormCustom/>} />
        <Route path='sangini-target-data-form' element={<AddSanginiTargetFormCustom/>} />
        <Route path='view-sangini-search' element={<ChooseSanginiFormCustom pathToRoute={'view-sangini'} formHeaderMessage={'View Sangini'}/>} />
        <Route path='view-sangini/:sangini_id' element={<ViewSanginiFormCustom/>}/>
        <Route path='/edit-sangini-target' element={<EditSanginiTarget/>} />
        <Route path='/edit-sangini-sale' element={<EditSanginiSale/>} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/admin" element={<AdminBoard />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/sangini-booklet" element={<SanginiBooklet/>} />
        <Route path='dashboard' element={<Dashboard/>} />
      </Routes>
    </div>
  );
}

export default App;
