import { useCallback, useState, useRef,useEffect } from 'react';

import { useSelector } from 'react-redux';

import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import Navbar from './Navbar/Navbar';
import VillageDataService from "./services/village.service";
import './../styles/Master.css';
import * as Constant from './constant';

export const villageMaster = {
    pages: [
    {
      title:  "Village Master",
      elements: [{
        name: "village_name",
        title: "Village Name",
        type: "text",
        isRequired: true
      }, 
      { 
        name: "zone",
        title: "Zone",
        type: "dropdown",
        isRequired: true,
        choicesByUrl: {
          url: `${Constant.API_URL}/villages/findZone`,
          valueName: "zone"
        }
      },
      { 
        name: "state",
        title: "State",
        type: "dropdown",
        isRequired: true,
        choicesByUrl: {
          url: `${Constant.API_URL}/villages/findState`,
          valueName: "state"
        }
      },
      { 
        name: "district",
        title: "District",
        type: "dropdown",
        isRequired: true,
        choicesByUrl: {
          url: `${Constant.API_URL}/villages/findDistrict`,
          valueName: "district"
        }
      },
      { 
        name: "block",
        title: "Block",
        type: "dropdown",
        isRequired: true,
        choicesByUrl: {
          url: `${Constant.API_URL}/villages/findBlock`,
          valueName: "block"
        }
      },
      { 
        name: "panchayat",
        title: "Panchayat",
        type: "dropdown",
        isRequired: true,
        choicesByUrl: {
          url: `${Constant.API_URL}/villages/findPanchayat`,
          valueName: "panchayat"
        }
      },
      { 
        name: "village_population",
        title: "Village Population",
        type: "text",
        inputType: "number",
        isRequired: true
      },
      { 
        name: "no_of_male",
        title: "No. of Male",
        type: "text",
        inputType: "number",
        isRequired: true
      },
      { 
        name: "no_of_female",
        title: "No. of Female",
        type: "text",
        inputType: "number",
        isRequired: true
      },
      { 
        name: "no_of_children",
        title: "No. of Children",
        type: "text",
        inputType: "number",
        isRequired: true
      },
      { 
        name: "date",
        title: "Date",
        type: "text",
        inputType: "date",
        isRequired: true
      },
      { 
        name: "updated_by_id",
        title: "UserId",
        type: "text",
        inputType: "text",
        visible:"false",
        clearInvisibleValues:"false"
      },
    ]
    }]
  ,
  showQuestionNumbers: "off",
  pageNextText: false,
  completeText: "Submit",
  showPrevButton: false,
  firstPageIsStarted: false,
  showPreviewBeforeComplete:false,
  // startSurveyText: "Take the Survey",
  completedHtml: "Thank you for submitting Village Master!"
  };

  const customCss = {
    "question": {
        "content": "report-header",
        "answered": "report-header",
        "titleRequired": "report-header"
    },
    "navigation": {
      "complete": "sv-btn sv-footer__next-btn my-button"
    }
};

  function VillageMaster() {
    // useRef enables the Model object to persist between state changes
    const survey = useRef(new Model(villageMaster)).current;
    const [surveyResults, setSurveyResults] = useState("");
    const [isSurveyCompleted, setIsSurveyCompleted] = useState(false);

    const { user: currentUser } = useSelector((state) => state.auth);
    const [roleId, setRoleId] = useState(0);
    const [userId, setUserId] = useState(0);

    useEffect(() => {
      if (currentUser) {
        setRoleId(currentUser.role_id);
        setUserId(currentUser.id);
        console.log("currentUser",currentUser);
      }
    }, [currentUser]);
  
    const displayResults = useCallback((sender) => {
      setSurveyResults(JSON.stringify(sender.data, null, 4));
      const results = JSON.stringify(sender.data);
      
      console.log("results",results);
      
         VillageDataService.create(results)
          .then(response => {
          console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });

          setIsSurveyCompleted(true);
       
     
    }, []);

    survey.css = customCss;
    survey.clearInvisibleValues=false;
    survey.data = {updated_by_id:userId};
    survey.onValueChanged.add((sender, options) => {
      const el = document.getElementById(options.updated_by_id);
      if (el) {
          el.value = options.value;
      }
  });

    survey.onServerValidateQuestions.add(ValidateDuplicates);
    survey.onComplete.add(displayResults);

   
    

    survey.onUpdateQuestionCssClasses
    .add(function (survey, options) {
        var classes = options.cssClasses

        classes.mainRoot += " sv_qstn";
        classes.root = "sq-root";
        classes.title += " sq-title"
        classes.item += " sq-item";
        classes.label += " sq-label";


        if (options.question.isRequired) {
            classes.title += " sq-title-required";
            classes.root += " sq-root-required";
        }
        if(!options.question.isEmpty()) {
            classes.root += " sv_answered";
        }
        if (options.question.getType() === "dropdown") {
            classes.root += " sq-dropdwn";
        }
    });
  
    return (
      <div>
        
        <Survey model={survey} id="surveyContainer" />
  
      </div>
    );
  }

  function ValidateDuplicates(_, { data, errors, complete }) {
    const villageName = data["village_name"];
    if (!villageName) {
      complete();
      return;
    }
    VillageDataService.findByName(villageName)
      .then((response) => {
        console.log(response.data);
        if(response.data.length>0)
        {
          errors["village_name"] = "Village Name already Exist";
        }
        complete();
        //return response.data;
      })
      .catch(e => {
        console.log(e);
      })
      /*.then((data) => {
        alert(data);
        if (data!=null) {
          errors["village_name"] = "Village Name already Exist";
        }
        complete();
      });*/
  }

  
  export default VillageMaster;