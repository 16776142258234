import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import UserService from "../../services/user.service";
import RoleService from "../../services/role.service";
import AuthService from "../../services/auth.service";
import "../../styles/AdminBoard.css";
import SanginiDataService from "./../services/sangini_details.service";

const PasswordChangeModal = ({ user, onClose, onSubmit }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = () => {
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    onSubmit(newPassword);
  };

  return (
    <div className="custom-modal">
      <div className="custom-modal-content">
        <h2>Change Password for {user.user_name}</h2>
        <input
          type="password"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="Confirm New Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {error && <p className="error">{error}</p>}
        <div className="custom-modal-actions">
          <button onClick={handleSubmit}>Change Password</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

const AdminBoard = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [changedUsers, setChangedUsers] = useState({});
  const [message, setMessage] = useState(null);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [passwordChangeUser, setPasswordChangeUser] = useState(null);
  const [sanginis, setSanginis] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [usersResponse, rolesResponse, sanginiResponse] = await Promise.all([
          UserService.getUsers(currentUser),
          RoleService.getRoles(),
          SanginiDataService.getAll()
        ]);
        setUsers(usersResponse.data);
        setRoles(rolesResponse.data);
        setSanginis(sanginiResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setMessage({ text: "Error fetching data", type: "error" });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUser]);

  const handleChange = (userId, field, value) => {
    setChangedUsers(prev => ({
      ...prev,
      [userId]: {
        ...prev[userId],
        [field]: value === '' ? null : value
      }
    }));
  };

  const handleSubmit = async (userId) => {
    try {
      const updateData = {};
      if (changedUsers[userId]?.role_id !== undefined) {
        updateData.role_id = changedUsers[userId].role_id;
      }
      if (changedUsers[userId]?.manager_id !== undefined) {
        updateData.manager_id = changedUsers[userId].manager_id;
      }
      if (changedUsers[userId]?.sangini_id !== undefined) {
        updateData.sangini_id = changedUsers[userId].sangini_id;
      }

      await UserService.updateUser(userId, updateData);
      setUsers(users.map(user => 
        user.user_id === userId 
          ? { ...user, ...updateData } 
          : user
      ));
      setMessage({ text: "User was updated successfully!", type: "success" });
      
      setChangedUsers(prev => {
        const newState = { ...prev };
        delete newState[userId];
        return newState;
      });

      // Set a timeout to reload the page after showing the success message
      setTimeout(() => {
        window.location.reload();
      }, 1500); // Reload after 1.5 seconds

    } catch (error) {
      console.error("Error updating user:", error);
      setMessage({ text: error.response?.data?.message || "Error updating user", type: "error" });
    }
  };

  const handlePasswordChangeClick = (user) => {
    console.log("Opening password change modal for user:", user);
    setPasswordChangeUser(user);
  };

  const handlePasswordChangeSubmit = async (newPassword) => {
    try {
      await AuthService.adminChangePassword(passwordChangeUser.user_id, newPassword);
      setMessage({ text: "Password changed successfully", type: "success" });
      setPasswordChangeUser(null);
      setTimeout(() => {
        setMessage(null);
      }, 3000);
    } catch (error) {
      console.error("Error changing password:", error);
      setMessage({ text: error.response?.data?.message || "Error changing password", type: "error" });
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  const adminRoleId = roles.find(role => role.role_name === "Admin")?.role_id;
  const programExecutiveRoleId = roles.find(role => role.role_name === "Program Executive")?.role_id;
  const programManagerRoleId = roles.find(role => role.role_name === "Program Manager")?.role_id;
  const sanginiRoleId = roles.find(role => role.role_name === "Sangini")?.role_id;

  return (
    <div className="admin-board">
      <div className="admin-container">
        <h1 className="admin-board-title">User Management</h1>
        {message && (
          <div className={`message ${message.type}`}>
            {message.text}
          </div>
        )}
        <div className="table-responsive">
          <table className="user-table">
            <thead>
              <tr>
                <th>Username</th>
                <th>Email</th>
                <th>Role</th>
                <th>Manager</th>
                <th>Sangini</th>
                <th>Action</th>
                <th>Change Password</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => {
                const isAdminUser = user.role_id === adminRoleId;
                const isProgramExecutive = user.role_id === programExecutiveRoleId;
                const isSangini = user.role_id === sanginiRoleId;
                const programManagers = users.filter(u => u.role_id === programManagerRoleId);

                return (
                  <tr key={user.user_id}>
                    <td>{user.user_name}</td>
                    <td>{user.email}</td>
                    <td>
                      <select
                        value={changedUsers[user.user_id]?.role_id || user.role_id}
                        onChange={(e) => handleChange(user.user_id, 'role_id', e.target.value)}
                        disabled={isAdminUser}
                        style={isAdminUser ? { backgroundColor: '#f0f0f0', color: '#888' } : {}}
                      >
                        {roles.map((role) => (
                          <option key={role.role_id} value={role.role_id}>
                            {role.role_name}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      {isProgramExecutive && (
                        <select
                          value={changedUsers[user.user_id]?.manager_id || user.manager_id || ''}
                          onChange={(e) => handleChange(user.user_id, 'manager_id', e.target.value)}
                        >
                          <option value="">Select Manager</option>
                          {programManagers.map((manager) => (
                            <option key={manager.user_id} value={manager.user_id}>
                              {manager.user_id} : {manager.user_name}
                            </option>
                          ))}
                        </select>
                      )}
                      {!isProgramExecutive && <span>-</span>}
                    </td>
                    <td>
                      {isSangini && (
                        <select
                          value={changedUsers[user.user_id]?.sangini_id || user.sangini_id || ''}
                          onChange={(e) => handleChange(user.user_id, 'sangini_id', e.target.value)}
                        >
                          <option value="">Select Sangini</option>
                          {sanginis.map((sangini) => (
                            <option key={sangini.sangini_id} value={sangini.sangini_id}>
                              {sangini.sangini_id} : {sangini.name}
                            </option>
                          ))}
                        </select>
                      )}
                      {!isSangini && <span>-</span>}
                    </td>
                    <td>
                      {changedUsers[user.user_id] && (
                        <button onClick={() => handleSubmit(user.user_id)} className="submit-button">
                          Submit
                        </button>
                      )}
                    </td>
                    <td>
                      <button onClick={() => handlePasswordChangeClick(user)} className="change-password-button">
                        Change Password
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {passwordChangeUser && (
        <PasswordChangeModal
          user={passwordChangeUser}
          onClose={() => setPasswordChangeUser(null)}
          onSubmit={handlePasswordChangeSubmit}
        />
      )}
    </div>
  );
};

export default AdminBoard;
