import axios from "axios";
import http from "../http-common";

const register = (username, email, password) => {
  return http.post("/auth/signup", {
    user_name: username,
    email,
    password,
  });
};

const login = (email, password) => {
  return http
    .post("/auth/signin", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const adminChangePassword = (userId, newPassword) => {
  const user = JSON.parse(localStorage.getItem('user'));
  return http.put(
    `/auth/admin/changepassword/${userId}`,
    { new_password: newPassword },
    { headers: { 'x-access-token': user.accessToken } }
  );
};

const AuthService = {
  register,
  login,
  logout,
  adminChangePassword,
};

export default AuthService;