import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from 'react-bootstrap';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import { login } from "../../actions/auth";
import "../../styles/forms/programPlanningData/ProgramPlanningData.css"
import "../../styles/Login.css";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { isLoggedIn } = useSelector(state => state.auth);
  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(login(email, password))
      .then(() => {
        navigate("/");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div className="login-container">
      <div className="login-card">
        <h2 className="login-header">Welcome Back!</h2>
        <p className="login-subheader">Please sign in to your account</p>
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <Button type="submit" className="adjust-width login-custom-btn" disabled={loading}>
              {loading && (
                <span className="spinner-border spinner-border-sm mr-2"></span>
              )}
              Sign In
            </Button>
          </div>
          <div className="form-group text-center">
            <Button
              variant="link"
              onClick={handleShowModal}
              className="p-0 text-decoration-none"
            >
              Forgot Password?
            </Button>
          </div>
          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
        </form>
        <p className="login-footer">Don't have an account? <Link to="/register">Sign up</Link></p>
        <div className="form-group">
          <Link to="/" className="adjust-width home-custom-btn btn btn-block">Go to Home</Link>
        </div>

        {/* Forgot Password Modal */}
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Forgot Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Please contact your system administrator to reset your password.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseModal}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Login;