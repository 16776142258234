import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Dropdown, DropdownButton } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../../styles/forms/programPlanningData/ProgramPlanningData.css'
import './../../styles/App.css';

const ReportGenMonthDropdown = ({ onChange }) => {
    const [reportMonths, setReportMonths] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchReportMonths = async () => {
            try {
                //const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/villageDetails`);
                var d = new Date();
                console.log("date:",d);
                let currentMonth = d.getMonth() ;             
                let currentYear = new Date().getFullYear();    
                let cntMonth = 1;    
                let monthLst=[]
                let actualCurrentMonth=0;
                while (cntMonth<=12) {   

                    actualCurrentMonth=currentMonth<10?"0"+currentMonth:currentMonth;
                  
                    monthLst.push({month_year:currentYear+"-"+actualCurrentMonth});
                    
                    //console.log("monthLst:",monthLst);
                    //console.log("currentYear:",currentYear);
                    //console.log("currentMonth:",currentMonth);
                    
                    currentMonth -= 1;   
                    if(currentMonth===0)
                    {
                        currentYear-=1
                        currentMonth=12
                    }
                    cntMonth+=1; 
                }
                console.log("monthLst:",monthLst);
                setReportMonths(monthLst);
             
            } catch (err) {
                console.log(err);
                setError('Failed to fetch report gen months');
            } finally {
                setLoading(false);
            }
        };

        fetchReportMonths();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;


    return (
        <Form.Group className="reportMonthDropdown">
            <Form.Control className='input' as="select" onChange={onChange}>
                <option value="" data_id="">Select Report Month</option>
                {reportMonths.map((detail) => (
                    <option key={detail.month_year} value={detail.month_year}>
                        {detail.month_year}
                    </option>
                ))}
            </Form.Control>
        </Form.Group>
    );
};

export default ReportGenMonthDropdown;
