import React, { useEffect, useState } from 'react';
import { Form, Container, Col, Button, Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import PartnerDetailsDropdown from './PartnerDetailsDropdown';
import ProgramDetailsDropdown from './ProgramDetailsDropdown';
import './../../../styles/App.css';
import './../../../styles/forms/programPlanningData/ProgramPlanningData.css';
import ImpactBudgetStatusSelect from './ImpactBudgetStatusSelect';
import { useParams, useNavigate } from 'react-router-dom';
import './../../../styles/forms/programPlanningData/EditProgram.css';
import EventPlanningDataService from './../../services/event_planning_data.service.js';
import ProgramDataService from './../../services/program.service.js';

function EditProgram() {
    const { event_id } = useParams();
    const navigate = useNavigate();
    const { user: currentUser } = useSelector((state) => state.auth);
    const [roleId, setRoleId] = useState(0);
    const [userId, setUserId] = useState(0);

    const [formData, setFormData] = useState({
        key: 0,
        eventPlannedDate: '',
        eventCategory: '',
        programCode: '',
        partnerCode: '',
        villageId: '',
        womenImpactedPlanned: 0,
        childrenImpactedPlanned: 0,
        othersImpactedPlanned: 0,
        remarks1: '',
        budgetAmountPlanned: 0,
        paidFromDFDFTPlanned: 0,
        remarks2: '',
        programPlannedStatus: '',
        heroesOfHumanity: 'N',
    });

    const [initialFormData, setInitialFormData] = useState({});
    const [programDetails, setProgramDetails] = useState([]);
    const [errors, setErrors] = useState([]);
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (currentUser) {
            setRoleId(currentUser.role_id);
            setUserId(currentUser.id);
            console.log(currentUser);
        }
        fetchEventData(event_id);
    }, [currentUser, event_id]);

    useEffect(() => {
        const fetchProgramDetails = async () => {
            if (formData.eventCategory) {
                try {
                    const response = await ProgramDataService.findAllByProgramCategory(formData.eventCategory);
                    setProgramDetails(response.data);
                } catch (error) {
                    console.error('Error fetching program details:', error);
                }
            }
        };

        fetchProgramDetails();
    }, [formData.eventCategory]);

    const fetchEventData = async (event_id) => {
        try {
            const response = await EventPlanningDataService.get(event_id);
            console.log(response);
            const eventPlannedDate = new Date(response.data.event_planned_date).toLocaleDateString('en-CA');
            const newFormData = {
                eventPlannedDate: eventPlannedDate || '',
                eventCategory: response.data.event_category || '',
                programCode: response.data.program_code || '',
                partnerCode: response.data.partner_code || '',
                heroesOfHumanity: response.data.hfh_flag || '',
                villageId: response.data.village_id || '',
                womenImpactedPlanned: response.data.no_of_women_impacted_planned || 0,
                childrenImpactedPlanned: response.data.no_of_children_impacted_planned || 0,
                othersImpactedPlanned: response.data.no_of_other_impacted_planned || 0,
                remarks1: response.data.impact_remark_planned || '',
                budgetAmountPlanned: response.data.budgeted_amount_planned || 0,
                paidFromDFDFTPlanned: response.data.payed_from_df_dft_planned || 0,
                remarks2: response.data.budgeted_remark_planned || '',
                programPlannedStatus: response.data.program_planning_status || ''
            };
            setFormData(newFormData);
            setInitialFormData(newFormData);
        } catch (error) {
            console.error('Error fetching program data:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value || ''
        });
    };

    const handleStatusChange = (e) => {
        setFormData({
            ...formData,
            programPlannedStatus: e.target.value || ''
        });
    };

    const validateData = () => {
        const newErrors = [];

        if (!formData.eventPlannedDate) newErrors.push('Please select a program date.');
        if (!formData.eventCategory) newErrors.push('Please select a program category.');
        if (!formData.programCode) newErrors.push('Please select a program code.');
        if (!formData.partnerCode) newErrors.push('Please select a partner code.');
        if (!formData.villageId) newErrors.push('Please select a village.');
        if (!formData.programPlannedStatus) newErrors.push('Please select status.');

        setErrors(newErrors);
        return newErrors.length === 0;
    };

    // Error handling with timeout
    useEffect(() => {
        let timeout;
        if (errors.length > 0) {
            timeout = setTimeout(() => {
                setErrors([]);
            }, 2000);
        }
        return () => clearTimeout(timeout); // Cleanup on unmount or when errors change
    }, [errors]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors([]);

        if (validateData()) {
            try {
                let dataToSave = {
                    event_planned_date: formData.eventPlannedDate,
                    event_category: formData.eventCategory,
                    program_code: formData.programCode,
                    partner_code: formData.partnerCode,
                    hfh_flag: formData.heroesOfHumanity,
                    village_id: formData.villageId,
                    no_of_women_impacted_planned: formData.womenImpactedPlanned,
                    no_of_children_impacted_planned: formData.childrenImpactedPlanned,
                    no_of_other_impacted_planned: formData.othersImpactedPlanned,
                    impact_remark_planned: formData.remarks1,
                    budgeted_amount_planned: formData.budgetAmountPlanned,
                    payed_from_df_dft_planned: formData.paidFromDFDFTPlanned,
                    budgeted_remark_planned: formData.remarks2,
                    program_planning_status: formData.programPlannedStatus,
                    updated_by_id: currentUser.id
                }

                if (window.confirm('Are you sure you want to edit this program?')) {
                    const response = await EventPlanningDataService.update(event_id, dataToSave);
                    setMessage('Program Planning Data successfully edited!');

                    setTimeout(() => {
                        navigate('/view-program-planning');
                    }, 2000);
                }
            } catch (error) {
                setMessage('Error submitting data.');
                console.error('Error updating data:', error);
            }
        }
    };

    const isFieldEditable = (fieldName) => {
        if (roleId === 2) return true; // Program manager can edit all fields
        if (roleId === 1) {
            // Program executive can only edit empty fields
            const initialValue = initialFormData[fieldName];
            if (typeof initialValue === 'number') {
                return initialValue === 0;
            }
            return !initialValue;
        }
        return false; // Default to not editable
    };

    const renderField = (fieldName, label, type = "text") => (
        <Form.Group id={fieldName} className='input-field-single-row input-field-backgroud'>
            <Form.Label>{label}:</Form.Label>
            <Form.Control
                type={type}
                name={fieldName}
                value={formData[fieldName] || ''}
                onChange={handleChange}
                disabled={!isFieldEditable(fieldName)}
                className="input"
            />
        </Form.Group>
    );

    return (
        <Container className='event-planning-page'>
            <Form id='edit-event-planning-form' onSubmit={handleSubmit}>
                {errors.length > 0 && <Alert className='alert' variant="danger">{errors[0]}</Alert>}
                {message && <Alert variant="info">{message}</Alert>}
                <Container className='input-grid'>
                    <h2>View/Edit Planned Data</h2>
                    <Container className='input-field-multiple'>
                        {renderField("eventPlannedDate", "Program Planned Date", "date")}

                        <Form.Group id="event-category" className='input-field-single-row input-field-backgroud'>
                            <Form.Label>Program Category:</Form.Label>
                            <Col className="radio-list">
                                {['H', 'L', 'E'].map((value) => (
                                    <Form.Check
                                        key={value}
                                        type="radio"
                                        label={value === 'H' ? 'Health' : value === 'L' ? 'Livelihood' : 'Education'}
                                        name="eventCategory"
                                        value={value}
                                        checked={formData.eventCategory === value}
                                        onChange={handleChange}
                                        disabled={!isFieldEditable("eventCategory")}
                                    />
                                ))}
                            </Col>
                        </Form.Group>

                        <Form.Group id="heroes-of-humanity" className='input-field-single-row input-field-backgroud'>
                            <Form.Label>Heroes of Humanity?:</Form.Label>
                            <Col className="radio-list">
                                {['Y', 'N'].map((value) => (
                                    <Form.Check
                                        key={value}
                                        type="radio"
                                        label={value === 'Y' ? 'Yes' : 'No'}
                                        name="heroesOfHumanity"
                                        value={value}
                                        checked={formData.heroesOfHumanity === value}
                                        onChange={handleChange}
                                        disabled={!isFieldEditable("heroesOfHumanity")}
                                    />
                                ))}
                            </Col>
                        </Form.Group>
                    </Container>

                    <Container className='input-field-multiple'>
                        <Form.Group id="program-code" className='input-field-single-column input-field-backgroud'>
                            <Form.Label>Program Code/Name:</Form.Label>
                            <ProgramDetailsDropdown
                                name="programCode"
                                onChange={handleChange}
                                programDetails={programDetails}
                                setProgramDetails={setProgramDetails}
                                value={formData.programCode || ''}
                                disabled={!isFieldEditable("programCode")}
                            />
                        </Form.Group>

                        <Form.Group id="partner-code" className='input-field-single-column input-field-backgroud'>
                            <Form.Label>Partner Code/Name:</Form.Label>
                            <PartnerDetailsDropdown
                                name="partnerCode"
                                onChange={handleChange}
                                value={formData.partnerCode || ''}
                                disabled={!isFieldEditable("partnerCode")}
                                roleId={currentUser.role_id}
                                userId={currentUser.id}
                            />
                        </Form.Group>
                    </Container>
                </Container>

                <Container className='dynamic-container'>
                    <hr className='line-break' />
                    <ImpactBudgetStatusSelect
                        names={["villageId", "womenImpactedPlanned", "childrenImpactedPlanned", "othersImpactedPlanned", "remarks1", "budgetAmountPlanned", "paidFromDFDFTPlanned", "programPlannedStatus", "remarks2"]}
                        errors={errors}
                        formData={formData}
                        handleChange={handleChange}
                        handleStatusChange={handleStatusChange}
                        villageValue={formData.villageId || ''}
                        isFieldEditable={isFieldEditable}
                        roleId={currentUser.role_id}
                        userId={currentUser.id}
                    />
                </Container>

                <Button variant="primary" type="submit" className="custom-btn">Save Changes</Button>
            </Form>
        </Container>
    );
}

export default EditProgram;