import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Dropdown, DropdownButton } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../../styles/forms/programPlanningData/ProgramPlanningData.css'
import './../../styles/App.css';
import Select from 'react-select';
import VillageDataService from "../services/village.service";

const VillageStateDropdown = ({ onChange }) => {
    const [villageStates, setVillageStates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchVillageDetails = async () => {
            try {
                //const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/villageDetails`);
                VillageDataService.getState()
                .then((response) => {
                    console.log(response.data);
                    setVillageStates(response.data);
                })
                .catch(e => {
                    console.log(e);
                })
            } catch (err) {
                setError('Failed to fetch villages');
            } finally {
                setLoading(false);
            }
        };

        fetchVillageDetails();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;


    return (
        <Form.Group className="villageDetailsDropdown">
            <Form.Control className='input' as="select" onChange={onChange} multiple={true}>
                <option value="" data_id="">Select All State</option>
                {villageStates.map((detail) => (
                    <option key={detail.state} value={detail.state}>
                        {detail.state}
                    </option>
                ))}
            </Form.Control>
        </Form.Group>
    );
};

export default VillageStateDropdown;
