import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Dropdown, DropdownButton } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../../styles/forms/programPlanningData/ProgramPlanningData.css'
import './../../styles/App.css';
import Select from 'react-select';
import ReportDataService from "../services/report.service";

const ReportMonthDropdown = ({ onChange }) => {
    const [reportMonths, setReportMonths] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchReportMonths = async () => {
            try {
                //const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/villageDetails`);
                ReportDataService.getReportMonths()
                .then((response) => {
                    console.log(response.data);
                    setReportMonths(response.data);
                })
                .catch(e => {
                    console.log(e);
                })
            } catch (err) {
                setError('Failed to fetch report months');
            } finally {
                setLoading(false);
            }
        };

        fetchReportMonths();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;


    return (
        <Form.Group className="reportMonthDropdown">
            <Form.Control className='input' as="select" onChange={onChange}>
                <option value="" data_id="">Select Report Month</option>
                {reportMonths.map((detail) => (
                    <option key={detail.month_year} value={detail.month_year}>
                        {detail.month_year}
                    </option>
                ))}
            </Form.Control>
        </Form.Group>
    );
};

export default ReportMonthDropdown;
