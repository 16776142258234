import http from "../../http-common";

class ProgramMasterDataService {
  getAll() {
    return http.get("/programs_master");
  }

  get(id) {
    return http.get(`/programs_master/${id}`);
  }

  create(data) {
    return http.post("/programs_master", data);
  }

  update(id, data) {
    return http.put(`/programs_master/${id}`, data);
  }

  delete(id) {
    return http.delete(`/programs_master/${id}`);
  }

  deleteAll() {
    return http.delete("/programs_master");
  }

  findByCode(program_code) {
    return http.get(`/programs_master?program_code=${program_code}`);
  }

  
}

export default new ProgramMasterDataService();