import { useCallback, useState, useRef } from 'react';

import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import Navbar from './Navbar/Navbar';
import VillageDataService from "./services/village.service";
import './../styles/Master.css';

export const master = {
    pages: [{
      title:  "Panchayat Master",
      elements: [{
        name: "panchayat_name",
        title: "Name of the Panchayat",
        type: "text",
        isRequired: true
      }
      ]
    },
    
    ]
  ,
    showQuestionNumbers: "off",
    pageNextText: false,
    completeText: "Submit",
    showPrevButton: false,
    firstPageIsStarted: false,
    showPreviewBeforeComplete:false,
    // startSurveyText: "Take the Survey",
    completedHtml: "Thank you for submitting Panchayat Master!"
  };

  const customCss = {
    "question": {
        "content": "report-header",
        "answered": "report-header",
        "titleRequired": "report-header"
    },
    "navigation": {
      "complete": "sv-btn sv-footer__next-btn my-button"
    }
};

  function Master() {
    // useRef enables the Model object to persist between state changes
    const survey = useRef(new Model(master)).current;
    const [surveyResults, setSurveyResults] = useState("");
    const [isSurveyCompleted, setIsSurveyCompleted] = useState(false);
  
    const displayResults = useCallback((sender) => {
      setSurveyResults(JSON.stringify(sender.data, null, 4));
      const results = JSON.stringify(sender.data);
      console.log("results",results);
      //alert(results);
      VillageDataService.createpanchayat(results)
      .then(response => {
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
      setIsSurveyCompleted(true);
    }, []);

    survey.css = customCss;
  
    //survey.onServerValidateQuestions.add(ValidateDuplicates);
    survey.onComplete.add(displayResults);

    survey.onUpdateQuestionCssClasses
    .add(function (survey, options) {
        var classes = options.cssClasses

        classes.mainRoot += " sv_qstn";
        classes.root = "sq-root";
        classes.title += " sq-title"
        classes.item += " sq-item";
        classes.label += " sq-label";


        if (options.question.isRequired) {
            classes.title += " sq-title-required";
            classes.root += " sq-root-required";
        }
        if(!options.question.isEmpty()) {
            classes.root += " sv_answered";
        }
        if (options.question.getType() === "checkbox") {
            classes.root += " sq-root-cb";
        }
    });
  
    return (
      <div>
        <Survey model={survey} id="surveyContainer" />
  
      </div>
    );
  }
  
  export default Master;