import http from "../../http-common";

class DashboardDataService {



 getPlannedProgramsCurrentYear() {
   return http.get("dashboard/findPlannedProgramsCurrentYear");
  }

  getPlannedImpactCurrentYear() {
    return http.get("dashboard/findPlannedImpactCurrentYear");
  }
  getProgramsExecutedCurrentYear() {
     return http.get("dashboard/findProgramsExecutedCurrentYear");
   }

   getImpactExecutedCurrentYear() {
    return http.get("dashboard/findImpactExecutedCurrentYear");
  }

  getProgramaticProgramsLastYear() {
    return http.get("dashboard/findProgramaticProgramsLastYear");
  }

  getProgramaticImpactLastYear() {
    return http.get("dashboard/findProgramaticImpactLastYear");
  }

  getTotalDirectIndirectImpact() {
    return http.get("dashboard/findTotalDirectIndirectImpact");
  }
}

export default new DashboardDataService();