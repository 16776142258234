import React from 'react';
import { Form, Container, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../../../styles/forms/programPlanningData/ProgramPlanningData.css'
import VillageDetailsDropdown from './VillageDetailsDropdown';
import './../../../styles/App.css';

const ImpactBudgetStatusSelect = ({ formData, handleChange, names, handleStatusChange, villageValue, isFieldEditable, roleId, userId, selectedVillageIds, partnerCode }) => {
  const isPlannedChecked = formData.programPlannedStatus === 'P';
  const isUnplannedChecked = formData.programPlannedStatus === 'U';

  const renderField = (fieldName, label, type = "text") => {

    const isDisabled = !isFieldEditable(fieldName);
    return (
      <Form.Group className='input-field-single-column input-field-backgroud'>
        <Form.Label>{label}:</Form.Label>
        <Form.Control
          type={type}
          name={fieldName}
          value={formData[fieldName] || ''}
          onChange={handleChange}
          disabled={isDisabled}
          className="input"
          placeholder={type === "number" ? "Enter impact" : "Enter remarks"}
          min={type === "number" ? "0" : undefined}
          as={type === "textarea" ? "textarea" : undefined}
          rows={type === "textarea" ? 3 : undefined}
        />
      </Form.Group>
    );
  };

  const madatory = () => <span className='mandatory-icon'>*</span>;

  return (
    <Container className='input-grid'>
      <Container className="input-field-multiple">
        <Form.Group id="village" className="input-field-single-column input-field-backgroud">
          <Form.Label>Village - Taluka - District - State:{madatory()}</Form.Label>
          <VillageDetailsDropdown
            name={names[0]}
            onChange={handleChange}
            value={villageValue}
            disabled={!isFieldEditable(names[0])}
            roleId={roleId}
            userId={userId}
            selectedVillageIds={selectedVillageIds}
            partnerCode={partnerCode}
          />
        </Form.Group>

        {renderField(names[1], "Women Impact Planned", "number")}
        {renderField(names[2], "Children Impact Planned", "number")}
        {renderField(names[3], "Others Impact Planned", "number")}
        {renderField(names[4], "Remarks (Impact)", "textarea")}
      </Container>

      <Container className="input-field-multiple">
        {renderField(names[5], "Budget Amount Planned", "number")}
        {renderField(names[6], "Paid from (DF/DFT) Planned", "number")}

        <Form.Group id="status" className='input-field-single-column input-field-backgroud'>
          <Form.Label>Program Planning Status:{madatory()}</Form.Label>
          <Col className="radio-list">
            <Form.Check
              type="radio"
              label="Planned"
              name={`${names[7]}-${formData.key}`}
              value="P"
              checked={isPlannedChecked}
              onChange={handleStatusChange}
              disabled={!isFieldEditable(names[7])}
            />
            <Form.Check
              type="radio"
              label="Unplanned"
              name={`${names[7]}-${formData.key}`}
              value="U"
              checked={isUnplannedChecked}
              onChange={handleStatusChange}
              disabled={!isFieldEditable(names[7])}
            />
          </Col>
        </Form.Group>

        {renderField(names[8], "Remarks (Budget)", "textarea")}
      </Container>
    </Container>
  );
};

export default ImpactBudgetStatusSelect;