import { useEffect, useState } from "react";
import { Alert, Button, Container, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import SanginiRegistrationService from "../services/sanginiRegistration.service.js";
import SanginiDropdown from "../forms/sanginiBookletData/SanginiDropdown.js";
import "../../styles/forms/sanginiData/sanginiData.css";
import { useSelector } from "react-redux";
function AddSanginiTargetFormCustom() {

    const { user: currentUser } = useSelector((state) => state.auth);

    const [formData, setFormData] = useState({
        sanginiId: '',
        targetYear: '',
        targetForPads: '',
        targetForVillages: '',
        targetForUsers: '',
        targetRemarks: '',
        updated_by_id: currentUser?.id
    });

    const [errors, setErrors] = useState([]);

    const [message, setMessage] = useState('');

    useEffect(() => {
        setFormData(currentFormData => ({
            ...currentFormData,
            updated_by_id: currentUser?.id
        }));
    }, [currentUser]);

    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleYearValueChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value.length > 4 ? value.slice(0, 4) : value
        })
    }

    const checkIfValidYear = (year) => {
        if (year.length < 4) {
            return false;
        }
        let yearInt = parseInt(year);
        if (yearInt >= 1901 && yearInt <= 2100) {
            return true;
        }
        return false;
    }

    const validateFormData = () => {
        let valid = true;

        if (!formData.sanginiId) {
            let errorMessage = "Please choose sangini to add target.";
            setErrors(prevErrors => {
                return [...prevErrors, errorMessage];
            });
            valid = false;
        }

        if (!formData.targetYear) {
            let errorMessage = "Please enter target year."
            setErrors(prevErrors => {
                return [...prevErrors, errorMessage];
            });
            valid = false;
        }

        if (!checkIfValidYear(formData.targetYear)) {
            let errorMessage = "Please enter a valid target year.";
            setErrors(prevErrors => {
                return [...prevErrors, errorMessage];
            });
            valid = false;
        }

        if (!formData.targetForPads) {
            let errorMessage = "Please enter target for pads."
            setErrors(prevErrors => {
                return [...prevErrors, errorMessage];
            });
            valid = false;
        }

        if (!formData.targetForVillages) {
            let errorMessage = "Please enter target for villages."
            setErrors(prevErrors => {
                return [...prevErrors, errorMessage];
            });
            valid = false;
        }

        if (!formData.targetForUsers) {
            let errorMessage = "Please enter target for users."
            setErrors(prevErrors => {
                return [...prevErrors, errorMessage];
            });
            valid = false;
        }

        return valid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
        setErrors([]);
        if (validateFormData()) {
            try {
                console.log(formData);
                const response = SanginiRegistrationService.addSanginiTargetData(formData)
                    .then(response => {
                        console.log("Submitted", response.data);
                        if (response.status === 400 || response.status === 500) {
                            if (response.message) {
                                setMessage("Failed adding sangini target details with error " + response.message);
                            } else {
                                setMessage("Error adding sangini target details.");
                            }
                        }
                        else
                            setMessage("Sangini target details updated successfully.")
                    })
                    .catch(e => {
                        if (e?.response?.data?.message) {
                            setMessage("Failed to update sangini target details with error: " + e.response.data.message);
                        } else {
                            setMessage("Error updating sangini target details.");
                        }
                        console.log(e);
                    });
            }
            catch (error) {
                setMessage("Error updating sangini target details.");
                console.error(error);
            }
        }
    }

    return (
        <Container className='event-planning-page'>

            <Form id='sangini-form'>
                <h2>Add Sangini Target Details</h2>
                <Container className='input-field-multiple'>
                    <Form.Group id="sangini-id" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Sangini Name & Mobile No:</Form.Label>
                        <SanginiDropdown
                            onChange={handleFormDataChange}
                            value={formData.sanginiId}
                            name="sanginiId"
                            currentUser={currentUser}
                        />
                    </Form.Group>
                </Container>
                <Container className='input-field-multiple'>
                    <Form.Group id="target-year" className='input-field-single-row input-field-backgroud'>
                        <Form.Label>Target Year:</Form.Label>
                        <Form.Control
                            type="number"
                            min="1901"
                            max="2100"
                            step="1"
                            name="targetYear"
                            value={formData.targetYear}
                            onChange={handleYearValueChange}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="target-pads" className='input-field-single-row input-field-backgroud'>
                        <Form.Label>Target for Pads sold in year:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            step="1"
                            name="targetForPads"
                            value={formData.targetForPads}
                            onChange={handleFormDataChange}
                            className="input" />
                    </Form.Group>
                </Container>
                <Container className='input-field-multiple'>
                    <Form.Group id="target-villages" className='input-field-single-row input-field-backgroud'>
                        <Form.Label>Target for No. of Villages:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            step="1"
                            name="targetForVillages"
                            value={formData.targetForVillages}
                            onChange={handleFormDataChange}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="target-users" className='input-field-single-row input-field-backgroud'>
                        <Form.Label>Target for No. of Users:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            step="1"
                            name="targetForUsers"
                            value={formData.targetForUsers}
                            onChange={handleFormDataChange}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="target-remarks" className='input-field-single-row input-field-backgroud'>
                        <Form.Label>Remarks:</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="targetRemarks"
                            value={formData.targetRemarks}
                            onChange={handleFormDataChange}
                            className="input" />
                    </Form.Group>
                </Container>

                {
                    errors.map((err, indx) => {
                        if (indx === 0) {
                            return (<Alert variant="danger" key={"danger"}>{err}</Alert>);
                        }
                        return;
                    })
                }
                {
                    message && <Alert variant="info">{message}</Alert>
                }
                <Button variant="primary" type="submit" onClick={handleSubmit} className="custom-btn">Add Sangini Target</Button>
            </Form>
        </Container>
    );

}
export default AddSanginiTargetFormCustom;