import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../../../styles/forms/programPlanningData/ProgramPlanningData.css';
import SanginiDataService from './../../services/sangini_details.service';
import SanginiUserDetailsDataService from './../../services/sangini_user_details.service.js';

const SanginiUserDropdown = ({ sanginiId, onChange, value, name, refresh, refresh2 }) => {
    const [userDetails, setUserDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await SanginiUserDetailsDataService.findAllBySanginiId(sanginiId);
                // console.log("user data:",response);
                setUserDetails(Array.isArray(response.data) ? response.data : []);
            } catch (err) {
                setError('Failed to fetch users');
                console.error('Fetch error:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchUserDetails();
    }, [sanginiId, refresh, refresh2]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <Form.Group className="sanginiUserDropdown">
            <Form.Control className='input' as="select" name={name} onChange={onChange} value={value}>
                <option value="">Select a user</option>
                {userDetails.length > 0 ? (
                    userDetails.map((detail) => (
                        <option key={detail.user_id} value={detail.user_id}>
                            {detail.user_name} - {detail.mobileno}
                        </option>
                    ))
                ) : (
                    <option disabled> No users available</option>
                )}
            </Form.Control>
        </Form.Group>
    );
};

export default SanginiUserDropdown;
