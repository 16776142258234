import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Container, Alert } from 'react-bootstrap';
import SanginiDataService from './../../services/sangini_details.service';
import SanginiUserDetailsDataService from './../../services/sangini_user_details.service.js';
import VillageDetailsDropdown from '../programPlanningData/VillageDetailsDropdown.js';
import { useSelector } from 'react-redux';

const AddUserPopup = ({ sanginiId, show, handleClose }) => {
    const [loading, setLoading] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(() => {
        if (currentUser) {
            console.log(currentUser);
        }
    }, [currentUser]);

    const mandatory = () => <span className='mandatory-icon'>*</span>;

    const [userData, setUserData] = useState({
        sangini_id: sanginiId,
        user_name: '',
        dob: '',
        mobileno: '',
        village_id: '',
        feedback: '',
        pad_cloth: 'pad',
        brand_of_pad: '',
        date_user_added: new Date().toLocaleDateString('en-CA'), // defaultDate
        is_educated_flag: 'Y',
        feedback_rating_from_user: 0,
        feedback_remark: '',
        updated_by_id: currentUser.id
    });

    const [sanginiName, setSanginiName] = useState('');

    useEffect(() => {
        const fetchSanginiName = async () => {
            try {
                const response = await SanginiDataService.get(sanginiId);
                setSanginiName(response.data.name);
            } catch (error) {
                console.error('Error fetching Sangini name:', error);
            }
        };

        if (sanginiId) {
            fetchSanginiName();
        }
    }, [sanginiId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const [errors, setErrors] = useState([]);

    const validateData = async () => {
        const newErrors = [];

        // Mobile number regex validation
        const mobileNoPattern = /^[0-9]{10}$/;
        if (!userData.mobileno) {
            newErrors.push('Please enter mobile no.');
        } else if (!mobileNoPattern.test(userData.mobileno)) {
            newErrors.push('Mobile number must be 10 digits.');
        }

        // Check for duplicate mobile numbers
        try {
            const response = await SanginiDataService.findAllByMobileNo(userData.mobileno);
            if (response.data.length > 0) {
                newErrors.push('Mobile No. Already Exists!!');
            }
        } catch (e) {
            console.log(e);
        }

        try {
            const response = await SanginiUserDetailsDataService.findAllByMobileNo(userData.mobileno);
            if (response.data.length > 0) {
                newErrors.push('Mobile No. Already Exists!!');
            }
        } catch (e) {
            console.log(e);
        }

        if (!userData.user_name) {
            newErrors.push('Please enter username');
        }
        if (!userData.dob) {
            newErrors.push('Please enter date of birth.');
        }

        if(userData.dob >= new Date().toLocaleDateString('en-CA')){
            newErrors.push('Enter valid date of birth!')
        }
        if (!userData.village_id) {
            newErrors.push('Please select a village.');
        }
        if (!userData.date_user_added) {
            newErrors.push('Please enter a joining date.');
        }
        if (userData.date_user_added <= userData.dob) {
            newErrors.push('Please enter valid joining date! Cannot be on or before date of birth!!');
        }

        setErrors(newErrors);
        return newErrors.length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission
        setLoading(true);
        if (await validateData() && errors.length === 0) {
            try {
                await SanginiUserDetailsDataService.create(userData);
                setLoading(false);
                alert('User added successfully!');
                handleClose();
            } catch (error) {
                setLoading(false);
                console.error('Error adding user data:', error);
            }
        }
        else {
            setLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Add User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    errors.length > 0 &&
                    <Alert className='alert' variant="danger">{errors[0]}</Alert>
                }
                <Container className='input-grid'>
                    <Form onSubmit={handleSubmit} className='add-user-form'>
                        <Container className='input-field-multiple'>
                            <Form.Group id="sangini-id" className='input-field-single-column input-field-backgroud'>
                                <Form.Label>Sangini Name:</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={sanginiName}
                                    readOnly
                                    className='input'
                                />
                            </Form.Group>
                            <Form.Group id="user-name" className='input-field-single-column input-field-backgroud'>
                                <Form.Label>User Name:{mandatory()}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="user_name"
                                    value={userData.user_name}
                                    onChange={handleChange}
                                    className='input'
                                    placeholder="Enter name"
                                />
                            </Form.Group>

                            <Form.Group id="dob" className='input-field-single-column input-field-backgroud'>
                                <Form.Label>Date of Birth:{mandatory()}</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="dob"
                                    value={userData.dob}
                                    onChange={handleChange}
                                    max={new Date().toLocaleDateString('en-CA')}
                                    className='input'
                                />
                            </Form.Group>
                        </Container>

                        <Container className='input-field-multiple'>
                            <Form.Group id="mobileno" className='input-field-single-column input-field-backgroud'>
                                <Form.Label>Mobile Number:{mandatory()}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="mobileno"
                                    min={0}
                                    value={userData.mobileno}
                                    onChange={handleChange}
                                    className='input'
                                    placeholder="Enter mobile no."
                                />
                            </Form.Group>

                            <Form.Group id="village-id" className='input-field-single-column input-field-backgroud'>
                                <Form.Label>Village Name:{mandatory()}</Form.Label>
                                <VillageDetailsDropdown
                                    name="village_id"
                                    onChange={handleChange}
                                    value={userData.village_id}
                                />
                            </Form.Group>

                            <Form.Group id="pad-cloth" className='input-field-single-column input-field-backgroud'>
                                <Form.Label>Pad/Cloth:</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="pad_cloth"
                                    value={userData.pad_cloth}
                                    onChange={handleChange}
                                    className='input'
                                >
                                    <option value="pad">Pad</option>
                                    <option value="cloth">Cloth</option>
                                </Form.Control>
                            </Form.Group>
                        </Container>

                        <Container className='input-field-multiple'>
                            <Form.Group id="brand-of-pad" className='input-field-single-column input-field-backgroud'>
                                <Form.Label>Brand of Pad:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="brand_of_pad"
                                    value={userData.brand_of_pad}
                                    onChange={handleChange}
                                    className='input'
                                    placeholder="Enter brand"
                                />
                            </Form.Group>

                            <Form.Group id="date-user-added" className='input-field-single-column input-field-backgroud'>
                                <Form.Label>Date User Added:{mandatory()}</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="date_user_added"
                                    value={userData.date_user_added}
                                    onChange={handleChange}
                                    className='input'
                                />
                            </Form.Group>

                            <Form.Group id="feedback" className='input-field-single-column input-field-backgroud'>
                                <Form.Label>Feedback:</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="feedback"
                                    value={userData.feedback}
                                    onChange={handleChange}
                                    className='input'
                                    placeholder="Enter feedback"
                                />
                            </Form.Group>
                        </Container>

                        <Container className='input-field-multiple'>
                            <Form.Group id="is-educated-flag" className='input-field-single-column input-field-backgroud'>
                                <Form.Label>Is User Educated?:</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="is_educated_flag"
                                    value={userData.is_educated_flag}
                                    onChange={handleChange}
                                    className='input'
                                >
                                    <option value="N">N</option>
                                    <option value="Y">Y</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group id="feedback-rating" className='input-field-single-column input-field-backgroud'>
                                <Form.Label>Feedback Rating (Training):</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="feedback_rating_from_user"
                                    value={userData.feedback_rating_from_user}
                                    onChange={handleChange}
                                    className='input'
                                >
                                    <option value="">Select rating</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group id="feedback-remark" className='input-field-single-column input-field-backgroud'>
                                <Form.Label>Feedback Remark (Training):</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="feedback_remark"
                                    value={userData.feedback_remark}
                                    onChange={handleChange}
                                    className='input'
                                    placeholder="Enter remarks"
                                />
                            </Form.Group>
                        </Container>
                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button className='program-planning-custom-btn change-width-btn' variant="secondary" onClick={handleClose}>
                    Back
                </Button>
                <Button className='program-planning-custom-btn change-width-btn' variant="primary" onClick={handleSubmit}>
                    {
                        loading
                            ? 'Saving Data....'
                            : 'Submit'
                    }

                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddUserPopup;
