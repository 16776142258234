import React, { useState } from "react";
import Navbar from "./Navbar/Navbar";
import "../styles/RegistrationMatrix.css";
import http from "../http-common";

function RegistrationMatrix() {
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [result, setResult] = useState(null);
    const [feedbackType, setFeedbackType] = useState("health");

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleFeedbackTypeChange = (event) => {
        setFeedbackType(event.target.value);
    };

    const validateFileName = (fileName, type) => {
        const lowercaseFileName = fileName.toLowerCase();
        if (type === "health" && !lowercaseFileName.includes("health")) {
            return "File name must include 'health' for Health Registration and Feedback upload.";
        }
        if (type === "livelihood" && !lowercaseFileName.includes("livelihood")) {
            return "File name must include 'livelihood' for Livelihood Registration and Feedback upload.";
        }
        if (type === "education" && !lowercaseFileName.includes("education")) {
            return "File name must include 'education' for Education Registration and Feedback upload.";
        }
        return null;
    };

    const handleUpload = async () => {
        if (!file) {
            alert("Please select a file first!");
            return;
        }

        const fileNameError = validateFileName(file.name, feedbackType);
        if (fileNameError) {
            alert(fileNameError);
            return;
        }

        setUploading(true);
        const formData = new FormData();
        formData.append("file", file);

        const endpoint = feedbackType === "health" 
      ? "/health-feedback/bulk-upload"
      : feedbackType === "livelihood" 
        ? "/livelihood-feedback/bulk-upload"
        : "/education-feedback/bulk-upload";

        try {
            const response = await http.post(endpoint, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            const data = response.data;

            setResult({
                success: data.successfulRows > 0,
                message: data.message,
                totalRows: data.totalRows,
                successfulRows: data.successfulRows,
                failedRows: data.totalRows - data.successfulRows,
                errors: data.errors || [],
            });
        } catch (error) {
            setResult({
                success: false,
                message: error.response?.data?.message || "An error occurred during file upload.",
                totalRows: error.response?.data?.totalRows || 0,
                successfulRows: error.response?.data?.successfulRows || 0,
                failedRows: error.response?.data?.totalRows - error.response?.data?.successfulRows || 0,
                errors: error.response?.data?.errors || [],
            });
        }

        setUploading(false);
    };

    const handleDownload = async () => {
        const confirmDownload = window.confirm("Are you sure you want to download all registration data?");
        if (!confirmDownload) {
            return;
        }

        setDownloading(true);
        try {
            const response = await http.get('/health-feedback/download-all', {
                responseType: 'blob',
            });
    
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'all_registration_data.xlsx';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Download error:', error);
            alert('Failed to download the file. Please try again.');
        }
        setDownloading(false);
    };

    return (
        <div>
            <div className="main-content">
                <h1>Registration and Feedback Matrix</h1>
                <div className="upload-container">
                    <select 
                        value={feedbackType} 
                        onChange={handleFeedbackTypeChange}
                        className="feedback-type-select"
                    >
                        <option value="health">Health</option>
                        <option value="livelihood">Livelihood</option>
                        <option value="education">Education</option>
                    </select>
                    <input
                        type="file"
                        onChange={handleFileChange}
                        accept=".xlsx,.xls,.csv"
                        className="file-input"
                    />
                    <button
                        onClick={handleUpload}
                        disabled={uploading}
                        className="upload-button"
                    >
                        {uploading ? "Uploading..." : "Upload"}
                    </button>
                    <button
                        onClick={handleDownload}
                        disabled={downloading}
                        className="download-button"
                    >
                        {downloading ? "Downloading..." : "Download All Registration Data"}
                    </button>
                </div>
                {result && ( 
                    <div className={`result ${result.success ? 'success' : 'error'}`}>
                        <h2>{result.success ? 'Upload Completed' : 'Upload Failed'}</h2>
                        <p>{result.message}</p>
                        <p>Total rows processed: {result.totalRows}</p>
                        <p>Successful rows: {result.successfulRows}</p>
                        <p>Failed rows: {result.failedRows}</p>
                        {result.errors && result.errors.length > 0 && (
                            <div className="error-list">
                                <h3>Errors:</h3>
                                <ul>
                                    {result.errors.map((error, index) => (
                                        <li key={index}>
                                            Row {error.row}: {error.error}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default RegistrationMatrix;