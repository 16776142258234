import http from "../../http-common";

class ProgramExecutiveDataService {
  getAll() {
    return http.get("/program_executives");
  }

  get(program_executive_id) {
    return http.get(`/program_executives/${program_executive_id}`);
  }
  
}

export default new ProgramExecutiveDataService();