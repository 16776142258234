import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';
import logo from './../../images/DesaiTrustLogo1.png';
import '../../styles/Navbar.css';
import { logout } from "../../actions/auth";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import http from '../../http-common';

const Navbar = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [roleId, setRoleId] = useState(0);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadComplete, setDownloadComplete] = useState(false);
  const [downloadError, setDownloadError] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSanginiConfirmation, setShowSanginiConfirmation] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setRoleId(currentUser.role_id);
    } else {
      setRoleId(0);
    }
  }, [currentUser]);

  const handleDropdown = (id) => {
    setActiveDropdown(activeDropdown === id ? null : id);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const isDropdownVisible = (dropdownId) => {
    if (roleId === 0) return false;
    return (
      (roleId === 1 && dropdownId <= 6) ||
      (roleId === 2 && dropdownId > 1 && dropdownId <= 6) ||
      (roleId === 4 && [7, 8, 13, 14, 15, 16].includes(dropdownId)) ||
      (roleId === 5 && dropdownId === 10) ||
      (roleId === 3 && [7, 8, 11, 12, 13, 14, 15, 16].includes(dropdownId)) ||
      (roleId === 8 && dropdownId === 6)
    );
  };

  const handleDownloadConfirmation = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  const handleSanginiDownloadConfirmation = (e) => {
    e.preventDefault();
    setShowSanginiConfirmation(true);
  };

  const handleDownload = async () => {
    setShowConfirmation(false);
    setIsDownloading(true);
    setDownloadError(null);

    try {
      const response = await http.get('/programs/master/download-all', {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'MasterData.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);

      setDownloadComplete(true);
    } catch (err) {
      setDownloadError('An error occurred while downloading the file. Please try again.');
    } finally {
      setIsDownloading(false);
    }
  };

  const handleSanginiDownload = async () => {
    setShowSanginiConfirmation(false);
    setIsDownloading(true);
    setDownloadError(null);

    try {
      const response = await http.get('/sangini_details/download/all', {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'SanginiData.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);

      setDownloadComplete(true);
    } catch (err) {
      setDownloadError('An error occurred while downloading the Sangini data. Please try again.');
    } finally {
      setIsDownloading(false);
    }
  };

  const dropdowns = [
    { id: 1, title: "Registration Matrix", items: ["Bulk Upload Registration & Feedback Matrix"], links: ["/registration-matrix"] },
    { id: 2, title: "Program", items: ["Add Program", "View/Edit Program", "Upload Program"], links: ["/program-planning", "/view-program-planning", "/upload-program-planning"] },
    { 
      id: 4, 
      title: "Master", 
      items: ["Village Master","Partner Master","Program Master", "Volunteer Master", "Zone Master","State Master","District Master","Block Master","Panchayat Master",  "Download Master Data"], 
      links: ["/village-master","/partner-master","/program-master", "/volunteer-master", "/zone-master","/state-master","/district-master","/block-master","/panchayat-master", "#"]
    },
    { 
      id: 5, 
      title: "Asani", 
      items: ["Add Sangini", "Update Sangini", "Update Sangini Target", "Update Sangini Sales", "View Sangini", "Download Sangini Data"], 
      links: ["/registration-attendance-form", "/update-sangini-search", "/sangini-target-data-form", "/sangini-sales-data-search", "/view-sangini-search", "#"]
    },
    { id: 6, title: "Sangini Booklet", items: ["Add Booklet Entry"], links: ["/sangini-booklet"] },
    { id: 7, title: "Dashboard", items: ["Dashboard"], links: ["/dashboard"] },
    { id: 8, title: "Dynamic Report", items: ["Dynamic Report"], links: ["/dynamic-report"] },
    { id: 13, title: "Sangini Reports", items: ["Sangini Level", "Sangini Top10 and Bottom 10", "Sangini Performance"], links: ["/sangini-level-report", "/sangini-top-bottom-10-report", "/sangini-kpi-report"] },
    { id: 14, title: "Executive Performance Reports", items: ["Executive Performance Reports"], links: ["/executive-kpi-report"] },
    { id: 15, title: "Reconciliation Reports", items: ["Reconciliation Reports"], links: ["/recon-report"] },
    { id: 16, title: "Static Reports", items: ["Partnerwise Impact", "Zone Overview", "HFH", "Program Details"], links: ["/partnerwise-impact-report", "/zoneoverview-report", "/hfh-report", "/programdetails-report"] },
    { id: 10, title: "Admin Dashboard", items: ["Admin Dashboard"], links: ["/admin"] },
    { id: 11, title: "Fund Tracking Report", items: ["Fund Tracking Report"], links: ["/fund-tracking-report"] },
    { id: 12, title: "Funds released Data to Partners", items: ["Funds released Data to Partners"], links: ["/fundReleased-master"] }
  ];

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/"><img src={logo} alt="Logo" id="logo" /></Link>
        <p className="logo-footer">EVENT MANAGEMENT</p>
      </div>
      <div className="navbar-container">
        <ul className="navbar-list">
          {dropdowns.map((dropdown) => (
            isDropdownVisible(dropdown.id) && (
              <li key={dropdown.id} className="nav-list-items">
                {dropdown.items.length === 1 ? (
                  <Link to={dropdown.links[0]} className="nav-list-buttons" onClick={() => handleDropdown(dropdown.id)}>{dropdown.title}</Link>
                ) : (
                  <div 
                    className={`nav-list-items ${activeDropdown === dropdown.id ? 'active' : ''}`} 
                    onClick={() => handleDropdown(dropdown.id)}
                  >
                    <div className="nav-list-buttons">
                      {dropdown.title}
                      {activeDropdown === dropdown.id ? <FiChevronUp /> : <FiChevronDown />}
                    </div>
                    {activeDropdown === dropdown.id && (
                      <ul className="dropdown-menu">
                        {dropdown.items.map((item, index) => (
                          <li key={index}>
                            {item === "Download Master Data" ? (
                              <a href="#" className="nav-links" onClick={handleDownloadConfirmation}>{item}</a>
                            ) : item === "Download Sangini Data" ? (
                              <a href="#" className="nav-links" onClick={handleSanginiDownloadConfirmation}>{item}</a>
                            ) : (
                              <Link to={dropdown.links[index]} className="nav-links">{item}</Link>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              </li>
            )
          ))}
        </ul>
        <div className="navbar-auth">
          {currentUser ? (
            <div className="auth-buttons">
              <Link to="/profile" className="auth-button profile">Profile</Link>
              <button className="auth-button logout" onClick={handleLogout}>Logout</button>
            </div>
          ) : (
            <div className="auth-buttons">
              <Link to="/login" className="auth-button login">Login</Link>
              <Link to="/register" className="auth-button signup">Signup</Link>
            </div>
          )}
        </div>
      </div>

      {showConfirmation && (
        <div className="download-popup">
          <div className="download-popup-content">
            <h3>Confirm Download</h3>
            <p>Are you sure you want to download the Master Data?</p>
            <button onClick={handleDownload}>Yes, Download</button>
            <button onClick={() => setShowConfirmation(false)}>Cancel</button>
          </div>
        </div>
      )}

      {showSanginiConfirmation && (
        <div className="download-popup">
          <div className="download-popup-content">
            <h3>Confirm Download</h3>
            <p>Are you sure you want to download the Sangini Data?</p>
            <button onClick={handleSanginiDownload}>Yes, Download</button>
            <button onClick={() => setShowSanginiConfirmation(false)}>Cancel</button>
          </div>
        </div>
      )}

      {isDownloading && (
        <div className="download-popup">
          <div className="download-popup-content">
            <h3>Downloading...</h3>
            <p>Please wait while we prepare your file.</p>
            <div className="loader"></div>
          </div>
        </div>
      )}

      {downloadComplete && (
        <div className="download-popup">
          <div className="download-popup-content">
            <h3>Download Complete</h3>
            <p>Your file has been downloaded successfully.</p>
            <button onClick={() => setDownloadComplete(false)}>OK</button>
          </div>
        </div>
      )}

      {downloadError && (
        <div className="download-popup">
          <div className="download-popup-content">
            <h3>Error</h3>
            <p>{downloadError}</p>
            <button onClick={() => setDownloadError(null)}>OK</button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;