
import React, { useState,useEffect } from 'react';
import { Form, Container, Col, Button, Row, Alert } from 'react-bootstrap';
import axios from 'axios';
import VillageDetailsDropdown from './VillageDetailsDropdown';
import VillageBlockDropdown from './VillageBlockDropdown';
import VillageDistrictDropdown from './VillageDistrictDropdown';
import VillageStateDropdown from './VillageStateDropdown';
import VillageZoneDropdown from './VillageZoneDropdown';
import './../../styles/Report.css';
import './../../styles/App.css';
import './../../styles/Top10Bottom10.css';
import ReportGrid from './ReportGrid';
import ReportDataService from "../services/report.service";
import ReportMonthDropdown from './ReportMonthDropdown';


function SanginiLevelReport() {


  //Static Data Attributes
  const [staticFormData, setStaticFormData] = useState(
    {
        reportMonth: '',
        villageCode:[],
        villageState:[],
        villageZone:[],
        villageDistrict:[],
        villageBlock:[]
    }
  );

  
  const [villageDetails, setVillageDetails] = useState([]);
  const [villageStates, setVillageStates] = useState([]);
  const [villageZones, setVillageZones] = useState([]);
  const [villageDistricts, setVillageDistricts] = useState([]);
  const [villageBlocks, setVillageBlocks] = useState([]);
  const [reportMonth, setReportMonth] = useState();

  const handleVillageChange = async (e) => {
    let arrVillage=[];
    staticFormData.villageCode=[];
    var i;
    let village_id = e.target.options[e.target.selectedIndex].getAttribute('data_id');
    for (i=0; i<e.target.length; i++) { 
        //alert(count);
        if (e.target.options[i].selected) {
          arrVillage.push(e.target.options[i].getAttribute('data_id'));
           
        } 
    }
    setStaticFormData({
      ...staticFormData,
      villageCode : [...staticFormData.villageCode,arrVillage]
    }) 
    
  }

  const handleDistrictChange = async (e) => {
    let arrDistrict=[];
    staticFormData.villageDistrict=[];
    var i;
    for (i=0; i<e.target.length; i++) { 
        //alert(count);
        if (e.target.options[i].selected) {
          arrDistrict.push(e.target.options[i].value);
            
        } 
    }
    setStaticFormData({
      ...staticFormData,
      villageDistrict : [...staticFormData.villageDistrict,arrDistrict]
    }) 
    
  }

  const handleBlockChange = async (e) => {
    let arrBlock=[];
    staticFormData.villageBlock=[];
    var i;
    for (i=0; i<e.target.length; i++) { 
        //alert(count);
        if (e.target.options[i].selected) {
          arrBlock.push(e.target.options[i].value);
           
        } 
    }
    setStaticFormData({
      ...staticFormData,
      villageBlock: [...staticFormData.villageBlock,arrBlock]
    }) 
    
  }

  const handleStateChange = async (e) => {
    let arrState=[];
    staticFormData.villageState=[];
    var i;
    for (i=0; i<e.target.length; i++) { 
        //alert(count);
        if (e.target.options[i].selected) {
          arrState.push(e.target.options[i].value);
           
        } 
    } 
    setStaticFormData({
      ...staticFormData,
      villageState : [...staticFormData.villageState,arrState]
    })
    
  }

  const handleZoneChange = async (e) => {
    let arrZone=[];
    staticFormData.villageZone=[];
    var i;
    
    for (i=0; i<e.target.length; i++) { 
        //alert(count);
        if (e.target.options[i].selected) {
          arrZone.push(e.target.options[i].value);
           
        } 
    }
    
    setStaticFormData({
      ...staticFormData,
      villageZone : [...staticFormData.villageZone,arrZone]
    })
    
  }


  
  const handleReportMonthChange = async (e) => {
    let report_val = e.target.options[e.target.selectedIndex].value;
    setStaticFormData({
      ...staticFormData,
      reportMonth: report_val
    })
  }


  //Error Handling and Validations
  const [errors, setErrors] = useState([]);

  const [message, setMessage] = useState('');



  const validateStaticData = () => {
    let validMsg = "";

    if (!staticFormData.reportMonth || staticFormData.reportMonth==='') {
      let error = 'Please Select Month to View Report';
      setErrors({
        ...errors, error
      })
      //console.log(error);
      validMsg = error;
    }



      if (staticFormData.reportMonth) {
        let error = ""
        //console.log(error);
        validMsg = error;
      }
      console.log("validMsg",validMsg);
    return validMsg;
  };

  

  const [rowData, setRowData] = useState([]);

  const [colDefs, setColDefs] = useState([]);

  const [rowData2, setRowData2] = useState([]);

  const [colDefs2, setColDefs2] = useState([]);


  useEffect(() => {
    const fetchRows = async () => {
        var dynamicFilters="";
       
        //ReportDataService.getPlannedImpReport(dynamicFilters)
        //.then(function (response) {
           // console.log("recieved data")
           // console.log(response.data)
            //setRowData(response.data);
            
            
          //})
          //.catch(e => {
          //  console.log(e);
          //})
      
    };
    fetchRows();
  }, []);

  const reset=()=> {
    //alert("inside reset");
    document.getElementById("report-form").reset();
    setMessage('');
    setStaticFormData({
        ...staticFormData,
        reportMonth: "",
        reportGenMonth:"",
        villageCode:[],
        villageState:[],
        villageZone:[],
        villageDistrict:[],
        villageBlock:[]
      })
      window.location.reload();
    
  }


  const getSanginiTop10=(dynamicFilters)=>{

    ReportDataService.getSanginiTop10Report(dynamicFilters)
    .then(function (response) {
        console.log("recieved data")
        console.log(response.data)
        if(response.data===null || response.data==='')
        {
            console.log("no data found");
            let noDataRow=[];
            setRowData(noDataRow);
        }
        else
        {
            //staticFormData.villageCode=[];
            //staticFormData.villageBlock=[];
            //staticFormData.villageState=[];
            //staticFormData.villageDistrict=[];
            //staticFormData.villageZone=[];
        setRowData(response.data);
        }
        
      })
      .catch(e => {
        console.log(e);
      })
  }

  const getSanginiBottom10=(dynamicFilters)=>{

    ReportDataService.getSanginiBottom10Report(dynamicFilters)
    .then(function (response) {
        console.log("recieved data")
        console.log(response.data)
        if(response.data===null || response.data==='')
        {
            console.log("no data found");
            let noDataRow=[];
            setRowData2(noDataRow);
        }
        else
        {
            //staticFormData.villageCode=[];
            //staticFormData.villageBlock=[];
            //staticFormData.villageState=[];
            //staticFormData.villageDistrict=[];
            //staticFormData.villageZone=[];
        setRowData2(response.data);
        }
        
      })
      .catch(e => {
        console.log(e);
      })
  }

  const handleSubmit = async (e) => {
    let finalValidMsg=validateStaticData();
    //console.log("final")
    e.preventDefault();

    if(finalValidMsg==="")
    {
    setMessage("");
    

    staticFormData.villageCode.map( function(obj){
        if(obj == ''){
            staticFormData.villageCode=[];
        }
    });

    staticFormData.villageState.map( function(obj){
        if(obj == ''){
            staticFormData.villageState=[];
        }
    });

    staticFormData.villageDistrict.map( function(obj){
        if(obj == ''){
            staticFormData.villageDistrict=[];
        }
    });

    staticFormData.villageZone.map( function(obj){
        if(obj == ''){
            staticFormData.villageZone=[];
        }
    });

    staticFormData.villageBlock.map( function(obj){
        if(obj == ''){
            staticFormData.villageBlock=[];
        }
    });

    let dynamicVillageId=staticFormData.villageCode.length===0?'NULL':staticFormData.villageCode;
    let dynamicVillageState=staticFormData.villageState.length===0?'NULL':staticFormData.villageState;
    let dynamicVillageBlock=staticFormData.villageBlock.length===0?'NULL':staticFormData.villageBlock;
    let dynamicVillageZone=staticFormData.villageZone.length===0?'NULL':staticFormData.villageZone;
    let dynamicVillageDistrict=staticFormData.villageDistrict.length===0?'NULL':staticFormData.villageDistrict;
    let dynamicReportMonth=staticFormData.reportMonth.length===0?'NULL':staticFormData.reportMonth;
    
    var dynamicFilters="'"+dynamicReportMonth+"'"+"|"+"'"+dynamicVillageId+"'"+"|"+"'"+dynamicVillageState+"'"+"|"+"'"+dynamicVillageBlock+"'"+"|"+"'"+dynamicVillageZone+"'"+"|"+"'"+dynamicVillageDistrict+"'";
    
    console.log("dynamicFilters:"+dynamicFilters);
    
    handleTop10Report();
    handleBottom10Report();
    
    getSanginiTop10(dynamicFilters);
    getSanginiBottom10(dynamicFilters);
}
else
{
    setMessage('Validation Failed: '+ finalValidMsg);
}

  };

  const handleTop10Report = () => { 
    let reportColumns=[
      { headerName: "Top 10",children: [ 
        { headerName: "Name",field: "name" ,width: "200px",tooltipField: "name",headerTooltip: "Name"},
        { headerName: "Mobile No",field: "mobile_no" ,width: "200px",tooltipField: "mobile_no",headerTooltip: "Mobile No"},
        { headerName: "Zone",field: "zone" ,width: "50px",tooltipField: "zone",headerTooltip: "Zone"},
        { headerName: "State",field: "state" ,width: "200px",tooltipField: "state",headerTooltip: "State"},
        { headerName: "District",field: "district" ,width: "50px",tooltipField: "district",headerTooltip: "District"},
        { headerName: "Block",field: "block" ,width: "50px",tooltipField: "block",headerTooltip: "Block"},
        { headerName: "Village Name",field: "village_name" ,width: "50px",tooltipField: "village_name",headerTooltip: "Village Name"},
        { headerName: "Level Name",field: "level_name" ,width: "200px",tooltipField: "level_name",headerTooltip: "Level Name"},
        { headerName: "Sale Numbers",field: "sale_numbers" ,width: "200px",tooltipField: "sale_numbers",headerTooltip: "Sale Numbers"},
        { headerName: "Rank",field: "rank_final" ,width: "200px",tooltipField: "rank_final",headerTooltip: "Rank"},
  ]}];
  setColDefs(reportColumns);
}

const handleBottom10Report = () => { 
  let reportColumns=[
    { headerName: "Bottom 10", children: [ 
      { headerName: "Name",field: "name" ,width: "200px",tooltipField: "name",headerTooltip: "Name"},
      { headerName: "Mobile No",field: "mobile_no" ,width: "200px",tooltipField: "mobile_no",headerTooltip: "Mobile No"},
      { headerName: "Zone",field: "zone" ,width: "50px",tooltipField: "zone",headerTooltip: "Zone"},
      { headerName: "State",field: "state" ,width: "200px",tooltipField: "state",headerTooltip: "State"},
      { headerName: "District",field: "district" ,width: "50px",tooltipField: "district",headerTooltip: "District"},
      { headerName: "Block",field: "block" ,width: "50px",tooltipField: "block",headerTooltip: "Block"},
      { headerName: "Village Name",field: "village_name" ,width: "50px",tooltipField: "village_name",headerTooltip: "Village Name"},
      { headerName: "Level Name",field: "level_name" ,width: "200px",tooltipField: "level_name",headerTooltip: "Level Name"},
      { headerName: "Sale Numbers",field: "sale_numbers" ,width: "200px",tooltipField: "sale_numbers",headerTooltip: "Sale Numbers"},
      { headerName: "Rank",field: "rank_final" ,width: "200px",tooltipField: "rank_final",headerTooltip: "Rank"},
]}];
setColDefs2(reportColumns);
}


  return (

    <Container className='report-page'>
      <Form id='report-form' onSubmit={handleSubmit} >
      
        {
          message && <Alert variant="info">{message}</Alert>
        }
        
        <Container className='input-grid'>
        <Container className='input-field-multiple'>
          <Form.Group id="header-text" className='header-input-field-single-row input-field-backgroud'>
              <Form.Label className='report-header'>Sangini Top 10 and Bottom 10 Reports</Form.Label>
            
            </Form.Group>

          </Container>
          <Container className='input-field-multiple'>
          <Form.Group id="reportMonth" className='input-field-single-column input-field-backgroud'>
            <Form.Label>View Report For Month:</Form.Label>
            <ReportMonthDropdown
                name="monthCode"
                onChange={handleReportMonthChange}
                reportMonth={reportMonth}
                setReportMonth={setReportMonth}
              />
            </Form.Group>
            <Form.Group id="village-code" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Village Code/Name:</Form.Label>
              <VillageDetailsDropdown
                name="villageCode"
                onChange={handleVillageChange}
                villageDetails={villageDetails}
                setVillageDetails={setVillageDetails}
              />
            </Form.Group>
          </Container>

          <Container className='input-field-multiple'>
         
          <Form.Group id="village-block" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Village Block:</Form.Label>
              <VillageBlockDropdown
                name="villageBlock"
                onChange={handleBlockChange}
                villageBlocks={villageBlocks}
                setVillageBlocks={setVillageBlocks}
              />
            </Form.Group>
            <Form.Group id="village-district" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Village District:</Form.Label>
              <VillageDistrictDropdown
                name="villageDistrict"
                onChange={handleDistrictChange}
                villageDistricts={villageDistricts}
                setVillageDistricts={setVillageDistricts}
              />
            </Form.Group>

            <Form.Group id="village-state" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Village State:</Form.Label>
              <VillageStateDropdown
                name="villageState"
                onChange={handleStateChange}
                villageStates={villageStates}
                setVillageStates={setVillageStates}
              />
            </Form.Group>
            
            <Form.Group id="village-zone" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Village Zone:</Form.Label>
              <VillageZoneDropdown
                name="villageZone"
                onChange={handleZoneChange}
                villageZones={villageZones}
                setVillageZones={setVillageZones}
              />
            </Form.Group>
          </Container>
        </Container>

    <Container>
        <Form.Group id="planned-pgm-status" className='input-field-single-row input-field-backgroud'>
        <Button variant="primary" type="submit" className="custom-btn">Submit</Button>
        <Button type="button" className="custom-btn" onClick={() => reset()}>Reset</Button>
       </Form.Group>
    </Container>
       <ReportGrid rowData={rowData} colDefs={colDefs} isScroll='none' grdWidth='2000px'/><br></br>
       <ReportGrid rowData={rowData2} colDefs={colDefs2} isScroll='none' grdWidth='2000px'/>
      </Form>
    </Container>
    
  );
}

export default SanginiLevelReport;