
import React, { useState,useEffect } from 'react';
import { Form, Container, Col, Button, Row, Alert } from 'react-bootstrap';
import './../../styles/Report.css';
import './../../styles/App.css';
import ReportGrid from './ReportGrid';
import ReportDataService from "../services/report.service";


function PartnerwiseImpactReport() {


  //Static Data Attributes
  const [staticFormData, setStaticFormData] = useState(
    {
        fromDate: '',
        toDate:''
    }
  );



  const handleStaticDataChange = (e) => {
    const { name, value } = e.target;
    setStaticFormData({
      ...staticFormData,
      [name]: value
    });
    // console.log("Static data", staticFormData);
  };



  


  //Error Handling and Validations
  const [errors, setErrors] = useState([]);

  const [message, setMessage] = useState('');



  const validateStaticData = () => {
    let validMsg = "";

    if (!staticFormData.fromDate || staticFormData.fromDate==='' || !staticFormData.toDate || staticFormData.toDate==='') {
      let error = 'Please Select from and to date to View Report';
      setErrors({
        ...errors, error
      })
      //console.log(error);
      validMsg = error;
    }



      if (staticFormData.fromDate ||  staticFormData.toDateDate) {
        let error = ""
        //console.log(error);
        validMsg = error;
      }
      console.log("validMsg",validMsg);
    return validMsg;
  };

  const validateGenStaticData = () => {
    let validMsg = "";

    if (!staticFormData.fromDate || staticFormData.fromDate==='' || !staticFormData.toDate || staticFormData.toDate==='') {
        let error = 'Please Select from and to date to View Report';
      setErrors({
        ...errors, error
      })
      //console.log(error);
      validMsg = error;
    }

    if (staticFormData.fromDate ||  staticFormData.toDateDate) {
        let error = ""
        //console.log(error);
        validMsg = error;
      }
      console.log("validMsg",validMsg);
    return validMsg;
  };



  const [rowData, setRowData] = useState([]);

  const [colDefs, setColDefs] = useState([]);


  useEffect(() => {
    const fetchRows = async () => {
        var dynamicFilters="";
       
        //ReportDataService.getPlannedImpReport(dynamicFilters)
        //.then(function (response) {
           // console.log("recieved data")
           // console.log(response.data)
            //setRowData(response.data);
            
            
          //})
          //.catch(e => {
          //  console.log(e);
          //})
      
    };
    fetchRows();
  }, []);

  const reset=()=> {
    //alert("inside reset");
    document.getElementById("report-form").reset();
    setMessage('');
    setStaticFormData({
        ...staticFormData,
        fromDate: "",
        toDate:""
      })
      window.location.reload();
    
  }

  const generateReport=()=>{

    let finalValidMsg=validateGenStaticData();
    //console.log("final")
   
    if(finalValidMsg==="")
    {
    setMessage("");
    
    

  let dynamicDateFrom=staticFormData.fromDate===''?'NULL':staticFormData.fromDate;
  let dynamicDateTo=staticFormData.toDate===''?'NULL':staticFormData.toDate;
  
  var dynamicFilters="'"+dynamicDateFrom+"'"+"|"+"'"+dynamicDateTo+"'";
  
  console.log("dynamicFilters:"+dynamicFilters);
    
    handlePartnerwiseImpactReport();

    ReportDataService.getPartnerwiseImpactReport(dynamicFilters)
    .then(function (response) {
        console.log(response.data)
        if(response.data===null || response.data==='')
        {
            console.log("No data");

        //   ReportDataService.putSanginiKPIReport(dynamicFilters)
        //   .then(response => {
        //     console.log("Sangini KPI Report Generated");
        //     setMessage('Validation Success: Report Generated Successfully');
        //   })
        //   .catch(e => {
        //     console.log(e);
        //   });
        }
        else
        {
          console.log("data Exist");
        setRowData(response.data);
          //setMessage('Validation Success: Report Generated');
        }
        })
        .catch(e => {
          console.log(e);
        })
    
}
else
{
    
    setMessage('Validation Failed: '+ finalValidMsg);
}

  }

  

  const handleSubmit = async (e) => {
    let finalValidMsg=validateStaticData();
    //console.log("final")
    e.preventDefault();

    if(finalValidMsg==="")
    {
    setMessage("");
    
    let dynamicDateFrom=staticFormData.fromDate===''?'NULL':staticFormData.fromDate;
    let dynamicDateTo=staticFormData.toDate===''?'NULL':staticFormData.toDate;
    
    var dynamicFilters="'"+dynamicDateFrom+"'"+"|"+"'"+dynamicDateTo+"'";
    
    console.log("dynamicFilters:"+dynamicFilters);
    
    handlePartnerwiseImpactReport();
    
}
else
{
    setMessage('Validation Failed: '+ finalValidMsg);
}

  };

  const handlePartnerwiseImpactReport = () => { 
    let reportColumns=[
      { headerName: "Partner Name",field: "partner_name" ,width: "200px",tooltipField: "partner_name",headerTooltip: "Partner Name"},
      { headerName: "Total Impact # of People",field: "total_impact" ,width: "200px",tooltipField: "total_impact",headerTooltip: "Total Impact # of People"},
      { headerName: "Total Expense",field: "total_expense" ,width: "200px",tooltipField: "total_expense",headerTooltip: "Total Expense"},
      { headerName: "Expense per head",field: "expense_per_head" ,width: "200px",tooltipField: "expense_per_head",headerTooltip: "Expense per head"},
 ];
  setColDefs(reportColumns);
}

  return (

    <Container className='report-page'>
      <Form id='report-form' onSubmit={handleSubmit} >
      
        {
          message && <Alert variant="info">{message}</Alert>
        }
        
        <Container className='input-grid'>
        <Container className='input-field-multiple'>
          <Form.Group id="header-text" className='header-input-field-single-row input-field-backgroud'>
              <Form.Label className='report-header'>Partnerwise Impact Report</Form.Label>
            
            </Form.Group>

          </Container>

          <Container className='input-field-multiple'>
          <Form.Group id="from-date" className='input-field-single-row input-field-backgroud'>
              <Form.Label>From Date:</Form.Label>
              <Form.Control
                type="date"
                name="fromDate"
                value={staticFormData.fromDate}
                onChange={handleStaticDataChange}
                className="input" />
            </Form.Group>

            <Form.Group id="to-date" className='input-field-single-row input-field-backgroud'>
              <Form.Label>To Date:</Form.Label>
              <Form.Control
                type="date"
                name="toDate"
                value={staticFormData.toDate}
                onChange={handleStaticDataChange}
                className="input" />
            </Form.Group>
          </Container>
        </Container>

    <Container>
        <Form.Group id="planned-pgm-status" className='input-field-single-row input-field-backgroud'>
        <Button variant="primary" type="button" className="custom-btn"onClick={() => generateReport()}>View Report</Button>
        <Button type="button" className="custom-btn" onClick={() => reset()}>Reset</Button>
       </Form.Group>
    </Container>
       <ReportGrid rowData={rowData} colDefs={colDefs}/>
      </Form>
    </Container>
    
  );
}

export default PartnerwiseImpactReport;