import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../actions/auth";
import { isEmail } from "validator";
import "../../styles/Register.css";
import { Navigate, useNavigate, Link } from 'react-router-dom';


const Register = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successful, setSuccessful] = useState(false);
  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();

  const handleRegister = (e) => {
    e.preventDefault();
    setSuccessful(false);
    dispatch(register(username, email, password))
      .then(() => {
        setSuccessful(true);
      })
      .catch(() => {
        setSuccessful(false);
      });
  };

  const validateForm = () => {
    return username.length > 0 && isEmail(email) && password.length >= 6 && password.length <= 40;
  };

  return (
    <div className="register-container">
      <div className="register-card">
        <h2 className="register-header">Create Account</h2>
        <form onSubmit={handleRegister}>
          <div className="form-group">
            <label htmlFor="username">Name</label>
            <input
              type="text"
              className="form-control"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <small className="form-text text-muted">Password must be 6-40 characters long.</small>
          </div>
          <div className="form-group">
            <button className="adjust-width login-custom-btn btn btn-primary btn-block" disabled={!validateForm()}>
              Sign Up
            </button>
          </div>
          {message && (
            <div className="form-group">
              <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                {message}
              </div>
            </div>
          )}
        </form>
        <p className="register-footer">Already have an account? <a href="/login">Sign in</a></p>
        <div className="form-group">
          <Link to="/" className="adjust-width home-custom-btn btn btn-secondary btn-block">Go to Home</Link>
        </div>
      </div>
    </div>
  );
};

export default Register;