import http from "../../http-common";

class VolunteerDataService {
  getAll() {
    return http.get("/volunteers");
  }

  create(data) {
    return http.post("/volunteers", data);
  }

  update(id, data) {
    return http.put("/volunteers/${id}", data);
  }

  delete(id) {
    return http.delete("/volunteers/${id}");
  }

  deleteAll() {
    return http.delete("/volunteers");
  }
}

export default new VolunteerDataService();