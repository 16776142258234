import http from "../../http-common";

class SanginiUserDetailsDataService {


    create(data) {
        return http.post("/sangini_user_details", data);
    }

    // Find all users by sangini_id
    findAllBySanginiId(sangini_id) {
        return http.get(`/sangini_user_details/findAllBySanginiId`, {
            params: { sangini_id }
        });
    }

    // Find user by user_id
    findByUserId(user_id) {
        return http.get(`/sangini_user_details/findByUserId`, {
            params: { user_id }
        });
    }

    findAllByMobileNo(mobileno) {
        console.log(mobileno);
        return http.get(`/sangini_user_details/findAllByMobileNo/${mobileno}`);
    }

    update(id, data) {
        return http.put(`/sangini_user_details/${id}`, data);
    }


}
export default new SanginiUserDetailsDataService();
