import http from "../../http-common";

class SanginiDataService {

  get(id) {
    return http.get(`/sangini_details/${id}`);
  }

  getAll() {
    return http.get("/sangini_details");
  }

  findAllByMobileNo(mobileno) {
    console.log(mobileno);
    return http.get(`/sangini_details/findAllByMobileNo/${mobileno}`);
  }

  getAllTargetsBySanginiId(sanginiId) {
    return http.get(`/sangini_details/getAllTargetsBySanginiId/${sanginiId}`);
  }

  getAllSalesBySanginiId(sanginiId) {
    return http.get(`/sangini_details/getAllSalesBySanginiId/${sanginiId}`)
  }

  findAllByExecutiveId(userId) {
    return http.get(`/sangini_details/findAllByExecutiveId/${userId}`);
  }

  findAllByManagerId(userId) {
    return http.get(`/sangini_details/findAllByManagerId/${userId}`);
  }


}

export default new SanginiDataService();