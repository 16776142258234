import React, { useState } from "react";
import { useSelector } from "react-redux";
import Navbar from "./Navbar/Navbar";
import http from "../http-common";

function ProgramPlanningBulk() {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [result, setResult] = useState(null);
  const { user: currentUser } = useSelector((state) => state.auth);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const validateFileName = (fileName) => {
    const lowercaseFileName = fileName.toLowerCase();
    if (!lowercaseFileName.includes("event")) {
      return "File name must include 'event'.";
    }
    return null;
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file first!");
      return;
    }

    const fileNameError = validateFileName(file.name);
    if (fileNameError) {
      alert(fileNameError);
      return;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("updated_by_id", currentUser.id);

    try {
      const response = await http.post("/event_planning_data/bulk-upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const data = response.data;

      setResult({
        success: response.status === 200,
        message: data.message,
        totalRows: data.totalRows,
        successfulRows: data.successfulRows,
        failedRows: data.failedRows,
        errors: data.errors || [],
        details: data.details,
      });
    } catch (error) {
      setResult({
        success: false,
        message: error.response?.data?.message || "An error occurred during file upload.",
        totalRows: error.response?.data?.totalRows || 0,
        successfulRows: error.response?.data?.successfulRows || 0,
        failedRows: error.response?.data?.failedRows || 0,
        errors: error.response?.data?.errors || [],
        details: error.response?.data?.details || error.message,
      });
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
      <div className="main-content">
        <h1>Event Planning Matrix</h1>
        <div className="upload-container">
          <input
            type="file"
            onChange={handleFileChange}
            accept=".xlsx,.xls,.csv"
            className="file-input"
          />
          <button
            onClick={handleUpload}
            disabled={uploading}
            className="upload-button"
          >
            {uploading ? "Uploading..." : "Upload"}
          </button>
        </div>
        {result && (
          <div className={`result ${result.success ? "success" : "error"}`}>
            <h2>{result.success ? "Upload Completed" : "Upload Failed"}</h2>
            <p>{result.message}</p>
            <p>Total rows processed: {result.totalRows}</p>
            <p>Successful rows: {result.successfulRows}</p>
            <p>Failed rows: {result.failedRows}</p>
            {result.details && <p>{result.details}</p>}
            {result.errors && result.errors.length > 0 && (
              <div className="error-list">
                <h3>Errors:</h3>
                <ul>
                  {result.errors.map((error, index) => (
                    <li key={index}>
                      Row {error.row}: {error.error}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ProgramPlanningBulk;