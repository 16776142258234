import { useEffect, useState } from "react";
import { Alert, Button, Container, Form, Nav } from "react-bootstrap";
import SanginiRegistrationService from "../services/sanginiRegistration.service.js";
import SanginiDropdown from "../forms/sanginiBookletData/SanginiDropdown.js";
import { useNavigate } from "react-router-dom";
import "../../styles/forms/sanginiData/sanginiData.css";
import { useSelector } from "react-redux";

function ChooseSanginiFormCustom({pathToRoute, formHeaderMessage}) {

    const { user: currentUser } = useSelector((state) => state.auth);

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        sanginiId: ''
    });

    const [errors, setErrors] = useState([]);
    const [message, setMessage] = useState('');

    const resetFormData = () => {
        setFormData({
            sanginiId: ''
        });
    }
    
    useEffect(() => {
        resetFormData();
    }, [pathToRoute, currentUser])
    
    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    
    const validateFormData = () => {
        let valid = true;
        setMessage('');
        if (!formData.sanginiId) {
            let errorMessage = "Please select sangini details.";
            setErrors(prevErrors => {
                return [...prevErrors, errorMessage];
            });
            valid = false;
        }
        return valid;
    }

    const handleSubmit = async (e) => {
        console.log(formData);
        e.preventDefault();
        setErrors([]);
        if (validateFormData()) {
            try {
                if (formData.sanginiId) {
                    navigate(`/${pathToRoute}/${formData.sanginiId}`)
                }
            } catch(error) {
                setMessage("Error occurred fetching sangini details.");
                console.error(error);
            }
        }
    }

    return (
        <Container className="event-planning-page">
            
            <Form id="sangini-form" onSubmit={handleSubmit}>
                <h2>{formHeaderMessage}</h2>
                <Form.Group id="sangini-id" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Sangini Name & Mobile No:</Form.Label>
                        <SanginiDropdown
                            onChange={handleFormDataChange}
                            value={formData.sanginiId}
                            name="sanginiId"
                            currentUser={currentUser}
                        />
                    </Form.Group>
                {
                    errors.map((err, indx) => {
                        if (indx === 0) {
                            return (<Alert variant="danger" key={"danger"}>{err}</Alert>);
                        }
                        return;
                    })
                }
                {
                    message && <Alert variant="info">{message}</Alert>
                }
                <Button variant="primary" type="submit" onClick={handleSubmit} className="custom-btn">Submit</Button>
            </Form>
        </Container>
    );
}

export default ChooseSanginiFormCustom;