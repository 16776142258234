import '../styles/App.css';
import image2 from './../images/image2.png';
import image3 from './../images/image3.png';
import missionCirle from './../images/Mission-Circle.png';
import './../styles/Home.css';
import React, { useState, useEffect } from "react";

import UserService from "../services/user.service";

import Navbar from './Navbar/Navbar';

function Home() {
  const [content, setContent] = useState("");
  useEffect(() => {
    UserService.getPublicContent().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );
  }, []);

  return (
    <div className="Home">
      <div className='main-page-images'>
        <h1 className='home-header'>Event Management System</h1>
        <img src={image2} />
        <br></br>
        <br></br>
        <img src={missionCirle} id='missionCircle'/>

      </div>
    </div>
  );
}

export default Home;
