import http from "../../http-common";

class PartnerDataService {
  getAll() {
    return http.get("/partners");
  }

  get(id) {
    return http.get(`/partners/${id}`);
  }

  create(data) {
    return http.post("/partners", data);
  }

  updateresult(data) {
    return http.put("/partners", data);
  }

  update(id, data) {
    return http.put(`/partners/${id}`, data);
  }

  delete(id) {
    return http.delete(`/partners/${id}`);
  }

  deleteAll() {
    return http.delete("/partners");
  }

  findByCode(partner_code) {
    return http.get(`/partners?partner_code=${partner_code}`);
  }

  getExecutivePartnerDetails(userId) {
    return http.get(`/partners/getExecutivePartnerDetails/${userId}`);
  }

  getManagerPartnerDetails(userId) {
    return http.get(`/partners/getManagerPartnerDetails/${userId}`);
  }


}

export default new PartnerDataService();