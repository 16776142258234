import { useEffect, useState } from "react";
import { Alert, Button, Container, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import SanginiRegistrationService from "../services/sanginiRegistration.service.js";
import SanginiDropdown from "../forms/sanginiBookletData/SanginiDropdown.js";
import "../../styles/forms/sanginiData/sanginiData.css";
import { useSelector } from "react-redux";
function EditSanginiTarget() {

    const {state} = useLocation();
    const { user: currentUser } = useSelector((state) => state.auth);
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        sanginiId: state.sangini_id,
        targetYear: state.target_year,
        targetForPads: state.target_for_pads,
        targetForVillages: state.target_for_no_of_villages,
        targetForUsers: state.target_for_no_of_users,
        targetRemarks: state.remarks,
        updated_by_id: currentUser?.id
    });

    const [errors, setErrors] = useState([]);

    const [message, setMessage] = useState('');

    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    useEffect(() => {
        setFormData(currentFormData => ({
            ...currentFormData,
            updated_by_id: currentUser?.id
        }));
    }, [currentUser]);

    const handleYearValueChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value.length > 4 ? value.slice(0, 4) : value
        })
    }

    const validateFormData = () => {
        let valid = true;

        if (!formData.targetForPads) {
            let errorMessage = "Please enter target for pads."
            setErrors(prevErrors => {
                return [...prevErrors, errorMessage];
            });
            valid = false;
        }

        if (!formData.targetForVillages) {
            let errorMessage = "Please enter target for villages."
            setErrors(prevErrors => {
                return [...prevErrors, errorMessage];
            });
            valid = false;
        }

        if (!formData.targetForUsers) {
            let errorMessage = "Please enter target for users."
            setErrors(prevErrors => {
                return [...prevErrors, errorMessage];
            });
            valid = false;
        }

        return valid;
    }

    const handleRedirectBack = () => {
        navigate(`/view-sangini/${state.sangini_id}`);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
        setErrors([]);
        if (validateFormData()) {
            try {
                console.log(formData);
                const response = SanginiRegistrationService.editSanginiTargetData(formData)
                    .then(response => {
                        if (response.status === 400 || response.status === 500) {
                            if (response.message) {
                                setMessage("Failed editing sangini target details with error " + response.message);
                            } else {
                                setMessage("Error editing sangini target details.");
                            }
                        }
                        else
                            setMessage("Sangini target details edited successfully.")
                    })
                    .catch(e => {
                        if (e?.response?.data?.message) {
                            setMessage("Failed to edit sangini target details with error: " + e.response.data.message);
                        } else {
                            setMessage("Error editing sangini target details.");
                        }
                        console.log(e);
                    });
            }
            catch (error) {
                setMessage("Error editing sangini target details.");
                console.error(error);
            }
        }
    }

    return (
        <Container className='event-planning-page'>

            <Form id='sangini-form'>
                <h2>Edit Sangini Target Details</h2>
                <Container className='input-field-multiple'>
                    <Form.Group id="sangini-id" className='input-field-single-column input-field-backgroud'>
                        <Form.Label>Sangini Name & Mobile No:</Form.Label>
                        <SanginiDropdown
                            onChange={handleFormDataChange}
                            value={formData.sanginiId}
                            name="sanginiId"
                            disabled={true}
                            currentUser={currentUser}
                        />
                    </Form.Group>
                </Container>
                <Container className='input-field-multiple'>
                    <Form.Group id="target-year" className='input-field-single-row input-field-backgroud'>
                        <Form.Label>Target Year:</Form.Label>
                        <Form.Control
                            type="number"
                            min="1901"
                            max="2100"
                            step="1"
                            name="targetYear"
                            value={formData.targetYear}
                            onKeyDown={ (evt) => (evt.key === 'e' || evt.key === 'E') && evt.preventDefault() }
                            onChange={handleYearValueChange}
                            disabled={true}
                            className="input" />
                    </Form.Group>

                    <Form.Group id="target-pads" className='input-field-single-row input-field-backgroud'>
                        <Form.Label>Target for Pads sold in year:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            step="1"
                            name="targetForPads"
                            value={formData.targetForPads}
                            onChange={handleFormDataChange}
                            onKeyDown={ (evt) => (evt.key === 'e' || evt.key === 'E') && evt.preventDefault() }
                            className="input" />
                    </Form.Group>
                </Container>
                <Container className='input-field-multiple'>
                    <Form.Group id="target-villages" className='input-field-single-row input-field-backgroud'>
                        <Form.Label>Target for No. of Villages:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            step="1"
                            name="targetForVillages"
                            value={formData.targetForVillages}
                            onChange={handleFormDataChange}
                            onKeyDown={ (evt) => (evt.key === 'e' || evt.key === 'E') && evt.preventDefault() }
                            className="input" />
                    </Form.Group>

                    <Form.Group id="target-users" className='input-field-single-row input-field-backgroud'>
                        <Form.Label>Target for No. of Users:</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            step="1"
                            name="targetForUsers"
                            value={formData.targetForUsers}
                            onChange={handleFormDataChange}
                            onKeyDown={ (evt) => (evt.key === 'e' || evt.key === 'E') && evt.preventDefault() }
                            className="input" />
                    </Form.Group>

                    <Form.Group id="target-remarks" className='input-field-single-row input-field-backgroud'>
                        <Form.Label>Remarks:</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="targetRemarks"
                            value={formData.targetRemarks}
                            onChange={handleFormDataChange}
                            className="input" />
                    </Form.Group>
                </Container>

                {
                    errors.map((err, indx) => {
                        if (indx === 0) {
                            return (<Alert variant="danger" key={"danger"}>{err}</Alert>);
                        }
                        return;
                    })
                }
                {
                    message && <Alert variant="info">{message}</Alert>
                }
                <Button variant="primary" type="submit" onClick={handleSubmit} className="custom-btn">Edit Sangini Target</Button>
                <Button variant="primary" type="submit" onClick={handleRedirectBack} className="custom-btn">Back</Button>
            </Form>
        </Container>
    );

}
export default EditSanginiTarget;