
import React, { useState, useEffect } from 'react';
import { Form, Container, Col, Button, Alert } from 'react-bootstrap';
import PartnerDetailsDropdown from './PartnerDetailsDropdown';
import ProgramDetailsDropdown from './ProgramDetailsDropdown';
import './../../../styles/App.css';
import './../../../styles/forms/programPlanningData/ProgramPlanningData.css';
import ImpactBudgetStatusSelect from './ImpactBudgetStatusSelect';
import EventPlanningDataService from './../../services/event_planning_data.service.js';
import ProgramDataService from './../../services/program.service.js';
import { useSelector } from 'react-redux';



function ProgramPlanning() {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentUser) {
      console.log(currentUser);
    }
  }, [currentUser]);

  //Static Data Attributes
  const [staticFormData, setStaticFormData] = useState(
    {
      eventPlannedDate: '',
      eventCategory: '',
      programCode: '',
      partnerCode: '',
      heroesOfHumanity: 'N',
    }
  );

  const handleStaticDataChange = (e) => {
    const { name, value } = e.target;
    setStaticFormData({
      ...staticFormData,
      [name]: value
    });

    if(name === 'partnerCode'){
      setVillageFormData([
        {
          key: 0,
          villageId: '',
          womenImpactedPlanned: 0,
          childrenImpactedPlanned: 0,
          othersImpactedPlanned: 0,
          remarks1: '',
          budgetAmountPlanned: 0,
          paidFromDFDFTPlanned: 0,
          remarks2: '',
          programPlannedStatus: ''
        }
      ]);
    }
    // console.log("Static data", staticFormData);
  };

  const [programDetails, setProgramDetails] = useState([]);

  const handleEventCategoryChange = async (e) => {
    const category = e.target.value;

    // Determine the new category state
    const newCategory = staticFormData.eventCategory === category ? '' : category;

    setStaticFormData({
      ...staticFormData,
      eventCategory: newCategory,
      programCode: ''
    });

    console.log(newCategory); // Log the new category state

    if (newCategory) {
      try {
        const response = await ProgramDataService.findAllByProgramCategory(newCategory);
        setProgramDetails(Array.isArray(response.data) ? response.data : []);
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching program details:', error);
        setProgramDetails([]);
      }
    } else {
      // Clear program details if no category is selected
      setProgramDetails([]);
    }
  };








  //Dynamic Village Data Attributes
  const [villageCounter, setVillageCounter] = useState(0);

  const [villageFormData, setVillageFormData] = useState([
    {
      key: 0,
      villageId: '',
      womenImpactedPlanned: 0,
      childrenImpactedPlanned: 0,
      othersImpactedPlanned: 0,
      remarks1: '',
      budgetAmountPlanned: 0,
      paidFromDFDFTPlanned: 0,
      remarks2: '',
      programPlannedStatus: ''
    }
  ]);


  const addVillage = () => {
    let newCounter = villageCounter + 1;
    setVillageCounter(newCounter);
    setVillageFormData([...villageFormData,
    {
      key: newCounter,
      villageId: 0,
      womenImpactedPlanned: 0,
      childrenImpactedPlanned: 0,
      othersImpactedPlanned: 0,
      remarks1: '',
      budgetAmountPlanned: 0,
      paidFromDFDFTPlanned: 0,
      remarks2: '',
      programPlannedStatus: ''
    }]);
    console.log(villageFormData);
  }


  const removeVillage = (key) => {
    setVillageFormData(villageFormData.filter(village => village.key !== key));
    console.log(villageFormData);
  }



  const handleVillageFormDataChange = (e, key) => {

    const { name, value } = e.target;
    const updatedVillageFormData = villageFormData.map((selectedVillage) => {
      if (selectedVillage.key === key) {
        return {
          ...selectedVillage,
          [name]: value,
        };
      }
      return selectedVillage;
    });

    setVillageFormData(updatedVillageFormData);
    console.log(name, value);
  }

  const handleStatusChange = (e, key) => {
    const { name, value } = e.target;

    const updatedVillageFormData = villageFormData.map((selectedVillage) => {
      if (selectedVillage.key === key) {
        return {
          ...selectedVillage,
          programPlannedStatus: value,
        };
      }
      return selectedVillage;
    });

    setVillageFormData(updatedVillageFormData);
    console.log(name, value);

  }


  const getVillageIds = () => {
    // console.log(villageFormData.map(village => village.villageId));
    return villageFormData.map(village => village.villageId);

  };










  //Error Handling and Validations
  const [errors, setErrors] = useState([]);

  const [message, setMessage] = useState('');



  const validateStaticData = () => {
    const newErrors = [];

    if (!staticFormData.eventPlannedDate) {
      newErrors.push('Please select a program date.');
    }

    if(staticFormData.eventPlannedDate < new Date().toLocaleDateString('en-CA')){
      newErrors.push('Program planned date cannot be older than today.')
    }

    if (!staticFormData.eventCategory) {
      newErrors.push('Please select a program category.');
    }

    if (!staticFormData.programCode) {
      newErrors.push('Please select a program code.');
    }

    if (!staticFormData.partnerCode) {
      newErrors.push('Please select a partner code.');
    }

    setErrors(newErrors);
    console.log(errors);
    return newErrors.length === 0;
  };



  const validateDynamicData = () => {
    const newErrors = [];

    villageFormData.forEach((selectedVillage) => {
      if (!selectedVillage.villageId) {
        newErrors.push('Please select a village.');
      }

      if (!selectedVillage.programPlannedStatus) {
        newErrors.push('Please select status');
      }
    });

    setErrors(newErrors);
    console.log(newErrors);
    return newErrors.length === 0;
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);

    setLoading(true);

    if (validateStaticData() && validateDynamicData()) {

      try {
        await Promise.all(villageFormData.map(async (selectedVillage) => {
          let eventPlannedData = {
            event_planned_date: staticFormData.eventPlannedDate,
            event_category: staticFormData.eventCategory,
            program_code: staticFormData.programCode,
            partner_code: staticFormData.partnerCode,
            hfh_flag: staticFormData.heroesOfHumanity,
            village_id: selectedVillage.villageId,
            no_of_women_impacted_planned: selectedVillage.womenImpactedPlanned,
            no_of_children_impacted_planned: selectedVillage.childrenImpactedPlanned,
            no_of_other_impacted_planned: selectedVillage.othersImpactedPlanned,
            impact_remark_planned: selectedVillage.remarks1,
            budgeted_amount_planned: selectedVillage.budgetAmountPlanned,
            payed_from_df_dft_planned: selectedVillage.paidFromDFDFTPlanned,
            budgeted_remark_planned: selectedVillage.remarks2,
            program_planning_status: selectedVillage.programPlannedStatus,
            updated_by_id: currentUser.id
          };
          // TODO: check for duplicate entry before adding program to DB table
          const response = await EventPlanningDataService.create(eventPlannedData);
          console.log('Program Planning Data successfully added: ', response.data);

          setLoading(false);
          setMessage('Program successfully added!');
        }));

        setTimeout(() => {
          window.location.reload();
        }, 4000);
      } catch (error) {

        setLoading(false);
        setMessage('Error submitting data.');
        console.error(error);
      }
    }
    else {
      setLoading(false);
    }
  };


  const mandatory = () => <span className='mandatory-icon'>*</span>;

  // Error handling with timeout
  useEffect(() => {
    let timeout;
    if (errors.length > 0) {
      timeout = setTimeout(() => {
        setErrors([]);
      }, 4000);
    }
    return () => clearTimeout(timeout); // Cleanup on unmount or when errors change
  }, [errors]);


  return (
    <Container className='event-planning-page'>

      <Form id='event-planning-form' onSubmit={handleSubmit}>
        <Container className='input-grid'>
          <h2>Add Program</h2>
          <Container className='input-field-multiple'>
            <Form.Group id="event-planned-date" className='input-field-single-row input-field-backgroud'>
              <Form.Label>Program Planned Date:{mandatory()}</Form.Label>
              <Form.Control
                type="date"
                name="eventPlannedDate"
                value={staticFormData.eventPlannedDate}
                onChange={handleStaticDataChange}
                min={new Date().toLocaleDateString('en-CA')}
                className="input" />
            </Form.Group>


            <Form.Group id="event-category" className='input-field-single-row input-field-backgroud'>
              <Form.Label >Program Category:{mandatory()}</Form.Label>
              <Col className="radio-list">
                <Form.Check
                  type="radio"
                  label="Health"
                  name="eventCategory"
                  value="H"
                  checked={staticFormData.eventCategory === 'H'}
                  onClick={handleEventCategoryChange}
                  id="health"
                />
                <Form.Check
                  type="radio"
                  label="Livelihood"
                  name="eventCategory"
                  value="L"
                  checked={staticFormData.eventCategory === 'L'}
                  onClick={handleEventCategoryChange}
                  id="livelihood"
                />
                <Form.Check
                  type="radio"
                  label="Education"
                  name="eventCategory"
                  value="E"
                  checked={staticFormData.eventCategory === 'E'}
                  onClick={handleEventCategoryChange}
                  id="education"
                />
              </Col>
            </Form.Group>


            <Form.Group id="heroes-of-humanity" className='input-field-single-row input-field-backgroud'>
              <Form.Label >Heroes of Humanity?:{mandatory()}</Form.Label>
              <Col className="radio-list">
                <Form.Check
                  type="radio"
                  label="Yes"
                  name="heroesOfHumanity"
                  value="Y"
                  checked={staticFormData.heroesOfHumanity === 'Y'}
                  onChange={handleStaticDataChange}
                  id="yes"
                />
                <Form.Check
                  type="radio"
                  label="No"
                  name="heroesOfHumanity"
                  value="N"
                  checked={staticFormData.heroesOfHumanity === 'N'}
                  onChange={handleStaticDataChange}
                  id="no"
                />
              </Col>
            </Form.Group>
          </Container>



          <Container className='input-field-multiple'>
            <Form.Group id="program-code" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Program Code/Name:{mandatory()}</Form.Label>
              <ProgramDetailsDropdown
                name="programCode"
                onChange={handleStaticDataChange}
                programDetails={programDetails}
                setProgramDetails={setProgramDetails}
                value={staticFormData.programCode}
              />
            </Form.Group>


            <Form.Group id="partner-code" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Partner Code/Name:{mandatory()}</Form.Label>
              <PartnerDetailsDropdown
                name="partnerCode"
                onChange={handleStaticDataChange}
                value={staticFormData.partnerCode}
                roleId={currentUser.role_id}
                userId={currentUser.id}
              />
            </Form.Group>
          </Container>
        </Container>


        {villageFormData.map((village) => (
          <Container key={village.key} className='dynamic-container'>
            <hr className='line-break' />
            <ImpactBudgetStatusSelect
              names={["villageId", "womenImpactedPlanned", "childrenImpactedPlanned", "othersImpactedPlanned", "remarks1", "budgetAmountPlanned", "paidFromDFDFTPlanned", "programPlannedStatus", "remarks2"]}
              errors={errors}
              formData={village}
              handleChange={(e) => handleVillageFormDataChange(e, village.key)}
              handleStatusChange={(e) => handleStatusChange(e, village.key)}
              villageValue={village.villageId}
              isFieldEditable={() => true}
              roleId={currentUser.role_id}
              userId={currentUser.id}
              selectedVillageIds={getVillageIds()}
              partnerCode={staticFormData.partnerCode}
            />
            {
              villageFormData.length > 1 &&
              <Button
                onClick={() => removeVillage(village.key)}
                className='program-planning-custom-btn village-remove-btn'
              >
                Remove
              </Button>
            }

          </Container>
        ))}

        <Button onClick={addVillage} className='program-planning-program-planning-custom-btn village-add-btn'>
          Add
        </Button>

        {
          errors.length > 0 &&
          <Alert className='alert' variant="danger">{errors[0]}</Alert>
        }

        {
          message && <Alert variant="info">{message}</Alert>
        }
        <Button variant="primary" type="submit" className="program-planning-custom-btn">
          {
            loading
              ? 'Saving Data....'
              : 'Submit'
          }
        </Button>

      </Form>
    </Container>
  );
}

export default ProgramPlanning;
