
import React, { useState,useEffect } from 'react';
import { Form, Container, Col, Button, Row, Alert } from 'react-bootstrap';
import './../../styles/Report.css';
import './../../styles/App.css';
import ReportGrid from './ReportGrid';
import ReportDataService from "../services/report.service";
import ReportGenYearDropdown from './ReportGenYearDropdown';
import VillageDetailsDropdown from './VillageDetailsDropdown';

function SanginiKPIReport() {


  //Static Data Attributes
  const [staticFormData, setStaticFormData] = useState(
    {
        reportYear: '',
        villageCode:[],
        sanginiLevel:[]
    }
  );

  const [reportYear, setReportYear] = useState([]);
  const [villageDetails, setVillageDetails] = useState([]);
  
  const [sanginiLevels, setSanginiLevels] = useState([]);

  const handleVillageChange = async (e) => {
    staticFormData.villageCode=[];
    let arrVillage=[];
    var i;
    let village_id = e.target.options[e.target.selectedIndex].getAttribute('data_id');
    for (i=0; i<e.target.length; i++) { 
        //alert(count);
        if (e.target.options[i].selected) {
          //alert(e.target.options[i].getAttribute('data_id'));
          arrVillage.push(e.target.options[i].getAttribute('data_id'));
          //alert(e.target.options[i].value);
            
        } 
    }
    console.log("arrVillage",arrVillage);
    setStaticFormData({
      ...staticFormData,
      villageCode : [...staticFormData.villageCode,arrVillage]
      
    }) 
    
  }

  const handleSanginiLevelChange = async (e) => {
    var i;
    let arrSanginiLevels=[];
    staticFormData.sanginiLevel=[];
    for (i=0; i<e.target.length; i++) { 
        //alert(count);
        if (e.target.options[i].selected) {
          arrSanginiLevels.push(e.target.options[i].value);
           
        } 
    } 

    setStaticFormData({
      ...staticFormData,
      sanginiLevel: [...staticFormData.sanginiLevel,arrSanginiLevels]
    })
    
  }

  const handleStaticDataChange = (e) => {
    const { name, value } = e.target;
    setStaticFormData({
      ...staticFormData,
      [name]: value
    });
    // console.log("Static data", staticFormData);
  };



  const handleReportYearChange = async (e) => {
    let report_val = e.target.options[e.target.selectedIndex].value;
    setStaticFormData({
      ...staticFormData,
      reportYear: report_val
    })
  }
  


  //Error Handling and Validations
  const [errors, setErrors] = useState([]);

  const [message, setMessage] = useState('');



  const validateStaticData = () => {
    let validMsg = "";

    if (!staticFormData.reportYear || staticFormData.reportYear==='') {
      let error = 'Please Select year to View Report';
      setErrors({
        ...errors, error
      })
      //console.log(error);
      validMsg = error;
    }



      if (staticFormData.reportYear ||  staticFormData.reportYear) {
        let error = ""
        //console.log(error);
        validMsg = error;
      }
      console.log("validMsg",validMsg);
    return validMsg;
  };

  const validateGenStaticData = () => {
    let validMsg = "";


    if (!staticFormData.reportYear || staticFormData.reportYear==='') {
      let error = 'Please Select year to View Report';
      setErrors({
        ...errors, error
      })
      //console.log(error);
      validMsg = error;
    }


    if (staticFormData.reportYear ||  staticFormData.reportYear) {
      let error = ""
      //console.log(error);
      validMsg = error;
    }
      console.log("validMsg",validMsg);
    return validMsg;
  };



  const [rowData, setRowData] = useState([]);

  const [colDefs, setColDefs] = useState([]);


  useEffect(() => {
    const fetchRows = async () => {
        var dynamicFilters="";
       
        //ReportDataService.getPlannedImpReport(dynamicFilters)
        //.then(function (response) {
           // console.log("recieved data")
           // console.log(response.data)
            //setRowData(response.data);
            
            
          //})
          //.catch(e => {
          //  console.log(e);
          //})
      
    };
    fetchRows();
  }, []);

  const reset=()=> {
    //alert("inside reset");
    document.getElementById("report-form").reset();
    setMessage('');
    setStaticFormData({
        ...staticFormData,
        reportYear: "",
        villageCode:[],
        sanginiLevel:[]
      })
      window.location.reload();
    
  }

  const generateReport=()=>{
    let noDataRow=[];
    setRowData(noDataRow);
    let finalValidMsg=validateGenStaticData();
    //console.log("final")
   
    if(finalValidMsg==="")
    {
    setMessage("");
    
    

    staticFormData.villageCode.map( function(obj){
      if(obj == ''){
          staticFormData.villageCode=[];
      }
  });



  let dynamicReportYear=staticFormData.reportYear===''?'NULL':staticFormData.reportYear;
  let dynamicVillageId=staticFormData.villageCode.length===0?'NULL':staticFormData.villageCode;
  //let dynamicSanginiLevel=staticFormData.sanginiLevel.length===0?'NULL':staticFormData.sanginiLevel;

  var dynamicFilters="'"+dynamicReportYear+"'"+"|"+"'"+dynamicVillageId+"'";
  
  console.log("dynamicFilters:"+dynamicFilters);
    
    handleSaginiReport();

    ReportDataService.getSanginiKPIReport(dynamicFilters)
    .then(function (response) {
        console.log(response.data)
        if(response.data===null || response.data==='')
        {
            console.log("No data");

        //   ReportDataService.putSanginiKPIReport(dynamicFilters)
        //   .then(response => {
        //     console.log("Sangini KPI Report Generated");
        //     setMessage('Validation Success: Report Generated Successfully');
        //   })
        //   .catch(e => {
        //     console.log(e);
        //   });
        }
        else
        {
          console.log("data Exist");
        setRowData(response.data);
         // setMessage('Validation Success: Report Already Generated');
        }
        })
        .catch(e => {
          console.log(e);
        })
    
}
else
{
    
    setMessage('Validation Failed: '+ finalValidMsg);
}

  }

  

  const handleSubmit = async (e) => {
    let finalValidMsg=validateStaticData();
    //console.log("final")
    e.preventDefault();

    if(finalValidMsg==="")
    {
    setMessage("");
    

    staticFormData.villageCode.map( function(obj){
      if(obj == ''){
          staticFormData.villageCode=[];
      }
  });

  let dynamicVillageId=staticFormData.villageCode.length===0?'NULL':staticFormData.villageCode;
    let dynamicReportYear=staticFormData.reportYear===''?'NULL':staticFormData.reportYear;
    //let dynamicSanginiLevel=staticFormData.sanginiLevel.length===0?'NULL':staticFormData.sanginiLevel;

    var dynamicFilters="'"+dynamicReportYear+"'"+"|"+"'"+dynamicVillageId+"'";
    console.log("dynamicFilters:"+dynamicFilters);
    
    handleSaginiReport();
    
}
else
{
    setMessage('Validation Failed: '+ finalValidMsg);
}

  };


  const handleSaginiReport = () => { 
    let reportColumns=[
        { headerName: "Sangini Name",field: "name" ,width: "200px",tooltipField: "name",headerTooltip: "Sangini Name"},
        { headerName: "Village",field: "village_name" ,width: "200px",tooltipField: "village_name",headerTooltip: "Village"},
        { headerName: "Block",field: "block" ,width: "200px",tooltipField: "block",headerTooltip: "Block"},
        { headerName: "Sales Target %",field: "sales_achieve_per" ,width: "200px",tooltipField: "sales_achieve_per",headerTooltip: "Sales Target %"},
        { headerName: "Village Target %", field: "village_target_per" ,width: "200px",tooltipField: "village_target_per",headerTooltip: "Village Target %"},
        { headerName: "No. of users target %",field: "no_of_user_per" ,width: "200px",tooltipField: "no_of_user_per",headerTooltip: "No. of users target %"},
        { headerName: "User Education %",field: "user_edu_per" ,width: "200px",tooltipField: "user_edu_per",headerTooltip: "User Education %"},
        { headerName: "% of user pads sold in network",field: "user_sales_in_network" ,width: "200px",tooltipField: "user_sales_in_network",headerTooltip: "% of user pads sold in network"},
        { headerName: "Product knowledge assessment",field: "product_knowledge_score_flag" ,width: "200px",tooltipField: "product_knowledge_score_flag",headerTooltip: "Product knowledge assessment"},
        { headerName: "Avg Customer Satisfaction Rating",field: "rating" ,width: "200px",tooltipField: "rating",headerTooltip: "Avg Customer Satisfaction Rating"},
        { headerName: "Marketing %",field: "marketing_per" ,width: "200px",tooltipField: "marketing_per",headerTooltip: "Marketing %"},
        { headerName: "Product Return Rate %",field: "returned_per" ,width: "200px",tooltipField: "returned_per",headerTooltip: "Product Return Rate %"},
        { headerName: "Reaching more than 5 schools & communities",field: "establish_partnership_flag" ,width: "200px",tooltipField: "establish_partnership_flag",headerTooltip: "Reaching more than 5 schools & communities"},
  ];
  setColDefs(reportColumns);
}

  return (

    <Container className='report-page'>
      <Form id='report-form' onSubmit={handleSubmit} >
      
        {
          message && <Alert variant="info">{message}</Alert>
        }
        
        <Container className='input-grid'>
        <Container className='input-field-multiple'>
          <Form.Group id="header-text" className='header-input-field-single-row input-field-backgroud'>
              <Form.Label className='report-header'>Sangini Performance Report</Form.Label>
            
            </Form.Group>

          </Container>

          <Container className='input-field-multiple'>
          

          <Form.Group id="report-year" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Report Year:</Form.Label>
              <ReportGenYearDropdown
                name="reportYear"
                onChange={handleReportYearChange}
                reportYear={reportYear}
                setReportYear={setReportYear}
              />
            </Form.Group>

          
            <Form.Group id="village-code" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Village Code/Name:</Form.Label>
              <VillageDetailsDropdown
                name="villageCode"
                onChange={handleVillageChange}
                villageDetails={villageDetails}
                setVillageDetails={setVillageDetails}
              />
            </Form.Group>
            {/* <Form.Group id="sangini-level" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Sangini Level:</Form.Label>
              <SanginiLevelDropdown
                name="sanginiLevel"
                onChange={handleSanginiLevelChange}
                villageBlocks={villageBlocks}
                setSanginiLevels={setSanginiLevels}
              />
            </Form.Group> */}
          </Container>
        </Container>

    <Container>
        <Form.Group id="planned-pgm-status" className='input-field-single-row input-field-backgroud'>
        <Button variant="primary" type="button" className="custom-btn"onClick={() => generateReport()}>View Report</Button>
        <Button type="button" className="custom-btn" onClick={() => reset()}>Reset</Button>
       </Form.Group>
    </Container>
       <ReportGrid rowData={rowData} colDefs={colDefs}/>
      </Form>
    </Container>
    
  );
}

export default SanginiKPIReport;