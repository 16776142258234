
import React, { useState,useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Container, Col, Button, Row, Alert } from 'react-bootstrap';
import axios from 'axios';
import VillageDetailsDropdown from './VillageDetailsDropdown';
import VillageBlockDropdown from './VillageBlockDropdown';
import VillageDistrictDropdown from './VillageDistrictDropdown';
import VillageStateDropdown from './VillageStateDropdown';
import VillageZoneDropdown from './VillageZoneDropdown';
import ReportMonthDropdown from './ReportMonthDropdown';
import ReportGenMonthDropdown from './ReportGenMonthDropdown';
import './../../styles/Report.css';
import './../../styles/App.css';
import ReportGrid from './ReportGrid';
import ReportDataService from "../services/report.service";


function SanginiLevelReport() {

  const { user: currentUser } = useSelector((state) => state.auth);
  const [roleId, setRoleId] = useState(0);
  const [userId, setUserId] = useState(0);

  useEffect(() => {
    if (currentUser) {
      setRoleId(currentUser.role_id);
      setUserId(currentUser.id);
      console.log("currentUser",currentUser);
    }
  }, [currentUser]);


  //Static Data Attributes
  const [staticFormData, setStaticFormData] = useState(
    {
        reportMonth: '',
        reportGenMonth: '',
        villageCode:[],
        villageState:[],
        villageZone:[],
        villageDistrict:[],
        villageBlock:[]
    }
  );

  
  const [villageDetails, setVillageDetails] = useState([]);
  const [villageStates, setVillageStates] = useState([]);
  const [villageZones, setVillageZones] = useState([]);
  const [villageDistricts, setVillageDistricts] = useState([]);
  const [villageBlocks, setVillageBlocks] = useState([]);
  const [reportMonth, setReportMonth] = useState();
  const [reportGenMonth, setReportGenMonth] = useState();
  

  const handleVillageChange = async (e) => {
    let arrVillage=[];
    staticFormData.villageCode=[];
    var i;
    let village_id = e.target.options[e.target.selectedIndex].getAttribute('data_id');
    for (i=0; i<e.target.length; i++) { 
        //alert(count);
        if (e.target.options[i].selected) {
          arrVillage.push(e.target.options[i].getAttribute('data_id'));
           
        } 
    }
    setStaticFormData({
      ...staticFormData,
      villageCode : [...staticFormData.villageCode,arrVillage]
    }) 
    
  }

  const handleDistrictChange = async (e) => {
    let arrDistrict=[];
    staticFormData.villageDistrict=[];
    var i;
    for (i=0; i<e.target.length; i++) { 
        //alert(count);
        if (e.target.options[i].selected) {
          arrDistrict.push(e.target.options[i].value);
            
        } 
    }
    setStaticFormData({
      ...staticFormData,
      villageDistrict : [...staticFormData.villageDistrict,arrDistrict]
    }) 
    
  }

  const handleBlockChange = async (e) => {
    let arrBlock=[];
    staticFormData.villageBlock=[];
    var i;
    for (i=0; i<e.target.length; i++) { 
        //alert(count);
        if (e.target.options[i].selected) {
          arrBlock.push(e.target.options[i].value);
           
        } 
    }
    setStaticFormData({
      ...staticFormData,
      villageBlock: [...staticFormData.villageBlock,arrBlock]
    }) 
    
  }

  const handleStateChange = async (e) => {
    let arrState=[];
    staticFormData.villageState=[];
    var i;
    for (i=0; i<e.target.length; i++) { 
        //alert(count);
        if (e.target.options[i].selected) {
          arrState.push(e.target.options[i].value);
           
        } 
    } 
    setStaticFormData({
      ...staticFormData,
      villageState : [...staticFormData.villageState,arrState]
    })
    
  }

  const handleZoneChange = async (e) => {
    let arrZone=[];
    staticFormData.villageZone=[];
    var i;
    
    for (i=0; i<e.target.length; i++) { 
        //alert(count);
        if (e.target.options[i].selected) {
          arrZone.push(e.target.options[i].value);
           
        } 
    }
    
    setStaticFormData({
      ...staticFormData,
      villageZone : [...staticFormData.villageZone,arrZone]
    })
    
  }


  
  const handleReportMonthChange = async (e) => {
    let report_val = e.target.options[e.target.selectedIndex].value;
    setStaticFormData({
      ...staticFormData,
      reportMonth: report_val
    })
  }

  const handleReportGenMonthChange = async (e) => {
    let report_val = e.target.options[e.target.selectedIndex].value;
    setStaticFormData({
      ...staticFormData,
      reportGenMonth: report_val
    })
    getPreviousMonth(report_val);
  }


  //Error Handling and Validations
  const [errors, setErrors] = useState([]);

  const [message, setMessage] = useState('');



  const validateStaticData = () => {
    let validMsg = "";

    if (!staticFormData.reportMonth || staticFormData.reportMonth==='') {
      let error = 'Please Select Month to View Report';
      setErrors({
        ...errors, error
      })
      //console.log(error);
      validMsg = error;
    }



      if (staticFormData.reportMonth ) {
        let error = ""
        //console.log(error);
        validMsg = error;
      }
      console.log("validMsg",validMsg);
    return validMsg;
  };

  const validateGenStaticData = () => {
    let validMsg = "";

    if (!staticFormData.reportGenMonth || staticFormData.reportGenMonth==='') {
      let error = 'Please Select Month to Generate Report';
      setErrors({
        ...errors, error
      })
      //console.log(error);
      validMsg = error;
    }



      if (staticFormData.reportGenMonth ) {
        let error = ""
        //console.log(error);
        validMsg = error;
      }
      console.log("validMsg",validMsg);
    return validMsg;
  };



  const [rowData, setRowData] = useState([]);

  const [colDefs, setColDefs] = useState([]);

  const [previousMonth, setPreviousMonth] = useState();


  useEffect(() => {
    const fetchRows = async () => {
        var dynamicFilters="";
       
        //ReportDataService.getPlannedImpReport(dynamicFilters)
        //.then(function (response) {
           // console.log("recieved data")
           // console.log(response.data)
            //setRowData(response.data);
            
            
          //})
          //.catch(e => {
          //  console.log(e);
          //})
      
    };
    fetchRows();
  }, []);

  const reset=()=> {
    //alert("inside reset");
    document.getElementById("report-form").reset();
    setMessage('');
    setStaticFormData({
        ...staticFormData,
        reportMonth: "",
        reportGenMonth:"",
        villageCode:[],
        villageState:[],
        villageZone:[],
        villageDistrict:[],
        villageBlock:[]
      })
      window.location.reload();
    
  }

  const generateReport=()=>{

    let finalValidMsg=validateGenStaticData();
    //console.log("final")
   
    if(finalValidMsg==="")
    {
    setMessage("");
    
    
    staticFormData.villageCode.map( function(obj){
      if(obj == ''){
          staticFormData.villageCode=[];
      }
  });

  staticFormData.villageState.map( function(obj){
      if(obj == ''){
          staticFormData.villageState=[];
      }
  });

  staticFormData.villageDistrict.map( function(obj){
      if(obj == ''){
          staticFormData.villageDistrict=[];
      }
  });

  staticFormData.villageZone.map( function(obj){
      if(obj == ''){
          staticFormData.villageZone=[];
      }
  });

  staticFormData.villageBlock.map( function(obj){
      if(obj == ''){
          staticFormData.villageBlock=[];
      }
  });

  let dynamicVillageId=staticFormData.villageCode.length===0?'NULL':staticFormData.villageCode;
  let dynamicVillageState=staticFormData.villageState.length===0?'NULL':staticFormData.villageState;
  let dynamicVillageBlock=staticFormData.villageBlock.length===0?'NULL':staticFormData.villageBlock;
  let dynamicVillageZone=staticFormData.villageZone.length===0?'NULL':staticFormData.villageZone;
  let dynamicVillageDistrict=staticFormData.villageDistrict.length===0?'NULL':staticFormData.villageDistrict;
  let dynamicReportGenMonth=staticFormData.reportGenMonth.length===0?'NULL':staticFormData.reportGenMonth;
  
  var dynamicFilters="'"+dynamicReportGenMonth+"'"+"|"+"'"+dynamicVillageId+"'"+"|"+"'"+dynamicVillageState+"'"+"|"+"'"+dynamicVillageBlock+"'"+"|"+"'"+dynamicVillageZone+"'"+"|"+"'"+dynamicVillageDistrict+"'"+"|"+"'"+userId+"'";
  
  console.log("dynamicFilters:"+dynamicFilters);

  
    handleSaginiReport();

    console.log("previousMonth:"+previousMonth);

    var dynamicPreviousFilters="'"+previousMonth+"'"+"|"+"'"+dynamicVillageId+"'"+"|"+"'"+dynamicVillageState+"'"+"|"+"'"+dynamicVillageBlock+"'"+"|"+"'"+dynamicVillageZone+"'"+"|"+"'"+dynamicVillageDistrict+"'";
  

    ReportDataService.getSanginiLevelReport(dynamicPreviousFilters)
    .then(function (response) {
      console.log(response.data)
      if(response.data===null || response.data==='')
      {
        setMessage('Validation Failed: Please Generate Previous Month Report.');
      }
      else
      {
    ReportDataService.getSanginiLevelReport(dynamicFilters)
    .then(function (response) {
        console.log(response.data)
        if(response.data===null || response.data==='')
        {
            console.log("No Duplicate");

          ReportDataService.putSanginiLevelReport(dynamicFilters)
          .then(response => {
            console.log("Sangini Report Generated");
            setMessage('Validation Success: Report Generated Successfully, Click Refresh or Reset Button to Reflect Generated Month in View Report Dropdown.');
            getSanginiMonthly(dynamicFilters);
          })
          .catch(e => {
            console.log(e);
          });
        }
        else
        {
          console.log("Duplicate Exist");
          setMessage('Validation Success: Report Already Generated');
          getSanginiMonthly(dynamicFilters); 
        }
        })
        .catch(e => {
          console.log(e);
        })
      }
    })
    .catch(e => {
      console.log(e);
    })
    
}
else
{
    
    setMessage('Validation Failed: '+ finalValidMsg);
}

  }

  const getSanginiMonthly=(dynamicFilters)=>{

    ReportDataService.getSanginiLevelReport(dynamicFilters)
    .then(function (response) {
        console.log("recieved data")
        console.log(response.data)
        if(response.data===null || response.data==='')
        {
            console.log("no data found");
            let noDataRow=[];
            setRowData(noDataRow);
        }
        else
        {
            //staticFormData.villageCode=[];
            //staticFormData.villageBlock=[];
            //staticFormData.villageState=[];
            //staticFormData.villageDistrict=[];
            //staticFormData.villageZone=[];
        setRowData(response.data);
        }
        
      })
      .catch(e => {
        console.log(e);
      })
  }

  const handleSubmit = async (e) => {
    let finalValidMsg=validateStaticData();
    //console.log("final")
    e.preventDefault();

    if(finalValidMsg==="")
    {
    setMessage("");
    

    staticFormData.villageCode.map( function(obj){
        if(obj == ''){
            staticFormData.villageCode=[];
        }
    });

    staticFormData.villageState.map( function(obj){
        if(obj == ''){
            staticFormData.villageState=[];
        }
    });

    staticFormData.villageDistrict.map( function(obj){
        if(obj == ''){
            staticFormData.villageDistrict=[];
        }
    });

    staticFormData.villageZone.map( function(obj){
        if(obj == ''){
            staticFormData.villageZone=[];
        }
    });

    staticFormData.villageBlock.map( function(obj){
        if(obj == ''){
            staticFormData.villageBlock=[];
        }
    });

    let dynamicVillageId=staticFormData.villageCode.length===0?'NULL':staticFormData.villageCode;
    let dynamicVillageState=staticFormData.villageState.length===0?'NULL':staticFormData.villageState;
    let dynamicVillageBlock=staticFormData.villageBlock.length===0?'NULL':staticFormData.villageBlock;
    let dynamicVillageZone=staticFormData.villageZone.length===0?'NULL':staticFormData.villageZone;
    let dynamicVillageDistrict=staticFormData.villageDistrict.length===0?'NULL':staticFormData.villageDistrict;
    let dynamicReportMonth=staticFormData.reportMonth.length===0?'NULL':staticFormData.reportMonth;
    
    var dynamicFilters="'"+dynamicReportMonth+"'"+"|"+"'"+dynamicVillageId+"'"+"|"+"'"+dynamicVillageState+"'"+"|"+"'"+dynamicVillageBlock+"'"+"|"+"'"+dynamicVillageZone+"'"+"|"+"'"+dynamicVillageDistrict+"'";
    
    console.log("dynamicFilters:"+dynamicFilters);
    
    handleSaginiReport();
    
    getSanginiMonthly(dynamicFilters);
}
else
{
    setMessage('Validation Failed: '+ finalValidMsg);
}

  };

  const getPreviousMonth=(selMonth)=>{
  let previousMth="";
  let previousYear="";
  let previousFullMth="";

  let arrSelMonth=selMonth.split("-");
  console.log("arrSelMonth",arrSelMonth);
  if(arrSelMonth[1]==="01")
  {
    previousMth="12"
    previousYear=parseInt(arrSelMonth[0])-1;
    console.log("previousYear",previousYear);
  }
  else
  {
    previousMth=parseInt(arrSelMonth[1])-1;
    if(parseInt(previousMth)<10)
    {
      previousMth="0"+previousMth;
    }
    previousYear=arrSelMonth[0];
    console.log("previousMth",previousMth);
  }
  previousFullMth=previousYear+"-"+previousMth;

  console.log("previousFullMth",previousFullMth);

  setPreviousMonth(previousFullMth);

  }

  const handleSaginiReport = () => { 
    let reportColumns=[
        { headerName: "Name",field: "name" ,width: "50px",tooltipField: "name",headerTooltip: "Name"},
        { headerName: "Mobile No",field: "mobile_no" ,width: "50px",tooltipField: "mobile_no",headerTooltip: "Mobile No"},
        { headerName: "Zone",field: "zone" ,width: "50px",tooltipField: "zone",headerTooltip: "Zone"},
        { headerName: "State",field: "state" ,width: "200px",tooltipField: "state",headerTooltip: "State"},
        { headerName: "District",field: "district" ,width: "50px",tooltipField: "district",headerTooltip: "District"},
        { headerName: "Block",field: "block" ,width: "50px",tooltipField: "block",headerTooltip: "Block"},
        { headerName: "Village Name",field: "village" ,width: "50px",tooltipField: "village",headerTooltip: "Village Name"},
        { headerName: "Number of Users",field: "no_of_user" ,width: "50px",tooltipField: "no_of_user",headerTooltip: "Number of Users"},
        { headerName: "Previous Level",field: "previous_level" ,width: "50px",tooltipField: "previous_level",headerTooltip: "Previous Level"},
        { headerName: "Current Level",field: "current_level" ,width: "50px",tooltipField: "current_level",headerTooltip: "Current Level"},
        { headerName: "Proficiency",field: "proficiency" ,width: "50px",tooltipField: "proficiency",headerTooltip: "Proficiency"},
        { headerName: "Sale Number",field: "sale_no" ,width: "50px",tooltipField: "sale_no",headerTooltip: "Sale Number"},
        { headerName: "Level Change",field: "level_change" ,width: "50px",tooltipField: "level_change",headerTooltip: "Level Change"},
  ];
  setColDefs(reportColumns);
}

  return (

    <Container className='report-page'>
      <Form id='report-form' onSubmit={handleSubmit} >
      
        {
          message && <Alert variant="info">{message}</Alert>
        }
        
        <Container className='input-grid'>
        <Container className='input-field-multiple'>
          <Form.Group id="header-text" className='header-input-field-single-row input-field-backgroud'>
              <Form.Label className='report-header'>Sangini Level Change Reports</Form.Label>
            
            </Form.Group>

          </Container>
          <Container className='input-field-multiple'>
            <Form.Group id="reportMonthGenerate" className='input-field-single-column input-field-backgroud'>
            <Form.Label>Generate Report For Month:</Form.Label>
            <ReportGenMonthDropdown
                name="monthGenCode"
                onChange={handleReportGenMonthChange}
                reportGenMonth={reportGenMonth}
                setReportGenMonth={setReportGenMonth}
              />
            </Form.Group>
            <Form.Group id="planned-pgm-status" className='input-field-single-row input-field-backgroud'>
            <Button variant="primary" type="button" className="custom-btn"onClick={() => generateReport()}>Generate & View Report</Button>
            </Form.Group>
          </Container>

          <Container className='input-field-multiple'>
          <Form.Group id="reportMonth" className='input-field-single-column input-field-backgroud'>
            <Form.Label>View Report For Month:</Form.Label>
            <ReportMonthDropdown
                name="monthCode"
                onChange={handleReportMonthChange}
                reportMonth={reportMonth}
                setReportMonth={setReportMonth}
              />
            </Form.Group>
          <Form.Group id="village-code" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Village Code/Name:</Form.Label>
              <VillageDetailsDropdown
                name="villageCode"
                onChange={handleVillageChange}
                villageDetails={villageDetails}
                setVillageDetails={setVillageDetails}
              />
            </Form.Group>
          <Form.Group id="village-block" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Village Block:</Form.Label>
              <VillageBlockDropdown
                name="villageBlock"
                onChange={handleBlockChange}
                villageBlocks={villageBlocks}
                setVillageBlocks={setVillageBlocks}
              />
            </Form.Group>
            <Form.Group id="village-district" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Village District:</Form.Label>
              <VillageDistrictDropdown
                name="villageDistrict"
                onChange={handleDistrictChange}
                villageDistricts={villageDistricts}
                setVillageDistricts={setVillageDistricts}
              />
            </Form.Group>
          </Container>
          <Container className='input-field-multiple'>
            <Form.Group id="village-state" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Village State:</Form.Label>
              <VillageStateDropdown
                name="villageState"
                onChange={handleStateChange}
                villageStates={villageStates}
                setVillageStates={setVillageStates}
              />
            </Form.Group>
            
            <Form.Group id="village-zone" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Village Zone:</Form.Label>
              <VillageZoneDropdown
                name="villageZone"
                onChange={handleZoneChange}
                villageZones={villageZones}
                setVillageZones={setVillageZones}
              />
            </Form.Group>
          </Container>
        </Container>

    <Container>
        <Form.Group id="planned-pgm-status" className='input-field-single-row input-field-backgroud'>
        <Button variant="primary" type="submit" className="custom-btn">View Report</Button>
        <Button type="button" className="custom-btn" onClick={() => reset()}>Reset</Button>
       </Form.Group>
    </Container>
       <ReportGrid rowData={rowData} colDefs={colDefs} isScroll='none' grdWidth='2000px'/>
      </Form>
    </Container>
    
  );
}

export default SanginiLevelReport;