import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../../../styles/forms/programPlanningData/ProgramPlanningData.css';
import SanginiDataService from './../../services/sangini_details.service';

const SanginiDropdown = ({ onChange, value, name, disabled=false, currentUser }) => {
    const [sanginiDetails, setSanginiDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSanginiDetails = async () => {
            try {
                if (currentUser.role_id === 1) {
                    const response = await SanginiDataService.findAllByExecutiveId(currentUser.id);
                    setSanginiDetails(Array.isArray(response.data) ? response.data : []);
                }
                else if (currentUser.role_id === 2) {
                    const response = await SanginiDataService.findAllByManagerId(currentUser.id);
                    setSanginiDetails(Array.isArray(response.data) ? response.data : []);
                }
                else if (currentUser.role_id === 8) {
                    const response = await SanginiDataService.get(currentUser.sangini_id);
                    console.log(response.data);
                    setSanginiDetails([response.data]);
                }
                else {
                    const response = await SanginiDataService.getAll();
                    // Ensure response.data is an array
                    setSanginiDetails(Array.isArray(response.data) ? response.data : []);
                }

            } catch (err) {
                setError('Failed to fetch sanginis');
                console.error('Fetch error:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchSanginiDetails();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <Form.Group className="sanginiDetailsDropdown">
            <Form.Control className='input' as="select" name={name} onChange={onChange} value={value} disabled={disabled}>
                <option value="">Select a sangini</option>
                {sanginiDetails.length > 0 ? (
                    sanginiDetails.map((detail) => (
                        <option key={detail.sangini_id} value={detail.sangini_id}>
                            {detail.name} - {detail.mobileno}
                        </option>
                    ))
                ) : (
                    <option disabled> No sanginis available</option>
                )}
            </Form.Control>
        </Form.Group>
    );
};

export default SanginiDropdown;
