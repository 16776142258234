
import React, { useState,useEffect } from 'react';
import { Form, Container, Col, Button, Row, Alert } from 'react-bootstrap';
import './../../styles/Report.css';
import './../../styles/App.css';
import ReportGrid from './ReportGrid';
import ReportDataService from "../services/report.service";
import ProgramDetailsDropdown from './ProgramDetailsDropdown';

function ProgramDetailsReport() {


  //Static Data Attributes
  const [staticFormData, setStaticFormData] = useState(
    {
        programCode: []
    }
  );


  const [programDetails, setProgramDetails] = useState([]);

  const handleProgramChange = async (e) => {
    let arrProgram=[];
    var i;
    staticFormData.programCode=[];
    for (i=0; i<e.target.length; i++) { 
        //alert(count);
        if (e.target.options[i].selected) {
          arrProgram.push(e.target.options[i].value);
           // alert("inside:",e.target.options[i].value);
            //selectedArray[count] = e.target.options[i].value;
            //setProgramList([...programList, e.target.options[i].value]);
           
        } 
    }
    
    setStaticFormData({
      ...staticFormData,
      //programCode: program_code
      programCode : [...staticFormData.programCode,arrProgram]
    })
   
  }



  


  //Error Handling and Validations
  const [errors, setErrors] = useState([]);

  const [message, setMessage] = useState('');



  const [rowData, setRowData] = useState([]);

  const [colDefs, setColDefs] = useState([]);


  useEffect(() => {
    const fetchRows = async () => {
        var dynamicFilters="";
       
        //ReportDataService.getPlannedImpReport(dynamicFilters)
        //.then(function (response) {
           // console.log("recieved data")
           // console.log(response.data)
            //setRowData(response.data);
            
            
          //})
          //.catch(e => {
          //  console.log(e);
          //})
      
    };
    fetchRows();
  }, []);

  const reset=()=> {
    //alert("inside reset");
    document.getElementById("report-form").reset();
    setMessage('');
    setStaticFormData({
        ...staticFormData,
        programCode: []
      })
      window.location.reload();
    
  }
  const validateGenStaticData = () => {
    let validMsg = "";
console.log("staticFormData.programCode" + staticFormData.programCode.length);
    if ( staticFormData.programCode.length===0) {
        let error = 'Please Select Program code';
      setErrors({
        ...errors, error
      })
      //console.log(error);
      validMsg = error;
    }
      console.log("validMsg",validMsg);
    return validMsg;
  };

  const generateReport=()=>{
    let noDataRow=[];
    setRowData(noDataRow);

    
    
    let finalValidMsg="";
    finalValidMsg=validateGenStaticData();
    //console.log("final")
   
    if(finalValidMsg==="")
    {
    setMessage("");
    
    
    staticFormData.programCode.map( function(obj){
        if(obj == ''){
            staticFormData.programCode=[];
        }
    });
    
    let dynamicProgramCode=staticFormData.programCode.length===0?'NULL':staticFormData.programCode;
    
    var dynamicFilters="'"+dynamicProgramCode+"'";
    
    console.log("dynamicFilters:"+dynamicFilters);
    
    handleProgramDetailsReport();

    ReportDataService.getProgramDetailsReport(dynamicFilters)
    .then(function (response) {
        console.log(response.data)
        if(response.data===null || response.data==='')
        {
            console.log("No data");

        //   ReportDataService.putSanginiKPIReport(dynamicFilters)
        //   .then(response => {
        //     console.log("Sangini KPI Report Generated");
        //     setMessage('Validation Success: Report Generated Successfully');
        //   })
        //   .catch(e => {
        //     console.log(e);
        //   });
        }
        else
        {
          console.log("data Exist");
        setRowData(response.data);
         // setMessage('Validation Success: Report Generated');
        }
        })
        .catch(e => {
          console.log(e);
        })
    
}
else
{
    
    setMessage('Validation Failed: '+ finalValidMsg);
}

  }

  

  const handleSubmit = async (e) => {

    //console.log("final")
    e.preventDefault();

    staticFormData.programCode.map( function(obj){
        if(obj == ''){
            staticFormData.programCode=[];
        }
    });
    
    let dynamicProgramCode=staticFormData.programCode.length===0?'NULL':staticFormData.programCode;
    
    var dynamicFilters="'"+dynamicProgramCode+"'";
    
    console.log("dynamicFilters:"+dynamicFilters);
    
    handleProgramDetailsReport();
    


  };

  const handleProgramDetailsReport = () => { 
    let reportColumns=[
      { headerName: "Date",field: "event_planned_date" ,width: "200px",tooltipField: "event_planned_date",headerTooltip: "Date"},
      { headerName: "Village",field: "village_name" ,width: "200px",tooltipField: "village_name",headerTooltip: "Village"},
      { headerName: "Partner",field: "partner_name" ,width: "200px",tooltipField: "partner_name",headerTooltip: "Partner"},
      { headerName: "Total Beneficiaries",field: "total_beneficiaries" ,width: "200px",tooltipField: "total_beneficiaries",headerTooltip: "Total Beneficiaries"},
      { headerName: "Total Expense",field: "total_expense" ,width: "200px",tooltipField: "total_expense",headerTooltip: "Total Expense"},
      { headerName: "Total Inkind Donation",field: "total_inkind_donation" ,width: "200px",tooltipField: "total_inkind_donation",headerTooltip: "Total Inkind Donation"},
      { headerName: "Partner Expense",field: "partner_expense" ,width: "200px",tooltipField: "partner_expense",headerTooltip: "Partner Expense"},
      { headerName: "Avg Feedback Rating",field: "avg_feedback" ,width: "200px",tooltipField: "avg_feedback",headerTooltip: "Avg Feedback Rating"},
 ];
  setColDefs(reportColumns);
}

  return (

    <Container className='report-page'>
      <Form id='report-form' onSubmit={handleSubmit} >
      
        {
          message && <Alert variant="info">{message}</Alert>
        }
        
        <Container className='input-grid'>
        <Container className='input-field-multiple'>
          <Form.Group id="header-text" className='header-input-field-single-row input-field-backgroud'>
              <Form.Label className='report-header'>Program Details Report</Form.Label>
            
            </Form.Group>

          </Container>

          <Container className='input-field-multiple'>
          <Form.Group id="program-code" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Program Code/Name:</Form.Label>
              <ProgramDetailsDropdown
                name="programCode"
                onChange={handleProgramChange}
                programDetails={programDetails}
                setProgramDetails={setProgramDetails}
              />
            </Form.Group>
          </Container>
        </Container>

    <Container>
        <Form.Group id="planned-pgm-status" className='input-field-single-row input-field-backgroud'>
        <Button variant="primary" type="button" className="custom-btn"onClick={() => generateReport()}>View Report</Button>
        <Button type="button" className="custom-btn" onClick={() => reset()}>Reset</Button>
       </Form.Group>
    </Container>
       <ReportGrid rowData={rowData} colDefs={colDefs}/>
      </Form>
    </Container>
    
  );
}

export default ProgramDetailsReport;