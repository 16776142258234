
import React, { useState,useEffect } from 'react';
import { Form, Container, Col, Button, Row, Alert } from 'react-bootstrap';
import axios from 'axios';
import ReportGenYearDropdown from './ReportGenYearDropdown';
import './../../styles/Report.css';
import './../../styles/App.css';
import ReportGrid from './ReportGrid';
import ReportDataService from "../services/report.service";


function FundTrackingReport() {


  //Static Data Attributes
  const [staticFormData, setStaticFormData] = useState(
    {
        reportYear: ''
    }
  );

  const [reportYear, setReportYear] = useState([]);

  const handleStaticDataChange = (e) => {
    const { name, value } = e.target;
    setStaticFormData({
      ...staticFormData,
      [name]: value
    });
    // console.log("Static data", staticFormData);
  };

  const handleReportYearChange = async (e) => {
    let report_val = e.target.options[e.target.selectedIndex].value;
    setStaticFormData({
      ...staticFormData,
      reportYear: report_val
    })
  }

  


  //Error Handling and Validations
  const [errors, setErrors] = useState([]);

  const [message, setMessage] = useState('');



  const validateStaticData = () => {
    let validMsg = "";

    if (!staticFormData.reportYear || staticFormData.reportYear==='' ) {
      let error = 'Please Select year to View Report';
      setErrors({
        ...errors, error
      })
      //console.log(error);
      validMsg = error;
    }



      if (staticFormData.reportYear ) {
        let error = ""
        //console.log(error);
        validMsg = error;
      }
      console.log("validMsg",validMsg);
    return validMsg;
  };

  const validateGenStaticData = () => {
    let validMsg = "";

    if (!staticFormData.reportYear || staticFormData.reportYear==='' ) {
        let error = 'Please year to View Report';
      setErrors({
        ...errors, error
      })
      //console.log(error);
      validMsg = error;
    }

    if (staticFormData.fromDate ||  staticFormData.toDateDate) {
        let error = ""
        //console.log(error);
        validMsg = error;
      }
      console.log("validMsg",validMsg);
    return validMsg;
  };



  const [rowData, setRowData] = useState([]);

  const [colDefs, setColDefs] = useState([]);


  useEffect(() => {
    const fetchRows = async () => {
        var dynamicFilters="";
       
        //ReportDataService.getPlannedImpReport(dynamicFilters)
        //.then(function (response) {
           // console.log("recieved data")
           // console.log(response.data)
            //setRowData(response.data);
            
            
          //})
          //.catch(e => {
          //  console.log(e);
          //})
      
    };
    fetchRows();
  }, []);

  const reset=()=> {
    //alert("inside reset");
    document.getElementById("report-form").reset();
    setMessage('');
    setStaticFormData({
        ...staticFormData,
        reportYear: ""
      })
      window.location.reload();
    
  }

  const generateReport=()=>{

    let finalValidMsg=validateGenStaticData();
    //console.log("final")
   
    if(finalValidMsg==="")
    {
    setMessage("");
    
    





  let dynamicReportYear=staticFormData.reportYear===''?'NULL':staticFormData.reportYear;

  
  var dynamicFilters="'"+dynamicReportYear+"'";
  
  console.log("dynamicFilters:"+dynamicFilters);
    
    handleFundTrackingReport();

    ReportDataService.getFundTrackingReport(dynamicFilters)
    .then(function (response) {
        console.log(response.data)
        if(response.data===null || response.data==='')
        {
            console.log("No data");

        //   ReportDataService.putFundTrackingReport(dynamicFilters)
        //   .then(response => {
        //     console.log("Sangini KPI Report Generated");
        //     setMessage('Validation Success: Report Generated Successfully');
        //   })
        //   .catch(e => {
        //     console.log(e);
        //   });
        }
        else
        {
          console.log("data Exist");
        setRowData(response.data);
         // setMessage('Validation Success: Report Already Generated');
        }
        })
        .catch(e => {
          console.log(e);
        })
    
}
else
{
    
    setMessage('Validation Failed: '+ finalValidMsg);
}

  }

  

  const handleSubmit = async (e) => {
    let finalValidMsg=validateStaticData();
    //console.log("final")
    e.preventDefault();

    if(finalValidMsg==="")
    {
    setMessage("");
    



    let dynamicReportYear=staticFormData.reportYear===''?'NULL':staticFormData.reportYear;

  
    var dynamicFilters="'"+dynamicReportYear+"'";
    
    console.log("dynamicFilters:"+dynamicFilters);
      
      handleFundTrackingReport();

    
}
else
{
    setMessage('Validation Failed: '+ finalValidMsg);
}

  };


  const handleFundTrackingReport = () => { 
    let reportColumns=[
      { headerName: "Partner Name",field: "partner_name" ,width: "200px",tooltipField: "partner_name",headerTooltip: "Partner Name"},
      { headerName: "Partner Code",field: "partner_code" ,width: "200px",tooltipField: "partner_code",headerTooltip: "Partner Code"},
      { headerName: "Q1",width: "1500px", children: 
        [   { headerName: "Opening Balance",field: "Q1_opening_balance" ,width: "500px",tooltipField: "Q1_opening_balance",headerTooltip: "Q1 Opening Balance"},
        { headerName: "Funds Received",field: "Q1_funds_received" ,width: "500px",tooltipField: "Q1_funds_received",headerTooltip: "Q1_funds_received"},
        { headerName: "Total Available Funds", field: "Q1_total_available_fund" ,width: "500px",tooltipField: "Q1_total_available_fund",headerTooltip: "Q1_total_available_fund"},
        { headerName: "Total Expenses",field: "Q1_expenses" ,width: "500px",tooltipField: "Q1_expenses",headerTooltip: "Q1_expenses"},
        { headerName: "Remaining Funds",field: "Q1_remaining" ,width: "500px",tooltipField: "Q1_remaining",headerTooltip: "Q1_remaining"},] },
        { headerName: "Q2",width: "1500px", children: [  
           { headerName: "Opening Balance",field: "Q2_opening_balance" ,width: "200px",tooltipField: "Q2_opening_balance",headerTooltip: "Q2 Opening Balance"},
          { headerName: "Funds Received",field: "Q2_funds_received" ,width: "500px",tooltipField: "Q2_funds_received",headerTooltip: "Q2_funds_received"},
          { headerName: "Total Available Funds", field: "Q2_total_available_fund" ,width: "200px",tooltipField: "Q2_total_available_fund",headerTooltip: "Q2_total_available_fund"},
          { headerName: "Total Expenses",field: "Q2_expenses" ,width: "200px",tooltipField: "Q2_expenses",headerTooltip: "Q2_expenses"},
          { headerName: "Remaining Funds",field: "Q2_remaining" ,width: "200px",tooltipField: "Q2_remaining",headerTooltip: "Q2_remaining"},] },
        { headerName: "Q3",width: "1500px", children: [   
          { headerName: "Opening Balance",field: "Q3_opening_balance" ,width: "200px",tooltipField: "Q3_opening_balance",headerTooltip: "Q3 Opening Balance"},
            { headerName: "Funds Received",field: "Q3_funds_received" ,width: "200px",tooltipField: "Q3_funds_received",headerTooltip: "Q3_funds_received"},
            { headerName: "Total Available Funds", field: "Q3_total_available_fund" ,width: "200px",tooltipField: "Q3_total_available_fund",headerTooltip: "Q3_total_available_fund"},
            { headerName: "Total Expenses",field: "Q3_expenses" ,width: "200px",tooltipField: "Q3_expenses",headerTooltip: "Q3_expenses"},
            { headerName: "Remaining Funds",field: "Q3_remaining" ,width: "200px",tooltipField: "Q3_remaining",headerTooltip: "Q3_remaining"},] },
        { headerName: "Q4",width: "1500px", children: [   
          { headerName: "Opening Balance",field: "Q4_opening_balance" ,width: "200px",tooltipField: "Q4_opening_balance",headerTooltip: "Q4 Opening Balance"},
              { headerName: "Funds Received",field: "Q4_funds_received" ,width: "200px",tooltipField: "Q4_funds_received",headerTooltip: "Q4_funds_received"},
              { headerName: "Total Available Funds", field: "Q4_total_available_fund" ,width: "200px",tooltipField: "Q4_total_available_fund",headerTooltip: "Q4_total_available_fund"},
              { headerName: "Total Expenses",field: "Q4_expenses" ,width: "200px",tooltipField: "Q4_expenses",headerTooltip: "Q4_expenses"},
              { headerName: "Remaining Funds",field: "Q4_remaining" ,width: "200px",tooltipField: "Q4_remaining",headerTooltip: "Q4_remaining"},] },
  ];
  setColDefs(reportColumns);
}

  return (

    <Container className='report-page'>
      <Form id='report-form' onSubmit={handleSubmit} >
      
        {
          message && <Alert variant="info">{message}</Alert>
        }
        
        <Container className='input-grid'>
        <Container className='input-field-multiple'>
          <Form.Group id="header-text" className='header-input-field-single-row input-field-backgroud'>
              <Form.Label className='report-header'>Fund Tracking Reports</Form.Label>
            
            </Form.Group>

          </Container>

          <Container className='input-field-multiple'>
          <Form.Group id="report-year" className='input-field-single-column input-field-backgroud'>
              <Form.Label>Report Year:</Form.Label>
              <ReportGenYearDropdown
                name="reportYear"
                onChange={handleReportYearChange}
                reportYear={reportYear}
                setReportYear={setReportYear}
              />
            </Form.Group>

          </Container>
        </Container>

    <Container>
        <Form.Group id="planned-pgm-status" className='input-field-single-row input-field-backgroud'>
        <Button variant="primary" type="button" className="custom-btn"onClick={() => generateReport()}>View Report</Button>
        <Button type="button" className="custom-btn" onClick={() => reset()}>Reset</Button>
       </Form.Group>
    </Container>
       <ReportGrid rowData={rowData} colDefs={colDefs}/>
      </Form>
    </Container>
    
  );
}

export default FundTrackingReport;