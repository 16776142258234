import { useCallback, useState, useRef,useEffect } from 'react';

import { useSelector } from 'react-redux';
import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import Navbar from './Navbar/Navbar';
import PartnerDataService from "./services/partner.service";
import './../styles/Master.css';
import * as Constant from './constant';

export const partnerMaster = {
    pages: [
    {
      title:  "Partner Master",
      elements: [{ 
        name: "partner_name",
        title: "Partner Name",
        type: "text",
        isRequired: true
      },
      { 
        name: "partner_code",
        title: "Partner Code",
        type: "text",
        isRequired: true
      },
      { 
        name: "village_id",
        title: "Village",
        type: "tagbox",
        isRequired: true,
        choicesByUrl: {
          url:`${Constant.API_URL}/villages/villageIdName`,
          valueName: "village_id",
          titleName:"village_name"
        }
      },
      { 
        name: "fcra_no",
        title: "FCRA Number",
        type: "text",
        inputType: "number",
        isRequired: true
      },
      { 
        name: "bank_name_branch",
        title: "Bank Name and branch",
        type: "text",
        isRequired: true
      },{ 
        name: "account_name",
        title: "Account Name",
        type: "text",
        isRequired: true
      },
      { 
        name: "account_number",
        title: "Account Number",
        type: "text",
        inputType: "number",
        isRequired: true
      },{ 
        name: "ifsc_code",
        title: "IFSC Code",
        type: "text",
        isRequired: true
      },{ 
        name: "swift_code",
        title: "Swift Code",
        type: "text",
        isRequired: true
      },{ 
        name: "program_executive_name",
        title: "Program Executive Name",
        type: "dropdown",
        isRequired: true,
        choicesByUrl: {
          url:`${Constant.API_URL}/villages/findExecutive`,
          valueName: "executive"
        }
      },{ 
        name: "program_manager_name",
        title: "Program Manager Name",
        type: "dropdown",
        isRequired: true,
        choicesByUrl: {
          url: `${Constant.API_URL}/villages/findManager?executive={program_executive_name}`,
          valueName: "manager"
        }
      },{ 
        name: "remarks",
        title: "Remarks",
        type: "comment",
        isRequired: false
      },
      { 
        name: "updated_by_id",
        title: "UserId",
        type: "text",
        inputType: "text",
        visible:"false",
        clearInvisibleValues:"false"
      },
    ]
    }]
  ,
  showQuestionNumbers: "off",
  pageNextText: false,
  completeText: "Submit",
  showPrevButton: false,
  firstPageIsStarted: false,
  showPreviewBeforeComplete:false,
  // startSurveyText: "Take the Survey",
  completedHtml: "Thank you for submitting Partner Master!"
  };

  const customCss = {
    "question": {
        "content": "report-header",
        "answered": "report-header",
        "titleRequired": "report-header"
    },
    "navigation": {
      "complete": "sv-btn sv-footer__next-btn my-button"
    }
};

  function PartnerMaster() {
    // useRef enables the Model object to persist between state changes
    const survey = useRef(new Model(partnerMaster)).current;
    const [surveyResults, setSurveyResults] = useState("");
    const [isSurveyCompleted, setIsSurveyCompleted] = useState(false);

    const { user: currentUser } = useSelector((state) => state.auth);
    const [roleId, setRoleId] = useState(0);
    const [userId, setUserId] = useState(0);

    useEffect(() => {
      if (currentUser) {
        setRoleId(currentUser.role_id);
        setUserId(currentUser.id);
        console.log("currentUser",currentUser);
      }
    }, [currentUser]);
  
    const displayResults = useCallback((sender) => {
      setSurveyResults(JSON.stringify(sender.data, null, 4));
      const results = JSON.stringify(sender.data);
      console.log("results",results);
      //alert(results);
     PartnerDataService.create(results)
      .then(response => {
        console.log(response.data);
        PartnerDataService.updateresult(results)
      .then(response => {
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
      })
      .catch(e => {
        console.log(e);
      });
      setIsSurveyCompleted(true);
    }, []);

    survey.css = customCss;
    survey.clearInvisibleValues=false;
    survey.data = {updated_by_id:userId};
    survey.onValueChanged.add((sender, options) => {
      const el = document.getElementById(options.updated_by_id);
      if (el) {
          el.value = options.value;
      }
  });
    survey.onServerValidateQuestions.add(ValidateDuplicates);
    survey.onComplete.add(displayResults);

    survey.onUpdateQuestionCssClasses
    .add(function (survey, options) {
        var classes = options.cssClasses

        classes.mainRoot += " sv_qstn";
        classes.root = "sq-root";
        classes.title += " sq-title"
        classes.item += " sq-item";
        classes.label += " sq-label";


        if (options.question.isRequired) {
            classes.title += " sq-title-required";
            classes.root += " sq-root-required";
        }
        if(!options.question.isEmpty()) {
            classes.root += " sv_answered";
        }
        if (options.question.getType() === "checkbox") {
            classes.root += " sq-root-cb";
        }
    });
  
    return (
      <div>
        <Survey model={survey} id="surveyContainer" />
  
      </div>
    );
  }

  function ValidateDuplicates(_, { data, errors, complete }) {
    const partnerCode = data["partner_code"];
    if (!partnerCode) {
      complete();
      return;
    }
    PartnerDataService.findByCode(partnerCode)
      .then((response) => {
        console.log(response.data);
        if(response.data.length>0)
        {
          errors["partner_code"] = "Partner Code already Exist";
        }
        complete();
      })
      .catch(e => {
        console.log(e);
      })
  }
  
  export default PartnerMaster;