import { useCallback, useState, useRef,useEffect } from 'react';

import { useSelector } from 'react-redux';

import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import Navbar from './Navbar/Navbar';
import FundReleasedDataService from "./services/fundReleased.service";
import VillageDataService from "./services/village.service";
import './../styles/Master.css';
import * as Constant from './constant';

export const fundReleasedMaster = {
    pages: [
    {
      title:  "Fund Released Master",
      elements: [{ 
        name: "partner_code",
        title: "Partner",
        type: "dropdown",
        isRequired: true,
        choicesByUrl: {
          url: `${Constant.API_URL}/partners`,
          valueName: "partner_code",
          titleName: "partner_name",
        }
      },
      { 
        name: "funds_transferred",
        title: "Funds Transferred",
        type: "text",
        inputType: "number",
        isRequired: true
      },
      { 
        name: "date_transferred",
        title: "Date Transferred",
        type: "text",
        inputType: "date",
        isRequired: true
      },
      { 
        name: "remarks",
        title: "Remarks",
        type: "comment",
        isRequired: false
      },
      { 
        name: "updated_by_id",
        title: "UserId",
        type: "text",
        inputType: "text",
        visible:"false",
        clearInvisibleValues:"false"
      },
    ]
    }]
  ,
  showQuestionNumbers: "off",
  pageNextText: false,
  completeText: "Submit",
  showPrevButton: false,
  firstPageIsStarted: false,
  showPreviewBeforeComplete:false,
  // startSurveyText: "Take the Survey",
  completedHtml: "Thank you for submitting Fund released records!"
  };

  const customCss = {
    "question": {
        "content": "report-header",
        "answered": "report-header",
        "titleRequired": "report-header"
    },
    "navigation": {
      "complete": "sv-btn sv-footer__next-btn my-button"
    }
};

  function FundReleasedMaster() {
    // useRef enables the Model object to persist between state changes
    const survey = useRef(new Model(fundReleasedMaster)).current;
    const [surveyResults, setSurveyResults] = useState("");
    const [isSurveyCompleted, setIsSurveyCompleted] = useState(false);

    const { user: currentUser } = useSelector((state) => state.auth);
    const [roleId, setRoleId] = useState(0);
    const [userId, setUserId] = useState(0);

    useEffect(() => {
      if (currentUser) {
        setRoleId(currentUser.role_id);
        setUserId(currentUser.id);
        console.log("currentUser",currentUser);
      }
    }, [currentUser]);
  
    const displayResults = useCallback((sender) => {
      setSurveyResults(JSON.stringify(sender.data, null, 4));
      const results = JSON.stringify(sender.data);
      
      console.log("results",results);
      
      FundReleasedDataService.create(results)
          .then(response => {
          console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });

          setIsSurveyCompleted(true);
       
     
    }, []);

    survey.css = customCss;
    survey.clearInvisibleValues=false;
    survey.data = {updated_by_id:userId};
    survey.onValueChanged.add((sender, options) => {
      const el = document.getElementById(options.updated_by_id);
      if (el) {
          el.value = options.value;
      }
  });


    survey.onComplete.add(displayResults);

   
    

    survey.onUpdateQuestionCssClasses
    .add(function (survey, options) {
        var classes = options.cssClasses

        classes.mainRoot += " sv_qstn";
        classes.root = "sq-root";
        classes.title += " sq-title"
        classes.item += " sq-item";
        classes.label += " sq-label";


        if (options.question.isRequired) {
            classes.title += " sq-title-required";
            classes.root += " sq-root-required";
        }
        if(!options.question.isEmpty()) {
            classes.root += " sv_answered";
        }
        if (options.question.getType() === "dropdown") {
            classes.root += " sq-dropdwn";
        }
    });
  
    return (
      <div>
        
        <Survey model={survey} id="surveyContainer" />
  
      </div>
    );
  }

  

  
  export default FundReleasedMaster;