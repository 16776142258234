import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../../styles/forms/programPlanningData/ProgramPlanningData.css'
import './../../styles/App.css';
import PartnerDataService from "../services/partner.service";

const PartnerDetailsDropdown = ({ onChange,partnerDetails, setPartnerDetails, name }) => {
    //const [partnerDetails, setPartnerDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPartnerDetails = async () => {
            try {
                //const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/partnerDetails`);
                PartnerDataService.getAll()
                .then((response) => {
                    console.log(response.data);
                    setPartnerDetails(response.data);
                })
                .catch(e => {
                    console.log(e);
                })
            } catch (err) {
                setError('Failed to fetch partner details');
            } finally {
                setLoading(false);
            }
        };

        fetchPartnerDetails();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <Form.Group className="partnerDetailsDropdown">
            <Form.Control className='input' as="select" name={name} onChange={onChange} multiple={true}>
                <option value="">Select All Partner Code</option>
                {partnerDetails.map((detail) => (
                    <option key={detail.partner_id} value={detail.partner_code}>
                        {detail.partner_code} : {detail.partner_name}
                    </option>
                ))}
            </Form.Control>
        </Form.Group>
    );
};

export default PartnerDetailsDropdown;