import http from "../../http-common";

class EventPlanningDataService {

  getAll(fromDate, toDate, eventCategory, programCode, partnerCode, villageId, currentPage, pageSize, allowedPartners) {
    return http.get("/event_planning_data", {
      params: {
        fromDate,
        toDate,
        event_category: eventCategory,
        program_code: programCode,
        partner_code: partnerCode,
        village_id: villageId,
        page: currentPage,
        pageSize,
        allowedPartners
      }
    });
  }


  get(id) {
    // console.log(id);
    return http.get(`/event_planning_data/${id}`);
  }

  create(data) {
    return http.post("/event_planning_data", data);
  }

  update(id, data) {
    return http.put(`/event_planning_data/${id}`, data);
  }

  delete(id) {
    return http.delete(`/event_planning_data/${id}`);
  }

}

export default new EventPlanningDataService();