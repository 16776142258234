import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../../../styles/forms/programPlanningData/ProgramPlanningData.css';
import PartnerDataService from './../../services/partner.service.js';
import VillageDataService from './../../services/village.service.js';

const VillageDetailsDropdown = ({ onChange, value, name, disabled, roleId, userId, selectedVillageIds, partnerCode }) => {
    const [villageDetails, setVillageDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    const filterIds = selectedVillageIds || [];

    useEffect(() => {
        const fetchVillageDetails = async () => {
            try {
                let response;
                const map = new Map();

                if (roleId === 1) {
                    response = await PartnerDataService.getExecutivePartnerDetails(userId);
                    console.log(response.data);
                    response.data.forEach((i) => {
                        map.set(i.partner_code, i.village_id ? i.village_id.split(',') : []);
                    });                    
                } else if (roleId === 2) {
                    response = await PartnerDataService.getManagerPartnerDetails(userId);
                    response.data.forEach((i) => {
                        map.set(i.partner_code, i.village_id ? i.village_id.split(',') : []);
                    });                    
                } else {
                    response = await VillageDataService.getAll();
                    setVillageDetails(Array.isArray(response.data) ? response.data : []);
                    return;
                }

                // Get village IDs for the selected partnerCode
                const villageIds = map.get(partnerCode) || Array.from(new Set(Array.from(map.values()).flat())); // default to all ids for all partnerCodes 

                console.log(villageIds);
                // Fetch details for the selected village IDs
                const villagePromises = villageIds.map(async (id) => {
                    const villageResponse = await VillageDataService.get(id);
                    return villageResponse.data;
                });

                const villages = await Promise.all(villagePromises);
                setVillageDetails(villages);
                console.log(villageIds)

            } catch (err) {
                setError('Failed to fetch villages');
                console.error('Fetch error:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchVillageDetails();
    }, [roleId, userId, partnerCode]); // Added dependencies for useEffect

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    const getAvailableVillages = () => {
        // Include the currently selected village to be available for display
        return villageDetails.filter(detail =>
            !filterIds.includes(String(detail.village_id)) ||
            String(detail.village_id) === value // Ensure selected value remains available
        );
    };

    const availableVillages = getAvailableVillages();

    return (
        <Form.Group className="villageDetailsDropdown">
            <Form.Control
                className='input'
                as="select"
                name={name}
                onChange={onChange}
                value={value}
                disabled={disabled}
            >
                <option value="">Select a village</option>
                {
                    availableVillages.length > 0 ? (
                        availableVillages.map((detail) => (
                            <option key={detail.village_id} value={detail.village_id}>
                                {detail.village_name} - {detail.block} - {detail.district} - {detail.state}
                            </option>
                        ))
                    ) : (
                        <option disabled>No villages available</option>
                    )
                }
            </Form.Control>
        </Form.Group>
    );
};

export default VillageDetailsDropdown;
